import styled, { css } from "styled-components";

const sizes = {
  small: {
    height: "60",
    fontSize: "18",
    borderRadius: "6",
    fontWeight: 400,
  },
};

const buttonTypes = {
  white: {
    backgroundColor: "transparent",
    fontColor: "var(--gray03)",
  },
  black: {
    backgroundColor: "var(--black)",
    fontColor: "#fff",
  },
  gray01: {
    backgroundColor: "var(--gray01)",
    fontColor: "var(--gray03)",
  },
  gray04: {
    backgroundColor: "var(--gray04)",
    fontColor: "var(--black)",
  },
};

//변수를 size 형태로 받아서 분기 처리가 가능합니다.
const sizeStyles = css`
  ${({ size }) => css`
    height: ${sizes[size] && sizes[size].height
      ? sizes[size].height + "px"
      : "75px"};
    font-size: ${sizes[size] && sizes[size].fontSize
      ? sizes[size].fontSize + "px"
      : "20px"};
    font-weight: ${sizes[size] && sizes[size].fontWeight
      ? sizes[size].fontWeight
      : "500"};
    border-radius: ${sizes[size] && sizes[size].borderRadius
      ? sizes[size].borderRadius + "px"
      : "10px"};

    @media (max-width: 499px) {
      height: ${sizes[size] && sizes[size].height
        ? "calc(100vw * (" + sizes[size].height + "/ 500))"
        : "calc(100vw * (75 / 500))"};
      font-size: ${sizes[size] && sizes[size].fontSize
        ? "calc(100vw * (" + sizes[size].fontSize + "/ 500))"
        : "calc(100vw * (20 / 500))"};
      border-radius: ${sizes[size] && sizes[size].borderRadius
        ? "calc(100vw * (" + sizes[size].borderRadius + "/ 500))"
        : "calc(100vw * (10 / 500))"};
    }
  `}
`;

const buttonTypeStyles = css`
  ${({ buttonType }) => css`
    background-color: ${buttonTypes[buttonType] &&
    buttonTypes[buttonType].backgroundColor
      ? buttonTypes[buttonType].backgroundColor
      : "var(--main)"};
    color: ${buttonTypes[buttonType] && buttonTypes[buttonType].fontColor
      ? buttonTypes[buttonType].fontColor
      : "#fff"};
  `}
`;

const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${sizeStyles}
  ${buttonTypeStyles}

  &:disabled {
    color: var(--gray03);
    background-color: var(--gray01);
  }

  &.iconButton {
    justify-content: space-between;
    padding: 0 25px;
  }

  & .iconWrap {
    display: inline-block;
    width: 23px;
    height: 23px;
  }

  @media (max-width: 499px) {
    &.iconButton {
      padding: 0 calc(100vw * (25 / 500));
    }

    & .iconWrap {
      width: calc(100vw * (23 / 500));
      height: calc(100vw * (23 / 500));
    }
  }
`;

export { Container };

export default {
  Container,
};
