import Styles from "./styles";

function GuageContainer({ check, setCheck, name, disabled, id }) {
  const temperatureList = [
    {
      id: "35.5",
      min: 0.01,
    },
    {
      id: "36.0",
      min: 35.51,
    },
    {
      id: "36.5",
      min: 36.01,
    },
    {
      id: "37.0",
      min: 36.51,
    },
    {
      id: "37.5",
      min: 37.01,
    },
    {
      id: "38.0",
      min: 37.51,
    },
  ];

  return (
    <Styles.Container>
      <ul>
        {temperatureList?.map((item, index) => {
          const itemId = `${id}_${item.id}`;

          return (
            <li
              className={
                (parseFloat(check) >= item.min &&
                parseFloat(check) <= parseFloat(item.id)
                  ? "on"
                  : check == 0 || check === null ? "nothing" : ""
                )
              }
              key={"temperatureList" + index}
            >
              <input
                type="radio"
                id={itemId}
                name={name}
                checked={
                  parseFloat(check) >= item.min &&
                  parseFloat(check) <= parseFloat(item.id)
                }
                disabled={disabled}
                onChange={(e) => {
                  setCheck(e.target.id.split("_")[1]);
                }}
              />

              <label htmlFor={itemId}>
                <span className="iconWrap"></span>
              </label>
            </li>
          );
        })}
      </ul>
    </Styles.Container>
  );
}

export default GuageContainer;
