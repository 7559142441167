import styled from "styled-components";

// img
import IconEssential from "resources/image/icon/icon_essential.svg";

const Container = styled.div`
  & .guideText {
    margin-bottom: 30px;
    padding: 10px 0;
    font-size: 13px;
    font-weight: 500;
    color: #b7b7d2;
    text-align: center;
    background-color: var(--gray01);
    border-radius: 5px;
  }

  & .listWrap {
    margin-bottom: 60px;

    & .list {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &.speaker .inputWrap {
        gap: 10px;

        & .peopleContainer {
          flex: 1 0;

          &:first-child .iconWrap {
            width: 30px;
          }

          &:last-child .iconWrap {
            width: 40px;
          }
        }
      }

      &.check label {
        align-items: flex-start;

        & .labelText {
          padding-top: 2px;
        }
      }

      & .label {
        display: inline-block;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 500;

        & span {
          position: relative;

          &::after {
            position: absolute;
            top: 50%;
            right: -12px;
            transform: translateY(-50%);
            width: 8px;
            height: 8px;
            background: url(${IconEssential}) no-repeat center / cover;
            content: "";
          }
        }
      }

      & .inputWrap {
        display: flex;
        align-items: center;
        gap: 20px;

        & .selectContainer {
          width: 134px;
        }
      }

      & .addButton {
        flex-shrink: 0;
        width: 104px;
      }

      & .keywordListWrap {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 20px;

        & .keywordList {
          display: flex;
          gap: 10px;
          padding: 8px 15px;
          font-size: 16px;
          font-weight: 500;
          border-radius: 500px;
          background-color: var(--gray01);
          box-shadow: 2px 2px 7px 0 rgba(68, 74, 102, 0.2);

          & .deleteButton {
            width: 19px;
            height: 19px;
          }
        }
      }
    }
  }

  & .buttonContainer {
    position: relative;

    & .errorText {
      position: absolute;
      top: -29px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 16px;
    }

    & button:first-child {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 499px) {
    & .guideText {
      margin-bottom: calc(100vw * (30 / 500));
      padding: calc(100vw * (10 / 500)) 0;
      font-size: calc(100vw * (13 / 500));
      border-radius: calc(100vw * (5 / 500));
    }

    & .listWrap {
      margin-bottom: calc(100vw * (60 / 500));

      & .list {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (20 / 500));
        }

        &.speaker .inputWrap {
          gap: calc(100vw * (10 / 500));

          & .peopleContainer {
            &:first-child .iconWrap {
              width: calc(100vw * (30 / 500));
            }

            &:last-child .iconWrap {
              width: calc(100vw * (40 / 500));
            }
          }
        }

        &.check label {
          & .labelText {
            padding-top: calc(100vw * (2 / 500));
          }
        }

        & .label {
          margin-bottom: calc(100vw * (10 / 500));
          font-size: calc(100vw * (20 / 500));

          & span {
            &::after {
              right: calc(100vw * (-12 / 500));
              width: calc(100vw * (8 / 500));
              height: calc(100vw * (8 / 500));
            }
          }
        }

        & .inputWrap {
          gap: calc(100vw * (20 / 500));

          & .selectContainer {
            width: calc(100vw * (134 / 500));
          }
        }

        & .addButton {
          width: calc(100vw * (104 / 500));
        }

        & .keywordListWrap {
          gap: calc(100vw * (10 / 500));
          margin-top: calc(100vw * (20 / 500));

          & .keywordList {
            gap: calc(100vw * (10 / 500));
            padding: calc(100vw * (8 / 500)) calc(100vw * (15 / 500));
            font-size: calc(100vw * (16 / 500));
            border-radius: calc(100vw * (500 / 500));
            box-shadow: calc(100vw * (2 / 500)) calc(100vw * (2 / 500))
              calc(100vw * (7 / 500)) 0 rgba(68, 74, 102, 0.2);

            & .deleteButton {
              width: calc(100vw * (19 / 500));
              height: calc(100vw * (19 / 500));
            }
          }
        }
      }
    }

    & .buttonContainer {
      & .errorText {
        top: calc(100vw * (-29 / 500));
        font-size: calc(100vw * (16 / 500));
      }

      & button:first-child {
        margin-bottom: calc(100vw * (20 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
