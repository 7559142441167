import styled from "styled-components";

// img
import ImgIntroduce from "resources/image/img_introduce_section01.png";

const Container = styled.div`
  min-height: 100vh;
  min-height: 100dvh;

  & .lightTitle {
    margin-bottom: 10px;
    color: var(--gray03);
  }

  & .title {
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.33;

    & span {
      color: var(--main);
    }
  }

  & .description {
    font-size: 16px;
    line-height: 1.37;

    & span {
      font-weight: 600;
    }
  }

  & .section01 {
    position: relative;
    height: 439px;
    margin-bottom: 71px;
    background: url(${ImgIntroduce}) no-repeat center / cover;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        0deg,
        rgba(28, 28, 28, 0.8) 2.63%,
        rgba(181, 181, 181, 0) 77.57%
      );
      content: "";
    }

    & h2 {
      position: absolute;
      left: 20px;
      bottom: 30px;
      font-size: 40px;
      font-weight: bold;
      color: #fff;
      z-index: 1;
    }
  }

  & .section02 {
    margin-bottom: 92px;

    & .description {
      margin-bottom: 38px;
    }

    & .imgContainer {
      width: 419px;
      height: 289px;
      margin-left: auto;
      filter: drop-shadow(16px 15px 20px rgba(0, 0, 0, 0.19));
    }
  }

  & .section03 {
    position: relative;

    & .imgContainer {
      width: 234px;
      height: 239px;
      margin: 29px auto -20px;
    }
  }

  & .section04 {
    padding: 61px 0 60px;
    background-color: var(--main);

    & * {
      color: #fff;
    }

    & .description {
      margin-bottom: 32px;
    }

    & .imgContainer {
      width: 463px;
      height: 393px;
    }
  }

  @media (max-width: 499px) {
    & .lightTitle {
      margin-bottom: calc(100vw * (10 / 500));
    }

    & .title {
      margin-bottom: calc(100vw * (30 / 500));
      font-size: calc(100vw * (30 / 500));
    }

    & .description {
      font-size: calc(100vw * (16 / 500));
    }

    & .section01 {
      height: calc(100vw * (439 / 500));
      margin-bottom: calc(100vw * (71 / 500));

      & h2 {
        left: calc(100vw * (20 / 500));
        bottom: calc(100vw * (30 / 500));
        font-size: calc(100vw * (40 / 500));
      }
    }

    & .section02 {
      margin-bottom: calc(100vw * (92 / 500));

      & .description {
        margin-bottom: calc(100vw * (38 / 500));
      }

      & .imgContainer {
        width: calc(100vw * (419 / 500));
        height: calc(100vw * (289 / 500));
        filter: drop-shadow(
          calc(100vw * (16 / 500)) calc(100vw * (15 / 500))
            calc(100vw * (20 / 500)) rgba(0, 0, 0, 0.19)
        );
      }
    }

    & .section03 {
      & .imgContainer {
        width: calc(100vw * (234 / 500));
        height: calc(100vw * (239 / 500));
        margin: calc(100vw * (29 / 500)) auto calc(100vw * (-20 / 500));
      }
    }

    & .section04 {
      padding: calc(100vw * (61 / 500)) 0 calc(100vw * (60 / 500));

      & .description {
        margin-bottom: calc(100vw * (32 / 500));
      }

      & .imgContainer {
        width: calc(100vw * (463 / 500));
        height: calc(100vw * (393 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
