import Styles from "./styles";

// img
import IconMinus from "resources/image/icon/icon_minus.svg";
import IconPlus from "resources/image/icon/icon_plus04.svg";

function AssetsList({ data, value, setInputs }) {
  const { title, categoryList, name } = data;
  
  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs((e) => {
      return { ...e, [name]: value };
    });
  };

  const handleMinus = () => {
    value = isNaN(parseInt(value)) ? 0 : parseInt(value)
    setInputs((e) => {
      return { ...e, [name]: value - 5 < 0 ? 0 : value - 5 };
    });
  };
  const handlePlus = () => {
    value = isNaN(parseInt(value)) ? 0 : parseInt(value)
    setInputs((e) => {
      return { ...e, [name]: value + 5 > 100 ? 100 : value + 5 };
    });
  };

  return (
    <Styles.Container>
      <div className="left">
        <p className="title">{title}</p>

        <ul className="category">
          {categoryList.map((item, index) => {
            return (
              <li className="categoryList" key={"categoryList" + index}>
                {item}
              </li>
            );
          })}
        </ul>
      </div>

      <div className="right">
        <button
          type="button"
          className="minusButton"
          onClick={() => {
            handleMinus();
          }}
        >
          <img src={IconMinus} alt="IconMinus" />
        </button>

        <input
          type="number"
          placeholder="0 %"
          name={name}
          value={value == 0 ? "" : value}
          onChange={onChange}
          onKeyDown={(e) => {
            const numberInput = !/^[0-9]+$/.test(e.key) && !(e.key === "Backspace" || e.key === "ArrowRight" || e.key === "ArrowLeft")
            if (numberInput) {
              e.preventDefault()
            }
          }}
          onBlur={() => {
            if (value > 100) {
              setInputs((e) => {
                return { ...e, [name]: 100 };
              });
            }
            if (value < 0) {
              setInputs((e) => {
                return { ...e, [name]: 0 };
              });
            }
          }}
        />

        <button
          type="button"
          className="plusButton"
          onClick={() => {
            handlePlus();
          }}
        >
          <img src={IconPlus} alt="IconPlus" />
        </button>
      </div>
    </Styles.Container>
  );
}

export default AssetsList;
