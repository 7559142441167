import useModals from "hooks/useModals";
import { api } from "service";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

export default function useMagazine({
  listOption,
  detailOption,
}) {
  const modalOption = useModals();
  const navigate = useNavigate();
  
  const listQuery = useQuery(["magazine",listOption], 
    ()=>{
      return api.magazine.getList(listOption);
    }, {
    enabled:!!listOption,
    refetchOnWindowFocus: false,
  });

  const detailQuery = useQuery(["magazineDetail",detailOption], 
    ()=>{
      return api.magazine.getDetail(detailOption);
    }, {
    enabled:!!detailOption,
    refetchOnWindowFocus: false,
  });

  const accessRestrict = async () => {
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.title = "내용을 확인할 수 없습니다.";
      e.text = "관리자에 의해 비노출 되었습니다.";
      e.confirmButtonText = "확인";
      e.confirmButtonClick = () => {
        navigate("/");
      };
      e.cancelButtonText = false;
      return { ...e };
    })
  }

  return {
    listQuery,
    detailQuery,
    accessRestrict
  };
}
