import Styles from "./styles";
import { useState } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom.svg";

function BasicSelect({ optionList, placeholder, size, select, setSelect, setUnrelatedCheck }) {
  const [isOptionOpen, setIsOptionOpen] = useState(false);

  return (
    <Styles.Container>
      <div>
        <div
          className={
            "nowSelect" +
            (select !== "" || isOptionOpen ? " on" : "") +
            (isOptionOpen ? " open" : "")
          }
          onClick={() => {
            setIsOptionOpen(!isOptionOpen);
          }}
        >
          {select !== "" ? select : placeholder}

          <div className="iconWrap">
            <img src={IconArrowBottom} alt="IconArrowBottom" />
          </div>
        </div>

        {isOptionOpen && (
          <Styles.OptionContainer size={size}>
            <OverlayScrollbarsComponent
              className="menuContainer"
              options={{ scrollbars: { autoHide: "scroll" } }}
            >
              <ul className="optionListWrap">
                {optionList?.map((item, index) => {
                  return (
                    <li
                      className="optionList"
                      key={"optionList" + index}
                      onClick={() => {
                        setSelect(item);
                        setIsOptionOpen(false);
                        {
                          setUnrelatedCheck && setUnrelatedCheck(false);
                        }
                      }}
                    >
                      {item.title ? item.title : item}
                    </li>
                  );
                })}
              </ul>
            </OverlayScrollbarsComponent>
          </Styles.OptionContainer>
        )}
      </div>
    </Styles.Container>
  );
}

export default BasicSelect;
