import ui from "components/ui";
import Styles from "./styles";
import layout from "components/layout";
import { useAuth } from "hooks";
import { useState, useEffect } from "react";

function DeleteModal({ modalClose }) {
  const authInfo = useAuth({});
  const [inputs, setInputs] = useState({
    reason: null,
    textarea: "",
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [check, setCheck] = useState(false);
  const [errorText, setErrorText] = useState(false);

  useEffect(() => {
    if (inputs.reason === "기타") {
      setIsDisabled(false);
    } else {
      setInputs((v) => { return { ...v, textarea: "" } });
      setIsDisabled(true);
    }
  }, [inputs.reason]);

  // map
  const list = [
    { text: "사이트 이용이 불편합니다." },
    { text: "원하는 정보가 부족합니다." },
    { text: "사이트 사용 빈도수가 적습니다." },
    { text: "새 아이디를 생성하고 싶습니다." },
    { text: "기타" }
  ];


  const validate = () => {
    if (inputs.reason === null && inputs.reason !== "기타") {
      setErrorText("탈퇴 사유를 선택해주세요.")
      return false;
    }
    if (!isDisabled && inputs.textarea === "") {
      setErrorText("기타 사유를 입력해주세요.")
      return false;
    }
    if (!check) {
      setErrorText("탈퇴 약관에 동의해주세요.")
      return false;
    }
    return true;
  }
  
  const onResign = () => {
    if (validate()) {
      authInfo.resign(inputs, modalClose);
    }
  }

  return (
    <Styles.Container>
      <div className="modalContainer">
        <form>
          <div className="title">회원 탈퇴</div>

          <p className="label">탈퇴 사유</p>

          <div className="listContainer">
            <ul className="listWrap">
              {list?.map((item, index) => {
                return (
                  <li className="list" key={"reasonList" + index}>
                    <ui.input.BasicRadio
                      labelText={item.text}
                      id={item.text}
                      name={"reason"}
                      checked={inputs.reason}
                      setInputs={setInputs}
                    />
                  </li>
                );
              })}
            </ul>

            <div className="textareaWrap">
              <ui.input.BasicTextarea
                value={inputs.textarea}
                setInputs={setInputs}
                name={"textarea"}
                placeholder={"기타 사유를 입력하세요."}
                disabled={isDisabled}
              />
            </div>
          </div>

          <p className="guideText">
            * 회원 탈퇴 시, 스크랩 게시물, 등록한 정보에 대해 복구가 불가능하며,{" "}
            <br />
            이벤트 참여와 일부 게시판 이용이 제한됩니다.
          </p>

          <div className="checkboxContainer">
            <ui.input.BasicCheckbox
              id={"terms"}
              checked={check}
              onChange={(e) => {
                setCheck(e.target.checked);
              }}
              labelText={"회원탈퇴 및 이용약관에 동의합니다.(필수)"}
            />
          </div>
        </form>

        <div className="buttonContainer">
          <div className="errorText">
            {errorText &&
              <layout.ConfirmText error text={errorText} />
            }
          </div>

          <div className="buttonList confirmButton">
            <ui.button.BasicButton
              buttonText={"탈퇴하기"}
              onClick={onResign}
            />
          </div>

          <div className="buttonList cancelButton">
            <ui.button.BasicButton
              buttonText={"닫기"}
              buttonType={"white"}
              onClick={modalClose}
            />
          </div>
        </div>
      </div>
    </Styles.Container>
  );
}

export default DeleteModal;
