import Styles from "./styles";
import ui from "components/ui";
import { useState, useEffect } from "react";
import useReport from "hooks/useReport";

function ReportModal({ modalOption, modalClose }) {
  const reportInfo = useReport();

  const [inputs, setInputs] = useState({
    reason: "",
    textarea: "",
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    setIsDisabled(true);
    setIsSubmitDisabled(false);
    inputs.reason !== "기타" && setInputs(e => { return { ...e, textarea: "" } })
    if (inputs.reason === "") { setIsSubmitDisabled(true) }
    if (inputs.reason === "기타"){
      setIsDisabled(false);
      inputs.textarea.trim() === "" && setIsSubmitDisabled(true);
    };
  }, [inputs.reason, inputs.textarea]);

  
  const list = [
    { text: "혐오발언・조장" },
    { text: "사기・거짓정보" },
    { text: "폭력적, 선정적 정서 피해" },
    { text: "상업적 홍보" },
    { text: "기타" },
  ];

  return (
    <Styles.Container>
      <div className="modalContainer">
        <form>
          <div className="title">신고하기</div>

          <p className="label">신고 사유</p>

          <div className="listContainer">
            <ul className="listWrap">
              {list?.map((item, index) => {
                return (
                  <li className="list" key={"reasonList" + index}>
                    <ui.input.BasicRadio
                      labelText={item.text}
                      id={item.text}
                      name={"reason"}
                      checked={inputs.reason}
                      setInputs={setInputs}
                    />
                  </li>
                );
              })}
            </ul>

            <div className="textareaWrap">
              <ui.input.BasicTextarea
                value={inputs.textarea}
                setInputs={setInputs}
                name={"textarea"}
                placeholder={"기타 사유를 입력하세요."}
                disabled={isDisabled}
              />
            </div>
          </div>
        </form>

        <div className="buttonContainer">
          <div className="buttonList confirmButton">
            <ui.button.BasicButton
              buttonText={"신고하기"}
              disabled={isSubmitDisabled}
              onClick={() => {
                reportInfo.onReport(
                  inputs,
                  modalOption.id,
                  modalOption.type,
                  modalClose
                ) }}
            />
          </div>

          <div className="buttonList cancelButton">
            <ui.button.BasicButton
              buttonText={"닫기"}
              buttonType={"white"}
              onClick={() => {
                modalClose();
              }}
            />
          </div>
        </div>
      </div>
    </Styles.Container>
  );
}

export default ReportModal;
