import connector from "service/connector";

const getList = async () => {
  return await connector.authConnector("user/pet", "GET");
}
const registInfo = async (data) => {
  return await connector.authConnector("user/pet", "POST", data);
}
const deleteInfo = async (path) => {
  return await connector.authConnector("user/pet/delete?id=" + path, "POST");
}
//* 현재 안쓰는 API
const detailInfo = async (path) => {
  return await connector.authConnector("user/pet/detail?id=" + path, "GET");
}
const updateInfo = async (data) => {
  return await connector.authConnector("user/pet/update", "POST", data);
}

export {
  getList,
  registInfo,
  deleteInfo,
  detailInfo,
  updateInfo
};

export default {
  getList,
  registInfo,
  deleteInfo,
  detailInfo,
  updateInfo
}