import utils from "utils";
import Styles from "./styles";
import { ui } from "components";
import { Link } from "react-router-dom";
import { useShare } from "hooks";

// img
import IconShare from "resources/image/icon/icon_share02.svg";

function VoteContainer({ data, vote, setVote , doToggleVote, detail }) {
  const shareInfo = useShare();
  
  const renderButton = (type) => {
    switch (type) {
      case "N": return (
        <ui.button.BasicButton
          buttonText={"투표하기"}
          onClick={() => {
            doToggleVote();
          }}
        />
      );
      case "Y": return (
        <ui.button.BasicButton
          buttonText={"다시 투표하기"}
          onClick={() => {
            doToggleVote();
          }}
        />
      );
      case "Finish": return (
        <ui.button.BasicButton buttonText={"종료된 투표입니다."} disabled />
      );
      case null: return (
        <ui.button.BasicButton buttonText={"진행중인 투표가 없습니다."} disabled />
      );
    }
  };

  return (
    <Styles.Container>
      <div className="topContainer">
        <p className="statusText">
          {data.finish_yn == "Y" ? "종료된" : "진행중"}{" "}
          <span className="color">투표</span>
        </p>

        {data?.vote_list &&
          <div className={"stateBox" + (data.finish_yn == "Y" ? " on" : "")}>
            {data.total_vote_count}명 <br /> {data.finish_yn == "Y" ? "완료" : "참여"}
          </div>
        }
      </div>

      {data?.vote_list &&
        <p className="date">
          {utils.date.getDateType(data.started_at)} ~ {utils.date.getDateType(data.ended_at)}
        </p>
      }

      <p className="title">
        {detail
          ? <Link to={"/vote/result/" + data.id}>{data.title}</Link>
          : <>{data.title}</>
        }
      </p>



      <ul className="listWrap">
        {data?.vote_list?.map((item, index) => {
          return (
            <li className="list" key={"voteList" + index}>
              <ui.input.VoteRadio
                detail={data}
                data={item}
                id={item.id}
                name={"vote"}
                onChange={(e) => {
                  setVote(item.id);
                }}
                checked={vote === item.id}
                disabled={data.finish_yn === "Y"}
              />
            </li>
          );
        })}
      </ul>

      <div className="buttonContainer">
        {renderButton(
          data.finish_yn === "Y"
            ? "Finish" : data?.vote_list
            ? data?.vote_list?.filter(v => v.vote_yn == "Y")[0] ? "Y" : "N" : null
        )}

        {data?.vote_list &&
          <button type="button" className="shareButton" onClick={()=>{
            shareInfo.shareClick();
            utils.url.getUrlCopy();
          }}>
            <span className="iconWrap">
              <img src={IconShare} alt="IconShare" />
            </span>
          </button>
        }
      </div>
    </Styles.Container>
  );
}

export default VoteContainer;
