import styled from "styled-components";

// img
import ImgWiki from "resources/image/img_wiki_banner.png";

const Container = styled.div`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 110px;

  & .categorySection {
    padding-bottom: 20px;
    border-bottom: 20px solid var(--gray01);

    & .buttonContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & a {
        display: flex;
        align-items: center;
      }

      & .categoryButton {
        gap: 18px;
        padding: 11px 25px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        background-color: var(--main);
        border-top-right-radius: 20px;

        & .iconWrap {
          width: 29px;
          height: 29px;
        }
      }

      & .searchButton {
        gap: 30px;
        height: 36px;
        margin-right: 20px;
        padding: 0 20px;
        font-size: 14px;
        font-weight: 500;
        color: var(--gray02);
        background-color: var(--gray01);
        border: 1px solid var(--gray02);
        border-radius: 500px;

        & .iconWrap {
          width: 30px;
          height: 30px;
        }
      }
    }

    & .bannerContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 257px;
      padding: 20px;
      font-size: 16px;
      color: #fff;
      background: url(${ImgWiki}) no-repeat center / cover;
      white-space: pre-wrap;

      & .bannerTitle {
        margin-bottom: 12px;
        font-size: 26px;
        font-weight: bold;
      }

      & .desciption {
        font-size: 16px;
        line-height: 1.25;
      }
    }
  }

  & .listSection {
    padding-top: 20px;

    & .filterContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--gray04);

      & .selectContainer {
        width: 134px;
      }
    }

    & .listWrap {
      margin-bottom: 10px;

      & .list {
        border-bottom: 1px solid var(--gray04);

        & a {
          padding: 20px 0;
        }
      }
    }

    & .guideText {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 6px;
      margin-bottom: 66px;
      font-size: 14px;
      font-weight: 500;
      color: var(--gray03);

      & .iconWrap {
        width: 24px;
        height: 24px;
      }
    }

    & .emptyContainer {
      padding: 30px 0 103px;
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (110 / 500));

    & .categorySection {
      padding-bottom: calc(100vw * (20 / 500));
      border-bottom: calc(100vw * (20 / 500)) solid var(--gray01);

      & .buttonContainer {
        & .categoryButton {
          gap: calc(100vw * (18 / 500));
          padding: calc(100vw * (11 / 500)) calc(100vw * (25 / 500));
          font-size: calc(100vw * (16 / 500));
          border-top-right-radius: calc(100vw * (20 / 500));

          & .iconWrap {
            width: calc(100vw * (29 / 500));
            height: calc(100vw * (29 / 500));
          }
        }

        & .searchButton {
          gap: calc(100vw * (30 / 500));
          height: calc(100vw * (36 / 500));
          margin-right: calc(100vw * (20 / 500));
          padding: 0 calc(100vw * (20 / 500));
          font-size: calc(100vw * (14 / 500));
          border-radius: calc(100vw * (500 / 500));

          & .iconWrap {
            width: calc(100vw * (30 / 500));
            height: calc(100vw * (30 / 500));
          }
        }
      }

      & .bannerContainer {
        height: calc(100vw * (257 / 500));
        padding: calc(100vw * (20 / 500));
        font-size: calc(100vw * (16 / 500));

        & .bannerTitle {
          margin-bottom: calc(100vw * (12 / 500));
          font-size: calc(100vw * (26 / 500));
        }

        & .desciption {
          font-size: calc(100vw * (16 / 500));
        }
      }
    }

    & .listSection {
      padding-top: calc(100vw * (20 / 500));

      & .filterContainer {
        padding-bottom: calc(100vw * (20 / 500));

        & .selectContainer {
          width: calc(100vw * (134 / 500));
        }
      }

      & .listWrap {
        margin-bottom: calc(100vw * (10 / 500));

        & .list {
          & a {
            padding: calc(100vw * (20 / 500)) 0;
          }
        }
      }

      & .guideText {
        gap: calc(100vw * (6 / 500));
        margin-bottom: calc(100vw * (66 / 500));
        font-size: calc(100vw * (14 / 500));

        & .iconWrap {
          width: calc(100vw * (24 / 500));
          height: calc(100vw * (24 / 500));
        }
      }

      & .emptyContainer {
        padding: calc(100vw * (30 / 500)) 0 calc(100vw * (103 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
