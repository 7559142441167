import styled from "styled-components";

import IconCheckOff from "resources/image/icon/icon_check_off.svg";
import IconCheckOn from "resources/image/icon/icon_check_on.svg";

const Input = styled.input`
  display: none;

  &:checked + label .checkbox {
    background-image: url(${IconCheckOn});
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  & .checkbox {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 14px;
    background: url(${IconCheckOff}) no-repeat center / cover;
  }

  @media (max-width: 499px) {
    font-size: calc(100vw * (18 / 500));

    & .checkbox {
      width: calc(100vw * (25 / 500));
      height: calc(100vw * (25 / 500));
      margin-right: calc(100vw * (14 / 500));
    }
  }
`;

export { Input, Label };

export default { Input, Label };
