import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import { useModals, useShare } from "hooks";

// img
import IconPrev from "resources/image/icon/icon_arrow_left.svg";
import IconShare from "resources/image/icon/icon_share.svg";
import utils from "utils";

function SubHeader({ pageName, share, backEvent }) {
  const navigate = useNavigate();
  const modalOption = useModals();
  const shareInfo = useShare();

  return (
    <Styles.Container>
      <div className="inner">
        <button
          type="button"
          className="prevButton"
          onClick={() => {
            backEvent ? backEvent() : navigate(-1);
          }}
        >
          <img src={IconPrev} alt="IconPrev" />
        </button>

        <p className="pageName">{pageName}</p>

        {share && (
          <button
            type="button"
            className="shareButton"
            onClick={() => {
              shareInfo.shareClick();

              if (utils.url.getUrlCopy()) {
                modalOption.setDefaultModal((e) => {
                  e.show = true;
                  e.title = "클립보드에 복사되었습니다";
                  e.text = false;
                  e.confirmButtonText = "닫기";
                  e.cancelButtonText = false;
                  return { ...e };
                });
              }
            }}
          >
            <img src={IconShare} alt="IconShare" />
          </button>
        )}
      </div>
    </Styles.Container>
  );
}

export default SubHeader;
