import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  bottom: 115px;
  left: calc(50% + 480px);
  z-index: 6;

  & .buttonList {
    position: relative;
    z-index: 2;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 14px;
    }

    & .buttonText {
      position: absolute;
      top: 50%;
      right: calc(100% + 12px);
      width: max-content;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
    }

    & button {
      width: 75px;
      height: 75px;
    }

    & .writingButton {
      filter: drop-shadow(3px 3px 10px rgba(189, 175, 231, 0.3));
    }
  }

  & .overlay {
    position: fixed;
    top: 0;
    left: calc(50% + 75px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 100vh;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }

  @media (max-width: 1200px) {
    left: calc(50% + 155px);

    & .overlay {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media (max-width: 499px) {
    left: auto;
    right: calc(100vw * (20 / 500));
    bottom: calc(100vw * (115 / 500));

    & .buttonList {
      &:not(:last-child) {
        margin-bottom: calc(100vw * (14 / 500));
      }

      & .buttonText {
        right: calc(100% + calc(100vw * (12 / 500)));
        font-size: calc(100vw * (16 / 500));
      }

      & a,
      & button {
        width: calc(100vw * (75 / 500));
        height: calc(100vw * (75 / 500));
      }

      & button {
        filter: drop-shadow(
          calc(100vw * (3 / 500)) calc(100vw * (3 / 500))
            calc(100vw * (10 / 500)) rgba(189, 175, 231, 0.3)
        );
      }
    }

    & .overlay {
      width: 100%;
    }
  }
`;

export { Container };

export default { Container };
