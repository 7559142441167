import styled from "styled-components";

// img
import BgWiki from "resources/image/bg_wiki.png";
import BgCategory from "resources/image/bg_category.png";

const Container = styled.div`
  min-height: calc(100vh - 70px);
  min-height: calc(100dvh - 70px);

  & .topContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 125px;
    padding: 0 20px;
    color: #fff;
    background: url(${BgWiki}) no-repeat center / cover;

    & .topTitle {
      margin-bottom: 12px;
      font-size: 26px;
      font-weight: bold;
    }

    & .description {
      font-size: 16px;
      line-height: 1.25;
      word-break: keep-all;
      white-space: pre;
    }
  }

  & .categoryContainer {
    display: flex;
    height: calc(100vh - 195px);
    height: calc(100dvh - 195px);

    & .mainCategory {
      width: 200px;
      height: 100%;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      & .listWrap {
        position: relative;
        padding: 16px 0 60px 20px;

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 183px;
          height: 100%;
          background-color: var(--gray01);
          content: "";
        }
      }

      & .list {
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        height: 56px;
        padding-left: 20px;
        font-size: 20px;
        font-weight: 500;
        color: var(--gray03);
        cursor: pointer;
        z-index: 1;

        &.on {
          color: #fff;

          & .iconWrap {
            filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(3deg)
              brightness(106%) contrast(105%);
          }

          &::before {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 179px;
            height: 100%;
            background: url(${BgCategory}) no-repeat center / cover;
            content: "";
            z-index: 1;
          }
        }

        & > * {
          position: relative;
          z-index: 1;
        }

        &:not(:last-child) {
          margin-bottom: 6px;
        }

        & .iconWrap {
          width: 32px;
          height: 32px;
          filter: invert(62%) sepia(5%) saturate(853%) hue-rotate(202deg)
            brightness(94%) contrast(83%);
        }
      }
    }

    & .subCategory {
      width: calc(100% - 200px);
      padding: 20px 20px 20px 3px;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      & .bannerImgWrap {
        height: 98px;
        margin-bottom: 22px;
      }

      & .list {
        &:not(:last-child) {
          margin-bottom: 6px;
        }

        & a {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 0 16px;
          height: 56px;
          font-size: 20px;
          font-weight: 500;

          & span {
            color: var(--gray03);
          }
        }
      }
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (70 / 500)));
    min-height: calc(100dvh - calc(100vw * (70 / 500)));

    & .topContainer {
      height: calc(100vw * (125 / 500));
      padding: 0 calc(100vw * (20 / 500));

      & .topTitle {
        margin-bottom: calc(100vw * (12 / 500));
        font-size: calc(100vw * (26 / 500));
      }

      & .description {
        font-size: calc(100vw * (16 / 500));
      }
    }

    & .categoryContainer {
      height: calc(100vh - calc(100vw * (195 / 500)));
      height: calc(100dvh - calc(100vw * (195 / 500)));

      & .mainCategory {
        width: calc(100vw * (200 / 500));

        & .listWrap {
          padding: calc(100vw * (16 / 500)) 0 calc(100vw * (60 / 500))
            calc(100vw * (20 / 500));

          &::after {
            width: calc(100vw * (183 / 500));
          }
        }

        & .list {
          gap: calc(100vw * (10 / 500));
          height: calc(100vw * (56 / 500));
          padding-left: calc(100vw * (20 / 500));
          font-size: calc(100vw * (20 / 500));

          &.on {
            &::before {
              width: calc(100vw * (179 / 500));
            }
          }

          &:not(:last-child) {
            margin-bottom: calc(100vw * (6 / 500));
          }

          & .iconWrap {
            width: calc(100vw * (32 / 500));
            height: calc(100vw * (32 / 500));
          }
        }
      }

      & .subCategory {
        width: calc(100% - calc(100vw * (200 / 500)));
        padding: calc(100vw * (20 / 500)) calc(100vw * (20 / 500))
          calc(100vw * (20 / 500)) calc(100vw * (3 / 500));

        & .bannerImgWrap {
          height: calc(100vw * (98 / 500));
          margin-bottom: calc(100vw * (22 / 500));
        }

        & .list {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (6 / 500));
          }

          & a {
            gap: calc(100vw * (10 / 500));
            padding: 0 calc(100vw * (16 / 500));
            height: calc(100vw * (56 / 500));
            font-size: calc(100vw * (20 / 500));
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
