import { useQuery } from "react-query";
import { api } from "service";
import { useModals } from "hooks";

export default function useReview({
  listOption,
}) {
  const modalOption = useModals();
  const listQuery = useQuery(["review",listOption], 
    ()=>{
      return api.review.getList(listOption);
    }, {
    enabled:!!listOption,
    refetchOnWindowFocus: false,
  });
  const detailQuery = useQuery(["reviewDetail",listOption], 
    ()=>{
      return api.review.getDetail(listOption);
    }, {
    enabled:!!listOption,
    refetchOnWindowFocus: false,
  });


  const blockReview = () => {
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.title = "수발러 의견을 수정해주세요!";
      e.text = "이미 작성하신 리뷰가 존재합니다.";
      e.confirmButtonText = "확인";
      e.confirmButtonClick = false;
      e.cancelButtonText = false;
      return { ...e };
    });
  }
  const reviewModal = (modalClose, del = false) => {
    modalClose && modalClose();
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.title = del ? "리뷰가 삭제되었습니다!" : "수발러 의견이 등록되었어요!";
      e.text = false;
      e.confirmButtonText = "확인";
      e.confirmButtonClick = false;
      e.cancelButtonText = false;
      return { ...e };
    });
    listQuery.refetch();
    detailQuery.refetch();
  }
  const registReview = async (reviewData, modalClose) => {
    const result = await api.review.registReview({ ...listOption, ...reviewData })
    result.success && reviewModal(modalClose);
  }
  const updateReview = async (reviewData, modalClose) => {
    const result = await api.review.updateReview({ ...listOption, ...reviewData })
    result.success && reviewModal(modalClose)
  }
  const deleteReview = () => {
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.title = "리뷰를 삭제하시겠습니까?";
      e.text = "삭제된 리뷰는 복구가 불가능합니다.";
      e.confirmButtonText = "삭제하기";
      e.confirmButtonClick = async () => {
        const result = await api.review.deleteReview(listOption)
        result.success && reviewModal(null, true)
      };
      e.cancelButtonText = "취소";
      e.cancelButtonClick = false;
      return { ...e };
    });
  }

  return {
    listQuery,
    detailQuery,
    blockReview,
    registReview,
    updateReview,
    deleteReview,
  };
}
