// img
import ImgBannerDummy from "resources/image/img_dummy_banner.png";
import ImgSubBannerDummy from "resources/image/img_dummy_banner2.png";
import ImgEventDummy from "resources/image/img_dummy_event.png";
import ImgProfileDummy from "resources/image/img_dummy_profile.png";
import ImgProfile02Dummy from "resources/image/img_dummy_profile02.png";
import ImgVoteDummy from "resources/image/img_dummy_vote.png";
import ImgWikiDummy from "resources/image/img_dummy_wiki.png";
import IconCategory01 from "resources/image/icon/icon_category01.svg";
import IconCategory02 from "resources/image/icon/icon_category02.svg";
import IconCategory03 from "resources/image/icon/icon_category03.svg";
import IconCategory04 from "resources/image/icon/icon_category04.svg";
import IconCategory05 from "resources/image/icon/icon_category05.svg";
import IconCategory06 from "resources/image/icon/icon_category06.svg";
import IconCategory07 from "resources/image/icon/icon_category07.svg";
import IconCategory08 from "resources/image/icon/icon_category08.svg";
import IconCategory09 from "resources/image/icon/icon_category09.svg";
import IconCategory10 from "resources/image/icon/icon_category10.svg";
import IconCategory11 from "resources/image/icon/icon_category11.svg";
import IconCategory12 from "resources/image/icon/icon_category12.svg";
import IconCategory13 from "resources/image/icon/icon_category13.svg";
import IconCategory14 from "resources/image/icon/icon_category14.svg";
import IconDummyLink from "resources/image/icon_dummy_link.svg";

// main
const bannerImgList = [
  { img: ImgBannerDummy, url: "" },
  { img: ImgBannerDummy, url: "" },
  { img: ImgBannerDummy, url: "" },
  { img: ImgBannerDummy, url: "" },
  { img: ImgBannerDummy, url: "" },
  { img: ImgBannerDummy, url: "" },
  { img: ImgBannerDummy, url: "" },
  { img: ImgBannerDummy, url: "" },
  { img: ImgBannerDummy, url: "" },
  { img: ImgBannerDummy, url: "" },
];
const todayList = [
  "🐶 데일리 위클리 라이브 생방송 댕댕위크 올데이 선데이 ",
  "🐶 데일리 위클리 라이브 생방송 댕댕위크 올데이 선데이 ",
];
const subBannerImgList = [
  { img: ImgSubBannerDummy, url: "" },
  { img: ImgSubBannerDummy, url: "" },
  { img: ImgSubBannerDummy, url: "" },
  { img: ImgSubBannerDummy, url: "" },
  { img: ImgSubBannerDummy, url: "" },
  { img: ImgSubBannerDummy, url: "" },
  { img: ImgSubBannerDummy, url: "" },
  { img: ImgSubBannerDummy, url: "" },
  { img: ImgSubBannerDummy, url: "" },
  { img: ImgSubBannerDummy, url: "" },
];
// common
const keywordList = [
  "동결간식이슈",
  "넥카라",
  "애견동반카페",
  "가을행사",
  "병원후기",
  "배변봉투",
  "노즈워크",
  "목줄",
];
const eventList = [
  {
    img: ImgEventDummy,
    title: "2023년 제10회 해피투게더 반려동물 문화축제",
    description:
      "인천광역시에서 개최하는 반려동물 문화 축제로, 풍성한 이벤트와 먹거리를 한 곳에서 즐길 수 있습니다. 강아지 사랑하는 반려",
    startDate: "23.01.01",
    endDate: "23.01.01",
    location: "인천",
    locationDetail: "인천광역시 주소구 주소도로123 주소시민공원",
    locationLink:
      "https://map.naver.com/p/entry/place/1035072867?c=9.50,0,0,0,dh",
    like: false,
    end: false,
  },
  {
    img: ImgEventDummy,
    title: "2023년 제10회 해피투게더 강아지 문화축제 ",
    description:
      "인천광역시에서 개최하는 반려동물 문화 축제로, 풍성한 이벤트와 먹거리를 한 곳에서 즐길 수 있습니다. 사랑하는 반려",
    startDate: "23.01.01",
    endDate: "23.01.01",
    location: "인천",
    like: false,
    end: true,
  },
  {
    img: ImgEventDummy,
    title: "2023년 제10회 해피투게더 반려동물 문화축제",
    description:
      "인천광역시에서 개최하는 강아지 문화 축제로, 풍성한 이벤트와 먹거리를 한 곳에서 즐길 수 있습니다. 사랑하는 반려",
    startDate: "23.01.01",
    endDate: "23.01.01",
    location: "인천",
    like: false,
    end: false,
  },
];
const noticeList = [
  {
    important: true,
    title: "게시판 이용수칙 안내",
    description: "dd",
    date: "2023.10.27",
  },
  {
    important: true,
    title: "게시판 이용수칙 안내",
    description: "",
    date: "2023.10.27",
  },
  {
    number: 1,
    title: "펫트워크 이용자 대상 전체 공지입니다",
    description: "",
    date: "2023.10.27",
  },
  {
    number: 2,
    title: "펫트워크 이용자 대상 전체 공지입니다",
    description: "",
    date: "2023.10.27",
  },
];
const faqList = [
  {
    question:
      "문의글의 제목이 들어가는 자리입니다 문의글의 제목이 들어가는 자리입니다 문의글의 제목이 들어가는 자리입니다 문의글의 제목이 들어가는 자리입니다",
    answer:
      "답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 ",
  },
  {
    question: "문의글의 제목이 들어가는 자리입니다",
    answer:
      "답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 ",
  },
  {
    question: "문의글의 제목이 들어가는 자리입니다",
    answer:
      "답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 답변입니다 ",
  },
];
const communityList = [
  {
    type: "board",
    title: "자유게시판 우리 강아지 밥풀이 생일 기념으로 바다 다녀왔어요!",
    userName: "까까길만걷자",
    description:
      "수발러모임에 관련된 상세 내용이 들어가는 텍스트영역입니다. 수발러모임에 관련된 상세 내용이 들어가는 텍스트영역입니다. 수발러모임에 관련된 상세 내용이 들어가는 텍스트영역입니다. 수발러모임에 관련된 상세 내용이 들어가는 텍스트영역입니다. 수발러모임에 관련된 상세 내용이 들어가는 텍스트영역입니다. 수발러모임에 관련된 상세 내용이 들어가는 텍스트영역입니다. 수발러모임에 관련된 상세 내용이 들어가는 텍스트영역입니다. 수발러모임에 관련된 상세 내용이 들어가는 텍스트영역입니다. 수발러모임에 관련된 상세 내용이 들어가는 텍스트영역입니다. ",
    locate: "서울",
    view: 106,
    comment: 72,
    like: false,
    empathy: false,
    empathyNumber: 123,
    date: "2023.10.27",
    my: true,
  },
  {
    type: "secret",
    title: "비밀의 개나무숲 우리 강아지 밥풀이 생일 기념으로 바다 다녀왔어요!",
    profileImg: ImgProfileDummy,
    userName: "뛰는게제일좋아",
    locate: "경남",
    view: 106,
    comment: 72,
    like: true,
    empathy: false,
    empathyNumber: 123,
    date: "2023.10.27",
    my: false,
  },
  {
    type: "accident",
    person: 0,
    title: "사건사고 우리 강아지 생일 기념으로 바다 다녀왔어요!",
    profileImg: ImgProfileDummy,
    userName: "나는야개춘기",
    locate: "전남",
    view: 106,
    comment: 72,
    like: true,
    empathy: false,
    empathyNumber: 123,
    date: "2023.10.27",
    my: true,
  },
  {
    type: "notice",
    title: "공지 개린이 첫 유치원 고를 때 신경써야 할 부분 알려주세요",
    profileImg: ImgProfileDummy,
    userName: "나는야개춘기",
    locate: "전남",
    view: 106,
    comment: 72,
    like: true,
    empathy: false,
    empathyNumber: 123,
    date: "2023.10.27",
    my: true,
  },
  {
    type: "secret",
    title: "비밀의 개나무숲 첫 유치원 고를 때 신경써야 할 부분 알려주세요",
    profileImg: ImgProfileDummy,
    userName: "몸짱댕댕이가될테야",
    locate: "전남",
    view: 106,
    comment: 72,
    like: true,
    date: "2023.10.27",
    my: true,
  },
];
const vote = [
  {
    type: 0,
    title: (
      <>
        소중한 우리 댕댕이의 생일이 내일로 다가왔습니다. <br />
        어떤 선물을 해주고 싶으신가요?
      </>
    ),
    startDate: "2023.01.01",
    endDate: "2023.01.01",
    participants: 162,
    voteList: [
      {
        id: 1,
        img: ImgVoteDummy,
        text: "낡은 강아지 용품 모두 새 것으로 바꿔주기",
      },
      {
        id: 2,
        img: ImgVoteDummy,
        text: "케이크, 고깔모자, 예쁜 사진까지! 화려하게 생일파티 열어주기",
      },
      {
        id: 3,
        img: ImgVoteDummy,
        text: "새로운 세상을 구경할 기회! 강아지와 함께 1박 2일 국내여행 다녀오기",
      },
    ],
  },
  {
    type: 1,
    title:
      "샤워장 몰카사건이 발생했던 동반 캠핑장에서 거짓 공지로 사건을 축소하려는 시도가 있었습니다. 사고 후 수습에 진정성을 느낄 수 없다면 어떤 결정을 하실 건가요?",
    startDate: "2023.01.01",
    endDate: "2023.01.01",
    participants: 162,
    voteList: [
      {
        id: 1,
        img: ImgVoteDummy,
        text: "낡은 강아지 용품 모두 새 것으로 바꿔주기",
        percent: 14,
      },
      {
        id: 2,
        img: ImgVoteDummy,
        text: "케이크, 고깔모자, 예쁜 사진까지! 화려하게 생일파티 열어주기",
        percent: 55,
      },
      {
        id: 3,
        img: ImgVoteDummy,
        text: "새로운 세상을 구경할 기회! 강아지와 함께 1박 2일 국내여행 다녀오기",
        percent: 31,
      },
    ],
  },
  {
    type: 2,
    title:
      "샤워장 몰카사건이 발생했던 동반 캠핑장에서 거짓 공지로 사건을 축소하려는 시도가 있었습니다. 사고 후 수습에 진정성을 느낄 수 없다면 어떤 결정을 하실 건가요?",
    startDate: "2023.01.01",
    endDate: "2023.01.01",
    participants: 162,
    voteList: [
      {
        id: 1,
        img: ImgVoteDummy,
        text: "낡은 강아지 용품 모두 새 것으로 바꿔주기",
        percent: 14,
      },
      {
        id: 2,
        img: ImgVoteDummy,
        text: "케이크, 고깔모자, 예쁜 사진까지! 화려하게 생일파티 열어주기",
        percent: 55,
      },
      {
        id: 3,
        img: ImgVoteDummy,
        text: "새로운 세상을 구경할 기회! 강아지와 함께 1박 2일 국내여행 다녀오기",
        percent: 31,
      },
    ],
  },
  {
    type: 2,
    title:
      "샤워장 몰카사건이 발생했던 동반 캠핑장에서 거짓 공지로 사건을 축소하려는 시도가 있었습니다. 사고 후 수습에 진정성을 느낄 수 없다면 어떤 결정을 하실 건가요?",
    startDate: "2023.01.01",
    endDate: "2023.01.01",
    participants: 162,
  },
  {
    type: 2,
    title:
      "샤워장 몰카사건이 발생했던 동반 캠핑장에서 거짓 공지로 사건을 축소하려는 시도가 있었습니다. 사고 후 수습에 진정성을 느낄 수 없다면 어떤 결정을 하실 건가요?",
    startDate: "2023.01.01",
    endDate: "2023.01.01",
    participants: 162,
  },
];
const locationList = [
  "서울",
  "경기",
  "인천",
  "강원",
  "대전",
  "세종",
  "충북",
  "충남",
  "제주",
  "광주",
  "전북",
  "전남",
  "대구",
  "부산",
  "울산",
  "경북",
  "경남"
];
const commentList = [
  {
    profileImg: ImgProfileDummy,
    userName: "닉네임이 들어갑니다",
    writer: true,
    comment:
      "댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다",
    date: "2023.01.01",
  },
  {
    userName: "닉네임이 들어갑니다",
    comment:
      "댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다",
    date: "2023.01.01",
    my: true,
    reply: [
      {
        profileImg: ImgProfileDummy,
        userName: "닉네임이 들어갑니다",
        writer: true,
        comment:
          "리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다",
        date: "2023.01.01",
      },
      {
        my: true,
        userName: "닉네임이 들어갑니다",
        comment:
          "리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다",
        date: "2023.01.01",
      },
    ],
  },
  {
    profileImg: ImgProfileDummy,
    userName: "닉네임이 들어갑니다",
    writer: true,
    comment:
      "댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다",
    date: "2023.01.01",
  },
];
const magazineList = [
  {
    img: ImgEventDummy,
    title: "얌전히 있다가 갑자기 달리는 강아지? 우다다 타임, 왜 그러는 걸까?",
    description:
      "강아지를 키우다 보면 갑자기 달리는 아이들을 마주한 적 있을 것이다. 갑자기 놀라서 그런 건지, 그냥 신이 나서 그런 건지강아지를 키우다 보면 갑자기 달리는 아이들을 마주한 적 있을 것이다. 갑자기 놀라서 그런 건지, 그냥 신이 나서 그런 건지강아지를 키우다 보면 갑자기 달리는 아이들을 마주한 적 있을 것이다. 갑자기 놀라서 그런 건지, 그냥 신이 나서 그런 건지강아지를 키우다 보면 갑자기 달리는 아이들을 마주한 적 있을 것이다. 갑자기 놀라서 그런 건지, 그냥 신이 나서 그런 건지강아지를 키우다 보면 갑자기 달리는 아이들을 마주한 적 있을 것이다. 갑자기 놀라서 그런 건지, 그냥 신이 나서 그런 건지강아지를 키우다 보면 갑자기 달리는 아이들을 마주한 적 있을 것이다. 갑자기 놀라서 그런 건지, 그냥 신이 나서 그런 건지강아지를 키우다 보면 갑자기 달리는 아이들을 마주한 적 있을 것이다. 갑자기 놀라서 그런 건지, 그냥 신이 나서 그런 건지강아지를 키우다 보면 갑자기 달리는 아이들을 마주한 적 있을 것이다. 갑자기 놀라서 그런 건지, 그냥 신이 나서 그런 건지강아지를 키우다 보면 갑자기 달리는 아이들을 마주한 적 있을 것이다. 강아지를 키우다 보면 갑자기 달리는 아이들을 마주한 적 있을 것이다. 강아지를 키우다 보면 갑자기 달리는 아이들을 마주한 적 있을 것이다.",
    like: true,
    date: "23.01.01",
    view: 613,
  },
  {
    img: ImgEventDummy,
    title: "얌전히 있다가 갑자기 달리는 강아지? 우다다 타임, 왜 그러는 걸까?",
    description:
      "강아지를 키우다 보면 갑자기 달리는 아이들을 마주한 적 있을 것이다. 갑자기 놀라서 그런 건지, 그냥 신이 나서 그런 건지",
    like: false,
    date: "23.01.01",
    view: 613,
  },
];
const communityNoticeList = [
  {
    title: "컨텐츠 제목이 들어갈 자리입니다",
    userName: "펫트워크",
    description: "",
    date: "2023.10.27",
  },
  {
    title: "컨텐츠 제목이 들어갈 자리입니다 컨텐츠 제목이 들어갈 자리입니다",
    userName: "펫트워크",
    description: "",
    date: "2023.10.27",
  },
  {
    title: "컨텐츠 제목이 들어갈 자리입니다",
    userName: "펫트워크",
    description: "",
    date: "2023.10.27",
  },
];
const notificationList = [
  {
    nickname: "닉네임",
    title: "님이 댓글을 남겼습니다.",
    date: "2023.10.27",
  },
  {
    title: "공지사항이 등록되었습니다.",
    date: "2023.10.27",
  },
];
const contactList = [
  { title: "추천", id: 0 },
  { title: "제보", id: 1 },
  { title: "건의", id: 2 },
  { title: "문의", id: 3 },
  { title: "제안", id: 4 },
  { title: "정보수정요청", id: 5 },
  { title: "기타문의", id: 6 },
];
const wikiList = [
  {
    cat: true,
    img: ImgWikiDummy,
    company: "업체명이 들어갑니다.",
    category: "",
    subCategory: "서브 카테고리",
    description:
      "매월 테마를 가지고 구성된 박스를 받아볼 수 있고 강아지 매달 배송되는 귀여운 강아지 인형 장난감이 인기가 많아요!",
    url: "",
    address: "부산광역시 강서구 녹산산단382로14번가길 10번지(송정동)",
    addressLink:
      "https://map.naver.com/p/search/%ED%8E%AB%ED%8A%B8%EC%9B%8C%ED%81%AC/place/1035072867?placePath=?entry=pll&from=nx&fromNxList=true&searchType=place",
    etc: [
      {
        title: "항목",
        description:
          "항목에 대한 내용이 들어가는 영역입니다. 항목에 대한 내용이 들어가는 영역입니다. 항목에 대한 내용이 들어가는 영역입니다. ",
      },
    ],
    detail: "댕댕위키 업체에 관련된 상세 내용이 들어갑니다.",
    linkList: [
      {
        icon: IconDummyLink,
        description: "외부로 연결되는 링크 관련 내용 영역입니다.",
        link: "",
      },
      {
        icon: IconDummyLink,
        description: "외부로 연결되는 링크 관련 내용 영역입니다.",
        link: "",
      },
    ],
    keywordList: [
      "동결간식이슈",
      "넥카라",
      "애견동반카페",
      "가을행사",
      "병원후기",
      "배변봉투",
      "노즈워크",
      "목줄",
    ],
    reviewList: [
      {
        my: true,
        profileImg: ImgProfileDummy,
        userName: "닉네임이 들어갑니다",
        review:
          "댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다",
        date: "2023.01.01",
        temperature: 35.0,
      },
      {
        profileImg: ImgProfileDummy,
        userName: "닉네임이 들어갑니다",
        review:
          "댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다",
        date: "2023.01.01",
        temperature: 37.1,
        imgList: [ImgSubBannerDummy, ImgSubBannerDummy, ImgSubBannerDummy],
      },
    ],
  },
  {
    cat: true,
    img: ImgWikiDummy,
    company: "업체명 강아지",
    category: "",
    subCategory: "서브 카테고리",
    description:
      "매월 테마를 가지고 구성된 박스를 받아볼 수 있고 매달 배송되는 귀여운 인형 장난감이 인기가 많아요!",
  },
  {
    cat: false,
    img: ImgWikiDummy,
    company: "업체명입니다.",
    category: "",
    subCategory: "서브 카테고리",
    description:
      "매월 강아지 테마를 가지고 구성된 박스를 받아볼 수 있고 매달 배송되는 귀여운 인형 장난감이 인기가 많아요!",
  },
  {
    cat: true,
    img: ImgWikiDummy,
    company: "강아지 업체명이 들어갑니다.",
    category: "",
    subCategory: "서브 카테고리",
    description:
      "매월 테마를 가지고 구성된 박스를 받아볼 수 있고 매달 배송되는 귀여운 인형 장난감이 인기가 많아요!",
  },
];
const categoryList = [
  {
    icon: IconCategory01,
    text: "SEASON",
  },
  {
    icon: IconCategory02,
    text: "맘마냠냠",
  },
  {
    icon: IconCategory03,
    text: "레저",
  },
  {
    icon: IconCategory04,
    text: "이동",
  },
  {
    icon: IconCategory05,
    text: "외출",
  },
  {
    icon: IconCategory06,
    text: "패션",
  },
  {
    icon: IconCategory07,
    text: "쇼핑",
    subCategoryList: [
      {
        text: "오프라인 매장",
        number: 10000,
      },
      {
        text: "렌탈",
        number: 1000,
      },
      {
        text: "정기배송",
        number: 10000,
      },
      {
        text: "빠른배송",
        number: 9,
      },
      {
        text: "종합쇼핑",
        number: 0,
      },
      {
        text: "국내유명브랜드",
        number: 0,
      },
      {
        text: "해외유명브랜드",
        number: 0,
      },
      {
        text: "해외유명",
        number: 0,
      },
    ],
  },
  {
    icon: IconCategory08,
    text: "리빙",
  },
  {
    icon: IconCategory09,
    text: "건강",
  },
  {
    icon: IconCategory10,
    text: "전문서비스",
  },
  {
    icon: IconCategory11,
    text: "콘텐츠",
  },
  {
    icon: IconCategory12,
    text: "파이낸스",
  },
  {
    icon: IconCategory13,
    text: "헬로우",
  },
  {
    icon: IconCategory14,
    text: "굿바이",
  },
];
const myCommentList = [
  {
    comment:
      "댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니",
    date: "2023.01.01",
  },
  {
    comment:
      "댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니",
    date: "2023.01.01",
  },
];
const myInfo = {
  pro: false,
  userName: "달달한모카우유",
  dogInfoList: [
    {
      dogImg: ImgProfile02Dummy,
      motto:
        "견생좌우명이들어가는텍스트영역입니다 견생좌우명이들어가는텍스트영역입니다",
      dogName: "모카",
      dogType: "이탈리안 그레이하운드",
      age: "2021년생",
      gender: "여아",
      neutralization: true,
    },
  ],
};
const dogTypeList = [
  "믹스견",
  "고든 세터",
  "골든 두들",
  "골든 리트리버",
  "그레이 하운드",
  "그레이트 데인",
  "그레이트 피레니즈",
  "그로넨달",
  "기슈견",
  "꼬동 드 툴레아",
  "노르웨이 엘크하운드",
  "노르포크 테리어",
  "노리치 테리어",
  "뉴펀들랜드",
  "닥스훈트",
  "달마시안",
  "댄디딘몬트 테리어",
  "도고",
  "도베르만 핀셔",
  "도사견",
  "동경이 (경주개)",
  "라사 압소",
  "라이카",
  "래브라도 리트리버",
  "랫 테리어",
  "레이크랜드테리어",
  "로디지안 리즈백",
  "롯트 와일러",
  "마스티프",
  "말티즈",
  "말티푸",
  "맨체스터 테리어",
  "미니어쳐 핀셔",
  "바센지",
  "바셋하운드",
  "바이마라너 (와이마라너)",
  "버니즈 마운틴 독",
  "베들링턴 테리어",
  "벨지안 그로넨달",
  "벨지안 라케노이즈",
  "벨지안 말리노이즈",
  "벨지안 터뷰렌",
  "보더 콜리",
  "보더 테리어",
  "보르조이",
  "보스턴테리어",
  "복서",
  "불 독",
  "불 테리어",
  "불마스티프",
  "브뤼셀 그리펀",
  "브리타니",
  "블랙탄 쿤하운드",
  "블러드 하운드",
  "비글",
  "비숑 프리제",
  "비어디드 콜리",
  "비즐라",
  "사모예드",
  "사우스러시안 오프차카",
  "살루키",
  "삽살개",
  "서섹스 스파니엘",
  "세인트 버나드",
  "셔틀랜드 쉽독",
  "슈나우져",
  "스카이 테리어",
  "스코티쉬 테리어",
  "스탠다드 푸들",
  "스테포드셔 불테리어",
  "스프링거스파니엘",
  "시바 이누",
  "시베리안 허스키",
  "시츄",
  "실리함 테리어",
  "실키 테리어",
  "아메리칸에스키모독",
  "아이리쉬 세터",
  "아이리쉬 테리어",
  "아이리쉬울프하운드",
  "아키타",
  "아펜핀셔",
  "아프간하운드",
  "알래스칸 말라뮤트",
  "에어데일 테리어",
  "오스트레일리안테리어",
  "올드 잉글리쉬 쉽독",
  "요크셔테리어",
  "워터 스파니엘",
  "웨스티 화이트테리어",
  "웰시 테리어",
  "웰시코기",
  "이탈리안 그레이하운드",
  "잉글리쉬 세터",
  "잭 러셀 테리어",
  "저먼 포인터",
  "제주개",
  "제패니즈 스피츠",
  "제페니스 친",
  "져먼 셰퍼드",
  "진돗개",
  "차우차우",
  "차이니스크레스티드",
  "차이니즈 샤페이",
  "체사피크 리트리버",
  "치와와",
  "컬리코티드 리트리버",
  "케리 블루 테리어",
  "케언 테리어",
  "코몬돌",
  "코카 스파니엘",
  "코카시안오프차카",
  "콜리",
  "쿠바츠",
  "크럼버 스파니엘",
  "킹 찰스 스파니엘",
  "토이 멘체스터 테리어",
  "티베탄 마스티프",
  "티베탄 스파니엘",
  "티베탄 테리어",
  "파피용",
  "퍼그",
  "페키니즈",
  "포메라니안",
  "포인터",
  "포인팅 그리폰",
  "폭스 테리어",
  "푸들",
  "풀리",
  "풍산개",
  "프렌치 불독",
  "플랫코티드 리트리버",
  "필드 스파니엘",
  "홋카이도견",
  "휘튼 테리어",
  "휘핏",
  "기타견종",
];

export {
  bannerImgList,
  todayList,
  subBannerImgList,
  keywordList,
  eventList,
  noticeList,
  faqList,
  communityList,
  vote,
  locationList,
  commentList,
  magazineList,
  communityNoticeList,
  notificationList,
  contactList,
  wikiList,
  categoryList,
  myCommentList,
  myInfo,
  dogTypeList,
};

export default {
  bannerImgList,
  todayList,
  subBannerImgList,
  keywordList,
  eventList,
  noticeList,
  faqList,
  communityList,
  vote,
  locationList,
  commentList,
  magazineList,
  communityNoticeList,
  notificationList,
  contactList,
  wikiList,
  categoryList,
  myCommentList,
  myInfo,
  dogTypeList,
};
