import utils from "utils";
import Styles from "./styles";
import { layout, ui } from "components";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useEvent, useMagazine, useScrap, useSubaler, useWiki } from "hooks";

function Result({ data, filter, setFilter }) {
  const [keyword, setKeyword] = useState("");
  const [tab, setTab] = useState(0);
  const location = useLocation();
  const scrapInfo = useScrap({
    queryName: tab == 1 ? "magazine" : tab == 2 ? "subaler" : tab == 3 ? "event" : "",
    callFilter: filter
  });

  const renderComponents = (item, tab, index) => {
    switch (tab) {
      case 0: return <layout.WikiList
        data={item}
        keyword={keyword}
      />;
      case 1: return <layout.MagazineList
        data={item}
        index={index}
        keyword={keyword}
        scrapInfo={scrapInfo}
      />;
      case 2: return <layout.CommunityList
        data={item}
        index={index}
        keyword={keyword}
        scrapInfo={scrapInfo}
      />;
      case 3: return <layout.EventList
        data={item}
        index={index}
        keyword={keyword}
        scrapInfo={scrapInfo}
      />;
    }
  };

  useEffect(()=>{
    let types = [2,3,4,1];
    let param = utils.etc.getSearchParam();
    let f = { 
      page: param.page ? param.page : 1,
      recordSize: param.recordSize ? param.recordSize : 10,
      pageSize: 5,
      order: param.order ? param.order : 1,
      type: types[param.tab - 1],
    };
    if(param.category_info_id){
      f.category_info_id = param.category_info_id;
    }
    f.keyword = param.keyword ? param.keyword : "";
    setKeyword(f.keyword);
    setFilter({...f});
    setTab(parseInt(param.tab) - 1)
  },[location])

  const wikiInfo = useWiki({
    listOption: tab == 0 && filter
  });

  const magazineInfo = useMagazine({
    listOption: tab == 1 && filter
  });

  const subalerInfo = useSubaler({
    listOption: tab == 2 && filter
  });

  const eventInfo = useEvent({
    listOption: tab == 3 && filter
  });

  const wikiData = wikiInfo.listQuery?.data ? wikiInfo.listQuery?.data : {};
  const magazineData = magazineInfo.listQuery?.data ? magazineInfo.listQuery?.data : {};
  const subalerData = subalerInfo.listQuery?.data ? subalerInfo.listQuery?.data : {};
  const eventData = eventInfo.listQuery?.data ? eventInfo.listQuery?.data : {};

  const list = tab == 0 ? wikiData.list : tab == 1 ? magazineData.list : tab == 2 ? subalerData.list : tab == 3 ? eventData.list : [];
  const pagination = tab == 0 ? wikiData.pagination : tab == 1 ? magazineData.pagination : tab == 2 ? subalerData.pagination : tab == 3 ? eventData.pagination : {};

  return (
    <Styles.Container>
      <div className="inner">
        <h2>
          {data.title} <span>({pagination?.totalRecordCountAll ? pagination?.totalRecordCountAll : 0})</span>
        </h2>

        {list?.length > 0 ? (
          <>
            <ul className="listWrap">
              {list?.slice(0, 10).map((item, index) => {
                return (
                  <li
                    className={data.class + "List"}
                    key={data.class + "list" + index}
                  >
                    <Link
                      to={
                        `/${data.class}/detail/${item.id}`
                      }
                    >
                      {renderComponents(item, tab, index)}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <div className="paginationContainer">
              <ui.button.Pagination
                page={pagination?.page}
                list={pagination?.page_list}
                maxPage={pagination?.totalPageCount}
              />
            </div>
          </>
        ) : (
          <div className="emptyContainer">
            <layout.EmptyContainer search text={"검색 결과가 없습니다."} />
          </div>
        )}
      </div>
    </Styles.Container>
  );
}

export default Result;
