// import "./styles.css";
import utils from "utils";
import Styles from "./styles";
import { clickDetail } from "utils/etc";
import { common, ui, layout } from "components";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLike, useModals, useReply, useScrap, useSubaler } from "hooks";

// img
import ImgUserDefault from "resources/image/img_default_profile.svg";
import IconEmpathyOff from "resources/image/icon/icon_empathy_off.svg";
import IconEmpathyOn from "resources/image/icon/icon_empathy_on.svg";
import IconLikeOff from "resources/image/icon/icon_like_off.svg";
import IconLikeOn from "resources/image/icon/icon_like_on.svg";
import IconLocate from "resources/image/icon/icon_locate.svg";
import IconEye from "resources/image/icon/icon_eye.svg";
import IconList from "resources/image/icon/icon_list.svg";
import IconList02 from "resources/image/icon/icon_list_02.svg";
import IconList03 from "resources/image/icon/icon_list_03.svg";
import IconMore from "resources/image/icon/icon_more.svg";

function CommunityDetailPage() {
  const modalOption = useModals();
  const { id } = useParams();
  const navigate = useNavigate();

  const subalerInfo = useSubaler({ detailOption: id });
  const scrapInfo = useScrap({ queryName: "subalerDetail" });
  const likeInfo = useLike({ queryName: "subalerDetail" });
  const subalerNotice = useSubaler({ notice: true });
  const replyInfo = useReply({
    listOption: {
      relate_info_id: id,
      type: 4,
    },
  });

  const notice = subalerNotice.getSubalerNotice?.data?.data
    ? subalerNotice.getSubalerNotice?.data?.data
    : [];
  const detail = subalerInfo.detailQuery.data?.data
    ? subalerInfo.detailQuery.data?.data
    : {};
  const [isMoreOpen, setIsMoreOpen] = useState(false);

  useEffect(() => {
    subalerInfo.detailQuery?.data?.success === false &&
      subalerInfo.accessRestrict();
  }, [subalerInfo.detailQuery?.data?.success]);

  return (
    <>
      <common.FixedBg />
      <div className="wrap">
        <common.SubHeader share pageName={"수발러 게시판"} />

        <Styles.Container>
          <section className="detailSection">
            <div className="inner">
              <div className="titleContainer">
                <div className="topContainer">
                  <div className="profileContainer">
                    <div className="profileImgWrap">
                      <img
                        src={
                          detail.profile_image
                            ? detail.profile_image
                            : ImgUserDefault
                        }
                        alt="profileImg"
                      />
                    </div>
                    {detail.nickname}{" "}
                    {detail.category_title === "사건사고" && (
                      <div className="person">
                        {detail.self_yn == "Y" ? "당사자" : "전달자"}
                      </div>
                    )}
                  </div>

                  <div className="moreButtonContainer">
                    <button
                      type="button"
                      className="moreButton"
                      onClick={() => {
                        setIsMoreOpen(!isMoreOpen);
                      }}
                    >
                      <img src={IconMore} alt="IconMore" />
                    </button>

                    {isMoreOpen && (
                      <ul className="moreListWrap">
                        {detail.my_yn == "Y" ? (
                          <>
                            <li className="moreList">
                              <button
                                type="button"
                                onClick={() => {
                                  setIsMoreOpen(false);
                                  navigate("/edit/" + id, {
                                    state: { type: detail.category_title },
                                  });
                                }}
                              >
                                수정
                              </button>
                            </li>

                            <li className="moreList">
                              <button
                                type="button"
                                onClick={() => {
                                  setIsMoreOpen(false);
                                  subalerInfo.deleteSubaler(id);
                                }}
                              >
                                삭제
                              </button>
                            </li>
                          </>
                        ) : (
                          <li className="moreList">
                            <button
                              type="button"
                              onClick={() => {
                                setIsMoreOpen(false);
                                modalOption.setReportModal((e) => {
                                  e.show = true;
                                  e.type = 4;
                                  e.id = id;
                                  return { ...e };
                                });
                              }}
                            >
                              신고
                            </button>
                          </li>
                        )}
                      </ul>
                    )}
                  </div>
                </div>

                <p className="title">{detail.title}</p>

                <div className="bottomContainer">
                  <p className="date">
                    {detail.created_at
                      ? utils.date.getDateType(detail.created_at)
                      : "-"}
                  </p>

                  <ul className="listWrap">
                    {detail?.category_title != "매거진" && (
                      <li className="list">
                        <div className="iconWrap">
                          <img src={IconLocate} alt="IconLocate" />
                        </div>
                        {detail.location ? detail.location : "지역 무관"}
                      </li>
                    )}

                    <li className="list">
                      <div className="iconWrap">
                        <img src={IconEye} alt="IconEye" />
                      </div>
                      {detail.view}
                    </li>

                    <li className="list">
                      <div className="iconWrap">
                        <img src={IconList} alt="IconList" />
                      </div>
                      {detail.reply_count}
                    </li>
                  </ul>
                </div>
              </div>

              {detail.category_title === "사건사고" ? (
                <div className="editorContainer">
                  <div className="guideText">
                    해당 의견은 개인적인 견해로, 펫트워크는 다양한 의견을
                    존중합니다.
                  </div>

                  <div className="summaryContainer">
                    <div className="summaryTitleContainer">
                      <div className="summary">
                        <div className="iconWrap">
                          <img src={IconList03} alt="IconList" />
                        </div>
                        사건 요약
                      </div>

                      <button
                        type="button"
                        className="linkButton"
                        onClick={() => {
                          // 새 창으로
                          detail.url && clickDetail(detail?.url);
                        }}
                      >
                        <div className="iconWrap">
                          <img src={IconList02} alt="IconList" />
                        </div>
                        원문 보러가기
                      </button>
                    </div>

                    <div
                      className="editor ck-content"
                      dangerouslySetInnerHTML={{ __html: detail?.description }}
                    ></div>
                  </div>

                  <div className="opinionContainer">
                    <div className="profileContainer">
                      <div className="profileImgWrap">
                        <img
                          src={
                            detail.profile_image
                              ? detail.profile_image
                              : ImgUserDefault
                          }
                          alt="profileImg"
                        />
                      </div>
                      <b>{detail.nickname}</b>님의 의견
                    </div>

                    <div
                      className="editor ck-content"
                      dangerouslySetInnerHTML={{ __html: detail?.review }}
                    ></div>
                  </div>
                </div>
              ) : (
                <div
                  className="editor ck-content"
                  dangerouslySetInnerHTML={{ __html: detail?.description }}
                ></div>
              )}

              <div className="reactContainer">
                <button
                  type="button"
                  className={
                    "empathyButton" + (detail.like_yn === "Y" ? " on" : "")
                  }
                  onClick={() => {
                    likeInfo.toggleLike(id, 4);
                  }}
                >
                  <span className="iconWrap">
                    <img
                      src={
                        detail.like_yn === "Y" ? IconEmpathyOn : IconEmpathyOff
                      }
                      alt="IconEmpathy"
                    />
                  </span>
                  {detail.like_count}
                </button>

                {`${notice.map((item) => item.id)}`.indexOf(id) === -1 ? (
                  <button
                    type="button"
                    className="likeButton"
                    onClick={() => {
                      scrapInfo.toggleScrap(id, -1, 4);
                    }}
                  >
                    <img
                      src={detail.scrap_yn === "Y" ? IconLikeOn : IconLikeOff}
                      alt="IconLike"
                    />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="likeButton"
                    onClick={() => {
                      scrapInfo.toggleScrap(id, -1, 5);
                    }}
                  >
                    <img
                      src={detail.scrap_yn === "Y" ? IconLikeOn : IconLikeOff}
                      alt="IconLike"
                    />
                  </button>
                )}
              </div>

              <ul className="keywordListWrap">
                {detail.keyword_list?.map((item, index) => {
                  return (
                    <li className="keywordList" key={"keywordList" + index}>
                      <ui.button.Keyword
                        text={item.title}
                        onClick={() => {
                          navigate(`/search/detail?keyword=${item.title}`);
                        }}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </section>

          <section className="commentSection">
            <div className="inner">
              <layout.Comment replyInfo={replyInfo} detail={detail} />
            </div>
          </section>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default CommunityDetailPage;
