import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import { common, ui, layout } from "components";
import { useEffect, useState } from "react";

// img
import IconHeart from "resources/image/icon/icon_heart.svg";
import { useAuth } from "hooks";

function SignupStepBPage() {
  const navigate = useNavigate();
  const authInfo = useAuth({});

  const init = [
    { id: 1, title: "테크상품" },
    { id: 2, title: "맞춤 제작" },
    { id: 3, title: "관련 콘텐츠" },
    { id: 4, title: "동물 보호" },
    { id: 5, title: "동반 정보" },
    { id: 6, title: "전문 자격증" },
    { id: 7, title: "사건사고" },
    { id: 8, title: "패션용품" },
    { id: 9, title: "럭셔리 신상" },
    { id: 10, title: "관련 법규" },
    { id: 11, title: "행동 교육" },
    { id: 12, title: "지자체 지원" },
    { id: 13, title: "보호자 교육" },
    { id: 14, title: "어질리티" },
    { id: 15, title: "피트니스" },
    { id: 16, title: "액티비티" }
  ]
  const [interest, setInterest] = useState(init);
  const [errorText, setErrorText] = useState("")

  useEffect(()=>{
    if(authInfo.isLogin){
      window.location.replace("/");
    }
  },[authInfo])

  useEffect(()=>{
    if(JSON.parse(sessionStorage.getItem("userInfo"))?.interest_list){
      setInterest(() => {
        return init.map((item) => JSON.parse(sessionStorage.getItem("userInfo")).interest_list.indexOf(item.id) === -1 ? item : { ...item, checked: true })
      })
    }
  }, [sessionStorage.getItem("userInfo")])


  const myInterest = interest
    .filter((v) => { return v.checked === true })
    .map((v) => { return v.id })
  
  const validate = () => {
    if (myInterest.length === 0) {
      setErrorText("관심사를 선택해주세요.");
      return false;
    }
    if (myInterest.length > 3) {
      setErrorText("최대 3개까지 선택 가능합니다.");
      return false;
    }
    return true;
  }

  const onNext = () => {
    if (validate()) {
      navigate("/signup/stepC");
      sessionStorage.setItem("userInfo", JSON.stringify({
        ...JSON.parse(sessionStorage.getItem("userInfo")), interest_list: myInterest
      }));
    }
  }

  return (
    sessionStorage.getItem("userInfo") &&
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.SubHeader pageName={"회원가입"} />

        <Styles.Container>
          <div className="inner">
            <form autoComplete="off">
              <p className="title">
                관심사 <small>(최대 3개)</small>
              </p>

              <ul className="listWrap">
                {interest.map((item, index) => {
                  return (
                    <li className="list" key={"list" + index}>
                      <ui.input.BoxCheckbox
                        item={item}
                        setValue={setInterest}
                        name={"interest"}
                        icon={IconHeart}
                        isMulti={true}
                      />
                    </li>
                  );
                })}
              </ul>

              <div className="errorText">
                {errorText &&
                  <layout.ConfirmText error text={errorText} />
                }
              </div>

              <div className="nextButton">
                <ui.button.BasicButton
                  buttonText={"다음으로"}
                  onClick={onNext}
                />
              </div>
            </form>
          </div>
        </Styles.Container>
      </div>
    </>
  );
}

export default SignupStepBPage;
