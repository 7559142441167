import { api } from "service";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import utils from "utils";

export default function useSupport({
  options = {
    policy: false,
    faqList: false,
    noticeList: false,
    noticeDetail: false,
  },
}) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [type, setType] = useState();
  const [filter, setFilter] = useState(null);

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? param.page : 1,
      pageSize: 5,
      recordSize: 10,
    };
    setFilter({ ...f });
  }, [location]);

  const getPolicy = useQuery(
    ["policy", type],
    () => {
      return api.support.getPolicy(type);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!options.policy,
    }
  );
  const getFaqList = useQuery(
    ["faq_list", filter],
    () => {
      return api.support.getFaqList(filter);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!options.faqList,
    }
  );

  const getNoticeList = useQuery(
    ["notice_list", filter],
    () => {
      return api.support.getNoticeList(filter);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!options.noticeList && !!filter,
    }
  );

  const getNoticeHoldList = useQuery(
    ["notice_list", { ...filter, page: 1, hold_yn: "Y" }],
    () => {
      return api.support.getNoticeList({ ...filter, page: 1, hold_yn: "Y" });
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!options.noticeList && !!filter,
    }
  );

  const getNoticeDetail = useQuery(
    ["notice_detail", options.id || searchParams?.get("id")],
    () => {
      return api.support.getNoticeDetail(options.id || searchParams?.get("id"));
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!options.noticeDetail,
    }
  );

  return {
    type,
    setType,
    filter,
    setFilter,

    getPolicy: getPolicy.data?.data ? getPolicy.data?.data : {},
    getFaqList: getFaqList.data ? getFaqList.data : {},
    getNoticeList: getNoticeList.data ? getNoticeList.data : {},
    getNoticeHoldList: getNoticeHoldList.data ? getNoticeHoldList.data : {},
    getNoticeDetail: getNoticeDetail.data ? getNoticeDetail.data : {},
  };
}
