import { useAtom } from "jotai";
import { modalAtom } from "store/atoms";

export default function useModals() {
  const [closeFlag, setCloseFlag] = useAtom(modalAtom.modalFlagAtom);
  const [defaultModal, setDefaultModal] = useAtom(modalAtom.defaultModalAtom);
  const [reviewModal, setReviewModal] = useAtom(modalAtom.reviewModalAtom);
  const [deleteModal, setDeleteModal] = useAtom(modalAtom.deleteModalAtom);
  const [reportModal, setReportModal] = useAtom(modalAtom.reportModalAtom);
  const [webViewModal, setWebViewModal] = useAtom(modalAtom.webViewModalAtom);

  const clearModals = () => {
    setDefaultModal({ show: false });
    setReviewModal({ show: false });
    setDeleteModal({ show: false });
    setReportModal({ show: false });
    setWebViewModal({ show: false });
  };

  const clearSubModals = (callback) => {
    setCloseFlag((e) => !e);
    setTimeout(() => {
      callback();
    }, [0]);
  };

  return {
    clearModals,
    closeFlag,
    clearSubModals,
    defaultModal,
    setDefaultModal,
    reviewModal,
    setReviewModal,
    deleteModal,
    setDeleteModal,
    reportModal,
    setReportModal,
    webViewModal,
    setWebViewModal,
  };
}
