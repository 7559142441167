import styled from "styled-components";

const Container = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
  width: max-content;
  font-size: 16px;
  color: var(--main);

  &.error {
    color: var(--red);
  }

  & .iconWrap {
    display: inline-block;
    width: 19px;
    height: 19px;
  }

  @media (max-width: 499px) {
    gap: calc(100vw * (4 / 500));
    font-size: calc(100vw * (16 / 500));

    & .iconWrap {
      width: calc(100vw * (19 / 500));
      height: calc(100vw * (19 / 500));
    }
  }
`;

export { Container };

export default { Container };
