import Styles from "./styles";

function BoxRadio({ id, name, icon, labelText, checked, setInputs, maxCount }) {
  const onChange = (e) => {
    const { name, id } = e.target;

    setInputs((e) => {
      // if(e && (e[name] == null && Object.keys(e).length == maxCount))
      //   return {...e};
      // else if(e && e[name] == id){
      //   delete e[name];
      //   return {...e};
      // }
      return { ...e, [name]: id };
    });

    // e.preventDefault();
    // e.stopPropagation();
  };

  return (
    <>
      <Styles.Input
        id={id}
        name={name}
        type="radio"
        checked={checked === id}
        onChange={onChange}
        // onClick={onChange}
      />

      <Styles.Label htmlFor={id}>
        <span className="labelText">{labelText}</span>
        {icon && (
          <span className="iconWrap">
            <img src={icon} alt={icon} />
          </span>
        )}
      </Styles.Label>
    </>
  );
}

export default BoxRadio;
