import { api } from "service";
import { useQuery } from "react-query";

export default function useBusiness({
  business,
}) {

  const getBusiness = useQuery(["business"], () => {
    return api.business.getBusiness();
  }, {
    refetchOnWindowFocus: false, 
    enabled: !!business
  });

  return {
    getBusiness
  }
}