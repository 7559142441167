import styled from "styled-components";

// img
import IconPlus from "resources/image/icon/icon_plus.svg";
import ImgWikiBanner from "resources/image/img_main_wiki_section.png";

const Container = styled.div`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 60px;

  & section:not(.section01, .section02) {
    margin-bottom: 60px;
  }

  & .section01 {
    & .bannerSlider {
      position: relative;

      & .slideList {
        height: 370px;
        cursor: pointer;
      }

      & .swiper-pagination-fraction {
        top: auto;
        left: auto;
        bottom: 20px;
        right: 20px;
        display: flex;
        align-items: center;
        gap: 3px;
        width: 92px;
        height: 35px;
        padding: 0 16px;
        font-size: 16px;
        font-weight: 500;
        color: #a5a5a5;
        border-radius: 500px;
        background: url(${IconPlus}) no-repeat calc(100% - 15px) center / 10px
          rgba(51, 51, 51, 0.7);
      }

      & .swiper-pagination-current {
        color: #fff;
      }
    }
  }

  & .rollingContainer {
    display: flex;
    align-items: center;
    height: 53px;
    padding: 0 20px;
    background-color: var(--gray01);
    cursor: pointer;

    & .date {
      position: relative;
      margin-right: 15px;
      padding-right: 15px;
      font-size: 20px;
      font-weight: bold;

      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 17px;
        background-color: var(--gray02);
        content: "";
      }
    }

    & .marqueeContainer {
      width: calc(100% - 73px);
    }

    & .marqueeText {
      gap: 10px;
    }
  }

  & .section02 {
    margin: 40px 0;
    padding-bottom: 40px;
    border-bottom: 1px solid #e4e7f0;

    & .keywordListWrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      margin-top: 20px;
    }
  }

  & .section03 {
    & .inner {
      margin-bottom: 30px;
    }

    & .eventSlider {
      padding-left: 20px;

      & .swiper-slide {
        width: 255px;
        padding-right: 20px;
        box-sizing: content-box;
      }
    }
  }

  & .section04 {
    margin-top: 60px;

    & .listWrap {
      margin-top: 30px;

      & .list:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  & .section05 {
    & .wikiBanner {
      display: flex;
      align-items: flex-end;
      margin: 30px 0;
      height: 257px;
      padding: 20px;
      line-height: 1.33;
      color: #fff;
      background: url(${ImgWikiBanner}) no-repeat center / cover;
      cursor: pointer;
    }

    & .sliderTitleContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      & .title {
        font-size: 20px;
        font-weight: bold;
      }

      & .moreButton {
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 14px;
        font-weight: 500;

        & .iconWrap {
          width: 13px;
          height: 13px;
        }
      }
    }

    & .swiper-slide {
      /* width: 140px; */
    }
  }

  & .section06 {
    padding: 60px 0 45px;
    background-color: var(--gray01);

    & .date {
      margin: 20px 0 8px;
      font-size: 14px;
      font-weight: 500;
      color: var(--gray03);
    }

    & .description {
      margin-bottom: 30px;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.35;
    }

    & .listWrap {
      margin-bottom: 30px;

      & .list:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  & .section07 {
    & .slideList {
      height: 149px;
      cursor: pointer;
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (60 / 500));

    & section:not(.section01, .section02) {
      margin-bottom: calc(100vw * (60 / 500));
    }

    & .section01 {
      & .bannerSlider {
        & .slideList {
          height: calc(100vw * (370 / 500));
          cursor: pointer;
        }

        & .swiper-pagination-fraction {
          bottom: calc(100vw * (20 / 500));
          right: calc(100vw * (20 / 500));
          gap: calc(100vw * (3 / 500));
          width: calc(100vw * (92 / 500));
          height: calc(100vw * (35 / 500));
          padding: 0 calc(100vw * (16 / 500));
          font-size: calc(100vw * (16 / 500));
          border-radius: calc(100vw * (500 / 500));
          background: url(${IconPlus}) no-repeat
            calc(100% - calc(100vw * (15 / 500))) center /
            calc(100vw * (10 / 500)) rgba(51, 51, 51, 0.7);
        }
      }
    }

    & .rollingContainer {
      height: calc(100vw * (53 / 500));
      padding: 0 calc(100vw * (20 / 500));

      & .date {
        margin-right: calc(100vw * (15 / 500));
        padding-right: calc(100vw * (15 / 500));
        font-size: calc(100vw * (20 / 500));

        &::after {
          height: calc(100vw * (17 / 500));
        }
      }

      & .marqueeContainer {
        width: calc(100% - calc(100vw * (73 / 500)));
      }

      & .marqueeText {
        gap: calc(100vw * (10 / 500));
      }
    }

    & .section02 {
      margin: calc(100vw * (40 / 500)) 0;
      padding-bottom: calc(100vw * (40 / 500));

      & .keywordListWrap {
        gap: calc(100vw * (10 / 500));
        margin-top: calc(100vw * (20 / 500));
      }
    }

    & .section03 {
      & .inner {
        margin-bottom: calc(100vw * (30 / 500));
      }

      & .eventSlider {
        padding-left: calc(100vw * (20 / 500));

        & .swiper-slide {
          width: calc(100vw * (255 / 500));
          padding-right: calc(100vw * (20 / 500));
          box-sizing: content-box;
        }
      }
    }

    & .section04 {
      margin-top: calc(100vw * (60 / 500));

      & .listWrap {
        margin-top: calc(100vw * (30 / 500));

        & .list:not(:last-child) {
          margin-bottom: calc(100vw * (15 / 500));
        }
      }
    }

    & .section05 {
      & .wikiBanner {
        margin: calc(100vw * (30 / 500)) 0;
        height: calc(100vw * (257 / 500));
        padding: calc(100vw * (20 / 500));
        cursor: pointer;
      }

      & .sliderTitleContainer {
        margin-bottom: calc(100vw * (30 / 500));

        & .title {
          font-size: calc(100vw * (20 / 500));
        }

        & .moreButton {
          gap: calc(100vw * (6 / 500));
          font-size: calc(100vw * (14 / 500));

          & .iconWrap {
            width: calc(100vw * (13 / 500));
            height: calc(100vw * (13 / 500));
          }
        }
      }

      & .swiper-slide {
        /* width: calc(100vw * (140 / 500)); */
      }
    }

    & .section06 {
      padding: calc(100vw * (60 / 500)) 0 calc(100vw * (45 / 500));

      & .date {
        margin: calc(100vw * (20 / 500)) 0 calc(100vw * (8 / 500));
        font-size: calc(100vw * (14 / 500));
      }

      & .description {
        margin-bottom: calc(100vw * (30 / 500));
        font-size: calc(100vw * (20 / 500));
      }

      & .listWrap {
        margin-bottom: calc(100vw * (30 / 500));

        & .list:not(:last-child) {
          margin-bottom: calc(100vw * (10 / 500));
        }
      }
    }

    & .section07 {
      & .slideList {
        height: calc(100vw * (149 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
