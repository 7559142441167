import Styles from "./styles";
import { useState } from "react";
import { common, layout } from "components";
import { useReply, useVote } from "hooks";
import { useNavigate, useParams } from "react-router-dom";

function VoteResultPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const voteInfo = useVote({
    detailOption: id,
  });
  const detail = voteInfo.detailQuery.data?.data || {};
  const [vote, setVote] = useState(2);
  const replyInfo = useReply({
    listOption: {
      relate_info_id: id,
      type: 6,
    },
  });

  const doToggleVote = async () => {
    await voteInfo.toggleVote(
      JSON.stringify({
        vote_group_info_id: id,
        vote_info_id: vote,
      })
    );
    if (window.location.pathname != `/vote/result/${id}`)
      navigate(`/vote/result/${id}`);
  };

  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.SubHeader share pageName={"견심전심 투표"} />

        <Styles.Container>
          <section className="section01">
            <div className="inner">
              <layout.VoteContainer
                data={detail}
                vote={vote}
                setVote={setVote}
                doToggleVote={doToggleVote}
              />
            </div>
          </section>

          <section className="commentSection">
            <div className="inner">
              <layout.Comment replyInfo={replyInfo} />
            </div>
          </section>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default VoteResultPage;
