import styled from "styled-components";

// img
import IconEssential from "resources/image/icon/icon_essential.svg";

const Container = styled.div`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 110px;

  & section {
    &:not(:last-of-type) .inner {
      padding-bottom: 40px;
      border-bottom: 1px solid var(--gray02);
    }

    & .inner {
      padding-top: 40px;

      & h2 {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  & .infoSection {
    & .list {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      & .confirmButton {
        width: 120px;
      }

      & .listTitle {
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 500;
      }

      & .selectContainer {
        width: 134px;
      }

      & .genderListWrap {
        display: flex;
        gap: 10px;

        & .genderList {
          width: calc(calc(100% - 10px) / 2);

          & .iconWrap {
            width: 34px;
          }
        }
      }
    }
  }

  & .interestSection {
    & h2 {
      position: relative;
      display: flex;
      align-items: center;
      gap: 4px;
      width: fit-content;
      margin-bottom: 20px;

      &::after {
        position: absolute;
        top: 50%;
        right: -12px;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        background: url(${IconEssential}) no-repeat center / cover;
        content: "";
      }

      & span {
        font-size: 14px;
        font-weight: normal;
      }
    }

    & .listWrap {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      & .list {
        width: calc(calc(100% - 10px) / 2);

        & .iconWrap {
          width: 26px;
        }
      }
    }
  }

  & .experienceSection {
    & h2 {
      margin-bottom: 15px;
    }

    & .list {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 20px;

      &:not(:last-child) {
        margin-bottom: 14px;
      }

      & input {
        display: flex;
        justify-content: center;
        width: 52px;
        height: 34px;
        font-size: 20px;
        background-color: var(--gray01);
        border-radius: 10px;
        text-align: center;
      }
    }
  }

  & .profitSection {
    & h2 {
      margin-bottom: 20px;
    }

    & .list:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  & .checkSection {
    margin-bottom: 61px;

    & h2 {
      margin-bottom: 30px;
    }

    & .list:not(:last-child) {
      margin-bottom: 19px;
    }
  }

  & .buttonContainer {
    position: relative;

    & .errorTextContainer {
      position: absolute;
      left: 50%;
      top: -29px;
      transform: translateX(-50%);

      & p {
        width: max-content;
      }
    }

    & .confirmButton {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (110 / 500));

    & section {
      &:not(:last-of-type) .inner {
        padding-bottom: calc(100vw * (40 / 500));
      }

      & .inner {
        padding-top: calc(100vw * (40 / 500));

        & h2 {
          font-size: calc(100vw * (20 / 500));
        }
      }
    }

    & .infoSection {
      & .list {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (20 / 500));
        }

        & .confirmButton {
          width: calc(100vw * (120 / 500));
        }

        & .listTitle {
          margin-bottom: calc(100vw * (10 / 500));
          font-size: calc(100vw * (20 / 500));
        }

        & .selectContainer {
          width: calc(100vw * (134 / 500));
        }

        & .genderListWrap {
          gap: calc(100vw * (10 / 500));

          & .genderList {
            width: calc(calc(100% - calc(100vw * (10 / 500))) / 2);

            & .iconWrap {
              width: calc(100vw * (34 / 500));
            }
          }
        }
      }
    }

    & .interestSection {
      & h2 {
        gap: calc(100vw * (4 / 500));
        margin-bottom: calc(100vw * (20 / 500));

        &::after {
          right: calc(100vw * (-12 / 500));
          width: calc(100vw * (8 / 500));
          height: calc(100vw * (8 / 500));
        }

        & span {
          font-size: calc(100vw * (14 / 500));
        }
      }

      & .listWrap {
        gap: calc(100vw * (10 / 500));

        & .list {
          width: calc(calc(100% - calc(100vw * (10 / 500))) / 2);

          & .iconWrap {
            width: calc(100vw * (26 / 500));
          }
        }
      }
    }

    & .experienceSection {
      & h2 {
        margin-bottom: calc(100vw * (15 / 500));
      }

      & .list {
        gap: calc(100vw * (10 / 500));
        font-size: calc(100vw * (20 / 500));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (14 / 500));
        }

        & input {
          width: calc(100vw * (52 / 500));
          height: calc(100vw * (34 / 500));
          font-size: calc(100vw * (20 / 500));
          border-radius: calc(100vw * (10 / 500));
        }
      }
    }

    & .profitSection {
      & h2 {
        margin-bottom: calc(100vw * (20 / 500));
      }

      & .list:not(:last-child) {
        margin-bottom: calc(100vw * (20 / 500));
      }
    }

    & .checkSection {
      margin-bottom: calc(100vw * (61 / 500));

      & h2 {
        margin-bottom: calc(100vw * (30 / 500));
      }

      & .list:not(:last-child) {
        margin-bottom: calc(100vw * (19 / 500));
      }
    }

    & .buttonContainer {
      & .errorTextContainer {
        top: calc(100vw * (-29 / 500));
      }

      & .confirmButton {
        margin-bottom: calc(100vw * (20 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
