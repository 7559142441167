import styled from "styled-components";

const Container = styled.div`
  & .imgWrap {
    position: relative;
    height: 281px;
    margin-bottom: 20px;
    border-radius: 5px;
    overflow: hidden;
  }

  & .textContainer {
    display: flex;
    justify-content: space-between;

    & .left {
      width: 400px;

      & .title,
      .description {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      & .title {
        margin-bottom: 6px;
        font-size: 22px;
        font-weight: bold;
        line-height: 1.18;
        white-space: nowrap;
      }

      & .description {
        display: -webkit-box;
        margin-bottom: 20px;
        line-height: 1.44;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
      }

      & .dateContainer {
        display: flex;
        gap: 12px;
        font-size: 16px;
        font-weight: 500;
        color: var(--gray03);

        & > div {
          display: flex;
          align-items: center;
          gap: 6px;
        }

        & .iconWrap {
          width: 18px;
          height: 18px;
        }
      }
    }

    & .likeButton {
      flex-shrink: 0;
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 499px) {
    & .imgWrap {
      height: calc(100vw * (281 / 500));
      margin-bottom: calc(100vw * (20 / 500));
      border-radius: calc(100vw * (5 / 500));
    }

    & .textContainer {
      & .left {
        width: calc(100vw * (400 / 500));

        & .title {
          margin-bottom: calc(100vw * (6 / 500));
          font-size: calc(100vw * (22 / 500));
        }

        & .description {
          margin-bottom: calc(100vw * (20 / 500));
          white-space: pre-wrap;
        }

        & .dateContainer {
          gap: calc(100vw * (12 / 500));
          font-size: calc(100vw * (16 / 500));

          & > div {
            gap: calc(100vw * (6 / 500));
          }

          & .iconWrap {
            width: calc(100vw * (18 / 500));
            height: calc(100vw * (18 / 500));
          }
        }
      }

      & .likeButton {
        width: calc(100vw * (30 / 500));
        height: calc(100vw * (30 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
