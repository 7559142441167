import styled from "styled-components";

const Container = styled.div`
  margin-bottom: 300px;

  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 110px;

  & .inner {
    padding-top: 30px;
  }

  & .detailSection {
    border-bottom: 20px solid var(--gray01);

    & .imgWrap {
      height: 306px;
      margin-bottom: 30px;
      border-radius: 10px;
      overflow: hidden;
    }

    & .titleContainer {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 20px;

      & .title {
        font-size: 26px;
        font-weight: 600;
      }

      & .likeButton {
        flex-shrink: 0;
        width: 30px;
        height: 30px;
      }
    }

    & .listWrap {
      padding-bottom: 30px;
      border-bottom: 1px solid var(--gray04);

      & .list {
        display: flex;
        font-size: 16px;
        font-weight: 500;
        color: var(--gray03);
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        & .iconWrap {
          flex-shrink: 0;
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }

        & button {
          margin-left: 10px;
          font-size: 16px;
          font-weight: 500;
          color: var(--black);
          text-decoration: underline;
          text-underline-position: from-font;
        }
      }
    }

    & .description {
      padding: 30px 0;
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (110 / 500));

    & .inner {
      padding-top: calc(100vw * (30 / 500));
    }

    & .detailSection {
      border-bottom: calc(100vw * (20 / 500)) solid var(--gray01);

      & .imgWrap {
        height: calc(100vw * (306 / 500));
        margin-bottom: calc(100vw * (30 / 500));
        border-radius: calc(100vw * (10 / 500));
      }

      & .titleContainer {
        gap: calc(100vw * (10 / 500));
        margin-bottom: calc(100vw * (20 / 500));

        & .title {
          font-size: calc(100vw * (26 / 500));
        }

        & .likeButton {
          width: calc(100vw * (30 / 500));
          height: calc(100vw * (30 / 500));
        }
      }

      & .listWrap {
        padding-bottom: calc(100vw * (30 / 500));

        & .list {
          font-size: calc(100vw * (16 / 500));

          &:not(:last-child) {
            margin-bottom: calc(100vw * (10 / 500));
          }

          & .iconWrap {
            width: calc(100vw * (18 / 500));
            height: calc(100vw * (18 / 500));
            margin-right: calc(100vw * (4 / 500));
          }

          & a {
            margin-left: calc(100vw * (10 / 500));
            font-size: calc(100vw * (16 / 500));
          }
        }
      }

      & .description {
        padding: calc(100vw * (30 / 500)) 0;
      }
    }
  }
`;

export { Container };

export default { Container };
