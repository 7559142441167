import utils from "utils";
import Styles from "./styles";
import { api } from "service";
import { DOMAIN } from "service/connector";
import { useAuth } from "hooks";
import { getDateType } from "utils/date";
import { common, ui, layout } from "components";
import { useState, useEffect } from "react";
import {
  useNavigate,
  Link,
  useSearchParams,
  useLocation,
} from "react-router-dom";

// img
import IconWomanOn from "resources/image/icon/icon_woman_on.svg";
import IconWomanOff from "resources/image/icon/icon_woman_off.svg";
import IconManOn from "resources/image/icon/icon_man_on.svg";
import IconManOff from "resources/image/icon/icon_man_off.svg";

function SignupStepAPage() {
  const authInfo = useAuth({});
  const location = useLocation();
  const navigate = useNavigate();
  const [serchParams] = useSearchParams();

  const [check, setCheck] = useState([]);
  const [myDate, setMyDate] = useState("");
  const [inputs, setInputs] = useState({
    nickname: "",
    email: "",
    location: "",
    gender: null,
  });
  const [nameCheck, setNameCheck] = useState("");
  const [errorText, setErrorText] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (sessionStorage.getItem("userInfo")) {
      setInputs((e) => {
        return { ...e, ...JSON.parse(sessionStorage.getItem("userInfo")) };
      });
      JSON.parse(sessionStorage.getItem("userInfo")).birthday
        ? setMyDate({
            birthday: utils.date.getDateValidate(
              JSON.parse(sessionStorage.getItem("userInfo")).birthday
            ),
          })
        : setMyDate({ birthday: null });
      setCheck(["check01", "check02"]);
    }
  }, [location.state]);

  useEffect(() => {
    if (!serchParams.get("token")) {
      // window.location.replace('/');
      // return;
    } else {
      document.cookie = `jwt=${serchParams.get(
        "token"
      )};path=/;domain=${DOMAIN}`;
      // serchParams.get("token") &&
      // navigate("/signup/stepA", { state: true, replace: true });
    }
  }, []);

  useEffect(() => {
    if (authInfo.isLogin) {
      window.location.replace("/");
    } else {
      setInputs((e) => {
        return { ...e, email: authInfo?.userData?.email };
      });
    }
  }, [authInfo?.userData]);

  useEffect(() => {
    setNameCheck("");
    inputs.nickname !== "" ? setIsDisabled(false) : setIsDisabled(true);
  }, [inputs.nickname]);

  const validate = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (check.length !== 2) {
      setErrorText("이용약관에 동의해주세요.");
      return false;
    }
    if (inputs.nickname === "") {
      setErrorText("닉네임을 입력해주세요.");
      return false;
    }
    if (nameCheck === "" || nameCheck === false) {
      setErrorText("닉네임 중복확인을 해주세요.");
      return false;
    }
    if (emailRegex.test(inputs.email) === false) {
      setErrorText("이메일을 입력해주세요.");
      return false;
    }
    return true;
  };

  const onNameCheck = async () => {
    const result = await authInfo.findNickName(inputs.nickname);
    result?.success ? setNameCheck(true) : setNameCheck(false);
  };

  const onNext = () => {
    if (validate()) {
      sessionStorage.setItem(
        "userInfo",
        JSON.stringify({
          ...inputs,
          birthday: getDateType(myDate.birthday, "-"),
          personal_policy_yn: "Y",
          policy_yn: "Y",
        })
      );
      navigate("/signup/stepB");
    }
  };

  const handleSingleCheck = (checked, id) => {
    if (checked) {
      setCheck(check.concat(id));
    } else {
      setCheck(check.filter((el) => el !== id));
    }
  };

  // map
  const checkList = [
    {
      id: "check01",
      labelText: "[필수] 서비스 이용약관에 동의합니다.",
      url: "service",
    },
    {
      id: "check02",
      labelText: "[필수] 개인정보처리방침에 동의합니다.",
      url: "privacy",
    },
  ];

  return (
    // location.state &&
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.SubHeader pageName={"회원가입"} />

        <Styles.Container>
          <div className="inner">
            <form autoComplete="off">
              <div className="termsContainer">
                <h2 className="title">펫트워크 이용약관에 동의해주세요.</h2>

                <ul className="checkListWrap">
                  {checkList?.map((item, index) => {
                    return (
                      <li className="checkList" key={"checkList" + index}>
                        <ui.input.BasicCheckbox
                          id={item.id}
                          labelText={item.labelText}
                          onChange={(e) =>
                            handleSingleCheck(e.target.checked, e.target.id)
                          }
                          checked={check.includes(item.id) ? true : false}
                        />

                        <Link
                          to={"/notice/" + item.url}
                          className="termsButton"
                        >
                          보기
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="infoContainer">
                <h2 className="title">수발러 정보</h2>

                <ul className="listWrap">
                  <li className="list">
                    <ui.input.BasicInput
                      labelText={"닉네임"}
                      essential
                      placeholder={"활동명을 입력하세요."}
                      name={"nickname"}
                      value={inputs.nickname}
                      setInputs={setInputs}
                      errorText={
                        nameCheck === false && "사용 불가능한 닉네임입니다."
                      }
                      confirmText={
                        nameCheck === true && "사용 가능한 닉네임입니다."
                      }
                      nameType
                    >
                      <div className="confirmButton">
                        <ui.button.BasicButton
                          buttonText={"중복확인"}
                          buttonType={"black"}
                          size={"small"}
                          disabled={isDisabled}
                          onClick={onNameCheck}
                        />
                      </div>
                    </ui.input.BasicInput>
                  </li>

                  <li className="list">
                    <ui.input.BasicInput
                      labelText={"e-mail"}
                      essential
                      placeholder={"e-mail을 입력하세요."}
                      name={"email"}
                      value={inputs.email}
                      setInputs={setInputs}
                    />
                  </li>

                  <li className="list">
                    <ui.input.DateInput
                      labelText={"생년월일"}
                      name={"birthday"}
                      startDate={myDate.birthday}
                      setStartDate={setMyDate}
                    />
                  </li>

                  <li className="list">
                    <p className="listTitle">지역</p>

                    <div className="selectContainer">
                      <ui.input.BasicSelect
                        optionList={api.dummy.locationList}
                        placeholder={"지역"}
                        size={"small"}
                        select={inputs.location}
                        setSelect={(v) => {
                          setInputs((e) => {
                            return { ...e, location: v };
                          });
                        }}
                      />
                    </div>
                  </li>

                  <li className="list">
                    <p className="listTitle">성별</p>

                    <ul className="genderListWrap">
                      <li className="genderList">
                        <ui.input.BoxRadio
                          labelText={"여성"}
                          id={"woman"}
                          icon={
                            inputs.gender === "woman"
                              ? IconWomanOn
                              : IconWomanOff
                          }
                          name={"gender"}
                          checked={inputs.gender}
                          setInputs={setInputs}
                        />
                      </li>

                      <li className="genderList">
                        <ui.input.BoxRadio
                          labelText={"남성"}
                          id={"man"}
                          icon={
                            inputs.gender === "man" ? IconManOn : IconManOff
                          }
                          name={"gender"}
                          checked={inputs.gender}
                          setInputs={setInputs}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              <div className="errorText">
                {errorText && <layout.ConfirmText error text={errorText} />}
              </div>

              <div className="nextButton">
                <ui.button.BasicButton
                  buttonText={"다음으로"}
                  onClick={onNext}
                />
              </div>
            </form>
          </div>
        </Styles.Container>
      </div>
    </>
  );
}

export default SignupStepAPage;
