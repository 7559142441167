import Styles from "./styles";

// datepicker
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DateInput({ labelText, essential, name, startDate, setStartDate }) {
  return (
    <Styles.Container>
      <label className={essential ? "essential" : ""}>
        <span>{labelText}</span>
      </label>

      <div className="inputContainer">
        <ReactDatePicker
          dateFormat="yyyy.MM.dd"
          selected={startDate}
          onChange={(date) => {
            setStartDate((e) => {
              return { ...e, [name]: date };
            });
          }}
          placeholderText="YYYY.MM.DD"
          showMonthDropdown
          showYearDropdown
          name={name}
          maxDate={new Date()}
          dropdownMode="select"
        />
      </div>
    </Styles.Container>
  );
}

export default DateInput;
