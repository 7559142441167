import IntroPage from "./IntroPage";
import MainPage from "./MainPage";
import LoginPage from "./LoginPage";
import SignupStepAPage from "./SignupStepAPage";
import SignupStepBPage from "./SignupStepBPage";
import SignupStepCPage from "./SignupStepCPage";
import SignupCompletePage from "./SignupCompletePage";
import EventPage from "./EventPage";
import EventDetailPage from "./EventDetailPage";
import MagazinePage from "./MagazinePage";
import MagazineDetailPage from "./MagazineDetailPage";
import IntroducePage from "./IntroducePage";
import CommunityPage from "./CommunityPage";
import CommunityDetailPage from "./CommunityDetailPage";
import VotePage from "./VotePage";
import VoteResultPage from "./VoteResultPage";
import BusinessPage from "./BusinessPage";
import NoticePage from "./NoticePage";
import NoticeDetailPage from "./NoticeDetailPage";
import NotificationPage from "./NotificationPage";
import SearchPage from "./SearchPage";
import SearchDetailPage from "./SearchDetailPage";
import WritingPage from "./WritingPage";
import EditPage from "./EditPage";
import WikiPage from "./WikiPage";
import WikiCategoryPage from "./WikiCategoryPage";
import WikiDetailPage from "./WikiDetailPage";
import MyPage from "./MyPage";
import UserInfoPage from "./UserInfoPage";
import DogInfoStepAPage from "./DogInfoStepAPage";
import DogInfoStepBPage from "./DogInfoStepBPage";
import DogInfoStepCPage from "./DogInfoStepCPage";
import DogInfoStepDPage from "./DogInfoStepDPage";
import MyScrabPage from "./MyScrabPage";
import MyActivityPage from "./MyActivityPage";
import ModalGeneratePage from "./ModalGeneratePage";

export default {
  IntroPage,
  MainPage,
  LoginPage,
  SignupStepAPage,
  SignupStepBPage,
  SignupStepCPage,
  SignupCompletePage,
  EventPage,
  EventDetailPage,
  MagazinePage,
  MagazineDetailPage,
  IntroducePage,
  CommunityPage,
  CommunityDetailPage,
  VotePage,
  VoteResultPage,
  BusinessPage,
  NoticePage,
  NoticeDetailPage,
  NotificationPage,
  SearchPage,
  SearchDetailPage,
  WritingPage,
  EditPage,
  WikiPage,
  WikiCategoryPage,
  WikiDetailPage,
  MyPage,
  UserInfoPage,
  DogInfoStepAPage,
  DogInfoStepBPage,
  DogInfoStepCPage,
  DogInfoStepDPage,
  MyScrabPage,
  MyActivityPage,
  ModalGeneratePage,
};

export {
  IntroPage,
  MainPage,
  LoginPage,
  SignupStepAPage,
  SignupStepBPage,
  SignupStepCPage,
  SignupCompletePage,
  EventPage,
  EventDetailPage,
  MagazinePage,
  MagazineDetailPage,
  IntroducePage,
  CommunityPage,
  CommunityDetailPage,
  VotePage,
  VoteResultPage,
  BusinessPage,
  NoticePage,
  NoticeDetailPage,
  NotificationPage,
  SearchPage,
  SearchDetailPage,
  WritingPage,
  EditPage,
  WikiPage,
  WikiCategoryPage,
  WikiDetailPage,
  MyPage,
  UserInfoPage,
  DogInfoStepAPage,
  DogInfoStepBPage,
  DogInfoStepCPage,
  DogInfoStepDPage,
  MyScrabPage,
  MyActivityPage,
  ModalGeneratePage,
};
