import styled from "styled-components";

const Container = styled.div`
  & .total {
    margin-bottom: 18px;
    padding: 0 10px;
    font-weight: 500;
  }

  & .commentInputContainer {
    margin-bottom: 20px;
  }

  @media (max-width: 499px) {
    & .total {
      margin-bottom: calc(100vw * (18 / 500));
      padding: 0 calc(100vw * (10 / 500));
    }

    & .commentInputContainer {
      margin-bottom: calc(100vw * (20 / 500));
    }
  }
`;

export { Container };

export default { Container };
