import Styles from "./styles";
import { Link } from "react-router-dom";
import { common, ui, layout } from "components";

// img
import ImgSection02 from "resources/image/img_introduce_section02.png";
import ImgSection03 from "resources/image/img_introduce_section03.png";
import ImgSection04 from "resources/image/img_introduce_section04.png";

function IntroducePage() {
  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.Header />

        <Styles.Container>
          <section className="section01">
            <h2>
              나의 인생, 너의 견생 <br />
              모두 행복할 수 있도록
            </h2>
          </section>

          <section className="section02">
            <div className="inner">
              <p className="lightTitle">Who we are</p>

              <h2 className="title">
                펫트워크는 <br />
                <span>함께 만드는 공간</span>
                입니다.
              </h2>

              <p className="description">
                내 댕댕이를 향한 노력을 통해 깨달은 경험과 지식을 나누며 <br />
                서로의 수발 라이프를 이롭게 하는 <span>개인들의 랜선 허브</span>
                입니다.
              </p>
            </div>

            <div className="imgContainer">
              <img src={ImgSection02} alt="ImgSection02" />
            </div>
          </section>

          <section className="section03">
            <div className="inner">
              <p className="lightTitle">What we do</p>

              <h2 className="title">
                <span>견생</span>을 책임지는 <br />
                <span>인생</span>들을 위해 일합니다.
              </h2>

              <p className="description">
                수발러들의 현생에 힘이 되는 정보들을 제공함으로써 <br />
                <br />
                산책하기도 바쁜 수발러들의 <span>시간절약</span>을 돕고, <br />
                안전하고, <span>유의미한 소비</span>를 서포트하며, <br />
                댕댕이 보호자들의 <span>집단지성 네트워크</span> 플랫폼을 만들어
                갑니다.
              </p>
            </div>

            <div className="imgContainer">
              <img src={ImgSection03} alt="ImgSection03" />
            </div>
          </section>

          <section className="section04">
            <div className="inner">
              <p className="lightTitle">Let's get it!</p>

              <h2 className="title">펫트워크와 함께해요!</h2>

              <p className="description">
                <span>보호자의 정보력</span>은 댕댕이들의{" "}
                <span>안전과 행복에 직결</span>됩니다.
                <br />
                최선을 다하는 서로의 개수발 인생을 응원하면서, <br />
                더 나은 반려 일상을 도모할 수 있기를 바랍니다. <br />
                <br />
                펫트워크 팀 일동
              </p>
            </div>

            <div className="imgContainer">
              <img src={ImgSection04} alt="ImgSection04" />
            </div>
          </section>
        </Styles.Container>

        <common.Footer />

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default IntroducePage;
