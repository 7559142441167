import BasicButton from "./BasicButton";
import Pagination from "./Pagination";
import Keyword from "./Keyword";
import Tab from "./Tab";

export { BasicButton, Pagination, Keyword, Tab };

export default {
  BasicButton,
  Pagination,
  Keyword,
  Tab,
};
