import styled from "styled-components";

const Container = styled.div`
  & .topContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 9px;

    & .statusText {
      font-size: 26px;
      font-weight: bold;

      & .color {
        color: var(--main);
      }
    }

    & .stateBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 61px;
      height: 54px;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      background-color: var(--main);
      border-radius: 6px;
      text-align: center;

      &.on {
        color: var(--gray03);
        background-color: var(--gray04);
      }
    }
  }

  & .date {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    color: var(--gray03);
  }

  & .title {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.35;
    word-break: keep-all;
  }

  & .listWrap {
    margin-bottom: 30px;

    & .list:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  & .buttonContainer {
    display: flex;
    gap: 7px;

    & .shareButton {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 68px;
      height: 75px;
      background-color: var(--gray01);
      border-radius: 10px;

      & .iconWrap {
        display: inline-block;
        width: 34px;
        height: 34px;
      }
    }
  }

  @media (max-width: 499px) {
    & .topContainer {
      margin-bottom: calc(100vw * (9 / 500));

      & .statusText {
        font-size: calc(100vw * (26 / 500));
      }

      & .stateBox {
        width: calc(100vw * (61 / 500));
        height: calc(100vw * (54 / 500));
        font-size: calc(100vw * (14 / 500));
        border-radius: calc(100vw * (6 / 500));
      }
    }

    & .date {
      margin-bottom: calc(100vw * (8 / 500));
      font-size: calc(100vw * (14 / 500));
    }

    & .title {
      margin-bottom: calc(100vw * (30 / 500));
      font-size: calc(100vw * (20 / 500));
    }

    & .listWrap {
      margin-bottom: calc(100vw * (30 / 500));

      & .list:not(:last-child) {
        margin-bottom: calc(100vw * (10 / 500));
      }
    }

    & .buttonContainer {
      gap: calc(100vw * (7 / 500));

      & .shareButton {
        width: calc(100vw * (68 / 500));
        height: calc(100vw * (75 / 500));
        border-radius: calc(100vw * (10 / 500));

        & .iconWrap {
          width: calc(100vw * (34 / 500));
          height: calc(100vw * (34 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
