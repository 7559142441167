import styled from "styled-components";

// img
import ImgBubble from "resources/image/img_bubble.svg";

const Container = styled.div`
  position: relative;
  height: 407px;
  padding: 40px 20px;
  border: 2px solid var(--main);
  border-radius: 20px;

  & .moreButtonContainer {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 18px;
    height: 18px;
    z-index: 1;

    & .moreListWrap {
      position: absolute;
      top: 100%;
      right: 0;
      background: #fff;
      border-radius: 6px;
      box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.12);
      overflow: hidden;

      & .moreList:not(:last-child) {
        border-bottom: 1px solid var(--gray04);
      }

      & button {
        width: 68px;
        height: 38px;
        font-size: 16px;
      }
    }
  }

  & .mottoContainer {
    position: relative;
    display: flex;
    align-items: center;
    height: 66px;
    margin-bottom: 13px;
    padding: 0 13px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42;
    background-color: var(--purple01);
    border-radius: 10px;

    &::after {
      position: absolute;
      bottom: -11px;
      right: 60px;
      width: 14px;
      height: 12px;
      background: url(${ImgBubble}) no-repeat center / cover;
      content: "";
    }

    & p {
      display: -webkit-box;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & .dogImgWrap {
    width: 181px;
    height: 181px;
    margin: 0 auto 20px;
    border-radius: 50%;
    overflow: hidden;
  }

  & .dogName {
    margin-bottom: 9px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  & .infoListWrap {
    display: flex;
    justify-content: center;
    gap: 12px;
    font-size: 12px;
    font-weight: 500;
    color: var(--gray03);

    & .infoList {
      position: relative;

      &:not(:last-child)::after {
        position: absolute;
        top: 50%;
        right: -6px;
        transform: translateY(-50%);
        width: 1px;
        height: 12px;
        background-color: var(--gray03);
        content: "";
      }
    }
  }

  @media (max-width: 499px) {
    height: calc(100vw * (407 / 500));
    padding: calc(100vw * (40 / 500)) calc(100vw * (20 / 500));
    border: calc(100vw * (2 / 500)) solid var(--main);
    border-radius: calc(100vw * (20 / 500));

    & .moreButtonContainer {
      top: calc(100vw * (10 / 500));
      right: calc(100vw * (20 / 500));
      width: calc(100vw * (18 / 500));
      height: calc(100vw * (18 / 500));

      & .moreListWrap {
        border-radius: calc(100vw * (6 / 500));
        box-shadow: calc(100vw * (5 / 500)) calc(100vw * (5 / 500))
          calc(100vw * (30 / 500)) 0 rgba(0, 0, 0, 0.12);

        & button {
          width: calc(100vw * (68 / 500));
          height: calc(100vw * (38 / 500));
          font-size: calc(100vw * (16 / 500));
        }
      }
    }

    & .mottoContainer {
      height: calc(100vw * (66 / 500));
      margin-bottom: calc(100vw * (13 / 500));
      padding: 0 calc(100vw * (13 / 500));
      font-size: calc(100vw * (14 / 500));
      border-radius: calc(100vw * (10 / 500));

      &::after {
        bottom: calc(100vw * (-11 / 500));
        right: calc(100vw * (60 / 500));
        width: calc(100vw * (14 / 500));
        height: calc(100vw * (12 / 500));
      }
    }

    & .dogImgWrap {
      width: calc(100vw * (181 / 500));
      height: calc(100vw * (181 / 500));
      margin: 0 auto calc(100vw * (20 / 500));
    }

    & .dogName {
      margin-bottom: calc(100vw * (9 / 500));
      font-size: calc(100vw * (20 / 500));
    }

    & .infoListWrap {
      gap: calc(100vw * (12 / 500));
      font-size: calc(100vw * (12 / 500));

      & .infoList {
        &:not(:last-child)::after {
          right: calc(100vw * (-6 / 500));
          height: calc(100vw * (12 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
