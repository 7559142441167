import subaler from "./subaler";
import product from "./product";
import dummy from "./dummy";
import reply from "./reply";
import scrap from "./scrap";
import category from "./category";
import support from "./support";
import wiki from "./wiki";
import review from "./review";
import magazine from "./magazine";
import event from "./event";
import vote from "./vote";
import search from "./search";
import report from "./report";
import banner from "./banner";
import auth from "./auth";
import alarm from "./alarm";
import pet from "./pet";
import daily from "./daily";
import upload from "./upload";
import mypage from "./mypage";
import inquiry from "./inquiry";
import like from "./like";
import business from "./business";
import share from "./share";

export default {
  subaler,
  product,
  dummy,
  reply,
  scrap,
  category,
  support,
  wiki,
  review,
  magazine,
  event,
  vote,
  search,
  report,
  banner,
  auth,
  alarm,
  pet,
  daily,
  upload,
  mypage,
  inquiry,
  like,
  business,
  share,
};

export {
  subaler,
  product,
  dummy,
  reply,
  scrap,
  category,
  support,
  wiki,
  review,
  magazine,
  event,
  vote,
  search,
  report,
  banner,
  auth,
  alarm,
  pet,
  daily,
  upload,
  mypage,
  inquiry,
  like,
  business,
  share,
};
