import Styles from "./styles";
import FaqList from "../FaqList";
import { ui, layout } from "components";
import { useSupport } from "hooks";

function Faq() {
  const supportInfo = useSupport({ options: { faqList: true } });

  return (
    <Styles.Container>
      {supportInfo.getFaqList?.list?.length > 0 ? (
        <>
          <ul className="listWrap">
            {supportInfo.getFaqList?.list?.map((item, index) => {
              return (
                <li className="list" key={"faqList" + index}>
                  <FaqList data={item} />
                </li>
              );
            })}
          </ul>
          <div className="paginationContainer">
            <ui.button.Pagination
              page={supportInfo.getFaqList?.pagination?.page}
              maxPage={supportInfo.getFaqList?.pagination?.totalPageCount}
              list={supportInfo.getFaqList?.pagination?.page_list}
            />
          </div>
        </>
      ) : (
        <div className="emptyContainer">
          <layout.EmptyContainer text={"등록된 게시물이 없습니다."} />
        </div>
      )}
    </Styles.Container>
  );
}

export default Faq;
