import Styles from "./styles";

function VoteRadio({ id, name, checked, onChange, data, disabled, detail }) {
  let percent = data.vote_count == 0 ? 0 :`${(data.vote_count / detail?.total_vote_count) * 100}`.slice(0,5);

  return (
    <Styles.Container className={checked || data.vote_yn === "Y" ? "on" : ""}>
      <input
        type="radio"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={id}></label>

      <div className="imgWrap">
        <img src={data.image} alt="voteImg" />
      </div>

      <div className="textContainer">
        <p className="text">{data.title}</p>
        {data.vote_count !== 0 && <p className="percent">{percent}%</p>}
        {data.vote_count !== 0 && <div className="bg" style={{ width: `${percent}%` }}></div>} 
      </div>
    </Styles.Container>
  );
}

export default VoteRadio;
