import Styles from "./styles";

function BoxCheckbox({ item, setValue, callback, name, icon, isMulti }) {
  // 체크박스 단일 선택
  const onChange = (e) => {
    if(setValue)
      setValue((list) => {
        return [
          ...list.map((v) => {
            if (!isMulti && v.id != item.id) {
              v.checked = false;
            }

            if (v.id == item.id) v.checked = e.target.checked;

            return v;
          }),
        ];
      });
  };

  return (
    <>
      <Styles.Input
        id={item.id}
        name={name}
        type="checkbox"
        checked={!!item.checked}
        onChange={onChange}
        onClick={callback}
      />

      <Styles.Label htmlFor={item.id}>
        <span className="labelText">{item.title}</span>
        {icon && (
          <span className="iconWrap">
            <img src={icon} alt={icon} />
          </span>
        )}
      </Styles.Label>
    </>
  );
}

export default BoxCheckbox;
