import Styles from "./styles";
import components from "./components";
import { common, ui } from "components";
import { useSupport } from "hooks";
import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

function NoticePage() {
  const supportInfo = useSupport({ options: { policy: true } });
  const { id } = useParams();
  const location = useLocation();

  const [tabIndex, setTabIndex] = useState(0);

  const tabList = [
    {
      url: "/notice/notice?page=1",
      text: "공지사항",
    },
    {
      url: "/notice/faq?page=1",
      text: "FAQ",
    },
    {
      url: "/notice/service",
      text: "서비스 이용약관",
    },
    {
      url: "/notice/privacy",
      text: "개인정보처리방침",
    },
  ];

  // 새로고침 해도 tab active 유지
  useEffect(() => {
    setTabIndex(tabList.findIndex((e) => e.url.split("?")[0] === location.pathname));
  }, [tabIndex, location.pathname]);

  useEffect(()=> {
    id === "service" && supportInfo.setType(1);
    id === "privacy" && supportInfo.setType(2);
  },[id])

  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.Header />

        <Styles.Container>
          <h2 className="pageName">공지 · FAQ</h2>

          <ui.button.Tab
            tabList={tabList}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            onClick={() => {
              console.log(tabList[tabIndex]);
            }}
          />

          <section>
            <div className="inner">
              {id === "notice" && <components.Notice />}
              {id === "faq" && <components.Faq />}
              {id === "service" && (
                <div
                  className="termsContainer editor ck-content"
                  dangerouslySetInnerHTML={{__html: supportInfo.getPolicy?.description}}
                >
                </div>
              )}
              {id === "privacy" && (
                <div
                  className="termsContainer editor ck-content"
                  dangerouslySetInnerHTML={{__html: supportInfo.getPolicy?.description}}
                >
                </div>
              )}
            </div>
          </section>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default NoticePage;
