import utils from "utils";
import Styles from "./styles";
import { useAlarm } from "hooks";
import { useBanner } from "hooks";
import { clickDetail } from "utils/etc";
import { getDateType } from "utils/date";
import { common, ui, layout } from "components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";

// img
import IconNotification from "resources/image/icon/icon_notification.svg";

function NotificationPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const bannerInfo = useBanner({ type: 3 });
  const bannerList = bannerInfo.getBanner.data?.data || [];
  const [filter, setFilter] = useState(null);
  const alarmInfo = useAlarm({ listOption: filter });
  const list = alarmInfo.listQuery.data?.list || [];
  const data = alarmInfo.listQuery?.data ? alarmInfo.listQuery?.data : {};

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? param.page : 1,
      recordSize: param.recordSize ? param.recordSize : 20,
      pageSize: 5,
      order: param.order ? param.order : 1,
    };

    setFilter({ ...f });
  }, [location]);

  const detailPage = (type) => {
    switch (type) {
      case 1:
        return "/event/detail/";
      case 2:
        return "/wiki/detail/";
      case 3:
        return "/magazine/detail/";
      case 4:
        return "/community/detail/";
      case 6:
        return "/vote/result/";
    }
  };

  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.Header />

        <Styles.Container>
          <section className="section01">
            <div className="inner">
              <Swiper
                autoplay={{
                  delay: 4000,
                  speed: 500,
                  disableOnInteraction: false,
                }}
                spaceBetween={10}
                loop={true}
                modules={[Autoplay]}
                className="bannerSlider"
              >
                {bannerList &&
                  bannerList.map((item, index) => {
                    return (
                      <SwiperSlide
                        className="slideList"
                        onClick={() => {
                          if (item.url) {
                            clickDetail(item.url);
                            bannerInfo.clickBanner(item.id);
                          }
                        }}
                        key={"slideList" + index}
                      >
                        <img src={item.path} alt={item.img} />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </section>

          <section className="section02">
            <div className="inner">
              <p className="guideText">
                * 최근 30일 동안의 알림만 확인할 수 있습니다.
              </p>

              {list.length > 0 ? (
                <>
                  <ul className="listWrap">
                    {list.map((item, index) => {
                      return (
                        <li
                          className="list"
                          key={"list" + index}
                          onClick={() => {
                            switch (item.type) {
                              case 1:
                                navigate(
                                  "/notice/detail?id=" + item.relate_info_id
                                );
                                break;
                              case 2:
                              case 3:
                                navigate(
                                  detailPage(item.board_type) +
                                    item.relate_info_id
                                );
                                break;
                            }
                          }}
                        >
                          <div className="iconWrap">
                            <img
                              src={IconNotification}
                              alt="IconNotification"
                            />
                          </div>

                          <div className="title">
                            {item.nickname && (
                              <p className="nickname">{item.nickname}</p>
                            )}
                            {item.type === 1 && "공지사항이 등록되었습니다."}
                            {item.type === 2 &&
                              item.content + "님이 답글을 남겼습니다."}
                            {item.type === 3 &&
                              item.content + "님이 대댓글을 남겼습니다."}
                          </div>
                          <p className="date">{getDateType(item.created_at)}</p>
                        </li>
                      );
                    })}
                  </ul>

                  <div className="paginationContainer">
                    <ui.button.Pagination
                      page={data.pagination?.page}
                      list={data.pagination?.page_list}
                      maxPage={data.pagination?.totalPageCount}
                    />
                  </div>
                </>
              ) : (
                <div className="emptyContainer">
                  <layout.EmptyContainer text={"알림이 없습니다."} />
                </div>
              )}

              <div className="adContainer">
                <layout.AdContainer />
              </div>
            </div>
          </section>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default NotificationPage;
