import Styles from "./styles";
import { api } from "service";
import { getDateType } from "utils/date";
import { useLocation } from "react-router-dom";
import { common, ui, layout } from "components";
import { useAuth, useModals } from "hooks";
import { useState, useEffect } from "react";

// img
import IconWomanOn from "resources/image/icon/icon_woman_on.svg";
import IconWomanOff from "resources/image/icon/icon_woman_off.svg";
import IconManOn from "resources/image/icon/icon_man_on.svg";
import IconManOff from "resources/image/icon/icon_man_off.svg";
import IconHeart from "resources/image/icon/icon_heart.svg";

function UserInfoPage() {
  const location = useLocation();
  const authInfo = useAuth({ detail: location.state });
  const modalOption = useModals();
  const userDetail = authInfo.userDetail?.data;

  const [inputs, setInputs] = useState({
    nickname: "",
    email: "",
    gender: "",
    prev_amount: "",
    current_amount: "",
    interview: "",
    contract: "",
  });
  const [select, setSelect] = useState("");
  const [date, setDate] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [check, setCheck] = useState([]);
  const [nameCheck, setNameCheck] = useState("");
  const [errorText, setErrorText] = useState("");

  const handleSingleCheck = (checked, id) => {
    if (checked) {
      setCheck(check.concat(id));
    } else {
      setCheck(check.filter((el) => el !== id));
    }
  };

  const init = [
    { id: 1, title: "테크상품" },
    { id: 2, title: "맞춤 제작" },
    { id: 3, title: "관련 콘텐츠" },
    { id: 4, title: "동물 보호" },
    { id: 5, title: "동반 정보" },
    { id: 6, title: "전문 자격증" },
    { id: 7, title: "사건사고" },
    { id: 8, title: "패션용품" },
    { id: 9, title: "럭셔리 신상" },
    { id: 10, title: "관련 법규" },
    { id: 11, title: "행동 교육" },
    { id: 12, title: "지자체 지원" },
    { id: 13, title: "보호자 교육" },
    { id: 14, title: "어질리티" },
    { id: 15, title: "피트니스" },
    { id: 16, title: "액티비티" },
  ];
  const [interest, setInterest] = useState(init);
  const experienceList = [
    {
      prevText: "그 동안",
      nextText: "마리를 키워 본 경험이 있어요.",
      name: "prev_amount",
      value: inputs.prev_amount,
    },
    {
      prevText: "지금",
      nextText: "마리와 함께 살고 있어요.",
      name: "current_amount",
      value: inputs.current_amount,
    },
  ];
  const checkList = [
    {
      id: "check01",
      labelText: "마케팅 활용 여부에 동의합니다.",
    },
    {
      id: "check02",
      labelText: "이메일 수신에 동의합니다.",
    },
  ];

  useEffect(() => {
    if (userDetail && userDetail) {
      setInputs((v) => {
        return {
          ...v,
          nickname: userDetail.nickname,
          email: userDetail.email,
          gender: userDetail.gender,
          prev_amount: userDetail.prev_amount || "",
          current_amount: userDetail.current_amount || "",
          interview: userDetail.interview,
          contract: userDetail.contract,
          marketing_yn: userDetail.marketing_yn,
          mail_yn: userDetail.mail_yn,
        };
      });
      setCheck([
        userDetail.marketing_yn === "Y" ? "check01" : null,
        userDetail.mail_yn === "Y" ? "check02" : null,
      ]);
      setDate({
        birthday: userDetail.birthday && new Date(userDetail.birthday),
      });
      setSelect(userDetail.location || "");
      setInterest(() => {
        return init.map((item) =>
          userDetail.interest_list.map((item) => item.id).indexOf(item.id) ===
          -1
            ? item
            : { ...item, checked: true }
        );
      });
    }
  }, [userDetail]);

  useEffect(() => {
    setNameCheck("");
    inputs.nickname !== "" ? setIsDisabled(false) : setIsDisabled(true);
  }, [inputs.nickname]);

  const onNameCheck = async () => {
    if (authInfo.userData.nickname !== inputs.nickname) {
      const result = await authInfo.findNickName(inputs.nickname);
      result?.success ? setNameCheck(true) : setNameCheck(false);
    }
  };

  const myInterest = interest
    .filter((v) => {
      return v.checked === true;
    })
    .map((v) => {
      return v.id;
    });

  const validate = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (inputs.nickname === "") {
      setErrorText("닉네임을 입력하세요.");
      return false;
    }
    if (authInfo.userData.nickname !== inputs.nickname) {
      if (nameCheck === "" || nameCheck === false) {
        setErrorText("닉네임 중복확인을 해주세요.");
        return false;
      }
    }
    if (emailRegex.test(inputs.email) === false) {
      setErrorText("이메일을 입력해주세요.");
      return false;
    }
    if (myInterest.length === 0) {
      setErrorText("관심사를 선택해주세요.");
      return false;
    }
    if (myInterest.length > 3) {
      setErrorText("최대 3개까지 선택 가능합니다.");
      return false;
    }
    return true;
  };

  const onEdit = () => {
    if (validate()) {
      authInfo.userUpdate({
        ...inputs,
        interest_list: myInterest,
        location: select,
        birthday: date.birthday && getDateType(date.birthday, "-"),
        marketing_yn: /check01/g.test(check.join("")) ? "Y" : "N",
        mail_yn: /check02/g.test(check.join("")) ? "Y" : "N",
      });
    }
  };

  return (
    // location.state &&
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.SubHeader pageName={"수발러 정보"} />

        <Styles.Container>
          <section className="infoSection">
            <div className="inner">
              <form>
                <ul className="listWrap">
                  <li className="list">
                    <ui.input.BasicInput
                      labelText={"닉네임"}
                      essential
                      placeholder={"활동명을 입력하세요."}
                      name={"nickname"}
                      value={inputs.nickname}
                      setInputs={setInputs}
                      errorText={nameCheck === false && "사용 불가능한 닉네임입니다."}
                      confirmText={nameCheck === true && "사용 가능한 닉네임입니다."}
                      nameType
                    >
                      <div className="confirmButton">
                        <ui.button.BasicButton
                          buttonText={"중복확인"}
                          buttonType={"black"}
                          size={"small"}
                          disabled={isDisabled}
                          onClick={onNameCheck}
                        />
                      </div>
                    </ui.input.BasicInput>
                  </li>

                  <li className="list">
                    <ui.input.BasicInput
                      labelText={"e-mail"}
                      essential
                      placeholder={"e-mail을 입력하세요."}
                      name={"email"}
                      value={inputs.email}
                      setInputs={setInputs}
                    />
                  </li>

                  <li className="list">
                    <ui.input.DateInput
                      labelText={"생년월일"}
                      name={"birthday"}
                      startDate={date?.birthday}
                      setStartDate={setDate}
                    />
                  </li>

                  <li className="list">
                    <p className="listTitle">지역</p>

                    <div className="selectContainer">
                      <ui.input.BasicSelect
                        optionList={api.dummy.locationList}
                        placeholder={"지역"}
                        size={"small"}
                        select={select}
                        setSelect={setSelect}
                      />
                    </div>
                  </li>

                  <li className="list">
                    <p className="listTitle">성별</p>

                    <ul className="genderListWrap">
                      <li className="genderList">
                        <ui.input.BoxRadio
                          labelText={"여성"}
                          id={"woman"}
                          icon={
                            inputs.gender === "woman"
                              ? IconWomanOn
                              : IconWomanOff
                          }
                          name={"gender"}
                          checked={inputs.gender}
                          setInputs={setInputs}
                        />
                      </li>

                      <li className="genderList">
                        <ui.input.BoxRadio
                          labelText={"남성"}
                          id={"man"}
                          icon={
                            inputs.gender === "man" ? IconManOn : IconManOff
                          }
                          name={"gender"}
                          checked={inputs.gender}
                          setInputs={setInputs}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </form>
            </div>
          </section>

          <section className="interestSection">
            <div className="inner">
              <form>
                <h2>
                  관심사 <span>(최대 3개)</span>
                </h2>

                <ul className="listWrap">
                  {interest.map((item, index) => {
                    return (
                      <li className="list" key={"list" + index}>
                        <ui.input.BoxCheckbox
                          item={item}
                          setValue={setInterest}
                          name={"interest"}
                          icon={IconHeart}
                          isMulti={true}
                        />
                      </li>
                    );
                  })}
                </ul>
              </form>
            </div>
          </section>

          <section className="experienceSection">
            <div className="inner">
              <form>
                <h2>반려 경험</h2>

                <ul className="listWrap">
                  {experienceList?.map((item, index) => {
                    return (
                      <li className="list" key={"experienceList" + index}>
                        {item.prevText}{" "}
                        <input
                          type="number"
                          placeholder="0"
                          name={item.name}
                          value={item.value}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setInputs((e) => {
                              return { ...e, [name]: value };
                            });
                          }}
                        />{" "}
                        {item.nextText}
                      </li>
                    );
                  })}
                </ul>
              </form>
            </div>
          </section>

          <section className="profitSection">
            <div className="inner">
              <form>
                <h2>
                  수익활동(설문조사, 체험단 등) 혹은 자신만의 전문 분야, <br />
                  연재 등 “다양하고 자유로운 형태” 로 펫트워크에 참여
                  <br />
                  하실 의향이 있으신가요?
                </h2>

                <ul className="listWrap">
                  <li className="list">
                    <ui.input.BasicInput
                      labelText={"원하는 분야"}
                      placeholder={"ex) 식단 글 연재, 임보일기 등"}
                      name={"interview"}
                      value={inputs.interview}
                      setInputs={setInputs}
                    />
                  </li>

                  <li className="list">
                    <ui.input.BasicInput
                      labelText={"연락처"}
                      type={"number"}
                      placeholder={"- 없이 숫자만 입력하세요."}
                      name={"contract"}
                      value={inputs.contract}
                      setInputs={setInputs}
                      phoneType
                    />
                  </li>
                </ul>
              </form>
            </div>
          </section>

          <section className="checkSection">
            <div className="inner">
              <form>
                <h2>펫트워크 이용약관에 동의해주세요.</h2>

                <ul className="listWrap">
                  {checkList?.map((item, index) => {
                    return (
                      <li className="list" key={"checkList" + index}>
                        <ui.input.BasicCheckbox
                          id={item.id}
                          labelText={item.labelText}
                          onChange={(e) =>
                            handleSingleCheck(e.target.checked, e.target.id)
                          }
                          checked={check.includes(item.id) ? true : false}
                        />
                      </li>
                    );
                  })}
                </ul>
              </form>
            </div>
          </section>

          <div className="buttonContainer inner">
            <div className="errorTextContainer">
              {errorText && <layout.ConfirmText error text={errorText} />}
            </div>

            <div className="confirmButton">
              <ui.button.BasicButton
                buttonText={"정보 수정"}
                onClick={onEdit}
              />
            </div>

            <div className="cancelButton">
              <ui.button.BasicButton
                buttonText={"회원 탈퇴"}
                buttonType={"gray01"}
                onClick={() => {
                  modalOption.setDeleteModal((e) => {
                    e.show = true;
                    return { ...e };
                  });
                }}
              />
            </div>
          </div>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default UserInfoPage;
