import React from "react";
import AuthRoute from "routes/AuthRoute";
import { Route, Routes, useLocation } from "react-router-dom";
import Pages from "pages";
import utils from "utils";
import { getCookie } from "utils/etc";

export default function CustomRoute() {
  let location = useLocation();

  React.useEffect(() => {
    if (!utils.etc.getSearchParam().disableScrollTop) {
      window.scrollTo(0, 0);
      document.body.scrollTo(0, 0);
    }

    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "ISBACK",
          isBack:
            location.pathname == "" || location.pathname == "/"
              ? "true"
              : false,
        })
      );
    }

    if (
      window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage &&
      getCookie("jwt")
    ) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "LOGIN", token: getCookie("jwt") })
      );
    }

    try {
      if (window.adsbygoogle && window.location.href.includes("petwork.kr"))
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Pages.MainPage />} />
      {/* intro */}
      <Route path="/intro" element={<Pages.IntroPage />} />
      {/* login / signup */}
      <Route path="/login" element={<Pages.LoginPage />} />
      <Route path="/signup/stepA" element={<Pages.SignupStepAPage />} />
      <Route path="/signup/stepB" element={<Pages.SignupStepBPage />} />
      <Route path="/signup/stepC" element={<Pages.SignupStepCPage />} />
      <Route path="/signup/complete" element={<Pages.SignupCompletePage />} />
      {/* 행사와 지원혜택 */}
      <Route path="/event" element={<Pages.EventPage />} />
      <Route path="/event/detail/:id" element={<Pages.EventDetailPage />} />
      {/* 매거진 */}
      <Route path="/magazine" element={<Pages.MagazinePage />} />
      <Route
        path="/magazine/detail/:id"
        element={<Pages.MagazineDetailPage />}
      />
      {/* 펫트워크 소개 */}
      <Route path="/introduce" element={<Pages.IntroducePage />} />
      {/* 수발러 게시판 */}
      <Route path="/community" element={<Pages.CommunityPage />} />
      <Route
        path="/community/detail/:id"
        element={<Pages.CommunityDetailPage />}
      />
      {/* 투표 */}
      <Route path="/vote" element={<Pages.VotePage />} />
      <Route path="/vote/result/:id" element={<Pages.VoteResultPage />} />
      {/* 비즈니스 문의 */}
      <Route path="/business" element={<Pages.BusinessPage />} />
      {/* 공지사항 */}
      <Route path="/notice" element={<Pages.NoticePage />} />
      <Route path="/notice/:id" element={<Pages.NoticePage />} />
      <Route path="/notice/detail" element={<Pages.NoticeDetailPage />} />
      <Route path="/notice/detail/:id" element={<Pages.NoticeDetailPage />} />
      {/* 알림 */}
      <Route
        path="/notification"
        element={
          <AuthRoute>
            <Pages.NotificationPage />
          </AuthRoute>
        }
      />
      {/* 검색 */}
      <Route path="/search" element={<Pages.SearchPage />} />
      <Route path="/search/detail" element={<Pages.SearchDetailPage />} />
      {/* 글쓰기 */}
      <Route path="/writing" element={<Pages.WritingPage />} />
      <Route path="/edit/:id" element={<Pages.EditPage />} />
      {/* 댕댕위키 */}
      <Route path="/wiki" element={<Pages.WikiPage />} />
      <Route path="/wiki/category" element={<Pages.WikiCategoryPage />} />
      <Route path="/wiki/detail/:id" element={<Pages.WikiDetailPage />} />
      {/* 마이페이지 */}
      <Route
        path="/mypage"
        element={
          <AuthRoute>
            <Pages.MyPage />
          </AuthRoute>
        }
      />
      <Route
        path="/mypage/user"
        element={
          <AuthRoute>
            <Pages.UserInfoPage />
          </AuthRoute>
        }
      />
      <Route
        path="/mypage/dog/stepA"
        element={
          <AuthRoute>
            <Pages.DogInfoStepAPage />
          </AuthRoute>
        }
      />
      <Route
        path="/mypage/dog/stepB"
        element={
          <AuthRoute>
            <Pages.DogInfoStepBPage />
          </AuthRoute>
        }
      />
      <Route
        path="/mypage/dog/stepC"
        element={
          <AuthRoute>
            <Pages.DogInfoStepCPage />
          </AuthRoute>
        }
      />
      <Route
        path="/mypage/dog/stepD"
        element={
          <AuthRoute>
            <Pages.DogInfoStepDPage />
          </AuthRoute>
        }
      />
      <Route
        path="/mypage/scrab"
        element={
          <AuthRoute>
            <Pages.MyScrabPage />
          </AuthRoute>
        }
      />
      <Route
        path="/mypage/activity"
        element={
          <AuthRoute>
            <Pages.MyActivityPage />
          </AuthRoute>
        }
      />
    </Routes>
  );
}
