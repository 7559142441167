import Styles from "./styles";
import { useEffect } from "react";
import { useSubaler } from "hooks";
import { useLocation } from "react-router-dom";
import { common, layout, ui } from "components";
import { ContactWritingForm } from "./components";

function WritingPage() {
  const subalerInfo = useSubaler({});
  const location = useLocation();

  useEffect(() => {
    if (!location.state) {
      window.location.replace("/");
    }
    const body = document.body;
    const html = document.querySelector("html");
    body.classList.remove("hidden");
    html.classList.remove("hidden");
  }, [location]);

  return (
    location.state && (
      <>
        <common.FixedBg />

        <div className="wrap">
          <common.SubHeader
            pageName={location.state?.type}
            BackEvent={subalerInfo.cancelSubaler}
          />

          <Styles.Container>
            <section>
              <div className="inner">
                <form>
                  {location.state?.type === "Contact us" ? (
                    <ContactWritingForm />
                  ) : (
                    <layout.CommunityWritingForm subalerInfo={subalerInfo}>
                      <ui.button.BasicButton
                        buttonText={"등록하기"}
                        onClick={subalerInfo.insertSubaler}
                      />

                      <ui.button.BasicButton
                        buttonText={"취소"}
                        buttonType={"white"}
                        onClick={subalerInfo.cancelSubaler}
                      />
                    </layout.CommunityWritingForm>
                  )}
                </form>
              </div>
            </section>
          </Styles.Container>
        </div>
      </>
    )
  );
}

export default WritingPage;
