import styled from "styled-components";

const Container = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid var(--purple01);

  & > div {
    display: flex;
  }

  & .titleContainer {
    justify-content: space-between;
    margin-bottom: 11px;

    & .title {
      width: 375px;
      font-size: 20px;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & .likeButton {
      width: 24px;
      height: 24px;
    }
  }

  & .detailContainer {
    align-items: center;
    font-size: 14px;
    font-weight: 500;

    & .profileContainer,
    & .reactList {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    & .profileImg {
      width: 27px;
      height: 27px;
      border-radius: 50%;
      overflow: hidden;
    }

    & .userName {
      max-width: 107px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & .reactionListWrap {
      display: flex;
      gap: 8px;
      margin-left: 8px;
      color: var(--gray03);

      & .iconWrap {
        width: 18px;
        height: 18px;
      }
    }

    & .date {
      margin-left: auto;
      color: var(--gray03);
    }
  }

  @media (max-width: 499px) {
    padding-bottom: calc(100vw * (15 / 500));

    & .titleContainer {
      margin-bottom: calc(100vw * (11 / 500));

      & .title {
        width: calc(100vw * (375 / 500));
        font-size: calc(100vw * (20 / 500));
      }

      & .likeButton {
        width: calc(100vw * (24 / 500));
        height: calc(100vw * (24 / 500));
      }
    }

    & .detailContainer {
      font-size: calc(100vw * (14 / 500));

      & .profileContainer,
      & .reactList {
        gap: calc(100vw * (4 / 500));
      }

      & .profileImg {
        width: calc(100vw * (27 / 500));
        height: calc(100vw * (27 / 500));
      }

      & .userName {
        max-width: calc(100vw * (107 / 500));
      }

      & .reactionListWrap {
        gap: calc(100vw * (8 / 500));
        margin-left: calc(100vw * (8 / 500));

        & .iconWrap {
          width: calc(100vw * (18 / 500));
          height: calc(100vw * (18 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
