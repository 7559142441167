import styled from "styled-components";

const Container = styled.div`
  padding-top: 20px;

  & .listWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;

    & .list {
      width: calc((100% - 28px) / 3);

      & input {
        display: none;

        &:checked + label {
          color: var(--main);
          border-color: var(--main);
        }
      }

      & label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 45px;
        font-weight: 500;
        color: var(--gray03);
        border: 1px solid var(--gray03);
        border-radius: 6px;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 499px) {
    padding-top: calc(100vw * (20 / 500));

    & .listWrap {
      gap: calc(100vw * (14 / 500));

      & .list {
        width: calc((100% - calc(100vw * (28 / 500))) / 3);

        & label {
          height: calc(100vw * (45 / 500));
          border-radius: calc(100vw * (6 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
