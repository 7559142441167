import Styles from "./styles";
import components from "./components";
import { common, ui } from "components";
import { useAuth, usePet } from "hooks";
import { Link, useNavigate } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import { clickDetail } from "utils/etc";

// img
import IconCarer from "resources/image/icon/icon_carer.svg";
import IconPro from "resources/image/icon/icon_pro.svg";
import IconPlus from "resources/image/icon/icon_plus02.svg";

function MyPage() {
  const authInfo = useAuth({});
  const petInfo = usePet({ isList: true });
  const navigate = useNavigate();

  const userData = authInfo.userData;
  const petData = petInfo.listQuery;

  const linkList = [
    {
      title: "나의 스크랩",
      text: "스크랩한 게시물을 확인할 수 있습니다.",
      url: "/mypage/scrab",
    },
    {
      title: "나의 활동",
      text: "펫트워크 내 활동내역을 확인할 수 있습니다.",
      url: "/mypage/activity",
    },
  ];

  return (
    authInfo.isLogin && (
      <>
        <common.FixedBg />

        <div className="wrap">
          <common.Header />

          <Styles.Container>
            <section className="userInfoSection">
              <div className="inner">
                <div className="left">
                  <div className="iconWrap">
                    <img
                      src={userData.propet_yn === "Y" ? IconPro : IconCarer}
                      alt="icon"
                    />
                  </div>
                  <p className="userName">{userData.nickname}</p>
                </div>
                <button
                  type="button"
                  className="editButton"
                  onClick={() => {
                    navigate("/mypage/user", { state: userData.id });
                  }}
                >
                  수정
                </button>
              </div>
            </section>

            <section className="dogInfoSection">
              <div className="inner">
                <div className="title">
                  개린이 정보{" "}
                  <div className="dogListNumber">
                    {" "}
                    {petData.data?.data?.length}/10
                  </div>
                </div>

                <p className="text">
                  프로필 이미지는 첫 번째 개린이로 설정됩니다. <br />
                  아래 등록한 댕댕이마다 특징이 적힌 프로필이 만들어집니다!
                </p>
              </div>

              <ScrollContainer className="scroll-container dogContainer">
                <ul className="dogListWrap">
                  {petData.data?.data?.map((item, index) => {
                    return (
                      <li className="dogList" key={"dogList" + index}>
                        <components.DogList data={item} />
                      </li>
                    );
                  })}
                  {petData.data?.data?.length < 10 && (
                    <li className="dogList add">
                      <Link
                        onClick={() => {
                          sessionStorage.removeItem("petInfo");
                        }}
                        to={"/mypage/dog/stepA"}
                        state={true}
                      >
                        <div className="iconContainer">
                          <div className="iconWrap">
                            <img src={IconPlus} alt="IconPlus" />
                          </div>
                        </div>

                        <p className="addProfile">프로필 등록</p>
                      </Link>
                    </li>
                  )}
                </ul>
              </ScrollContainer>
            </section>

            <section className="buttonSection">
              <div className="inner">
                <ul className="linkListWrap">
                  {linkList.map((item, index) => {
                    return (
                      <li className="linkList" key={"buttonList" + index}>
                        <Link to={item.url}>
                          <p className="linkTitle">{item.title}</p>
                          <p className="linkText">{item.text}</p>
                        </Link>
                      </li>
                    );
                  })}
                  <li className="linkList">
                    {userData.propet_yn === "Y" ? (
                      <div>
                        <p className="linkTitle">프로펫서 등록</p>
                        <p className="linkText">
                          당신은 <span>프로펫서</span>입니다!
                        </p>
                      </div>
                    ) : (
                      <div
                        className="link"
                        onClick={() => {
                          clickDetail(
                            "https://docs.google.com/forms/d/1uM3Z40g6IY5cWihRhZEqrUJFeBQWfcnWu9KGoWsWiqo/viewform?edit_requested=true"
                          );
                        }}
                      >
                        <p className="linkTitle">프로펫서 등록</p>
                        <p className="linkText">
                          특정 분야에 전문성을 보유한 수발러라면 신청해 주세요.
                        </p>
                      </div>
                    )}
                  </li>
                </ul>

                <div className="logoutButton">
                  <ui.button.BasicButton
                    buttonText={"로그아웃"}
                    onClick={authInfo.logoutModal}
                  />
                </div>
              </div>
            </section>
          </Styles.Container>

          <common.QuickMenu />

          <common.NavigationBar />
        </div>
      </>
    )
  );
}

export default MyPage;
