import { useQuery } from "react-query";
import { api } from "service";

export default function useDaily({
  date
}) {

  const getDaily = useQuery(["daily", date], () => {
    return api.daily.getDaily(date);
  }, {
    enabled:!!date,
    refetchOnWindowFocus: false,
  });

  return { getDaily }
}