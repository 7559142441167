import Styles from "./styles";
import { clickDetail } from "utils/etc";
import { Link, useNavigate } from "react-router-dom";
import { useAlarm, useAuth, useShare } from "hooks";
import { useState, useEffect, useRef } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

// img
import Logo from "resources/image/icon/logo.svg";
import IconMenu from "resources/image/icon/icon_menu.svg";
import IconBell from "resources/image/icon/icon_bell.svg";
import IconClose from "resources/image/icon/icon_close.svg";
import IconKakao from "resources/image/icon/icon_kakao.svg";
import IconInsta from "resources/image/icon/icon_insta.svg";
import IconYoutube from "resources/image/icon/icon_youtube.svg";
import IconBlog from "resources/image/icon/icon_blog.svg";
import utils from "utils";

function Header() {
  const navigate = useNavigate();
  const authInfo = useAuth({});

  const shareInfo = useShare();
  const alarmInfo = useAlarm({
    isCount: true,
  });

  const bellNumber = parseInt(alarmInfo.countQuery.data?.data || 0);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const body = document.body;
    const html = document.querySelector("html");
    if (menuOpen) {
      body.className = "hidden";
      html.className = "hidden";
    } else {
      body.classList.remove("hidden");
      html.classList.remove("hidden");
    }
    if (
      menuOpen &&
      document.getElementById("kakao_share_link") &&
      !(window.ReactNativeWebView && window.ReactNativeWebView.postMessage)
    ) {
      shareInfo.kakaoButton(document.getElementById("kakao_share_link"));
    }
  }, [menuOpen]);

  useEffect(() => {
    setMenuOpen(false);
    setTimeout(() => {}, 1000);
  }, []);

  // map
  const list = [
    {
      title: "PETWORK",
      menuList: [
        {
          url: "/wiki?parent_info_id=20&category_info_id=20",
          text: "댕댕위키",
        },
        {
          url: "/magazine",
          text: "인생견생 매거진",
        },
        {
          url: "/community",
          text: "수발러 게시판",
        },
        {
          url: "/event",
          text: "행사와 지원혜택",
        },
        {
          url: "/vote",
          text: "견심전심 투표",
        },
        {
          url: "/introduce",
          text: "펫트워크 소개",
        },
      ],
    },
    {
      title: "SUPPORT",
      menuList: [
        {
          url: "/business",
          text: "비즈니스 제휴 · 제안",
        },
        {
          url: "/writing",
          text: "Contact us",
          state: "Contact us",
        },
        {
          url: "/notice/notice?page=1",
          text: "공지 · FAQ",
        },
        {
          id: "kakao_share_link",
          callback: (e) => {
            if (
              window.ReactNativeWebView &&
              window.ReactNativeWebView.postMessage
            ) {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  type: "KAKAOSHARE",
                  url: "https://petwork.kr",
                })
              );
            }
          },
          text: (
            <>
              친구에게 앱 알려주기{" "}
              <div className="iconWrap">
                <img src={IconKakao} alt="IconKakao" />
              </div>
            </>
          ),
        },
      ],
    },
  ];

  const snsList = [
    {
      url: "https://www.instagram.com/petwork_official/",
      icon: IconInsta,
    },
    {
      url: "https://www.youtube.com/channel/UC_c5uQYWQze4I-ILJNrH0MA",
      icon: IconYoutube,
    },
    {
      url: "https://blog.naver.com/petwork",
      icon: IconBlog,
    },
  ];

  return (
    <>
      <Styles.Container>
        <div className="inner">
          <button
            type="button"
            className="menuButton"
            onClick={() => {
              setMenuOpen(true);
            }}
          >
            <img src={IconMenu} alt="IconMenu" />
          </button>

          <Link to={"/"} className="logo">
            <img src={Logo} alt="logo" />
          </Link>

          <button
            type="button"
            className="bellButton"
            onClick={() => {
              if (authInfo.certification()) {
                navigate("/notification");
              }
            }}
          >
            <img src={IconBell} alt="IconBell" />
            {bellNumber > 0 && <span className="bellNumber">{bellNumber}</span>}
          </button>
        </div>
      </Styles.Container>

      {menuOpen && (
        <Styles.Menu>
          <div className="buttonContainer inner">
            <button
              type="button"
              className="closeButton"
              onClick={() => {
                setMenuOpen(false);
              }}
            >
              <img src={IconClose} alt="IconClose" />
            </button>
          </div>

          <OverlayScrollbarsComponent
            className="menuContainer"
            options={{ scrollbars: { autoHide: "scroll" } }}
          >
            <div className="inner">
              {list.map((item, index) => {
                return (
                  <div className="menuWrap" key={"menuContainer" + index}>
                    <p className="title">{item.title}</p>

                    <ul>
                      {item.menuList?.map((current, idx) => {
                        return (
                          <li
                            className="menuList"
                            key={"menuList" + idx}
                            onClick={() => {
                              setMenuOpen(false);
                            }}
                          >
                            {current.url ? (
                              <Link
                                to={current.url}
                                state={{ type: current.state }}
                              >
                                {current.text}
                              </Link>
                            ) : current.callback ? (
                              <a id={current.id} onClick={current.callback}>
                                {current.text}
                              </a>
                            ) : (
                              <a href={current.externalUrl}>{current.text}</a>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}

              <ul className="snsListWrap">
                {snsList.map((item, index) => {
                  return (
                    <li
                      className="snsList"
                      key={"snsList" + index}
                      onClick={() => {
                        item.url && clickDetail(item.url);
                      }}
                    >
                      <img src={item.icon} alt={item.icon} />
                    </li>
                  );
                })}
              </ul>
            </div>
          </OverlayScrollbarsComponent>
        </Styles.Menu>
      )}
    </>
  );
}

export default Header;
