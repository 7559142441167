import { api } from "service";

export default function useUpload() {

  const uploadImage = async (file) => {
    const postData = new FormData();
    postData.append("file", file)
    return await api.upload.postImage(postData);
  }

  const postDrag = async (file) => {
    const postData = new FormData();
    postData.append("files", file)
    return await api.upload.postDrag(postData);
  }

  const postPet = async (file) => {
    const postData = new FormData();
    postData.append("file", file)
    return await api.upload.postPet(postData);
  }

  return {
    uploadImage,
    postDrag,
    postPet
  };
}
