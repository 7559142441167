import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  background-color: var(--gray01);

  & .listWrap {
    display: flex;
    gap: 32px;
    width: max-content;
    padding: 0 20px;

    &::-webkit-scrollbar {
      display: none;
    }

    & .list {
      flex-shrink: 0;
      position: relative;
      font-size: 20px;
      color: var(--gray03);
      cursor: pointer;

      &.on {
        font-weight: bold;
        color: var(--main);
      }

      &:not(:last-child)::after {
        position: absolute;
        top: 50%;
        right: -16px;
        transform: translateY(-50%);
        width: 1px;
        height: 10px;
        background-color: var(--gray03);
        content: "";
      }
    }
  }

  @media (max-width: 499px) {
    height: calc(100vw * (44 / 500));

    & .listWrap {
      gap: calc(100vw * (32 / 500));
      padding: 0 calc(100vw * (20 / 500));

      & .list {
        font-size: calc(100vw * (20 / 500));

        &:not(:last-child)::after {
          right: calc(100vw * (-16 / 500));
          height: calc(100vw * (10 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
