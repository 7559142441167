import { useEffect } from "react";
import Styles from "./styles";

// img
import ImgAd from "resources/image/img_ad.png";
import { ip } from "service/connector";

function AdContainer() {
  useEffect(() => {
    try {
      setTimeout(() => {
        if (window.adsbygoogle && window.location.href.includes("petwork.kr"))
          (window.adsbygoogle = window.adsbygoogle || []).push({});
      }, 500);
      console.log("Advertise is pushed");
    } catch (e) {
      console.error("AdvertiseError", e);
    }
  }, []);

  return (
    <Styles.Container>
      <div className="adContainer">
        {ip.includes("petwork.kr") &&
        window.location.href.includes("petwork.kr") ? (
          <ins
            class="adsbygoogle"
            style={{
              overflowX: "auto",
              overflowY: "hidden",
              display: "block",
              textAlign: "center",
              height: "140px",
            }}
            data-ad-format="fluid"
            data-ad-layout-key="-hg-3+1f-3d+2z"
            data-ad-client="ca-pub-1905147220666316"
            data-ad-slot="2782982253"
          ></ins>
        ) : (
          <img src={ImgAd} alt="ImgAd" />
        )}
        {/* ImgAd 에 광고 이미지를 넣어주세요 */}
      </div>

      <p>* 구글 광고는 펫트워크의 지향 가치와 무관하게 노출될 수 있습니다.</p>
    </Styles.Container>
  );
}

export default AdContainer;
