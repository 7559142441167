import styled from "styled-components";

// img
import BgWebView from "resources/image/bg_web_view.png";
import ImgWebView from "resources/image/img_web_view.png";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background: url(${BgWebView}) no-repeat center / cover var(--gray01);
  z-index: -1;

  @font-face {
    font-family: "SBAggroB";
    src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroB.woff")
      format("woff");
    font-weight: normal;
    font-style: normal;
  }

  & .textWrap {
    position: relative;
    width: 1078px;
    margin: 0 auto;
    padding-top: calc(100vh * (120 / 1080));

    &::before {
      position: absolute;
      bottom: 92px;
      left: 0;
      width: 542px;
      height: 478px;
      background: url(${ImgWebView}) no-repeat center / cover;
      content: "";
    }

    & .title {
      margin-bottom: 40px;
      font-family: "SBAggroB";
      font-size: 54px;
      color: var(--main);
      line-height: 1.18;
    }

    & .description {
      margin-bottom: 14px;
      font-weight: 600;
      line-height: 1.33;
    }

    & .downloadContainer {
      display: flex;
      align-items: center;
      margin-top: calc(100vh * (339 / 1080));

      & .downloadLink {
        width: 138px;
        height: 52px;

        &:first-child {
          margin-right: 11px;
        }
      }

      & p {
        margin-left: 22px;
        font-size: 20px;
        font-weight: bold;
        color: var(--gray03);
      }
    }
  }
  @media (max-width: 1200px) {
    background: var(--gray01);

    & .textWrap {
      display: none;
    }
  }

  @media (max-width: 499px) {
    display: none;
  }
`;

export { Container };

export default { Container };
