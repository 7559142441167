import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 10px;
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 500;
  background-color: var(--gray01);
  border-radius: 500px;
  box-shadow: 2px 2px 7px 0px rgba(68, 74, 102, 0.2);
  cursor: pointer;

  & .deleteButton {
    width: 19px;
    height: 19px;
  }

  @media (max-width: 499px) {
    gap: calc(100vw * (10 / 500));
    padding: calc(100vw * (8 / 500)) calc(100vw * (15 / 500));
    font-size: calc(100vw * (16 / 500));
    border-radius: calc(100vw * (500 / 500));
    box-shadow: calc(100vw * (2 / 500)) calc(100vw * (2 / 500))
      calc(100vw * (7 / 500)) 0px rgba(68, 74, 102, 0.2);

    & .deleteButton {
      width: calc(100vw * (19 / 500));
      height: calc(100vw * (19 / 500));
    }
  }
`;

export { Container };

export default { Container };
