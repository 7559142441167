import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 110px;

  & section {
    padding-top: 30px;

    & .inner {
      padding-top: 30px;

      & .listWrap {
        margin-bottom: 80px;
      }

      & .emptyContainer {
        padding: 30px 0;
      }

      & .wikiList {
        padding: 20px 0;
        border-bottom: 1px solid var(--gray04);

        &:first-child {
          padding-top: 0;
        }
      }

      & .magazineList:not(:last-child),
      .eventList:not(:last-child) {
        margin-bottom: 40px;
      }

      & .communityList:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (110 / 500));

    & section {
      padding-top: calc(100vw * (30 / 500));

      & .inner {
        padding-top: calc(100vw * (30 / 500));

        & .listWrap {
          margin-bottom: calc(100vw * (80 / 500));
        }

        & .emptyContainer {
          padding: calc(100vw * (30 / 500)) 0;
        }

        & .wikiList {
          padding: calc(100vw * (20 / 500)) 0;
        }

        & .magazineList:not(:last-child),
        .eventList:not(:last-child) {
          margin-bottom: calc(100vw * (40 / 500));
        }

        & .communityList:not(:last-child) {
          margin-bottom: calc(100vw * (15 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
