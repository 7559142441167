import utils from "utils";
import Styles from "./styles";
import components from "./components";
import { common, ui, layout } from "components";
import { useMyPage, useScrap } from "hooks";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function MyActivityPage() {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [filter, setFilter] = useState(null);
  const [tabIndex, setTabIndex] = useState(parseInt(utils.etc.getSearchParam().tab || 0));
  const scrapInfo = useScrap({ queryName: "my_reply_list", callFilter: filter })
  const myPageInfo = useMyPage({
    replyListOption : filter
  });
  const data = myPageInfo.replyListQuery?.data ? myPageInfo.replyListQuery?.data : {};


  useEffect(()=>{
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? parseInt(param.page) : 1,
      recordSize: param.recordSize ? param.recordSize : 15,
      pageSize: 5,
      type: param.tab == 0 ? 1 : param.tab == 1 ? 2 : 1 
    }
    setFilter({...f});
    setTabIndex(parseInt((utils.etc.getSearchParam().tab) || 0));
  },[location])

  const renderComponents = (item, idx, index) => {
    switch (idx) {
      case 0:
        return <layout.CommunityList
          data={item}
          index={index}
          scrapInfo={scrapInfo}
        />;
      case 1:
        return <components.MyCommentList data={item} />;
    }
  };


  const tabList = [
    { text: "내가 쓴 글" },
    { text: "내가 쓴 댓글" }
  ];
  const list = [
    {
      detailList: myPageInfo.replyListQuery?.data?.list,
      class: "community"
    },
    {
      detailList: myPageInfo.replyListQuery?.data?.list,
      class: "community"
    }
  ];
  
  const typePath = { 
    '1': 'event/detail',
    '2': 'wiki/detail',
    '3': 'magazine/detail',
    '4': 'community/detail',
    '5': 'community/detail',
    '6': 'vote/result'
  }

  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.SubHeader
          pageName={"나의 활동"}
          BackEvent={() => { navigate("/mypage") }}  
        />

        <Styles.Container>
          <section>
            <ui.button.Tab
              tabList={tabList}
              tabIndex={tabIndex}
              setTabIndex={(e)=>{
                navigate(utils.etc.getQueryParamUrl({ tab : e, },true));
              }}
            />

            <div className="inner">
              {list[tabIndex].detailList?.length > 0 ? (
                <>
                  <ul className={"listWrap " + list[tabIndex].class}>
                    {list[tabIndex].detailList
                      .map((item, index) => {
                        return (
                          <li
                            className={list[tabIndex].class + "List"}
                            key={list[tabIndex].class + "list" + index}
                          >
                            {item.report_yn === "N" && item.delete_yn === "N"
                              ?
                                <Link to={`/${typePath[item.type]}/${item.id}`}>
                                  {renderComponents(item, tabIndex, index)}
                                </Link>
                              :
                                <>{renderComponents(item, tabIndex, index)}</>
                            }
                          </li>
                        );
                      })}
                  </ul>
                  <div className="paginationContainer">
                    <ui.button.Pagination
                      page={data.pagination?.page}
                      list={data.pagination?.page_list}
                      maxPage={data.pagination?.totalPageCount}
                    />
                  </div>
                </>
              ) : (
                <div className="emptyContainer">
                  <layout.EmptyContainer
                    pen
                    text={
                      tabIndex === 0
                        ? "게시글을 작성해보세요!"
                        : "댓글을 작성해보세요!"
                    }
                  />
                </div>
              )}
            </div>
          </section>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default MyActivityPage;
