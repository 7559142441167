import styled from "styled-components";

// img
import ImgWord from "resources/image/img_wiki_word.png";

const Container = styled.div`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 110px;

  & .detailSection {
    padding: 58px 0 30px;
    border-bottom: 20px solid var(--gray01);

    & .wordContainer {
      position: relative;
      margin-bottom: 20px;
      padding: 26px 16px 16px;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.37;
      background-color: var(--gray01);
      border: 1px solid var(--gray02);
      border-radius: 10px;
      white-space: pre-wrap;

      &::before {
        position: absolute;
        top: -28px;
        left: 0;
        width: 216px;
        height: 38px;
        background: url(${ImgWord}) no-repeat center / cover;
        content: "";
      }
    }

    & .imgContainer {
      position: relative;
      width: 237px;
      height: 237px;
      margin: 0 auto;
      border-radius: 5px;
      overflow: hidden;

      & .iconWrap {
        position: absolute;
        bottom: 12px;
        left: 12px;
        width: 50px;
        height: 50px;
      }
    }

    & .companyName {
      margin: 20px 0 6px;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }

    & .subCategory {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 500;
      color: var(--gray03);
      text-align: center;
    }

    & .utilListWrap {
      display: flex;
      gap: 14px;
      margin-bottom: 30px;

      & .utilList {
        width: calc(calc(100% - 28px) / 2);

        & button {
          display: flex;
          gap: 10px;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 44px;
          font-weight: 600;
          background-color: var(--gray01);
          border-radius: 6px;

          & .iconWrap {
            display: block;
            width: 30px;
            height: 30px;
          }
        }

        &:last-child button {
          color: #fff;
          background-color: var(--main);
        }
      }
    }

    & .list {

      &:not(:last-child) {
        margin-bottom: 46px;
      }

      & .title {
        margin-bottom: 8px;
        font-weight: bold;
        color: var(--main);
      }

      & .description {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        line-height: 1.33;
        white-space: pre-wrap;

        & button {
          font-weight: 500;
          color: var(--gray03);
          text-decoration: underline;
          text-underline-position: from-font;
        }
      }
    }

    & .linkListWrap {
      display: flex;
      flex-wrap: wrap;
      gap: 30px 40px;
      margin: 30px 0;
      padding: 30px 0;
      border-top: 1px solid var(--gray04);
      border-bottom: 1px solid var(--gray04);

      & .linkList {
        display: flex;
        align-items: center;
        gap: 10px;
        width: calc(calc(100% - 40px) / 2);
        cursor: pointer;

        & .iconWrap {
          flex-shrink: 0;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: var(--gray01);
          overflow: hidden;
        }

        & .description {
          font-size: 16px;
          white-space: pre-wrap;
        }
      }
    }

    & .detail {
      font-size: 20px;
    }

    & .keywordListWrap {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      margin-top: 30px;
    }
  }

  & .reviewSection {
    padding-top: 30px;

    & .reviewTitle {
      margin-bottom: 6px;
      font-weight: bold;

      & span {
        font-weight: 500;
      }
    }

    & .textContainer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 47px;
      font-size: 16px;

      & .reviewText {
        font-weight: 500;
        color: var(--gray03);
      }

      & .average {
        font-weight: bold;
      }
    }

    & .reviewButton {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 75px;
      margin: 59px 0 30px;
      padding: 0 25px;
      font-size: 20px;
      font-weight: 500;
      border-radius: 10px;
      background-color: var(--gray04);

      & .iconWrap {
        display: inline-block;
        width: 23px;
        height: 23px;
      }
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (110 / 500));

    & .detailSection {
      padding: calc(100vw * (58 / 500)) 0 calc(100vw * (30 / 500));
      border-bottom: calc(100vw * (20 / 500)) solid var(--gray01);

      & .wordContainer {
        margin-bottom: calc(100vw * (20 / 500));
        padding: calc(100vw * (26 / 500)) calc(100vw * (16 / 500))
          calc(100vw * (16 / 500));
        font-size: calc(100vw * (18 / 500));
        border-radius: calc(100vw * (10 / 500));
        white-space: pre-wrap;

        &::before {
          top: calc(100vw * (-28 / 500));
          width: calc(100vw * (216 / 500));
          height: calc(100vw * (38 / 500));
        }
      }

      & .imgContainer {
        width: calc(100vw * (237 / 500));
        height: calc(100vw * (237 / 500));
        border-radius: calc(100vw * (5 / 500));

        & .iconWrap {
          bottom: calc(100vw * (12 / 500));
          left: calc(100vw * (12 / 500));
          width: calc(100vw * (50 / 500));
          height: calc(100vw * (50 / 500));
        }
      }

      & .companyName {
        margin: calc(100vw * (20 / 500)) 0 calc(100vw * (6 / 500));
        font-size: calc(100vw * (20 / 500));
      }

      & .subCategory {
        margin-bottom: calc(100vw * (20 / 500));
        font-size: calc(100vw * (16 / 500));
      }

      & .utilListWrap {
        gap: calc(100vw * (14 / 500));
        margin-bottom: calc(100vw * (30 / 500));

        & .utilList {
          width: calc(calc(100% - calc(100vw * (28 / 500))) / 2);

          & button {
            gap: calc(100vw * (10 / 500));
            height: calc(100vw * (44 / 500));
            border-radius: calc(100vw * (6 / 500));

            & .iconWrap {
              width: calc(100vw * (30 / 500));
              height: calc(100vw * (30 / 500));
            }
          }
        }
      }

      & .list {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (46 / 500));
        }

        & .title {
          margin-bottom: calc(100vw * (8 / 500));
        }
      }

      & .linkListWrap {
        gap: calc(100vw * (30 / 500)) calc(100vw * (40 / 500));
        margin: calc(100vw * (30 / 500)) 0;
        padding: calc(100vw * (30 / 500)) 0;

        & .linkList {
          gap: calc(100vw * (10 / 500));
          width: calc(calc(100% - calc(100vw * (40 / 500))) / 2);

          & .iconWrap {
            width: calc(100vw * (40 / 500));
            height: calc(100vw * (40 / 500));
          }

          & .description {
            font-size: calc(100vw * (16 / 500));
            white-space: pre-wrap;
          }
        }
      }

      & .detail {
        font-size: calc(100vw * (20 / 500));
      }

      & .keywordListWrap {
        gap: calc(100vw * (10 / 500));
        margin-top: calc(100vw * (30 / 500));
      }
    }

    & .reviewSection {
      padding-top: calc(100vw * (30 / 500));

      & .reviewTitle {
        margin-bottom: calc(100vw * (6 / 500));
      }

      & .textContainer {
        margin-bottom: calc(100vw * (47 / 500));
        font-size: calc(100vw * (16 / 500));
      }

      & .reviewButton {
        height: calc(100vw * (75 / 500));
        margin: calc(100vw * (59 / 500)) 0 calc(100vw * (30 / 500));
        padding: 0 calc(100vw * (25 / 500));
        font-size: calc(100vw * (20 / 500));
        border-radius: calc(100vw * (10 / 500));

        & .iconWrap {
          width: calc(100vw * (23 / 500));
          height: calc(100vw * (23 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
