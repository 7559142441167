import styled from "styled-components";

// img
import Bg from "resources/image/bg_wiki.png";
import IconArrowRight from "resources/image/icon/icon_arrow_right02.svg";

const Container = styled.div`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 110px;

  & .userInfoSection {
    background: url(${Bg}) no-repeat center / cover;

    & .inner {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      height: 149px;
      padding: 20px 0;
      color: #fff;

      & .iconWrap {
        width: 37px;
        height: 37px;
        margin-bottom: 6px;
      }

      & .userName {
        font-size: 22px;
        font-weight: bold;
      }

      & .editButton {
        font-size: 14px;
        color: #fff;
        text-decoration: underline;
        text-underline-position: from-font;
      }
    }
  }

  & .dogInfoSection {
    & .inner {
      padding-top: 30px;

      & .title {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 14px;
        font-size: 22px;
        font-weight: bold;

        & .dogListNumber {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 63px;
          height: 30px;
          font-size: 16px;
          font-weight: 600;
          background-color: var(--gray04);
          border-radius: 60px;
        }
      }

      & .text {
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.42;
      }
    }

    & .dogContainer {
      & .dogListWrap {
        display: flex;
        gap: 20px;
        margin-bottom: 60px;
        padding: 0 20px;

        &::-webkit-scrollbar {
          display: none;
        }

        & .dogList {
          flex-shrink: 0;
          width: 280px;
          height: fit-content;

          &.add {
            width: 300px;
            padding-right: 20px;

            & a {
              padding: 20px;
              border: 1px dashed var(--gray02);
              border-radius: 20px;

              & .iconContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 181px;
                height: 181px;
                margin: 0 auto 20px;
                background-color: #f2f2f2;
                border-radius: 50%;

                & .iconWrap {
                  width: 40px;
                  height: 40px;
                }
              }

              & .addProfile {
                padding: 10px 0;
                font-size: 16px;
                font-weight: 500;
                color: #fff;
                text-align: center;
                background-color: var(--black);
                border-radius: 20px;
              }
            }
          }
        }
      }
    }
  }

  & .buttonSection {
    & .linkListWrap {
      margin-bottom: 100px;

      & .linkList {
        background-color: #fff;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        & a,
        div {
          padding: 25px 20px;
          border-radius: 20px;
          box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);

          & .linkTitle {
            margin-bottom: 6px;
            font-size: 20px;
            font-weight: bold;
          }

          & .linkText {
            font-size: 14px;
            font-weight: 500;
            color: var(--gray03);
            line-height: 1.42;

            & span {
              color: var(--main);
            }
          }
        }

        & a,
        & .link {
          background: url(${IconArrowRight}) no-repeat calc(100% - 20px) center /
            16px;
        }

        & .link {
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (110 / 500));

    & .userInfoSection {
      & .inner {
        height: calc(100vw * (149 / 500));
        padding: calc(100vw * (20 / 500)) 0;

        & .iconWrap {
          width: calc(100vw * (37 / 500));
          height: calc(100vw * (37 / 500));
          margin-bottom: calc(100vw * (6 / 500));
        }

        & .userName {
          font-size: calc(100vw * (22 / 500));
        }

        & .editButton {
          font-size: calc(100vw * (14 / 500));
        }
      }
    }

    & .dogInfoSection {
      & .inner {
        padding-top: calc(100vw * (30 / 500));

        & .title {
          gap: calc(100vw * (10 / 500));
          margin-bottom: calc(100vw * (14 / 500));
          font-size: calc(100vw * (22 / 500));

          & .dogListNumber {
            width: calc(100vw * (63 / 500));
            height: calc(100vw * (30 / 500));
            font-size: calc(100vw * (16 / 500));
            border-radius: calc(100vw * (60 / 500));
          }
        }

        & .text {
          margin-bottom: calc(100vw * (20 / 500));
          font-size: calc(100vw * (14 / 500));
        }
      }

      & .dogContainer {
        & .dogListWrap {
          gap: calc(100vw * (20 / 500));
          margin-bottom: calc(100vw * (60 / 500));
          padding: 0 calc(100vw * (20 / 500));

          & .dogList {
            width: calc(100vw * (280 / 500));

            &.add {
              width: calc(100vw * (300 / 500));
              padding-right: calc(100vw * (20 / 500));

              & a {
                padding: calc(100vw * (20 / 500));
                border-radius: calc(100vw * (20 / 500));

                & .iconContainer {
                  width: calc(100vw * (181 / 500));
                  height: calc(100vw * (181 / 500));
                  margin: 0 auto calc(100vw * (20 / 500));

                  & .iconWrap {
                    width: calc(100vw * (40 / 500));
                    height: calc(100vw * (40 / 500));
                  }
                }

                & .addProfile {
                  padding: calc(100vw * (10 / 500)) 0;
                  font-size: calc(100vw * (16 / 500));
                  border-radius: calc(100vw * (20 / 500));
                }
              }
            }
          }
        }
      }
    }

    & .buttonSection {
      & .linkListWrap {
        margin-bottom: calc(100vw * (100 / 500));

        & .linkList {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (20 / 500));
          }

          & a,
          div {
            padding: calc(100vw * (25 / 500)) calc(100vw * (20 / 500));
            border-radius: calc(100vw * (20 / 500));
            box-shadow: 0 0 calc(100vw * (30 / 500)) 0 rgba(0, 0, 0, 0.1);

            & .linkTitle {
              margin-bottom: calc(100vw * (6 / 500));
              font-size: calc(100vw * (20 / 500));
            }

            & .linkText {
              font-size: calc(100vw * (14 / 500));
            }
          }

          & a {
            background: url(${IconArrowRight}) no-repeat
              calc(100% - calc(100vw * (20 / 500))) center /
              calc(100vw * (16 / 500));
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
