import styled from "styled-components";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom_white.svg";

const Container = styled.div`
  margin-bottom: 300px;

  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);

  & .detailSection {
    padding-bottom: 30px;
    border-bottom: 20px solid var(--gray01);

    & .titleContainer {
      position: relative;
      margin-bottom: 14px;

      & .title {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        padding: 30px 20px;
        font-size: 26px;
        font-weight: 600;
        color: #fff;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 37.87%,
          rgba(0, 0, 0, 0.8) 101.15%
        );
      }

      & .imgWrap {
        height: 305px;
      }
    }

    & .topContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 30px;
      border-bottom: 1px solid var(--gray04);

      & .listWrap {
        display: flex;
        gap: 12px;

        & .list {
          display: flex;
          gap: 6px;
          font-size: 16px;
          font-weight: 500;
          color: var(--gray03);

          & .iconWrap {
            flex-shrink: 0;
            width: 18px;
            height: 18px;
          }
        }
      }

      & .likeButton {
        flex-shrink: 0;
        width: 30px;
        height: 30px;
      }
    }

    & .descriptionContainer {
      position: relative;
      margin-bottom: 30px;
      padding-top: 30px;

      & .description {
        max-height: 440px;
        line-height: 1.44;
        overflow: hidden;

        &.open {
          max-height: none;
        }
      }

      & .moreButtonContainer {
        position: absolute;
        bottom: 8px;
        left: 50%;
        transform: translateX(-50%);

        &::before {
          position: absolute;
          bottom: -22px;
          left: 50%;
          transform: translateX(-50%);
          width: 460px;
          height: 165px;
          background: linear-gradient(
            0deg,
            #fff 4.55%,
            rgba(255, 255, 255, 0) 100%
          );
          content: "";
        }

        & .moreButton {
          position: relative;
          display: flex;
          align-items: center;
          width: 301px;
          height: 44px;
          padding: 0 24px;
          font-size: 16px;
          font-weight: 500;
          color: #fff;
          background: url(${IconArrowBottom}) no-repeat calc(100% - 24px) center /
            14px var(--black);
          border-radius: 100px;
          z-index: 1;
        }
      }
    }

    & .keywordListWrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
    }
  }

  & .commentSection .inner {
    padding: 30px 0 40px;

    & .adContainer {
      margin-top: 40px;
    }
  }

  & .listSection {
    padding: 40px 0 47px;
    background-color: var(--gray01);

    & .list:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));

    & .detailSection {
      padding-bottom: calc(100vw * (30 / 500));
      border-bottom: calc(100vw * (20 / 500)) solid var(--gray01);

      & .titleContainer {
        margin-bottom: calc(100vw * (14 / 500));

        & .title {
          padding: calc(100vw * (30 / 500)) calc(100vw * (20 / 500));
          font-size: calc(100vw * (26 / 500));
        }

        & .imgWrap {
          height: calc(100vw * (305 / 500));
        }
      }

      & .topContainer {
        padding-bottom: calc(100vw * (30 / 500));

        & .listWrap {
          gap: calc(100vw * (12 / 500));

          & .list {
            gap: calc(100vw * (6 / 500));
            font-size: calc(100vw * (16 / 500));

            & .iconWrap {
              width: calc(100vw * (18 / 500));
              height: calc(100vw * (18 / 500));
            }
          }
        }

        & .likeButton {
          width: calc(100vw * (30 / 500));
          height: calc(100vw * (30 / 500));
        }
      }

      & .descriptionContainer {
        margin-bottom: calc(100vw * (30 / 500));
        padding-top: calc(100vw * (30 / 500));

        & .description {
          max-height: calc(100vw * (440 / 500));
        }

        & .moreButtonContainer {
          bottom: calc(100vw * (8 / 500));

          &::before {
            bottom: calc(100vw * (-22 / 500));
            width: calc(100vw * (460 / 500));
            height: calc(100vw * (165 / 500));
          }

          & .moreButton {
            width: calc(100vw * (301 / 500));
            height: calc(100vw * (44 / 500));
            padding: 0 calc(100vw * (24 / 500));
            font-size: calc(100vw * (16 / 500));
            background: url(${IconArrowBottom}) no-repeat
              calc(100% - calc(100vw * (24 / 500))) center /
              calc(100vw * (14 / 500)) var(--black);
            border-radius: calc(100vw * (100 / 500));
          }
        }
      }

      & .keywordListWrap {
        gap: calc(100vw * (10 / 500));
      }
    }

    & .commentSection .inner {
      padding: calc(100vw * (30 / 500)) 0 calc(100vw * (40 / 500));

      & .adContainer {
        margin-top: calc(100vw * (40 / 500));
      }
    }

    & .listSection {
      padding: calc(100vw * (40 / 500)) 0 calc(100vw * (47 / 500));

      & .list:not(:last-child) {
        margin-bottom: calc(100vw * (40 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
