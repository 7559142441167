import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 110px;

  & .pageName {
    padding: 30px 0 20px;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
  }

  & section {
    padding-top: 40px;

    & .filterContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      & .selectContainer {
        width: 134px;
      }
    }

    & .listWrap {
      margin-bottom: 80px;

      & .list:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (110 / 500));

    & .pageName {
      padding: calc(100vw * (30 / 500)) 0 calc(100vw * (20 / 500));
      font-size: calc(100vw * (26 / 500));
    }

    & section {
      padding-top: calc(100vw * (40 / 500));

      & .filterContainer {
        margin-bottom: calc(100vw * (20 / 500));

        & .selectContainer {
          width: calc(100vw * (134 / 500));
        }
      }

      & .listWrap {
        margin-bottom: calc(100vw * (80 / 500));

        & .list:not(:last-child) {
          margin-bottom: calc(100vw * (40 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
