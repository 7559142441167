import utils from "utils";
import Styles from "./styles";
import { useMyPage, useScrap } from "hooks";
import { common, ui, layout } from "components";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function MyScrabPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(parseInt(utils.etc.getSearchParam().tab || 0));
  const [filter, setFilter] = useState(null);

  const scrapInfo = useScrap({
    queryName: "my_scrap_list",
    callFilter: filter
  });

  const myPageInfo = useMyPage({
    scrapListOption : filter
  });
  const data = myPageInfo.scrapListQuery?.data ? myPageInfo.scrapListQuery?.data : {};


  useEffect(()=>{
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? parseInt(param.page) : 1,
      recordSize: param.recordSize ? param.recordSize : param.tab == 1 ? 15 : 10,
      pageSize: 5,
      order: param.order ? param.order : 1,
      type: param.tab ? 
      param.tab == 0 ? 2 : 
      param.tab == 2 ? 1 : 
      param.tab == 1 ? 4 : 
      param.tab : 2,
    }
    setFilter({...f});
    setTabIndex(parseInt((utils.etc.getSearchParam().tab) || 0));
  },[location])

  const renderComponents = (item, idx, index) => {
    switch (idx) {
      case 0:
        return <layout.WikiList
          data={item}
          index={index}
          scrapInfo={scrapInfo}
        />;
      case 1:
        return <layout.CommunityList
          data={item}
          index={index}
          scrapInfo={scrapInfo}
        />;
      case 2:
        return <layout.EventList
          data={item}
          index={index}
          scrapInfo={scrapInfo}
        />;
      case 3:
        return <layout.MagazineList
          data={item}
          index={index}
          scrapInfo={scrapInfo}
        />;
    }
  };

  // map
  const tabList = [
    {
      text: "댕댕위키",
    },
    {
      text: "수발러 게시판",
    },
    {
      text: "행사와 지원혜택",
    },
    {
      text: "매거진",
    },
  ];

  const list = [
    {
      detailList: myPageInfo.scrapListQuery?.data?.list,
      class: "wiki",
    },
    {
      detailList: myPageInfo.scrapListQuery?.data?.list,
      class: "community",
    },
    {
      detailList: myPageInfo.scrapListQuery?.data?.list,
      class: "event",
    },
    {
      detailList: myPageInfo.scrapListQuery?.data?.list,
      class: "magazine",
    },
  ];

  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.SubHeader
          pageName={"나의 스크랩"}
          BackEvent={() => { navigate("/mypage") }}
        />

        <Styles.Container>
          <section>
            <ui.button.Tab
              tabList={tabList}
              tabIndex={tabIndex}
              setTabIndex={(e)=>{
                navigate(utils.etc.getQueryParamUrl({ tab: e }, true));
              }}
            />

            <div className="inner">
              {list[tabIndex].detailList?.length > 0 ? (
                <>
                  <ul className="listWrap">
                    {list[tabIndex].detailList
                      .map((item, index) => {
                        return (
                          <li
                            className={list[tabIndex].class + "List"}
                            key={list[tabIndex].class + "list" + index}
                          >
                            <Link
                              to={`/${list[tabIndex].class}/detail/${item.id}`}
                            >
                              {renderComponents(item, tabIndex, index)}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                  <div className="paginationContainer">
                    <ui.button.Pagination
                      page={data.pagination?.page}
                      list={data.pagination?.page_list}
                      maxPage={data.pagination?.totalPageCount}
                    />
                  </div>
                </>
              ) : (
                <div className="emptyContainer">
                  <layout.EmptyContainer text={"스크랩한 게시물이 없습니다."} />
                </div>
              )}
            </div>
          </section>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default MyScrabPage;
