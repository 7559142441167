import Styles from "./styles";
import components from "./components";
import { usePet } from "hooks";
import { useNavigate } from "react-router-dom";
import { common, ui, layout } from "components";
import { useEffect, useState } from "react";

function DogInfoStepDPage() {
  const petInfo = usePet({});
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    real_estate: null,
    beauty: null,
    fashion: null,
    mobility: null,
    leisure: null,
    supplements: null,
  });
  const [errorText, setErrorText] = useState("");

  // map
  const assetsList = [
    {
      title: "부동산",
      categoryList: ["집", "계단", "방석"],
      name: "real_estate",
    },
    {
      title: "미용템",
      categoryList: ["샴푸", "입욕제"],
      name: "beauty",
    },
    {
      title: "패션템",
      categoryList: ["의류", "악세사리"],
      name: "fashion",
    },
    {
      title: "이동템",
      categoryList: ["카시트", "개모차"],
      name: "mobility",
    },
    {
      title: "레저템",
      categoryList: ["야외용품", "침낭"],
      name: "leisure",
    },
    {
      title: "영양제",
      categoryList: ["비타민", "파우더"],
      name: "supplements",
    },
  ];

  useEffect(() => {
    setInputs((e) => { return { ...e, ...JSON.parse(sessionStorage.getItem("petInfo")) }; });
  }, [sessionStorage.getItem("petInfo")]);

  const onNext = () => {
    setErrorText("");
    const callAPI = () => {
      JSON.parse(sessionStorage.getItem("petInfo"))?.id
        ? petInfo.updateInfo({ ...JSON.parse(sessionStorage.getItem("petInfo")).id, ...inputs })
        : petInfo.registInfo({ ...JSON.parse(sessionStorage.getItem("petInfo")).id, ...inputs })
    }
    let sum = 0
    assetsList.map((item) => { return parseInt(inputs[item.name]) }).filter(v => v).map((i) => { return sum += i })
    sum === 100
      ? callAPI()
      : setErrorText("총합을 100%로 맟춰주세요.");
  };

  return (
    sessionStorage.getItem("petInfo") ? (
      <>
        <common.FixedBg />

        <div className="wrap">
          <common.SubHeader pageName={`개린이 ${JSON.parse(sessionStorage.getItem("petInfo")).id ? "수정" : "등록"}`} />

          <Styles.Container>
            <section>
              <div className="inner">
                <form>
                  <h2>자산 포트폴리오</h2>

                  <p className="text">
                    아이를 위해 구매한 것들의 지분을 정리해보세요! (총합은 100%)
                  </p>

                  <ul className="listWrap">
                    {assetsList?.map((item, index) => {
                      return (
                        <li className="list" key={"list" + index}>
                          <components.AssetsList
                            data={item}
                            value={inputs[`${item.name}`]}
                            inputs={inputs}
                            setInputs={setInputs}
                          />
                        </li>
                      );
                    })}
                  </ul>

                  <div className="buttonContainer">
                    <div className="errorText">
                      {errorText && (
                        <layout.ConfirmText error text={errorText} />
                      )}
                    </div>

                    <div className="confirmButton">
                      <ui.button.BasicButton
                        buttonText={"등록하기"}
                        onClick={onNext}
                      />
                    </div>

                    <div className="cancelButton">
                      <ui.button.BasicButton
                        buttonText={"취소"}
                        buttonType={"gray01"}
                        onClick={() => {
                          sessionStorage.removeItem("petInfo")
                          navigate("/mypage");
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </Styles.Container>

          <common.QuickMenu />

          <common.NavigationBar />
        </div>
      </>
    ) : navigate(1)
  );
}

export default DogInfoStepDPage;
