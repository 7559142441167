import useCalendar from "./useCalendar";
import useCategory from "./useCategory";
import useModals from "./useModals";
import useReply from "./useReply";
import useScrap from "./useScrap";
import useSubaler from "./useSubaler";
import useSupport from "./useSupport";
import useWiki from "./useWiki";
import useReview from "./useReview";
import useMagazine from "./useMagazine";
import useEvent from "./useEvent";
import useVote from "./useVote";
import useSearch from "./useSearch";
import useReport from "./useReport";
import useBanner from "./useBanner";
import useAuth from "./useAuth";
import useAlarm from "./useAlarm";
import useImage from "./useImage";
import usePet from "./usePet";
import useDaily from "./useDaily";
import useUpload from "./useUpload";
import useMyPage from "./useMyPage";
import useInquiry from "./useInquiry";
import useLike from "./useLike";
import useBusiness from "./useBusiness";
import useShare from "./useShare";

export { 
  useCalendar, 
  useCategory,
  useModals,
  useReply,
  useScrap,
  useSubaler,
  useSupport,
  useWiki,
  useReview,
  useMagazine,
  useEvent,
  useVote,
  useSearch,
  useReport,
  useBanner,
  useAuth,
  useAlarm,
  useImage,
  usePet,
  useDaily,
  useUpload,
  useMyPage,
  useInquiry,
  useLike,
  useBusiness,
  useShare,
}

export default {
  useCalendar, 
  useCategory,
  useModals,
  useReply,
  useScrap,
  useSubaler,
  useSupport,
  useWiki,
  useReview,
  useMagazine,
  useEvent,
  useVote,
  useSearch,
  useReport,
  useBanner,
  useAuth,
  useAlarm,
  useImage,
  usePet,
  useDaily,
  useUpload,
  useMyPage,
  useInquiry,
  useLike,
  useBusiness,
  useShare,
};
