import styled from "styled-components";

const Container = styled.ul`
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  border-top: 1px solid var(--gray02);
  background-color: #fff;
  z-index: 5;

  & .navList {
    width: calc(100% / 5);

    & a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 95px;

      & .iconWrap {
        width: 35px;
        height: 35px;
        margin-bottom: 4px;
        filter: invert(61%) sepia(5%) saturate(787%) hue-rotate(201deg)
          brightness(96%) contrast(87%);
      }

      & .navText {
        font-size: 14px;
        font-weight: 500;
        color: var(--gray03);
      }

      &.active {
        & .iconWrap {
          filter: invert(18%) sepia(89%) saturate(5816%) hue-rotate(256deg)
            brightness(85%) contrast(89%);
        }

        & .navText {
          color: var(--main);
        }
      }
    }
  }

  @media (max-width: 499px) {
    & .navList {
      & a {
        height: calc(100vw * (95 / 500));

        & .iconWrap {
          width: calc(100vw * (35 / 500));
          height: calc(100vw * (35 / 500));
          margin-bottom: calc(100vw * (4 / 500));
        }

        & .navText {
          font-size: calc(100vw * (14 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
