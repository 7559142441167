import utils from "utils";
import Styles from "./styles";
import { common, ui, layout } from "components";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCategory, useMagazine, useScrap } from "hooks";

function MagazinePage() {
  const [filter, setFilter] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const scrapInfo = useScrap({ queryName: "magazine", callFilter: filter });
  const magazineInfo = useMagazine({ listOption: filter });
  const categoryInfo = useCategory({
    listOption: {
      type: 3,
      parent_info_id: 6,
    },
  });

  const data = magazineInfo.listQuery?.data ? magazineInfo.listQuery?.data : {};

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? param.page : 1,
      recordSize: param.recordSize ? param.recordSize : 10,
      pageSize: 5,
      order: param.order ? param.order : 1,
      type: 3,
    };

    if (param.category_info_id) {
      f.category_info_id = param.category_info_id;
    }
    setFilter({ ...f });
  }, [location]);

  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.Header />

        <Styles.Container>
          <h2 className="pageName">매거진</h2>

          <ui.button.Tab
            tabList={[{ title: "종합" }].concat(categoryInfo.list)}
            tabIndex={
              filter?.category_info_id
                ? categoryInfo.list.findIndex(
                    (e) => e.id == filter?.category_info_id
                  ) + 1
                : 0
            }
            setTabIndex={(e) => {
              if (e == 0)
                navigate(
                  utils.etc.getQueryParamUrl(
                    { category_info_id: null, page: 1 },
                    false
                  )
                );
              else
                navigate(
                  utils.etc.getQueryParamUrl(
                    { category_info_id: categoryInfo.list[e - 1].id, page: 1 },
                    false
                  )
                );
            }}
          />

          <section>
            <div className="inner">
              <ul className="listWrap">
                {data.list?.map((item, index) => {
                  return (
                    <>
                      <li className="list" key={"magazineList" + index}>
                        <Link to={`detail/${item.id}`}>
                          <layout.MagazineList
                            data={item}
                            index={index}
                            scrapInfo={scrapInfo}
                          />
                        </Link>
                      </li>
                      {index === 2 && (
                        <li className="list adContainer">
                          <layout.AdContainer key={"ads_ma_" + index} />
                        </li>
                      )}
                    </>
                  );
                })}
              </ul>

              <div className="paginationContainer">
                <ui.button.Pagination
                  page={data.pagination?.page}
                  list={data.pagination?.page_list}
                  maxPage={data.pagination?.totalPageCount}
                />
              </div>
            </div>
          </section>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default MagazinePage;
