import { api } from "service";
import { useLocation } from "react-router-dom";

export default function useShare() {
  const location = useLocation();
  const fullLocation = location.pathname + location.search;
  const shareData = {
    vote: { relate_info_id: fullLocation.split("/")[3], type: 5 },
    notice: {
      relate_info_id: fullLocation.split("/")[2]?.split("=")[1],
      type: 6,
    },
  };

  const kakaoButton = (e) => {
    if (window.Kakao) {
      const kakao = window.Kakao;

      if (!kakao.isInitialized()) {
        kakao.init("bcb7bc531c4f9e6ed826415e5a067260");
      }

      kakao.Share.createDefaultButton({
        container: e,
        objectType: "feed",
        content: {
          title: "댕댕이를 수발하는 당신께",
          description: "알찬 정보가 많아 이 곳을 추천해요!",
          imageUrl: "https://petwork.kr/meta640.png",
          link: {
            mobileWebUrl: "https://petwork.kr/",
            webUrl: "https://petwork.kr/",
          },
        },
        buttons: [
          {
            title: "앱으로 이동",
            link: {
              mobileWebUrl: "https://developers.kakao.com",
              webUrl: "https://developers.kakao.com",
            },
          },
        ],
      });
    }
  };

  const shareClick = async () => {
    return await api.share.postShare(
      JSON.stringify(
        shareData[fullLocation.split("/")[1]] || {
          relate_info_id: fullLocation.split("/")[3],
          type: 1,
        }
      )
    );
  };

  return {
    kakaoButton,
    shareClick,
  };
}
