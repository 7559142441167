import styled from "styled-components";

// img
import IconEssential from "resources/image/icon/icon_essential.svg";

const Container = styled.div`
  min-height: calc(100vh - 70px);
  min-height: calc(100dvh - 70px);
  padding: 40px 0 120px;

  & .title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
  }

  & .inflowContainer {
    margin-bottom: 30px;

    & .title {
      position: relative;
      width: fit-content;

      &::after {
        position: absolute;
        top: 50%;
        right: -12px;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        margin-left: 4px;
        background: url(${IconEssential}) no-repeat center / cover;
        content: "";
      }
    }
  }

  & .experienceContainer {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid var(--gray02);

    & .list {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 20px;

      &:not(:last-child) {
        margin-bottom: 14px;
      }

      & input {
        width: 52px;
        height: 34px;
        font-size: 20px;
        text-align: center;
        background-color: var(--gray01);
        border-radius: 10px;
      }
    }
  }

  & .questionContainer {
    margin-bottom: 60px;

    & .title {
      line-height: 1.4;
    }

    & .list:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  & .errorText {
    width: fit-content;
    margin: -29px auto 10px;
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (70 / 500)));
    min-height: calc(100dvh - calc(100vw * (70 / 500)));
    padding: calc(100vw * (40 / 500)) 0 calc(100vw * (120 / 500));

    & .title {
      margin-bottom: calc(100vw * (20 / 500));
      font-size: calc(100vw * (20 / 500));
    }

    & .inflowContainer {
      margin-bottom: calc(100vw * (30 / 500));

      & .title {
        &::after {
          right: calc(100vw * (-12 / 500));
          width: calc(100vw * (8 / 500));
          height: calc(100vw * (8 / 500));
          margin-left: calc(100vw * (4 / 500));
        }
      }
    }

    & .experienceContainer {
      margin-bottom: calc(100vw * (40 / 500));
      padding-bottom: calc(100vw * (40 / 500));

      & .list {
        gap: calc(100vw * (10 / 500));
        font-size: calc(100vw * (20 / 500));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (14 / 500));
        }

        & input {
          width: calc(100vw * (52 / 500));
          height: calc(100vw * (34 / 500));
          font-size: calc(100vw * (20 / 500));
          border-radius: calc(100vw * (10 / 500));
        }
      }
    }

    & .questionContainer {
      margin-bottom: calc(100vw * (60 / 500));

      & .list:not(:last-child) {
        margin-bottom: calc(100vw * (20 / 500));
      }
    }

    & .errorText {
      margin: calc(100vw * (-29 / 500)) auto calc(100vw * (10 / 500));
    }
  }
`;

export { Container };

export default { Container };
