// <path-to-your-build>/src/ckeditor.ts or file containing editor configuration if you are integrating an editor from source.
import connector from "service/connector";
import "./styles.css";

// The editor creator to use.
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useEffect, useRef } from "react";

const CustomCkEditor = ({ text, setText, readOnly }) => {
  const editorRef = useRef(null);

  const customUploadAdapter = (loader) => {
    return {
      upload() {
        return new Promise((resolve) => {
          const data = new FormData();
          loader.file.then((file) => {
            data.append("name", file.name);
            data.append("files[0]", file);
            connector
              .connectFetchController("upload/image/drag", "POST", data)
              .then((e) => {
                if (e.success == true) {
                  resolve({ default: e.data.files[0] });
                }
              });
          });
        });
      },
    };
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return customUploadAdapter(loader);
    };

    //툴바 더보기 클릭시 오토 포커스 끄기
    setTimeout(() => {
      editor.ui.view.toolbar.element.addEventListener("click", (event) => {
        const target = event.target;
        if (
          target.tagName == "circle" ||
          target.classList.contains("ck-dropdown__button") ||
          target.classList.contains("ck-reset_all-excluded")
        ) {
          function check(index) {
            let pan = document.getElementsByClassName(
              "ck-dropdown__panel-visible"
            )[index];
            if (pan) {
              pan.click();
              pan.focus();
            }
          }
          check(0);
          check(1);
          setTimeout(() => {
            check(0);
            check(1);
          }, 250);
        }
      });
    }, 500);
  }

  const edrtorConfiguration = {
    placeholder:
      "부적절하거나 불쾌감을 줄 수 있는 컨텐츠는 제재를 받을 수 있습니다.",
    extraPlugins: [uploadPlugin],
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "fontSize",
        "italic",
        "fontColor",
        "underline",
        "link",
        "|",
        "imageUpload",
        "mediaEmbed",
        "undo",
        "redo",
        "horizontalLine",
        "fontBackgroundColor",
      ],
    },
    mediaEmbed: {
      previewsInData: true,
    },
    language: "ko",
    image: {
      toolbar: [
        "imageTextAlternative",
        "toggleImageCaption",
        "imageStyle:inline",
        "imageStyle:block",
        "imageStyle:side",
        "linkImage",
      ],
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableCellProperties",
        "tableProperties",
      ],
    },
  };

  useEffect(() => {
    const editorInstance = editorRef.current.editor;

    if (!editorInstance) return;

    editorInstance.isComposing = false;

    const viewDocument = editorInstance.editing.view.document;

    const handleCompositionStart = () => {
      editorInstance.isComposing = true;
    };

    const handleCompositionEnd = () => {
      editorInstance.isComposing = false;
    };

    const handleKeyDown = (event, domEventData) => {
      // if (!editorInstance.isComposing) {
      //     // Your keydown event handling code here
      //     console.log('Keydown event triggered');
      //     alert('Keydown event triggered');
      // }
    };

    viewDocument.on("compositionstart", handleCompositionStart);
    viewDocument.on("compositionend", handleCompositionEnd);
    viewDocument.on("keydown", handleKeyDown);

    // Cleanup event listeners on component unmount
    return () => {
      viewDocument.off("compositionstart", handleCompositionStart);
      viewDocument.off("compositionend", handleCompositionEnd);
      viewDocument.off("keydown", handleKeyDown);
    };
  }, [editorRef]);

  return (
    <CKEditor
      ref={editorRef}
      onReady={(editor) => {
        editorRef.current = editor;
      }}
      editor={Editor}
      config={!readOnly && edrtorConfiguration}
      data={text ? text : ""}
      onBlur={(e, editor) => {
        !readOnly && setText(editor.getData());
      }}
    />
  );
};

export default CustomCkEditor;
