import Styles from "./styles";
import { common } from "components";
import { useSupport } from "hooks";
import { useLocation, useParams, useRoutes } from "react-router-dom";
import { getDateType } from "utils/date";

function NoticeDetailPage() {
  const location = useLocation();
  const params = useParams();
  const supportInfo = useSupport({
    options: { noticeDetail: true, id: params?.id ? params?.id : null },
  });

  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.SubHeader share pageName={"공지 · FAQ"} />

        <Styles.Container>
          <section>
            <div className="inner">
              <div className="topContainer">
                <div className="titleContainer">
                  {supportInfo.getNoticeDetail?.hold_yn === "Y" && (
                    <div className="important">중요</div>
                  )}
                  <p className="title">{supportInfo.getNoticeDetail?.title}</p>
                </div>

                <p className="date">
                  {getDateType(supportInfo.getNoticeDetail?.created_at)}
                </p>
              </div>

              <div
                className="editor ck-content"
                dangerouslySetInnerHTML={{
                  __html: supportInfo.getNoticeDetail?.description,
                }}
              ></div>
            </div>
          </section>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default NoticeDetailPage;
