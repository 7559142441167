import styled from "styled-components";

const Input = styled.input`
  display: none;

  &:checked + label .checkContainer {
    border-color: var(--main);

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 16px;
      height: 16px;
      background-color: var(--main);
      border-radius: 50%;
      content: "";
    }
  }

  @media (max-width: 499px) {
    &:checked + label .checkContainer {
      &::after {
        width: calc(100vw * (16 / 500));
        height: calc(100vw * (16 / 500));
      }
    }
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  & .checkContainer {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid var(--gray02);
    border-radius: 50%;
  }

  @media (max-width: 499px) {
    gap: calc(100vw * (16 / 500));

    & .checkContainer {
      width: calc(100vw * (30 / 500));
      height: calc(100vw * (30 / 500));
    }
  }
`;

export { Input, Label };

export default { Input, Label };
