import {
  DefaultModal,
  ReviewModal,
  DeleteModal,
  ReportModal,
  WebViewModal,
} from "components/modal";
import { useModals } from "hooks";
import { useEffect } from "react";

export default function ModalGeneratePage() {
  const modalOption = useModals();

  useEffect(() => {
    const body = document.body;
    const html = document.querySelector("html");
    if (
      modalOption.defaultModal.show ||
      modalOption.reviewModal.show ||
      modalOption.deleteModal.show ||
      modalOption.reportModal.show ||
      modalOption.webViewModal.show
    ) {
      body.className = "hidden";
      html.className = "hidden";
    } else {
      body.classList.remove("hidden");
      html.classList.remove("hidden");
    }
  }, [modalOption]);

  return (
    <>
      {
        //기본 모달
        modalOption.defaultModal.show ? (
          <DefaultModal
            modalOption={modalOption.defaultModal}
            modalClose={() => {
              modalOption.setDefaultModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // 리뷰 모달
        modalOption.reviewModal.show ? (
          <ReviewModal
            modalOption={modalOption.reviewModal}
            modalClose={() => {
              modalOption.setReviewModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // 회원 탈퇴 모달
        modalOption.deleteModal.show ? (
          <DeleteModal
            modalOption={modalOption.deleteModal}
            modalClose={() => {
              modalOption.setDeleteModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // 회원 탈퇴 모달
        modalOption.reportModal.show ? (
          <ReportModal
            modalOption={modalOption.reportModal}
            modalClose={() => {
              modalOption.setReportModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // 웹뷰 모달
        modalOption.webViewModal.show ? (
          <WebViewModal
            modalOption={modalOption.webViewModal}
            modalClose={() => {
              modalOption.setWebViewModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
    </>
  );
}
