import ui from "components/ui";
import Styles from "./styles";
import ConfirmText from "../ConfirmText";
import { api } from "service";
import { common } from "components";
import { useLocation } from "react-router-dom";

// img
import IconDelete from "resources/image/icon/icon_delete.svg";
import IconConcernedOn from "resources/image/icon/icon_concerned_on.svg";
import IconSpeakerOn from "resources/image/icon/icon_speaker_on.svg";
import IconConcernedOff from "resources/image/icon/icon_concerned_off.svg";
import IconSpeakerOff from "resources/image/icon/icon_speaker_off.svg";

function CommunityWritingForm({ children, subalerInfo }) {
  const location = useLocation();

  const handleAdd = () => {
    if (`${subalerInfo.inputs.keyword}`.trim() !== "") {
      if (subalerInfo.keywordList?.length < 5) {
        subalerInfo.setInputs((e) => {
          return { ...e, keyword: "" };
        });
        subalerInfo.setKeywordList((e) => {
          return [...e, subalerInfo.inputs.keyword];
        });
      } else {
        return;
      }
    }
  };
  const handleRemove = (index) => {
    subalerInfo.setKeywordList(
      subalerInfo.keywordList.filter((e, idx) => idx !== index)
    );
  };

  return (
    <Styles.Container>
      {location.state?.type === "비밀의 개나무숲" && (
        <p className="guideText">
          * 비밀의 개나무숲에 작성한 글은 익명으로 업로드 됩니다.
        </p>
      )}

      <ul className="listWrap">
        <li className="list">
          <p className="label">
            <span>지역</span>
          </p>

          <div className="inputWrap">
            <div className="selectContainer">
              <ui.input.BasicSelect
                optionList={[...api.dummy.locationList, "온라인"]}
                select={subalerInfo.locationSelect}
                setSelect={subalerInfo.setLocationSelect}
                setUnrelatedCheck={subalerInfo.setUnrelatedCheck}
                placeholder={"지역"}
                size={"small"}
              />
            </div>

            <div className="checkContainer">
              <ui.input.BasicCheckbox
                id={"unrelatedCheck"}
                labelText={"지역 무관"}
                checked={subalerInfo.unrelatedCheck}
                onChange={(e) => {
                  subalerInfo.setLocationSelect("");
                  subalerInfo.setUnrelatedCheck(e.target.checked);
                }}
              />
            </div>
          </div>
        </li>

        <li className="list">
          <ui.input.BasicInput
            labelText={"제목"}
            essential
            placeholder={"제목을 입력하세요."}
            name={"title"}
            value={subalerInfo.inputs.title}
            setInputs={subalerInfo.setInputs}
          />
        </li>

        {location.state?.type === "사건사고" ? (
          <>
            <li className="list speaker">
              <p className="label">
                <span>전달자</span>
              </p>

              <div className="inputWrap">
                <div className="peopleContainer">
                  <ui.input.BoxRadio
                    labelText={"사건 당사자"}
                    id={"concerned"}
                    icon={
                      subalerInfo.inputs.speaker === "concerned"
                        ? IconConcernedOn
                        : IconConcernedOff
                    }
                    name={"speaker"}
                    checked={subalerInfo.inputs.speaker}
                    setInputs={subalerInfo.setInputs}
                  />
                </div>

                <div className="peopleContainer">
                  <ui.input.BoxRadio
                    labelText={"내용 전달"}
                    id={"speaker"}
                    icon={
                      subalerInfo.inputs.speaker === "speaker"
                        ? IconSpeakerOn
                        : IconSpeakerOff
                    }
                    name={"speaker"}
                    checked={subalerInfo.inputs.speaker}
                    setInputs={subalerInfo.setInputs}
                  />
                </div>
              </div>
            </li>

            <li className="list">
              <p className="label">
                <span>사건 요약</span>
              </p>

              <div className="editor ck-editor">
                <common.CustomCkEditor
                  text={subalerInfo.description}
                  setText={subalerInfo.setDescription}
                />
              </div>
            </li>

            <li className="list">
              <p className="label">
                <span>의견</span>
              </p>

              <div className="editor ck-editor">
                <common.CustomCkEditor
                  text={subalerInfo.review}
                  setText={subalerInfo.setReview}
                />
              </div>
            </li>

            <li className="list">
              <ui.input.BasicInput
                labelText={"원문링크"}
                placeholder={"URL을 입력하세요."}
                name={"url"}
                value={subalerInfo.inputs.url}
                setInputs={subalerInfo.setInputs}
              />
            </li>
          </>
        ) : (
          <li className="list">
            <p className="label">
              <span>내용</span>
            </p>

            <div className="editor ck-editor">
              <common.CustomCkEditor
                text={subalerInfo.description}
                setText={subalerInfo.setDescription}
              />
            </div>
          </li>
        )}

        <li className="list">
          <ui.input.BasicInput
            labelText={"키워드"}
            placeholder={"#키워드 (최대 5개)"}
            name={"keyword"}
            value={subalerInfo.inputs.keyword}
            setInputs={subalerInfo.setInputs}
          >
            <div className="addButton">
              <ui.button.BasicButton
                buttonText={"추가"}
                size={"small"}
                onClick={() => {
                  handleAdd();
                }}
              />
            </div>
          </ui.input.BasicInput>

          {subalerInfo.keywordList?.length > 0 && (
            <ul className="keywordListWrap">
              {subalerInfo.keywordList?.map((item, index) => {
                return (
                  <li className="keywordList" key={"keywordList" + index}>
                    {item}
                    <button
                      type="button"
                      className="deleteButton"
                      onClick={() => {
                        handleRemove(index);
                      }}
                    >
                      <img src={IconDelete} alt="IconDelete" />
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </li>

        {location.state?.type === "사건사고" && (
          <li className="list check">
            <ui.input.BasicCheckbox
              id={"check"}
              labelText={
                <>
                  비방, 음해의 목적이 아닌 공익을 위해 정보를 <br />
                  공유합니다. (필수)
                </>
              }
              checked={subalerInfo.check}
              onChange={(e) => {
                subalerInfo.setCheck(e.target.checked);
              }}
            />
          </li>
        )}
      </ul>

      <div className="buttonContainer">
        <div className="errorText">
          {subalerInfo.errorText && (
            <ConfirmText error text={subalerInfo.errorText} />
          )}
        </div>

        {children}
      </div>
    </Styles.Container>
  );
}

export default CommunityWritingForm;
