import styled from "styled-components";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right.svg";

const Container = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: #fff;

  & .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;

    & .menuButton,
    .bellButton {
      display: block;
      width: 32px;
      height: 32px;
    }

    & .logo {
      width: 153px;
      height: 27px;
      margin: 0 auto;
    }

    & .bellButton {
      position: relative;

      & .bellNumber {
        position: absolute;
        top: -7px;
        right: -11px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        font-size: 14px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: -0.03em;
        color: #fff;
        background-color: var(--main);
        border-radius: 5px;
      }
    }
  }

  @media (max-width: 499px) {
    & .inner {
      height: calc(100vw * (70 / 500));

      & .menuButton,
      .bellButton {
        width: calc(100vw * (32 / 500));
        height: calc(100vw * (32 / 500));
      }

      & .logo {
        width: calc(100vw * (153 / 500));
        height: calc(100vw * (27 / 500));
      }

      & .bellButton {
        & .bellNumber {
          top: calc(100vw * (-7 / 500));
          right: calc(100vw * (-11 / 500));
          width: calc(100vw * (25 / 500));
          height: calc(100vw * (25 / 500));
          font-size: calc(100vw * (14 / 500));
          border-radius: calc(100vw * (5 / 500));
        }
      }
    }
  }
`;

const Menu = styled.div`
  position: fixed;
  top: 0;
  left: calc(50% + 75px);
  width: 500px;
  height: 100vh;
  height: 100dvh;
  background-color: #fff;
  z-index: 7;

  & .buttonContainer {
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #fff;

    & .closeButton {
      width: 24px;
      height: 24px;
    }
  }

  & .menuContainer {
    height: calc(100% - 70px);
    padding: 20px 0 60px;
    overflow: auto;
  }

  & .menuWrap {
    margin-bottom: 50px;

    & .title {
      margin-bottom: 10px;
      font-size: 35px;
      font-weight: bold;
      color: var(--main);
    }

    & .menuList {
      position: relative;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--gray01);
        border-radius: 2px;
        content: "";
      }

      & a {
        display: flex;
        align-items: center;
        height: 66px;
        font-size: 22px;
        background: url(${IconArrowRight}) no-repeat center right / 23px;

        & .iconWrap {
          width: 23px;
          height: 20px;
          margin-left: 6px;
        }
      }
    }
  }

  & .snsListWrap {
    display: flex;
    justify-content: center;
    gap: 30px;
    cursor: pointer;

    & .snsList {
      width: 67px;
      height: 67px;
    }
  }

  @media (max-width: 1200px) {
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 499px) {
    left: 0;
    transform: translateX(0);
    width: 100%;

    & .buttonContainer {
      height: calc(100vw * (70 / 500));

      & .closeButton {
        width: calc(100vw * (24 / 500));
        height: calc(100vw * (24 / 500));
      }
    }

    & .menuContainer {
      height: calc(100% - calc(100vw * (70 / 500)));
      padding: calc(100vw * (20 / 500)) 0 calc(100vw * (60 / 500));
    }

    & .menuWrap {
      margin-bottom: calc(100vw * (50 / 500));

      & .title {
        margin-bottom: calc(100vw * (10 / 500));
        font-size: calc(100vw * (35 / 500));
      }

      & .menuList {
        &::after {
          height: calc(100vw * (2 / 500));
          border-radius: calc(100vw * (2 / 500));
        }

        & a {
          height: calc(100vw * (66 / 500));
          font-size: calc(100vw * (22 / 500));
          background: url(${IconArrowRight}) no-repeat center right /
            calc(100vw * (23 / 500));

          & .iconWrap {
            width: calc(100vw * (23 / 500));
            height: calc(100vw * (20 / 500));
            margin-left: calc(100vw * (6 / 500));
          }
        }
      }
    }

    & .snsListWrap {
      gap: calc(100vw * (30 / 500));

      & .snsList {
        width: calc(100vw * (67 / 500));
        height: calc(100vw * (67 / 500));
      }
    }
  }
`;

export { Container, Menu };

export default { Container, Menu };
