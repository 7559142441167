import styled from "styled-components";

const Container = styled.div`
  display: flex;
  gap: 20px;

  &.on {
    flex-direction: column;

    & .imgContainer .imgWrap {
      width: 100%;
    }

    & .textContainer {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;

      & .company {
        margin: 0 0 6px;
      }
    }
  }

  & .imgContainer {
    position: relative;
    flex-shrink: 0;

    & .iconWrap {
      position: absolute;
      bottom: 8px;
      left: 8px;
      width: 30px;
      height: 30px;
    }

    & .imgWrap {
      width: 140px;
      height: auto;
      aspect-ratio: 1;
      border-radius: 5px;
      overflow: hidden;
    }
  }

  & .textContainer {
    position: relative;
    width: calc(100% - 160px);
    font-weight: 500;

    & .subCategory {
      font-size: 16px;
      color: var(--gray03);
    }

    & .company {
      margin: 4px 0 12px;
      font-size: 20px;
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & .description {
      line-height: 1.22;
    }

    & .more {
      position: absolute;
      top: 2px;
      right: 0;
      display: flex;
      gap: 5px;
      font-size: 16px;
      line-height: 1;

      & .iconWrap {
        width: 8px;
        height: 8px;
        margin-top: 5px;
      }
    }
  }

  @media (max-width: 499px) {
    gap: calc(100vw * (20 / 500));

    &.on {
      & .textContainer {
        & .company {
          margin: 0 0 calc(100vw * (6 / 500));
        }
      }
    }

    & .imgContainer {
      & .iconWrap {
        bottom: calc(100vw * (8 / 500));
        left: calc(100vw * (8 / 500));
        width: calc(100vw * (30 / 500));
        height: calc(100vw * (30 / 500));
      }

      & .imgWrap {
        width: calc(100vw * (140 / 500));
        border-radius: calc(100vw * (5 / 500));
      }
    }

    & .textContainer {
      width: calc(100% - calc(100vw * (160 / 500)));

      & .subCategory {
        font-size: calc(100vw * (16 / 500));
      }

      & .company {
        margin: calc(100vw * (4 / 500)) 0 calc(100vw * (12 / 500));
        font-size: calc(100vw * (20 / 500));
      }

      & .more {
        top: calc(100vw * (2 / 500));
        gap: calc(100vw * (5 / 500));
        font-size: calc(100vw * (16 / 500));

        & .iconWrap {
          width: calc(100vw * (8 / 500));
          height: calc(100vw * (8 / 500));
          margin-top: calc(100vw * (5 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
