import connector from "service/connector";


const postIcon = async (data) => {
  return await connector.authConnector("upload/icon", "POST", data);
}
const postImage = async (data) => {
  return await connector.connectFetchController("upload/image", "POST", data);
}
const postDrag = async (data) => {
  return await connector.connectFetchController("upload/image/drag", "POST", data);
}
const postPet = async (data) => {
  return await connector.connectFetchController("upload/pet", "POST", data);
}


export {
  postIcon,
  postImage,
  postDrag,
  postPet
};

export default {
  postIcon,
  postImage,
  postDrag,
  postPet
}