const ip = "https://petwork.kr:8080/api/";
// const ip = "http://localhost:8080/api/";
const DOMAIN = "petwork.kr";
// const DOMAIN = "localhost";

const connector = {
  connectFetchController: async (
    path,
    method,
    body,
    callBack,
    errorCallBack
  ) => {
    return fetch(`${ip}${path}`, {
      // credentials: 'include',
      method: method,
      body: body ? body : null,
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        if (callBack) callBack(data);
        return data;
      })
      .catch(function (e) {
        console.log(e);
        try {
          let data = e.json();
          if (errorCallBack) errorCallBack(e.json());
        } catch (err) {
          if (errorCallBack) errorCallBack(e);
        }
      });
  },

  jsonConnector: async (path, method, body, token, callBack, errorCallBack) => {
    return fetch(`${ip}${path}`, {
      credentials: "include",
      method: method,
      body: body ? body : null,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("jwt")}`,
        // Authorization: "Bearer " + (token? token : null),
      },
    })
      .then(async function (res) {
        if (res.status != 200) {
          throw await res.json();
        }
        return res.json();
      })
      .then(function (data) {
        if (callBack) callBack(data);
        return data;
      })
      .catch(function (e) {
        console.log(e);
        if (errorCallBack) errorCallBack(e);
      });
  },

  authConnector: async (path, method, body, token, callBack, errorCallBack) => {
    return fetch(`${ip}${path}`, {
      credentials: "include",
      method: method,
      body: body ? body : null,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("jwt")}`,
        // Authorization: "Bearer " + (token? token : null),
      },
    })
      .then(async function (res) {
        if (res.status != 200) {
          throw await res.json();
        }
        return res.json();
      })
      .then(function (data) {
        if (callBack) callBack(data);
        return data;
      })
      .catch(function (e) {
        console.log(e);
        if (errorCallBack) errorCallBack(e);
      });
  },
};

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default connector;
export { connector, ip, DOMAIN };
