import styled from "styled-components";

// img
import IconEssential from "resources/image/icon/icon_essential.svg";

const Container = styled.div`
  min-height: calc(100vh - 70px);
  min-height: calc(100dvh - 70px);
  padding: 40px 0 120px;

  & .title {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2px;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;

    &::after {
      width: 8px;
      height: 8px;
      margin-left: 4px;
      background: url(${IconEssential}) no-repeat center / cover;
      content: "";
    }

    & small {
      font-size: 14px;
      font-weight: normal;
    }
  }

  & .listWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 14px 10px;
    margin-bottom: 60px;

    & .list {
      width: calc(calc(100% - 10px) / 2);

      & .iconWrap {
        width: 26px;
      }
    }
  }

  & .errorText {
    width: fit-content;
    margin: -29px auto 10px;
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (70 / 500)));
    min-height: calc(100dvh - calc(100vw * (70 / 500)));
    padding: calc(100vw * (40 / 500)) 0 calc(100vw * (120 / 500));

    & .title {
      gap: calc(100vw * (2 / 500));
      margin-bottom: calc(100vw * (20 / 500));
      font-size: calc(100vw * (20 / 500));

      &::after {
        width: calc(100vw * (8 / 500));
        height: calc(100vw * (8 / 500));
        margin-left: calc(100vw * (4 / 500));
      }

      & small {
        font-size: calc(100vw * (14 / 500));
      }
    }

    & .listWrap {
      gap: calc(100vw * (14 / 500)) calc(100vw * (10 / 500));
      margin-bottom: calc(100vw * (60 / 500));

      & .list {
        width: calc(calc(100% - calc(100vw * (10 / 500))) / 2);

        & .iconWrap {
          width: calc(100vw * (26 / 500));
        }
      }
    }

    & .errorText {
      margin: calc(100vw * (-29 / 500)) auto calc(100vw * (10 / 500));
    }
  }
`;

export { Container };

export default { Container };
