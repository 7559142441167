import Styles from "./styles";
import utils from "utils";
import { useParams } from "react-router-dom";
import { clickDetail } from "utils/etc";
import { common, layout } from "components";
import { useEvent, useReply, useScrap } from "hooks";

// img
import exceptionImage from "resources/image/icon/logo_gray.svg";
import IconLikeOff from "resources/image/icon/icon_like_off.svg";
import IconLikeOn from "resources/image/icon/icon_like_on.svg";
import IconLocate from "resources/image/icon/icon_locate.svg";
import IconDate from "resources/image/icon/icon_date.svg";
import IconMap from "resources/image/icon/icon_map.svg";

function EventDetailPage() {
  const { id } = useParams();
  const eventInfo = useEvent({ detailOption: id });
  const scrapInfo = useScrap({ queryName: "eventDetail" });
  const replyInfo = useReply({
    listOption: {
      relate_info_id: id,
      type: 1,
    },
  });

  const detail = eventInfo.detailQuery.data?.data
    ? eventInfo.detailQuery.data?.data
    : {};
  const list = [
    {
      icon: IconLocate,
      text: detail.location ? detail.location : null,
    },
    {
      icon: IconDate,
      text: detail.started_at
        ? utils.date.getDateType(detail.started_at) +
          "~" +
          utils.date.getDateType(detail.ended_at)
        : null,
    },
    {
      icon: IconMap,
      text: detail.address ? (
        <>
          {detail.address}
          {detail.address_url && (
            <button
              type="button"
              onClick={() => {
                // 새 창으로
                detail.address_url && clickDetail(detail.address_url);
              }}
            >
              지도보기
            </button>
          )}
        </>
      ) : null,
    },
  ];

  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.SubHeader pageName={"행사와 지원혜택"} share />

        <Styles.Container>
          <section className="detailSection">
            <div className="inner">
              <div className="imgWrap">
                <img
                  src={detail?.thumbnail ? detail?.thumbnail : exceptionImage}
                  alt={detail?.thumbnail}
                />
              </div>

              <div className="titleContainer">
                <p className="title">{detail?.title}</p>

                <button
                  type="button"
                  className="likeButton"
                  onClick={() => {
                    scrapInfo.toggleScrap(id, -1, 1);
                  }}
                >
                  <img
                    src={detail.scrap_yn === "Y" ? IconLikeOn : IconLikeOff}
                    alt="IconLike"
                  />
                </button>
              </div>

              <ul className="listWrap">
                {list?.map((item, index) => {
                  return (
                    item.text && (
                      <li className="list" key={"list" + index}>
                        <div className="iconWrap">
                          <img src={item.icon} alt={item.icon} />
                        </div>
                        <p>{item.text}</p>
                      </li>
                    )
                  );
                })}
              </ul>

              <p
                className="editor ck-content"
                dangerouslySetInnerHTML={{ __html: detail?.description }}
              ></p>
            </div>
          </section>

          <section className="commentSection">
            <div className="inner">
              <layout.Comment replyInfo={replyInfo} />
            </div>
          </section>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default EventDetailPage;
