import Styles from "./styles";
import { Link } from "react-router-dom";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right_box.svg";

function SectionTitle({ children, url }) {
  return (
    <Styles.Container className="sectionTitleContainer">
      <h2 className="sectionTitle">{children}</h2>

      {url && (
        <Link to={url} className="moreButton">
          더보기{" "}
          <div className="iconWrap">
            <img src={IconArrowRight} alt="IconArrowRight" />
          </div>
        </Link>
      )}
    </Styles.Container>
  );
}

export default SectionTitle;
