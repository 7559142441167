import ui from "components/ui";
import Styles from "./styles";
import { useAuth } from "hooks";
import { useState } from "react";
import { CommentList } from "./components";

function Comment({ replyInfo, detail }) {
  const list = replyInfo.listQuery?.data?.list;
  const pagination = replyInfo.listQuery?.data?.pagination;
  const authInfo = useAuth({});
  const [content, setContent] = useState("");
  const [subWrite, setSubWrite] = useState(-1);

  const onReplyEdit = (index) => {
    setSubWrite(index);
  };

  return (
    <Styles.Container>
      <p className="total">
        댓글 {pagination?.totalRecordCount ? pagination?.totalRecordCount : 0}
      </p>

      <div className="commentInputContainer">
        <ui.input.CommentInput
          value={content}
          setValue={setContent}
          onClick={() => {
            if (authInfo.certification()) {
              replyInfo.createReply(
                JSON.stringify({
                  reply_info_id: 0,
                  relate_info_id: replyInfo?.listOption.relate_info_id,
                  type: replyInfo?.listOption.type,
                  content: content,
                })
              );
            }
          }}
        />
      </div>

      <ul className="listWrap">
        {list?.map((item, index) => {
          return (
            <li className="list" key={"commentList" + index}>
              <CommentList
                data={item}
                replyInfo={replyInfo}
                onDeleteReply={() => {
                  replyInfo.deleteReply(item.id);
                }}
                onEditReply={(text) => {
                  replyInfo.editReply(item.id, text);
                }}
                onReplyEdit={() => {
                  onReplyEdit(subWrite == index ? -1 : index);
                }}
              />
              {subWrite == index && (
                <ul className="replyListWrap">
                  <li
                    className="replyList"
                    key={"replyList" + index + " " + -1}
                  >
                    <CommentList
                      data={
                        detail?.nickname === "익명의 수발러"
                          ? { ...authInfo.userData, nickname: "익명의 수발러" }
                          : authInfo.userData
                      }
                      onCreateReply={(text) => {
                        if (authInfo.certification()) {
                          replyInfo.createReply(
                            JSON.stringify({
                              reply_info_id: item.id,
                              relate_info_id:
                                replyInfo?.listOption.relate_info_id,
                              type: replyInfo?.listOption.type,
                              content: text,
                            })
                          );
                        }
                      }}
                      reply
                      replyMode={true}
                    />
                  </li>
                </ul>
              )}

              {item.reply_list && (
                <ul className="replyListWrap">
                  {item.reply_list.map((current, idx) => {
                    return (
                      <li className="replyList" key={"replyList" + idx}>
                        <CommentList
                          data={current}
                          onDeleteReply={() => {
                            replyInfo.deleteReply(current.id);
                          }}
                          onEditReply={(text) => {
                            replyInfo.editReply(current.id, text);
                          }}
                          reply
                        />
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </Styles.Container>
  );
}

export default Comment;
