import Styles from "./styles";

// img
import IconNoList from "resources/image/icon/icon_no_list.svg";
import IconPen from "resources/image/icon/icon_pen.svg";

function EmptyContainer({ text, search, pen }) {
  return (
    <Styles.Container className={search ? "on" : ""}>
      <div className="iconWrap">
        <img src={pen ? IconPen : IconNoList} alt="IconNoList" />
      </div>

      <p className="emptyText">{text}</p>
    </Styles.Container>
  );
}

export default EmptyContainer;
