import styled from "styled-components";

const Container = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #fff;

  & .inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;

    & .prevButton {
      width: 24px;
      height: 24px;
    }

    & .shareButton {
      width: 28px;
      height: 28px;
    }

    & .pageName {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 26px;
      font-weight: bold;
    }
  }

  @media (max-width: 499px) {
    & .inner {
      height: calc(100vw * (70 / 500));

      & .prevButton {
        width: calc(100vw * (24 / 500));
        height: calc(100vw * (24 / 500));
      }

      & .shareButton {
        width: calc(100vw * (28 / 500));
        height: calc(100vw * (28 / 500));
      }

      & .pageName {
        font-size: calc(100vw * (26 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
