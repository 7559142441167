import Styles from "./styles";

// img
import ImgAppleStore from "resources/image/img_apple_store.svg";
import ImgGoogleStore from "resources/image/img_google_store.svg";

function FixedBg() {
  return (
    <Styles.Container>
      <div className="textWrap">
        <p className="title">
          견생은 두 번 오지 않아! <br />
          나의 정보력은
          <br />
          댕댕이의 웰빙!
        </p>

        <p className="description">
          인생보다 짧은 견생이라서
          <br />
          변하는 건강, 체질, 입맛, 멘탈을 보살피기 위해
          <br />
          우리는 신뢰할 정보와 솔직한 경험담을 필요로 합니다.
        </p>

        <p className="description">
          정보 선택과 분석에 마음을 다하는 펫트워크의
          <br />
          댕댕위키, 수발러 게시판, 행사소식, 매거진에서
        </p>

        <p className="description">
          현실적으로 도움되는 T스러운 정보
          <br />
          따뜻하게 위안 받을 수 있는 F스러운 정보
          <br />
          함께 나누고 누려보세요.
        </p>

        <div className="downloadContainer">
          <a
            href="https://apps.apple.com/kr/app/%ED%8E%AB%ED%8A%B8%EC%9B%8C%ED%81%AC-%EB%8C%95%EB%8C%95-%EB%B3%B4%ED%98%B8%EC%9E%90%EB%93%A4%EC%9D%98-%EC%A0%95%EB%B3%B4-%EB%84%A4%ED%8A%B8%EC%9B%8C%ED%81%AC/id1560767965"
            className="downloadLink"
          >
            <img src={ImgAppleStore} alt="ImgAppleStore" />
          </a>

          <a
            href="https://play.google.com/store/apps/details?id=kr.co.petwork&hl=ko"
            className="downloadLink"
          >
            <img src={ImgGoogleStore} alt="ImgGoogleStore" />
          </a>

          <p>
            앱으로 편하게 <br />
            펫트워크를 만나보세요!
          </p>
        </div>
      </div>
    </Styles.Container>
  );
}

export default FixedBg;
