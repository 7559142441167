import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 100px;

  & .section01 {
    padding: 30px 0 12px;

    & .slideList {
      height: 149px;
      cursor: pointer;
    }
  }

  & .section02 {
    & .guideText {
      margin-bottom: 40px;
      padding: 10px 0;
      font-size: 13px;
      font-weight: 500;
      color: #b7b7d2;
      text-align: center;
      background-color: var(--gray01);
      border-radius: 5px;
    }

    & .listWrap {
      margin-bottom: 80px;
      cursor: pointer;

      & .list {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 84px;
        font-weight: 500;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 4px 5px 27px 0px rgba(68, 74, 102, 0.1);

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        & .iconWrap {
          width: 24px;
          height: 24px;
        }

        & .title {
          display: flex;
          width: 325px;
          margin: 0 6px;
          font-size: 20px;

          & .nickname {
            max-width: 42%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        & .date {
          font-size: 14px;
          color: var(--gray03);
        }
      }
    }

    & .emptyContainer {
      margin-bottom: 317px;
    }

    & .adContainer {
      margin-top: 40px;
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (100 / 500));

    & .section01 {
      padding: calc(100vw * (30 / 500)) 0 calc(100vw * (12 / 500));

      & .slideList {
        height: calc(100vw * (149 / 500));
      }
    }

    & .section02 {
      & .guideText {
        margin-bottom: calc(100vw * (40 / 500));
        padding: calc(100vw * (10 / 500)) 0;
        font-size: calc(100vw * (13 / 500));
        border-radius: calc(100vw * (5 / 500));
      }

      & .listWrap {
        margin-bottom: calc(100vw * (80 / 500));
        cursor: pointer;

        & .list {
          height: calc(100vw * (84 / 500));
          border-radius: calc(100vw * (10 / 500));
          box-shadow: calc(100vw * (4 / 500)) calc(100vw * (5 / 500))
            calc(100vw * (27 / 500)) 0px rgba(68, 74, 102, 0.1);

          &:not(:last-child) {
            margin-bottom: calc(100vw * (10 / 500));
          }

          & .iconWrap {
            width: calc(100vw * (24 / 500));
            height: calc(100vw * (24 / 500));
          }

          & .title {
            width: calc(100vw * (325 / 500));
            margin: 0 calc(100vw * (6 / 500));
            font-size: calc(100vw * (20 / 500));
          }

          & .date {
            font-size: calc(100vw * (14 / 500));
          }
        }
      }

      & .emptyContainer {
        margin-bottom: calc(100vw * (317 / 500));
      }

      & .adContainer {
        margin-top: calc(100vw * (40 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
