import ui from "components/ui";
import Styles from "./styles";
import { useState } from "react";
import { useAuth, useModals } from "hooks";

// img
import ImgDefaultProfile from "resources/image/img_default_profile.svg";
import IconMore from "resources/image/icon/icon_more.svg";
import utils from "utils";

function CommentList({ data, reply, onDeleteReply, onEditReply, onReplyEdit , replyMode, onCreateReply }) {
  const modalOption = useModals();
  const authInfo = useAuth({});
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(0);
  const [content, setContent] = useState("");

  const renderDetail = (type) => {
    switch (type) {
      case 0:
        return (
          <>
            <div className="moreButtonContainer">
              {data.report_yn === "N" && data.delete_yn === "N" && authInfo.isLogin === true &&
                <button
                  type="button"
                  className="moreButton"
                  onClick={() => {
                    setIsMoreOpen(!isMoreOpen);
                  }}
                >
                  <img src={IconMore} alt="IconMore" />
                </button>
              } 

              {isMoreOpen && (
                <ul className="moreListWrap">
                  {data.my_yn == "Y" ? (
                    <>
                      <li className="moreList">
                        <button
                          type="button"
                          onClick={() => {
                            setIsEdit(1);
                            setIsMoreOpen(false);
                            setContent(data.content)
                          }}
                        >
                          수정
                        </button>
                      </li>

                      <li className="moreList">
                        <button
                          type="button"
                          onClick={() => {
                            onDeleteReply();
                            setIsMoreOpen(false);
                          }}
                        >
                          삭제
                        </button>
                      </li>
                    </>
                  ) : (
                    <li className="moreList">
                      <button
                        type="button"
                        onClick={() => {
                          setIsMoreOpen(false);
                          modalOption.setReportModal((e) => {
                            e.show = true;
                            e.type = 5;
                            e.id = data.id;
                            return { ...e };
                          });
                        }}
                      >
                        신고
                      </button>
                    </li>
                  )}
                </ul>
              )}
            </div>

            <p className="comment">{data.content}</p>

            <div className="bottomContainer">
              <p className="date">{utils.date.getDateType(data.created_at)}</p>

              {!reply && (
                <button
                  type="button"
                  className="replyButton"
                  onClick={() => {
                    if (authInfo.certification()) { onReplyEdit() }
                  }}
                >
                  답글 달기
                </button>
              )}
            </div>
          </>
        );
      case 1:
        return (
          <div className="editCommentContainer">
            <ui.input.CommentInput
              reply={reply}
              value={content}
              setValue={setContent}
              onClick={() => {
                setIsEdit(0);
                if(replyMode){
                  onCreateReply(content);
                }else
                  onEditReply(content);
              }}
            />
          </div>
        );
    }
  };

  return (
    <Styles.Container className={"commentContainer" + (reply ? " reply" : "")}>
      <div className="profileContainer">
        <div className="profileImgWrap">
          <img
            src={data.profile_image ? data.profile_image : ImgDefaultProfile}
            alt="profileImg"
          />
        </div>

        <p className="userName">{data.nickname}</p>

        {data.write_yn == "Y" && <div className="writer">글쓴이</div>}
      </div>

      {renderDetail(replyMode ? 1 : isEdit)}
    </Styles.Container>
  );
}

export default CommentList;
