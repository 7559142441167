import styled from "styled-components";

const Container = styled.div`
  display: flex;
  min-height: 60px;
  border: 1px solid var(--gray03);
  border-radius: 6px;

  & textarea {
    display: block;
    flex-grow: 1;
    margin: 18px 0;
    padding: 0 14px;
    word-break: break-all;

    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--purple01);
      border-radius: 999px;
      background-clip: padding-box;
      border: 4px solid transparent;
    }
  }

  & .submitButton {
    flex-shrink: 0;
    width: 59px;
    height: 60px;
    font-weight: 500;
    color: var(--main);
  }

  @media (max-width: 499px) {
    min-height: calc(100vw * (60 / 500));
    border-radius: calc(100vw * (6 / 500));

    & textarea {
      margin: calc(100vw * (18 / 500)) 0;
      padding: 0 calc(100vw * (14 / 500));

      &::-webkit-scrollbar {
        width: calc(100vw * (12 / 500));
      }
      &::-webkit-scrollbar-thumb {
        border: calc(100vw * (4 / 500)) solid transparent;
      }
    }

    & .submitButton {
      width: calc(100vw * (56 / 500));
      height: calc(100vw * (60 / 500));
    }
  }
`;

export { Container };

export default { Container };
