import connector from "service/connector";

const getBanner = async (path) => {
  return await connector.connectFetchController("banner?type=" + path, "GET");
}
const clickBanner = async (path) => {
  return await connector.connectFetchController("banner/click?id=" + path, "POST");
}

export { getBanner, clickBanner };
export default { getBanner, clickBanner };