import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.on {
    flex-direction: row;
    gap: 6px;

    & .iconWrap {
      width: 25px;
      height: 25px;
      margin-bottom: 0;
    }

    & .emptyText {
      font-size: 18px;
    }
  }

  & .iconWrap {
    width: 24px;
    height: 24px;
    margin-bottom: 10px;
  }

  & .emptyText {
    font-size: 17px;
  }

  @media (max-width: 499px) {
    &.on {
      gap: calc(100vw * (6 / 500));

      & .iconWrap {
        width: calc(100vw * (25 / 500));
        height: calc(100vw * (25 / 500));
      }

      & .emptyText {
        font-size: calc(100vw * (18 / 500));
      }
    }

    & .iconWrap {
      width: calc(100vw * (24 / 500));
      height: calc(100vw * (24 / 500));
      margin-bottom: calc(100vw * (10 / 500));
    }

    & .emptyText {
      font-size: calc(100vw * (17 / 500));
    }
  }
`;

export { Container };

export default { Container };
