import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: calc(50% + 75px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 100vh;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;

  & .modalContainer {
    width: 430px;
    max-height: 80vh;
    max-height: 80dvh;
    padding: 50px 20px;
    background-color: #fff;
    border-radius: 10px;
    overflow: auto;

    & .title {
      margin-bottom: 10px;
      font-size: 26px;
      font-weight: bold;
      text-align: center;
    }

    & .smallText {
      margin-bottom: 30px;
      font-size: 14px;
      color: var(--gray03);
      text-align: center;
    }

    & .text {
      margin-bottom: 57px;
      font-size: 20px;
      font-weight: bold;
    }

    & .inputListWrap {
      margin: 59px 0 30px;

      & .inputList {
        &:not(:last-child) {
          margin-bottom: 30px;
        }

        & .label {
          display: block;
          margin-bottom: 10px;
          font-size: 20px;
          font-weight: bold;
        }

        & .textareaContainer {
          height: 180px;
          padding: 16px;
          background-color: var(--gray01);

          & textarea {
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        & input[type="file"] {
          display: none;
        }

        & .imgUpload {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          width: 165px;
          height: 40px;
          font-size: 16px;
          font-weight: 500;
          color: var(--gray03);
          background-color: var(--gray01);
          cursor: pointer;

          & .iconWrap {
            display: inline-block;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    & .fileListWrap {
      display: flex;
      gap: 15px;
      margin-top: 20px;

      & .fileList {
        display: flex;
        gap: 2px;

        & .img {
          width: 99px;
          height: 99px;
          overflow: hidden;
        }

        & .deleteButton {
          width: 19px;
          height: 19px;
        }
      }
    }

    & .buttonListWrap {
      width: 320px;
      margin: 0 auto;

      & .buttonList:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  @media (max-width: 1200px) {
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 499px) {
    width: 100%;

    & .modalContainer {
      width: calc(100vw * (430 / 500));
      padding: calc(100vw * (50 / 500)) calc(100vw * (20 / 500));
      border-radius: calc(100vw * (10 / 500));

      & .title {
        margin-bottom: calc(100vw * (10 / 500));
        font-size: calc(100vw * (26 / 500));
      }

      & .smallText {
        margin-bottom: calc(100vw * (30 / 500));
        font-size: calc(100vw * (14 / 500));
      }

      & .text {
        margin-bottom: calc(100vw * (57 / 500));
        font-size: calc(100vw * (20 / 500));
      }

      & .inputListWrap {
        margin: calc(100vw * (59 / 500)) 0 calc(100vw * (30 / 500));

        & .inputList {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (30 / 500));
          }

          & .label {
            margin-bottom: calc(100vw * (10 / 500));
            font-size: calc(100vw * (20 / 500));
          }

          & textarea {
            height: calc(100vw * (180 / 500));
            padding: calc(100vw * (16 / 500));
          }

          & .imgUpload {
            gap: calc(100vw * (8 / 500));
            width: calc(100vw * (165 / 500));
            height: calc(100vw * (40 / 500));
            font-size: calc(100vw * (16 / 500));

            & .iconWrap {
              width: calc(100vw * (20 / 500));
              height: calc(100vw * (20 / 500));
            }
          }

          & .fileListWrap {
            gap: calc(100vw * (15 / 500));
            margin-top: calc(100vw * (20 / 500));

            & .fileList {
              gap: calc(100vw * (2 / 500));

              & .img {
                width: calc(100vw * (99 / 500));
                height: calc(100vw * (99 / 500));
              }

              & .deleteButton {
                width: calc(100vw * (19 / 500));
                height: calc(100vw * (19 / 500));
              }
            }
          }
        }
      }

      & .buttonListWrap {
        width: calc(100vw * (320 / 500));
        margin-top: calc(100vw * (51 / 500));

        & .buttonList:not(:last-child) {
          margin-bottom: calc(100vw * (16 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
