import utils from "utils";
import Styles from "./styles";
import { common, layout, ui } from "components";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMagazine, useReply, useScrap } from "hooks";

// img
import exceptionImage from "resources/image/icon/logo_gray.svg";
import IconLikeOff from "resources/image/icon/icon_like_off.svg";
import IconLikeOn from "resources/image/icon/icon_like_on.svg";
import IconEye from "resources/image/icon/icon_eye.svg";

function MagazineDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const filter = {
    page: 1,
    recordSize: 6,
    pageSize: 6,
    order: 4,
    type: 3,
  };
  const scrapInfo = useScrap({ queryName: "magazine", callFilter: filter });
  const scrapDetail = useScrap({ queryName: "magazineDetail" });
  const replyInfo = useReply({
    listOption: {
      relate_info_id: id,
      type: 3,
    },
  });
  const magazineInfo = useMagazine({ detailOption: id, listOption: filter });
  const detail = magazineInfo.detailQuery.data?.data
    ? magazineInfo.detailQuery.data?.data
    : {};
  const data = magazineInfo.listQuery?.data ? magazineInfo.listQuery?.data : {};

  const [more, setMore] = useState(false);
  const ref = useRef();

  useEffect(() => {
    setMore(ref.current?.offsetHeight >= 440);
  }, [magazineInfo.detailQuery.data]);

  useEffect(() => {
    magazineInfo.detailQuery?.data?.success === false &&
      magazineInfo.accessRestrict();
  }, [magazineInfo.detailQuery?.data?.success]);

  return (
    <>
      <common.FixedBg />

      {detail.id && (
        <div className="wrap">
          <common.SubHeader share pageName={"매거진"} />

          <Styles.Container>
            <section className="detailSection">
              <div className="titleContainer">
                <p className="title">{detail?.title}</p>

                <div className="imgWrap">
                  <img
                    src={detail?.thumbnail ? detail?.thumbnail : exceptionImage}
                    alt={detail?.thumbnail}
                  />
                </div>
              </div>

              <div className="inner">
                <div className="topContainer">
                  <ul className="listWrap">
                    <li className="list">
                      {utils.date.getDateType(detail?.created_at)}
                    </li>

                    <li className="list">
                      <div className="iconWrap">
                        <img src={IconEye} alt="IconEye" />
                      </div>

                      <p>{detail.view}</p>
                    </li>
                  </ul>

                  <button
                    type="button"
                    className="likeButton"
                    onClick={() => {
                      scrapDetail.toggleScrap(id, -1, 3);
                    }}
                  >
                    <img
                      src={detail.scrap_yn === "Y" ? IconLikeOn : IconLikeOff}
                      alt="IconLike"
                    />
                  </button>
                </div>

                <div className="descriptionContainer">
                  <p
                    className={
                      "editor ck-content description" + (more ? "" : " open")
                    }
                    ref={ref}
                    dangerouslySetInnerHTML={{ __html: detail?.description }}
                  ></p>
                  {more && (
                    <div className="moreButtonContainer">
                      <button
                        type="button"
                        className="moreButton"
                        onClick={() => {
                          setMore(false);
                        }}
                      >
                        계속 이어보기
                      </button>
                    </div>
                  )}
                </div>

                <ul className="keywordListWrap">
                  {detail?.keyword_list?.map((item, index) => {
                    return (
                      <li className="keywordList" key={"keywordList" + index}>
                        <ui.button.Keyword
                          text={item.title}
                          onClick={() => {
                            navigate(`/search/detail?keyword=${item.title}`);
                          }}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </section>

            <section className="commentSection">
              <div className="inner">
                <layout.Comment replyInfo={replyInfo} />

                <div className="adContainer">
                  <layout.AdContainer />
                </div>
              </div>
            </section>

            <section className="listSection">
              <div className="inner">
                <ul className="listWrap">
                  {data.list?.map((item, index) => {
                    return (
                      <li className="list" key={"magazineList" + index}>
                        <Link to={`/magazine/detail/${item.id}`}>
                          {item.id != id && (
                            <layout.MagazineList
                              data={item}
                              index={index}
                              scrapInfo={scrapInfo}
                              exception={true}
                            />
                          )}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </section>
          </Styles.Container>

          <common.QuickMenu />

          <common.NavigationBar />
        </div>
      )}
    </>
  );
}

export default MagazineDetailPage;
