import Styles from "./styles";

function BasicTextarea({
  labelText,
  essential,
  setInputs,
  placeholder,
  value,
  name,
  disabled,
}) {
  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs((e) => {
      return { ...e, [name]: value };
    });
  };

  return (
    <Styles.Container>
      {labelText && (
        <label className={"label" + (essential ? " essential" : "")}>
          <span>{labelText}</span>
        </label>
      )}

      <div className="textareaContainer">
        <textarea
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
        ></textarea>
      </div>
    </Styles.Container>
  );
}

export default BasicTextarea;
