import styled from "styled-components";

// img
import IconTemperature01 from "resources/image/icon/icon_temperature01.svg";
import IconTemperature02 from "resources/image/icon/icon_temperature02.svg";
import IconTemperature03 from "resources/image/icon/icon_temperature03.svg";
import IconTemperature04 from "resources/image/icon/icon_temperature04.svg";
import IconTemperature05 from "resources/image/icon/icon_temperature05.svg";
import IconTemperature06 from "resources/image/icon/icon_temperature06.svg";

const Container = styled.div`
  width: 390px;
  margin: 0 auto;

  & ul {
    display: flex;

    & li {
      position: relative;
      width: calc(100% / 6);

      &.nothing {
        & label {
          &::before {
            display: block;
          }

          &::after {
            bottom: -29px;
            font-size: 14px;
            color: var(--gray02);
          }
        }
      }

      &.on ~ li label::before {
        display: block;
      }
    }
  }

  & input {
    display: none;

    &:checked + label {
      & .iconWrap {
        filter: invert(15%) sepia(0%) saturate(0%) hue-rotate(157deg)
          brightness(96%) contrast(85%);
      }

      &::after {
        color: var(--black);
      }
    }

    &:disabled + label {
      cursor: unset;
    }
  }

  & label {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 30px;
    cursor: pointer;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      display: none;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.79);
      content: "";
      z-index: 1;
    }

    &::after,
    & .iconWrap {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: inline-block;
    }

    & .iconWrap {
      top: -27px;
      width: 22px;
      height: 22px;
      background: no-repeat center / cover;
      filter: invert(95%) sepia(4%) saturate(488%) hue-rotate(201deg)
        brightness(91%) contrast(88%);
      content: "";
    }

    &::after {
      bottom: -29px;
      font-size: 14px;
      color: var(--gray02);
    }
  }

  & li:first-child label {
    background-color: #c1eded;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;

    & .iconWrap {
      background-image: url(${IconTemperature01});
    }

    &::after {
      content: "35.5";
    }
  }

  & li:nth-of-type(2) label {
    background-color: #caf496;

    & .iconWrap {
      background-image: url(${IconTemperature02});
    }

    &::after {
      content: "36.0";
    }
  }

  & li:nth-of-type(3) label {
    background-color: #fff06e;

    & .iconWrap {
      background-image: url(${IconTemperature03});
    }

    &::after {
      content: "36.5";
    }
  }

  & li:nth-of-type(4) label {
    background-color: #ffd480;

    & .iconWrap {
      background-image: url(${IconTemperature04});
    }

    &::after {
      content: "37.0";
    }
  }

  & li:nth-of-type(5) label {
    background-color: #ffa28e;

    & .iconWrap {
      background-image: url(${IconTemperature05});
    }

    &::after {
      content: "37.5";
    }
  }

  & li:last-child label {
    background-color: #ff80ad;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;

    & .iconWrap {
      background-image: url(${IconTemperature06});
    }

    &::after {
      content: "38.0";
    }
  }

  @media (max-width: 499px) {
    width: calc(100vw * (390 / 500));

    & label {
      height: calc(100vw * (30 / 500));

      & .iconWrap {
        top: calc(100vw * (-27 / 500));
        width: calc(100vw * (22 / 500));
        height: calc(100vw * (22 / 500));
      }

      &::after {
        bottom: calc(100vw * (-29 / 500));
        font-size: calc(100vw * (14 / 500));
      }
    }

    & li:first-child label {
      border-top-left-radius: calc(100vw * (50 / 500));
      border-bottom-left-radius: calc(100vw * (50 / 500));
    }

    & li:last-child label {
      border-top-right-radius: calc(100vw * (50 / 500));
      border-bottom-right-radius: calc(100vw * (50 / 500));
    }
  }
`;

export { Container };

export default { Container };
