import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 70px);
  min-height: calc(100dvh - 70px);
  padding: 40px 0 120px;

  & .title {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: bold;
  }

  & .termsContainer {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--gray02);

    & .checkList {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 18px;
      }

      & .termsButton {
        font-size: 16px;
        color: var(--gray02);
        text-decoration: underline;
        text-underline-position: from-font;
      }
    }
  }

  & .infoContainer {
    margin-bottom: 60px;
  }

  & .list {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    & .confirmButton {
      width: 120px;
    }

    & .listTitle {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    & .selectContainer {
      width: 134px;
    }

    & .genderListWrap {
      display: flex;
      gap: 10px;

      & .genderList {
        width: calc(calc(100% - 10px) / 2);

        & .iconWrap {
          width: 34px;
        }
      }
    }
  }

  & .errorText {
    width: fit-content;
    margin: -29px auto 10px;
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (70 / 500)));
    min-height: calc(100dvh - calc(100vw * (70 / 500)));
    padding: calc(100vw * (40 / 500)) 0 calc(100vw * (120 / 500));

    & .title {
      margin-bottom: calc(100vw * (30 / 500));
      font-size: calc(100vw * (20 / 500));
    }

    & .termsContainer {
      margin-bottom: calc(100vw * (30 / 500));
      padding-bottom: calc(100vw * (30 / 500));

      & .checkList {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (18 / 500));
        }

        & .termsButton {
          font-size: calc(100vw * (16 / 500));
        }
      }
    }

    & .infoContainer {
      margin-bottom: calc(100vw * (60 / 500));
    }

    & .list {
      &:not(:last-child) {
        margin-bottom: calc(100vw * (20 / 500));
      }

      & .confirmButton {
        width: calc(100vw * (120 / 500));
      }

      & .listTitle {
        font-size: calc(100vw * (20 / 500));
        margin-bottom: calc(100vw * (10 / 500));
      }

      & .selectContainer {
        width: calc(100vw * (134 / 500));
      }

      & .genderListWrap {
        gap: calc(100vw * (10 / 500));

        & .genderList {
          width: calc(calc(100% - calc(100vw * (10 / 500))) / 2);

          & .iconWrap {
            width: calc(100vw * (34 / 500));
          }
        }
      }
    }

    & .errorText {
      margin: calc(100vw * (-29 / 500)) auto calc(100vw * (10 / 500));
    }
  }
`;

export { Container };

export default { Container };
