import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";

function Tab({ tabList, tabIndex, setTabIndex }) {
  const navigate = useNavigate();

  return (
    <Styles.Container>
      <ScrollContainer className="scroll-container">
        <ul className="listWrap">
          {tabList?.map((item, index) => {
            return (
              <li
                className={
                  "list" +
                  (tabIndex === index || tabIndex === item.url ? " on" : "")
                }
                key={"tabList" + index}
                onClick={() => {
                  setTabIndex(index);
                  item.url && navigate(item.url);
                }}
              >
                {item.title ? item.title : item.text}
              </li>
            );
          })}
        </ul>
      </ScrollContainer>
    </Styles.Container>
  );
}

export default Tab;
