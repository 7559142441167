import utils from "utils";
import Styles from "./styles";
import { api } from "service";
import { common, ui, layout } from "components";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCategory, useEvent, useScrap } from "hooks";

function EventPage() {
  const [filter, setFilter] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  
  const scrapInfo = useScrap({ queryName: "event", callFilter: filter });
  const eventInfo = useEvent({ listOption: filter });
  const categoryInfo = useCategory({
    listOption: {
      type : 1,
      parent_info_id : 11
    }
  });

  const data = eventInfo.listQuery?.data ? eventInfo.listQuery?.data : {};

  useEffect(()=>{
    let param = utils.etc.getSearchParam();
    let f = { 
      page: param.page ? param.page : 1,
      recordSize: param.recordSize ? param.recordSize : 10,
      pageSize: 5,
      order: param.order ? param.order : 1,
      type: 1,
    };
    if(param.location){
      f.location = param.location;
    }
    if(param.category_info_id){
      f.category_info_id = param.category_info_id;
    }
    setFilter({...f});
  },[location])

  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.Header />

        <Styles.Container>
          <h2 className="pageName">행사와 지원혜택</h2>

          <ui.button.Tab
            tabList={[{title:"종합"}].concat(categoryInfo.list)}
            tabIndex={filter?.category_info_id ? categoryInfo.list.findIndex(e => e.id == filter?.category_info_id) + 1 : 0}
            setTabIndex={(e)=>{
              if(e == 0)
                navigate(utils.etc.getQueryParamUrl({ category_info_id : null, page:1 },false));
              else
                navigate(utils.etc.getQueryParamUrl({ category_info_id : categoryInfo.list[e - 1].id, page:1 },false));
            }}
          />

          <section>
            <div className="inner">
              <div className="filterContainer">
                <div className="checkContainer">
                  <ui.input.BasicCheckbox
                    labelText={"마감 임박순"}
                    checked={filter?.order == 2}
                    id={"deadline"}
                    onChange={(e) => {
                      navigate(utils.etc.getQueryParamUrl({ order : filter?.order == 2 ? 1 :  2 , page:1, },false));
                    }}
                  />
                </div>

                <div className="selectContainer">
                  <ui.input.BasicSelect
                    optionList={[...["전국"].concat(api.dummy.locationList),"온라인"]}
                    select={filter?.location || ""}
                    setSelect={(e) => {
                      navigate(utils.etc.getQueryParamUrl({ location: e == "전국" ? null : e, page:1, },false));
                    }}
                    placeholder={"지역"}
                    size={"small"}
                  />
                </div>
              </div>

              <ul className="listWrap">
                {data?.list?.map((item, index) => {
                  return (
                    <>
                      <li className="list" key={"eventList" + index}>
                        <Link to={`detail/${item.id}`}>
                          <layout.EventList
                            data={item}
                            index={index}
                            scrapInfo={scrapInfo}
                          />
                        </Link>
                      </li>
                      {index === 2 &&
                        <li className="list adContainer">
                          <layout.AdContainer />
                        </li>
                      }
                    </>
                  );
                })}
              </ul>

              <div className="paginationContainer">
                <ui.button.Pagination
                  page={data.pagination?.page}
                  list={data.pagination?.page_list}
                  maxPage={data.pagination?.totalPageCount}
                />
              </div>
            </div>
          </section>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default EventPage;
