import useModals from "hooks/useModals";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "service";

export default function useReport() {
  const modalOption = useModals();
  const location = useLocation();
  const navigate = useNavigate();

  const onReport = (inputs, id, type, modalClose) => {
    modalClose();
    const data = () => {
      return {
        reason: inputs.textarea ? inputs.textarea : inputs.reason,
        relate_info_id: id,
        type: type
      }
    }

    console.log(data());
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.title = "신고하시겠습니까?";
      e.text = "신고 시 취소가 불가능합니다.";
      e.confirmButtonText = "신고하기";
      e.confirmButtonClick = async () => {
        const result = await api.report.report(JSON.stringify(data()));
        if (result.success) {
          if(modalOption.reportModal.callback){
            modalOption.reportModal.callback();
          }
          navigate(type === "1" && "/" + location.pathname.split("/")[1])
          modalOption.setDefaultModal((e) => {
            e.show = true;
            e.title = "신고해주셔서 감사합니다.";
            e.text = "빠른 시일 내에 확인 후 조치하겠습니다.";
            e.confirmButtonText = "확인";
            e.confirmButtonClick = () => {
              if(data().type == 5 || data().type == 6) {
                window.location.reload();
              }
              else {
                window.history.back();
              }
            };
            e.cancelButtonText = false;
            e.callback = () => {
              if(data().type == 5 || data().type == 6) {
                window.location.reload();
              }
              else {
                window.history.back();
              }
            }
            return { ...e };
          });
        }
      };
      e.cancelButtonText = "취소";
      e.cancelButtonClick = false;
      return { ...e };
    });
  }

  return {
    onReport
  };
}
