import styled from "styled-components";

// img
import ImgIntro from "resources/image/img_intro.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  height: 100dvh;
  padding-top: 40vh;
  padding-top: 40dvh;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  background: url(${ImgIntro}) no-repeat center / cover;

  & .logoWrap {
    width: 342px;
    height: 56px;
    margin-bottom: 20px;
  }

  @media (max-width: 499px) {
    font-size: calc(100vw * (24 / 500));

    & .logoWrap {
      width: calc(100vw * (342 / 500));
      height: calc(100vw * (56 / 500));
      margin-bottom: calc(100vw * (20 / 500));
    }
  }
`;

export { Container };

export default { Container };
