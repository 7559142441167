import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 110px;

  & .pageName {
    padding: 30px 0 20px;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
  }

  & .section01 {
    padding: 40px 0 30px;

    & .slideList {
      height: 149px;
      cursor: pointer;
    }
  }

  & .section02 {
    margin-bottom: 30px;

    & .listWrap {
      border-top: 1px solid var(--purple01);

      & .list {
        border-bottom: 1px solid var(--purple01);
        & a {
          display: flex;
          gap: 12px;
          padding: 15px 16px;
          background-color: var(--gray01);

          & .iconWrap {
            width: 24px;
            height: 24px;
          }

          & .right {
            width: calc(100% - 36px);

            & .listTitle {
              margin-bottom: 16px;
              font-size: 20px;
              font-weight: 600;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            & .bottomContainer {
              display: flex;
              gap: 10px;
              font-size: 14px;
              font-weight: 500;

              & .date {
                color: var(--gray03);
              }
            }
          }
        }
      }
    }
  }

  & .section03 {
    & .filterContainer {
      display: flex;
      justify-content: flex-end;
      gap: 14px;
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 2px solid var(--gray03);

      & .selectContainer {
        width: 134px;
      }
    }

    & .listWrap {
      margin-bottom: 80px;

      & .list {
        &:not(:last-child) {
          margin-bottom: 15px;
        }

        &.adContainer {
          padding: 25px 0;
        }
      }
    }

    & .emptyContainer {
      padding-top: 15px;
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (110 / 500));

    & .pageName {
      padding: calc(100vw * (30 / 500)) 0 calc(100vw * (20 / 500));
      font-size: calc(100vw * (26 / 500));
    }

    & .section01 {
      padding: calc(100vw * (40 / 500)) 0 calc(100vw * (30 / 500));

      & .slideList {
        height: calc(100vw * (149 / 500));
      }
    }

    & .section02 {
      margin-bottom: calc(100vw * (30 / 500));

      & .listWrap {
        & .list {
          & a {
            gap: calc(100vw * (12 / 500));
            padding: calc(100vw * (15 / 500)) calc(100vw * (16 / 500));

            & .iconWrap {
              width: calc(100vw * (24 / 500));
              height: calc(100vw * (24 / 500));
            }

            & .right {
              width: calc(100% - calc(100vw * (36 / 500)));

              & .listTitle {
                margin-bottom: calc(100vw * (16 / 500));
                font-size: calc(100vw * (20 / 500));
              }

              & .bottomContainer {
                gap: calc(100vw * (10 / 500));
                font-size: calc(100vw * (14 / 500));
              }
            }
          }
        }
      }
    }

    & .section03 {
      & .filterContainer {
        gap: calc(100vw * (14 / 500));
        margin-bottom: calc(100vw * (15 / 500));
        padding-bottom: calc(100vw * (15 / 500));
        border-bottom: calc(100vw * (2 / 500)) solid var(--gray03);

        & .selectContainer {
          width: calc(100vw * (134 / 500));
        }
      }

      & .listWrap {
        margin-bottom: calc(100vw * (80 / 500));

        & .list {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (15 / 500));
          }

          &.adContainer {
            padding: calc(100vw * (25 / 500)) 0;
          }
        }
      }

      & .emptyContainer {
        padding-top: calc(100vw * (15 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
