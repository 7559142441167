import styled from "styled-components";

// img
import IconEssential from "resources/image/icon/icon_essential.svg";

const Container = styled.div`
  & label {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 500;

    &.essential span {
      position: relative;

      &::after {
        position: absolute;
        top: 50%;
        right: -12px;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        background: url(${IconEssential}) no-repeat center / cover;
        content: "";
      }
    }
  }

  & .inputContainer {
    position: relative;
    display: flex;
    gap: 20px;

    & .react-datepicker-wrapper {
      flex-grow: 1;
    }

    & input {
      width: 100%;
      height: 60px;
      padding: 0 16px;
      background-color: var(--gray01);
      border-radius: 6px;
    }

    .react-datepicker__tab-loop {
      position: absolute;
      top: 0;
    }

    .react-datepicker__triangle {
      display: none !important;
    }
  }

  @media (max-width: 499px) {
    & label {
      margin-bottom: calc(100vw * (10 / 500));
      font-size: calc(100vw * (20 / 500));

      &.essential span {
        &::after {
          right: calc(100vw * (-12 / 500));
          width: calc(100vw * (8 / 500));
          height: calc(100vw * (8 / 500));
        }
      }
    }

    & .inputContainer {
      gap: calc(100vw * (20 / 500));

      & input {
        height: calc(100vw * (60 / 500));
        padding: 0 calc(100vw * (16 / 500));
        border-radius: calc(100vw * (6 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
