import utils from "utils";
import Styles from "./styles";
import { api } from "service";
import { common, ui, layout } from "components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useImage, usePet, useUpload } from "hooks";

// img
import IconPlus from "resources/image/icon/icon_plus02.svg";

function DogInfoStepAPage() {
  const petInfo = usePet({ isList: true });
  const imageInfo = useImage();
  const uploadInfo = useUpload();
  const location = useLocation();
  const navigate = useNavigate();

  const editData = petInfo.listQuery?.data?.data?.filter(v=>{return v.id === location.state?.id})[0]

  const [dogImage, setDogImage] = useState();
  const [inputs, setInputs] = useState({
    title: "",
    dog_type: "",
    goal: "",
    gender: null,
    meet: null,
    image: undefined,
    resize_image: undefined
  });
  const [date, setDate] = useState({ date: null });
  const [unknownCheck, setUnknownCheck] = useState(false);
  const [errorText, setErrorText] = useState(null);


  useEffect(()=>{
    if (sessionStorage.getItem("petInfo") === null && location.state?.id) {
      sessionStorage.setItem("petInfo", JSON.stringify(editData))
    }
    if (sessionStorage.getItem("petInfo")) {
      setInputs(e => { return { ...e, ...JSON.parse(sessionStorage.getItem("petInfo")) } })
      JSON.parse(sessionStorage.getItem("petInfo")).birthday
        ? setDate({ date: utils.date.getDateValidate(JSON.parse(sessionStorage.getItem("petInfo")).birthday) })
        : setUnknownCheck(true);
    }
  }, [location.state])

  // map
  const genderList = [
    {
      id: "gender1",
      labelText: "여아"
    },
    {
      id: "gender2",
      labelText: "여아+중성화"
    },
    {
      id: "gender3",
      labelText: "남아"
    },
    {
      id: "gender4",
      labelText: "남아+중성화"
    }
  ];
  const meetingList = [
    {
      id: "meeting1",
      labelText: "분양"
    },
    {
      id: "meeting2",
      labelText: "입양"
    },
    {
      id: "meeting3",
      labelText: "기타"
    }
  ];

  const validate = () => {
    if (location.state?.id === undefined && JSON.parse(sessionStorage.getItem("petInfo"))?.image === undefined) {
      if (dogImage === undefined) {
        setErrorText("이미지를 넣어주세요.");
        return false;
      }
    }
    if (inputs.title === "") {
      setErrorText("이름을 입력해주세요.");
      return false;
    }
    if (unknownCheck === false && date.date === undefined) {
      setErrorText("출생년도를 선택해주세요.");
      return false;
    }
    if (inputs.dog_type === "") {
      setErrorText("견종을 선택해주세요.");
      return false;
    }
    if (inputs.goal === "") {
      setErrorText("견생좌우명을 입력해주세요.");
      return false;
    }
    if (inputs.gender === null) {
      setErrorText("성별을 선택해주세요.");
      return false;
    }
    if (inputs.meet === null) {
      setErrorText("첫만남을 선택해주세요.");
      return false;
    }
    return true;
  }

  const onNext = async () => {
    const data = { ...inputs,
      birthday: !unknownCheck ? utils.date.getDateType(date.date, "-") : null
    }
    if (validate()) {
      const result = await uploadInfo.postPet(dogImage)
      if (result.success) {
        sessionStorage.setItem("petInfo", JSON.stringify({ ...data, 
          image: result?.data.image,
          resize_image: result?.data.resize_image
        }))
        navigate("/mypage/dog/stepB");
      } else {
        sessionStorage.setItem("petInfo", JSON.stringify(data))
        navigate("/mypage/dog/stepB");
      }
    }
  }

  return (
    location.state &&
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.SubHeader pageName={`개린이 ${location.state?.id ? "수정" : "등록"}`} />

        <Styles.Container>
          <section>
            <div className="inner">
              <form>
                <div className="profileImgContainer">
                  <div className="imgContainer">
                    <div className="imgWrap">
                      {imageInfo.imageUrl
                        ? <img src={imageInfo.imageUrl} alt="dogImg" />
                        : inputs?.image
                          ? <img src={inputs?.image} alt="dogImg" />
                          : (
                            <div className="iconWrap">
                              <img src={IconPlus} alt="IconPlus" />
                            </div>
                          )
                      }
                    </div>

                    <input
                      type="file"
                      id="imgUpload"
                      name="dogImg"
                      accept=".png, .jpeg, .jpg"
                      value={inputs.dogImg}
                      onChange={(e) => {
                        const maxSize = 20971520; // 20MB
                        if (e.target.files[0].size < maxSize){
                          setDogImage(e.target.files[0]);
                          imageInfo.imagePrint(e.target.files[0]);
                        }
                      }}
                    />

                    <label htmlFor="imgUpload" className="uploadButton">
                      등록하기
                    </label>
                  </div>

                  <p className="text">
                    예쁜 프로필을 위해 댕댕이가 <br />
                    <span>중앙에 위치한 사진</span>을 선택해주세요.
                  </p>
                </div>

                <ul className="listWrap">
                  <li className="list">
                    <ui.input.BasicInput
                      labelText={"이름"}
                      essential
                      placeholder={"이름을 입력하세요."}
                      name={"title"}
                      value={inputs.title}
                      setInputs={setInputs}
                    />
                  </li>

                  <li className="list date">
                    <div className="dateContainer">
                      <ui.input.DateInput
                        labelText={"출생년도"}
                        essential
                        name={"date"}
                        startDate={date?.date && date.date}
                        setStartDate={setDate}
                      />
                    </div>

                    <div className="checkboxContainer">
                      <ui.input.BasicCheckbox
                        id={"unknownCheck"}
                        checked={unknownCheck}
                        labelText={"알 수 없음"}
                        onChange={(e) => {
                          setDate({ date: null });
                          setUnknownCheck(e.target.checked);
                        }}
                      />
                    </div>
                  </li>

                  <li className="list">
                    <p className="label">견종</p>

                    <div className="selectContainer">
                      <ui.input.BasicSelect
                        optionList={api.dummy.dogTypeList}
                        placeholder={"견종을 선택하세요."}
                        select={inputs.dog_type}
                        setSelect={(v) => { setInputs((e) => { return { ...e, dog_type: v } }) }}
                      />
                    </div>
                  </li>

                  <li className="list">
                    <ui.input.BasicInput
                      labelText={"견생 좌우명"}
                      essential
                      placeholder={"ex) 내 간식에 손대면 응징!"}
                      name={"goal"}
                      value={inputs.goal}
                      setInputs={setInputs}
                    />
                  </li>

                  <li className="list">
                    <p className="label">성별</p>

                    <ul className="genderListWrap">
                      {genderList?.map((item, index) => {
                        return (
                          <li className="genderList" key={"genderList" + index}>
                            <ui.input.BoxRadio
                              labelText={item.labelText}
                              id={item.labelText}
                              name={"gender"}
                              checked={inputs.gender}
                              setInputs={setInputs}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </li>

                  <li className="list">
                    <p className="label">첫만남</p>

                    <ul className="meetingListWrap">
                      {meetingList?.map((item, index) => {
                        return (
                          <li
                            className="meetingList"
                            key={"meetingList" + index}
                          >
                            <ui.input.BoxRadio
                              labelText={item.labelText}
                              id={item.labelText}
                              name={"meet"}
                              checked={inputs.meet}
                              setInputs={setInputs}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                </ul>

                <div className="buttonContainer">
                  <div className="errorTextContainer">
                    {errorText &&
                      <layout.ConfirmText error text={errorText} />
                    }
                  </div>

                  <div className="confirmButton">
                    <ui.button.BasicButton
                      buttonText={"다음단계"}
                      onClick={onNext}
                    />
                  </div>

                  <div className="cancelButton">
                    <ui.button.BasicButton
                      buttonText={"취소"}
                      buttonType={"gray01"}
                      onClick={() => {
                        sessionStorage.removeItem("petInfo")
                        navigate("/mypage");
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </section>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default DogInfoStepAPage;
