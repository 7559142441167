import BasicInput from "./BasicInput";
import BasicSelect from "./BasicSelect";
import BasicCheckbox from "./BasicCheckbox";
import BoxRadio from "./BoxRadio";
import BoxCheckbox from "./BoxCheckbox";
import VoteRadio from "./VoteRadio";
import DateInput from "./DateInput";
import CommentInput from "./CommentInput";
import BasicRadio from "./BasicRadio";
import BasicTextarea from "./BasicTextarea";
import RoundCheckbox from "./RoundCheckbox";

export {
  BasicInput,
  BasicSelect,
  BasicCheckbox,
  BoxRadio,
  BoxCheckbox,
  VoteRadio,
  DateInput,
  CommentInput,
  BasicRadio,
  BasicTextarea,
  RoundCheckbox,
};

export default {
  BasicInput,
  BasicSelect,
  BasicCheckbox,
  BoxRadio,
  BoxCheckbox,
  VoteRadio,
  DateInput,
  CommentInput,
  BasicRadio,
  BasicTextarea,
  RoundCheckbox,
};
