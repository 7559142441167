import { api } from "service";
import { useQuery } from "react-query";
import { useModals } from "hooks"


export default function useReply({listOption}) {
  const modalOption = useModals();

  const listQuery = useQuery(["reply_list", listOption], () => {
    return api.reply.getReply(listOption);
  }, { 
    refetchOnWindowFocus: false,
    enabled: !!listOption
  });

  const createReply = async (data) => {
    let result = await api.reply.postReply(data);
    if (result.success) {
      window.location.reload();
      return result;
    }
  }

  const deleteReply = async (id) => {
  
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.title = "댓글을 삭제하시겠습니까?";
      e.text = "삭제된 댓글은 복구가 불가능합니다.";
      e.confirmButtonText = "삭제";
      e.confirmButtonClick = async () => {

        let data = { id : id }
        let result = await api.reply.deleteReply(JSON.stringify(data));
        let title = "";
        if (result.success) {
          title = "댓글이 삭제되었습니다!";
          listQuery.refetch();
        } else {
          title = "댓글 삭제에 실패하였습니다!";
        }

        modalOption.setDefaultModal((e) => {
          e.show = true;
          e.title = title;
          e.text = false;
          e.confirmButtonText = "확인";
          e.confirmButtonClick = false;
          e.cancelButtonText = false;
          return { ...e };
        });
        
      };
      e.cancelButtonText = "취소";
      e.cancelButtonClick = false;
      return { ...e };
    });

  }

  const editReply = async (id,content) => {

    let data = { 
      id : id,
      content : content
    }

    let result = await api.reply.editReply(JSON.stringify(data));
    if (result.success) {
      window.location.reload();
      return result;
    }
  }


  return {
    listOption,

    listQuery : listQuery,
    createReply : createReply,
    deleteReply : deleteReply,
    editReply : editReply, 
  }
}