import { useAuth } from "hooks";
import { useEffect } from "react";

const AuthRoute = ({ children }) => {
  let authInfo = useAuth({});


  useEffect(()=>{
    authInfo.getValidateToken();
  },[authInfo.userData])

  if (authInfo.isLoad === false) {
    if (authInfo.isLogin === true) {
      return children;
    } else {
      window.location.replace("/login");
      return <></>;
    }
  }
}

export default AuthRoute