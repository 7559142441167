import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 11px;
  border-bottom: 1px solid var(--purple01);

  &.on {
    padding-bottom: 15px;

    & .number {
      color: #fff;
      background-color: var(--main);
    }
  }

  & .number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 51px;
    height: 32px;
    font-weight: 600;
    background-color: var(--gray01);
    border-radius: 6px;
  }

  & .title {
    max-width: 313px;
    margin: 0 16px 0 11px;
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & .date {
    margin-left: auto;
    font-size: 14px;
    font-weight: 500;
    color: var(--gray03);
  }

  @media (max-width: 499px) {
    padding-bottom: calc(100vw * (11 / 500));

    &.on {
      padding-bottom: calc(100vw * (15 / 500));
    }

    & .number {
      width: calc(100vw * (51 / 500));
      height: calc(100vw * (32 / 500));
      font-size: calc(100vw * (18 / 500));
      border-radius: calc(100vw * (6 / 500));
    }

    & .title {
      max-width: calc(100vw * (313 / 500));
      margin: 0 calc(100vw * (16 / 500)) 0 calc(100vw * (11 / 500));
      font-size: calc(100vw * (20 / 500));
    }

    & .date {
      font-size: calc(100vw * (14 / 500));
    }
  }
`;

export { Container };

export default { Container };
