import { useAtom } from "jotai";
import { useQuery } from "react-query";
import { api } from "service";
import { modalAtom } from "store/atoms";

export default function useMyPage({
  scrapListOption,
  replyListOption,
}) {
  
  const scrapListQuery = useQuery(["my_scrap_list",scrapListOption], 
    ()=>{
      return api.mypage.getScrapList(scrapListOption);
    }, {
    enabled:!!scrapListOption,
    refetchOnWindowFocus: false,
  });

  const replyListQuery = useQuery(["my_reply_list",replyListOption], 
    ()=>{
      return api.mypage.getReplyList(replyListOption);
    }, {
    enabled:!!replyListOption,
    refetchOnWindowFocus: false,
  });

  return {
    scrapListQuery,
    replyListQuery,
  };
}
