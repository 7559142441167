import connector from "service/connector"
import utils from "utils";


const getList = async (filter) => {
  return await connector.authConnector("board" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}

const getKeywordList = async (filter) => {
  return await connector.authConnector("keyword/best" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}

const getSearchKeywordList = async (filter) => {
  return await connector.authConnector("keyword" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}

export {
  getList,
  getKeywordList,
  getSearchKeywordList,
};

export default {
  getList,
  getKeywordList,
  getSearchKeywordList,
};