import Styles from "./styles";
import { useState } from "react";
import { useModals } from "hooks";

// img
import ImgDefaultProfile from "resources/image/img_default_profile.svg";
import IconMore from "resources/image/icon/icon_more.svg";
import utils from "utils";

function ReviewList({ data, reviewInfo, reportCallback }) {
  const modalOption = useModals();
  const [isMoreOpen, setIsMoreOpen] = useState(false);

  return (
    <Styles.Container className="reviewContainer">
      <div className="profileContainer">
        <div className="profileImgWrap">
          <img
            src={data.profile_image ? data.profile_image : ImgDefaultProfile}
            alt="profileImg"
          />
        </div>

        <p className="userName">{data.nickname}</p>
      </div>

      <div className="moreButtonContainer">
        <button
          type="button"
          className="moreButton"
          onClick={() => {
            setIsMoreOpen(!isMoreOpen);
          }}
        >
          <img src={IconMore} alt="IconMore" />
        </button>

        {isMoreOpen && (
          <ul className="moreListWrap">
            {data.write_yn == "Y" ? (
              <>
                <li className="moreList">
                  <button
                    type="button"
                    onClick={() => {
                      setIsMoreOpen(false);
                      modalOption.setReviewModal((e) => {
                        e.show = true;
                        e.edit = true;
                        e.reviewInfo = reviewInfo;

                        return { ...e };
                      });
                    }}
                  >
                    수정
                  </button>
                </li>

                <li className="moreList">
                  <button
                    type="button"
                    onClick={() => {
                      setIsMoreOpen(false);
                      reviewInfo.deleteReview()
                    }}
                  >
                    삭제
                  </button>
                </li>
              </>
            ) : (
              <li className="moreList">
                <button
                  type="button"
                  onClick={() => {
                    setIsMoreOpen(false);
                    modalOption.setReportModal((e) => {
                      e.show = true;
                      e.type = 6;
                      e.id = data.id;
                      e.callback = reportCallback;
                      return { ...e };
                    });
                  }}
                >
                  신고
                </button>
              </li>
            )}
          </ul>
        )}
      </div>

      <div className="reviewWrap">
        <p className="review">{data.description}</p>

        {data && data.images?.length > 0 && (
          <ul className="imgListWrap">
            {data.images?.split(",")?.map((item, index) => {
              return (
                <li className="imgList" key={"imgList" + index}>
                  <img src={item} alt={item} />
                </li>
              );
            })}
          </ul>
        )}

        <div className="bottomContainer">
          <p className="date">{utils.date.getDateType(data?.created_at)}</p>
          <p className="temperature">온도 {data.score}</p>
        </div>
      </div>
    </Styles.Container>
  );
}

export default ReviewList;
