import styled from "styled-components";

// img
import IconEssential from "resources/image/icon/icon_essential.svg";

const Container = styled.div`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 110px;

  & section {
    padding-top: 30px;

    & .listWrap {
      margin-bottom: 60px;

      & .list {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        & .label {
          position: relative;
          width: fit-content;
          margin-bottom: 10px;
          font-size: 20px;
          font-weight: 500;

          & span {
            font-size: 14px;
            font-weight: normal;
          }

          &.essential::after {
            position: absolute;
            top: 50%;
            right: -12px;
            transform: translateY(-50%);
            width: 8px;
            height: 8px;
            background: url(${IconEssential}) no-repeat center / cover;
            content: "";
          }
        }

        & .inputContainer {
          align-items: center;
        }

        & .personalityListWrap,
        & .placeListWrap,
        & .concernsListWrap {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }
      }
    }

    & .buttonContainer {
      position: relative;

      & .errorTextContainer {
        position: absolute;
        top: -29px;
        left: 50%;
        transform: translateX(-50%);
      }

      & .confirmButton {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (110 / 500));

    & section {
      padding-top: calc(100vw * (30 / 500));

      & .listWrap {
        margin-bottom: calc(100vw * (60 / 500));

        & .list {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (20 / 500));
          }

          & .label {
            margin-bottom: calc(100vw * (10 / 500));
            font-size: calc(100vw * (20 / 500));

            & span {
              font-size: calc(100vw * (14 / 500));
            }

            &.essential::after {
              right: calc(100vw * (-12 / 500));
              width: calc(100vw * (8 / 500));
              height: calc(100vw * (8 / 500));
            }
          }

          & .personalityListWrap,
          & .placeListWrap,
          & .concernsListWrap {
            gap: calc(100vw * (10 / 500));
          }
        }
      }

      & .buttonContainer {
        & .errorTextContainer {
          top: calc(100vw * (-29 / 500));
        }

        & .confirmButton {
          margin-bottom: calc(100vw * (20 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
