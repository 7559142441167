import styled from "styled-components";

// img
import IconEssential from "resources/image/icon/icon_essential.svg";

const Container = styled.div`
  & label {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;

    &.essential span {
      position: relative;

      &::after {
        position: absolute;
        top: 50%;
        right: -12px;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        background: url(${IconEssential}) no-repeat center / cover;
        content: "";
      }
    }
  }

  & .textareaContainer {
    padding: 16px;
    background-color: var(--gray01);

    & textarea {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 499px) {
    & label {
      margin-bottom: calc(100vw * (10 / 500));
      font-size: calc(100vw * (20 / 500));

      &.essential span {
        &::after {
          right: calc(100vw * (-12 / 500));
          width: calc(100vw * (8 / 500));
          height: calc(100vw * (8 / 500));
        }
      }
    }

    & .textareaContainer {
      padding: calc(100vw * (16 / 500));
    }
  }
`;

export { Container };

export default { Container };
