import styled from "styled-components";

const Container = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid var(--purple01);

  & .comment {
    margin-bottom: 6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & .date {
    font-size: 16px;
    color: var(--gray03);
  }

  @media (max-width: 499px) {
    padding-bottom: calc(100vw * (15 / 500));

    & .comment {
      margin-bottom: calc(100vw * (6 / 500));
    }

    & .date {
      font-size: calc(100vw * (16 / 500));
    }
  }
`;

export { Container };

export default { Container };
