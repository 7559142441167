import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 113px;

  & .pageName {
    padding: 30px 0 20px;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
  }

  & section {
    padding-top: 30px;

    & .topContainer {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid var(--purple01);

      & .titleContainer {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        & .important {
          flex-shrink: 0;
          margin-right: 13px;
          padding: 8px 10px;
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          line-height: 0.88;
          background-color: var(--main);
          border-radius: 6px;
        }

        & .title {
          font-size: 26px;
          font-weight: 600;
        }
      }

      & .date {
        font-size: 14px;
        font-weight: 500;
        color: var(--gray03);
      }
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (113 / 500));

    & .pageName {
      padding: calc(100vw * (30 / 500)) 0 calc(100vw * (20 / 500));
      font-size: calc(100vw * (26 / 500));
    }

    & section {
      padding-top: calc(100vw * (30 / 500));

      & .topContainer {
        margin-bottom: calc(100vw * (30 / 500));
        padding-bottom: calc(100vw * (30 / 500));

        & .titleContainer {
          margin-bottom: calc(100vw * (10 / 500));

          & .important {
            margin-right: calc(100vw * (13 / 500));
            padding: calc(100vw * (8 / 500)) calc(100vw * (10 / 500));
            font-size: calc(100vw * (18 / 500));
            border-radius: calc(100vw * (6 / 500));
          }

          & .title {
            font-size: calc(100vw * (26 / 500));
          }
        }

        & .date {
          font-size: calc(100vw * (14 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
