import { Head } from "components/common";
import { CustomRoute } from "routes";
import { ModalGeneratePage } from "pages";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "overlayscrollbars/overlayscrollbars.css";

export default function App() {
  return (
    <BrowserRouter>
      <Head />
      <Routes>
        <Route path="*" element={<CustomRoute></CustomRoute>} />
      </Routes>
      <ModalGeneratePage />
    </BrowserRouter>
  );
}