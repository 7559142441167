import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import { common, ui, layout } from "components";
import { useEffect, useState } from "react";

function DogInfoStepBPage() {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    weight: "",
    body: null
  });
  const [concerns, setConcerns] = useState([]);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    let obj = {};
    featuresList.map((value) => [value.list.map((v) => { if (obj[v.title]) { return v.checked = true } })])
    setInputs(e => { return { ...e, ...JSON.parse(sessionStorage.getItem("petInfo")) } });
    JSON.parse(sessionStorage.getItem("petInfo"))?.concern &&
      setConcerns(`${JSON.parse(sessionStorage.getItem("petInfo")).concern}`.split(/,(?!\s)/));    
    `${JSON.parse(sessionStorage.getItem("petInfo"))?.extra_body}`.split(",").map((v) => { obj[v] = true });
    JSON.parse(sessionStorage.getItem("petInfo"))?.extra_body && (
      setFeaturesList(
        featuresList?.map(item => { return { name: item.name, list: item.list.map(_item => {
          return JSON.parse(sessionStorage.getItem("petInfo")).extra_body.indexOf(_item.title) === -1 ? _item : { ..._item, checked: true }
        })}})
      )
    )
  },[sessionStorage.getItem("petInfo")])

  const handleSingleCheck = (checked, id) => {
    if (checked) {
      concerns.length < 3 && setConcerns(concerns.concat(id))
    } else {
      setConcerns(concerns.filter((el) => el !== id));
    }
  };


  const bodyTypeList = [
    {
      id: "bodyType1",
      labelText: "마른"
    },
    {
      id: "bodyType2",
      labelText: "날씬"
    },
    {
      id: "bodyType3",
      labelText: "보통"
    },
    {
      id: "bodyType4",
      labelText: "통통"
    },
    {
      id: "bodyType5",
      labelText: "뚱뚱"
    }
  ];
  const [featuresList, setFeaturesList] = useState([
    {
      name: "leg",
      list: [
        {
          id: "features1",
          title: "다리 길어요",
        },
        {
          id: "features2",
          title: "다리 짧아요",
        }
      ]
    },
    {
      name: "fat",
      list: [
        {
          id: "features3",
          title: "살 많아요",
        },
        {
          id: "features4",
          title: "근육 많아요",
        }
      ]
    },
    {
      name: "neck",
      list: [
        {
          id: "features5",
          title: "목 굵어요",
        },
        {
          id: "features6",
          title: "목 길어요",
        }
      ]
    },
    {
      name: "waist",
      list: [
        {
          id: "features7",
          title: "몸통 두꺼워요",
        },
        {
          id: "features8",
          title: "허리 길어요",
        }
      ]
    }
  ]);
  const concernsList = [
    { text: "눈물" },
    { text: "비만" },
    { text: "입냄새" },
    { text: "피부" },
    { text: "관절" },
    { text: "노화" },
    { text: "발사탕" },
    { text: "사회성" },
    { text: "알러지" },
    { text: "편식" },
    { text: "식탐" },
    { text: "분리불안" },
    { text: "짖음" },
    { text: "개춘기" },
    { text: "멀미" }
  ];

  const onNext = () => {
    if (inputs.weight === "") {
      setErrorText("무게를 입력하세요.");
    } else {
      sessionStorage.setItem("petInfo", JSON.stringify({
        ...JSON.parse(sessionStorage.getItem("petInfo")),
        ...inputs,
        concern: concerns.filter((v)=>{return v !== ""}),
        extra_body: featuresList.map(value => { return value.list.filter(v => v.checked)[0] }).filter(v => v).map(v => v.title) || ''
      }));
      navigate("/mypage/dog/stepC");
    }
  }

  return (
    sessionStorage.getItem("petInfo") &&
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.SubHeader pageName={`개린이 ${JSON.parse(sessionStorage.getItem("petInfo")).id ? "수정" : "등록"}`} />

        <Styles.Container>
          <section>
            <div className="inner">
              <form>
                <ul className="listWrap">
                  <li className="list weight">
                    <ui.input.BasicInput
                      labelText={"무게"}
                      essential
                      placeholder={"0"}
                      name={"weight"}
                      value={inputs.weight}
                      setInputs={setInputs}
                    >
                      kg
                    </ui.input.BasicInput>
                  </li>

                  <li className="list">
                    <p className="label">체형</p>

                    <ul className="bodyTypeListWrap">
                      {bodyTypeList?.map((item, index) => {
                        return (
                          <li
                            className="bodyTypeList"
                            key={"bodyTypeList" + index}
                          >
                            <ui.input.BoxRadio
                              labelText={item.labelText}
                              id={item.labelText}
                              name={"body"}
                              checked={inputs.body}
                              setInputs={setInputs}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </li>

                  <li className="list">
                    <p className="label">
                      신체특징 <span>(최대 2개)</span>
                    </p>

                    {featuresList?.map((item, index) => {
                      return (
                        <ul
                          className="featuresListWrap"
                          key={"featuresListWrap" + index}
                        >
                          {item.list?.map((current, idx) => {
                            return (
                              <li
                                className="featuresList"
                                key={"featuresList" + idx}
                              >
                                <ui.input.BoxCheckbox
                                  item={current}
                                  callback={()=>{
                                    let list = featuresList.filter(v=>v.list.filter(_v => _v.checked).length > 0);
                                    let currentList = item.list?.filter(v => v.checked);

                                    if(list.length == 2 && currentList.length == 0){
                                      return;
                                    }

                                    item.list[idx == 0 ? 1 : 0].checked = false;
                                    current.checked = !current.checked;

                                    setFeaturesList([...featuresList])
                                  }}
                                />
                              </li>
                            );
                          })}
                        </ul>
                      );
                    })}
                  </li>

                  <li className="list">
                    <p className="label">
                      고민사항 <span>(최대 3개)</span>
                    </p>

                    <ul className="concernsListWrap">
                      {concernsList?.map((item, index) => {
                        return (
                          <li
                            className="concernsList"
                            key={"concernsList" + index}
                          >
                            <ui.input.RoundCheckbox
                              id={item.text}
                              labelText={item.text}
                              checked={
                                concerns.includes(item.text) ? true : false
                              }
                              onChange={(e) => {
                                handleSingleCheck(
                                  e.target.checked,
                                  e.target.id
                                );
                              }}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                </ul>

                <div className="buttonContainer">
                  <div className="errorTextContainer">
                    {errorText &&
                      <layout.ConfirmText error text={errorText} />
                    }
                  </div>

                  <div className="confirmButton">
                    <ui.button.BasicButton
                      buttonText={"다음단계"}
                      onClick={onNext}
                    />
                  </div>

                  <div className="cancelButton">
                    <ui.button.BasicButton
                      buttonText={"취소"}
                      buttonType={"gray01"}
                      onClick={() => {
                        sessionStorage.removeItem("petInfo")
                        navigate("/mypage");
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </section>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default DogInfoStepBPage;
