import styled from "styled-components";

import IconCheckOff from "resources/image/icon/icon_plus03.svg";
import IconCheckOn from "resources/image/icon/icon_check.svg";

const Input = styled.input`
  display: none;

  &:checked + label {
    border-color: var(--main);

    & .checkbox {
      background-image: url(${IconCheckOn});
    }
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 45px;
  padding: 0 19px;
  font-weight: 500;
  border: 2px solid var(--gray04);
  border-radius: 500px;
  cursor: pointer;

  & .checkbox {
    display: inline-block;
    width: 17px;
    height: 17px;
    background: url(${IconCheckOff}) no-repeat center / cover;
  }

  @media (max-width: 499px) {
    gap: calc(100vw * (6 / 500));
    height: calc(100vw * (45 / 500));
    padding: 0 calc(100vw * (19 / 500));
    border: calc(100vw * (2 / 500)) solid var(--gray04);
    border-radius: calc(100vw * (500 / 500));

    & .checkbox {
      width: calc(100vw * (17 / 500));
      height: calc(100vw * (17 / 500));
    }
  }
`;

export { Input, Label };

export default { Input, Label };
