import Styles from "./styles";
import { useAuth } from "hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// img
import IconWrite from "resources/image/icon/icon_write.svg";
import IconClose from "resources/image/icon/icon_close02.svg";
import IconContact from "resources/image/icon/icon_contact.svg";
import IconAccident from "resources/image/icon/icon_accident.svg";
import IconSecret from "resources/image/icon/icon_secret.svg";
import IconBoard from "resources/image/icon/icon_board02.svg";

function QuickMenu() {
  const [buttonModalOpen, setButtonModalOpen] = useState(false);
  const navigate = useNavigate();
  const authInfo = useAuth({});

  // map
  const list = [
    {
      class: "board",
      url: "board",
      icon: IconBoard,
      text: "자유게시판",
    },
    {
      class: "secret",
      url: "secret",
      icon: IconSecret,
      text: "비밀의 개나무숲",
    },
    {
      class: "accident",
      url: "accident",
      icon: IconAccident,
      text: "사건사고",
    },
    {
      class: "contact",
      url: "contact",
      icon: IconContact,
      text: "Contact us",
    },
  ];

  return (
    <Styles.Container>
      <ul className={"buttonListWrap" + (buttonModalOpen ? " on" : "")}>
        {buttonModalOpen &&
          list?.map((item, index) => {
            return (
              <li className="buttonList" key={"buttonList" + index}>
                <button type="button" onClick={() => {
                  if (item.text === "Contact us") {
                    navigate("/writing", { state: { type: item.text, state: true } });
                  } else {
                    setButtonModalOpen(false);
                    if (authInfo.certification()) {
                      navigate("/writing", { state: { type: item.text, state: true } });
                    }
                  }
                }}>
                  <img src={item.icon} alt={item.icon} />
                  <p className="buttonText">{item.text}</p>
                </button>
              </li>
            );
          })
        }

        <li className="buttonList">
          <button
            className="writingButton"
            type="button"
            onClick={() => {
              setButtonModalOpen(!buttonModalOpen);
            }}
          >
            <img src={buttonModalOpen ? IconClose : IconWrite} alt="IconPen" />
          </button>
        </li>
      </ul>

      {buttonModalOpen && <div className="overlay"></div>}
    </Styles.Container>
  );
}

export default QuickMenu;
