import Styles from "./styles";
import { api } from "service";
import { layout, ui } from "components";
import { useLocation } from "react-router-dom";
import { useAuth, useInquiry } from "hooks";
import { useEffect, useState } from "react";

function ContactWritingForm() {
  const authInfo = useAuth({});
  const inquiryInfo = useInquiry();
  const location = useLocation();

  const [select, setSelect] = useState({ title: "" });
  const [inputs, setInputs] = useState({
    title: "",
    email: "",
    content: "",
  });
  const [check, setCheck] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    if (location?.state?.select && location?.state?.title) {
      let data = { ...location.state.select };
      if (location?.state?.select.title == "정보수정요청") {
        data.id = 6;
      }
      setSelect(data);
      setInputs((v) => {
        return { ...v, title: location.state?.title };
      });
    }
    setInputs((v) => {
      return { ...v, email: authInfo.userData.email };
    });
  }, []);

  const validate = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (select.title === "") {
      setErrorText("문의 분류를 선택해주세요.");
      return false;
    }
    if (inputs.title === "") {
      setErrorText("제목을 입력해주세요.");
      return false;
    }
    if (emailRegex.test(inputs.email) === false) {
      setErrorText("이메일을 입력해주세요.");
      return false;
    }
    if (inputs.content === "") {
      setErrorText("내용을 작성해주세요.");
      return false;
    }
    if (!check) {
      setErrorText("개인정보 수집 및 이용에 동의해주세요.");
      return false;
    }
    return true;
  };

  const onNext = async () => {
    if (validate()) {
      await inquiryInfo.postInquiry({ ...inputs, type: select.id });
      setSelect({ title: "" });
      setInputs({
        title: "",
        email: "",
        content: "",
      });
      setCheck(false);
    }
  };

  return (
    <Styles.Container>
      <ul className="listWrap">
        <li className="list">
          <p className="label">
            <span>문의 분류</span>
          </p>

          <div className="selectContainer">
            <ui.input.BasicSelect
              optionList={api.dummy.contactList}
              select={select.title}
              setSelect={setSelect}
              size={"small"}
              placeholder={"분류"}
            />
          </div>
        </li>

        <li className="list">
          <ui.input.BasicInput
            labelText={"제목"}
            essential
            placeholder={"제목을 입력하세요."}
            name={"title"}
            value={inputs.title}
            setInputs={setInputs}
          />
        </li>

        <li className="list">
          <ui.input.BasicInput
            labelText={"e-mail"}
            essential
            placeholder={"e-mail을 입력하세요."}
            name={"email"}
            value={inputs.email}
            setInputs={setInputs}
          />
        </li>

        <li className="list">
          <label className="label">
            <span>내용</span>
          </label>

          <div className="textareaContainer">
            <textarea
              placeholder="문의 내용을 입력하세요."
              onChange={(e) => {
                setInputs((v) => {
                  return { ...v, content: e.target.value };
                });
              }}
            ></textarea>
          </div>

          <p className="guideText">
            * 제공하신 이메일은 문의에 대한 답변 및 응대 품질 향상을 위해 <br />
            펫트워크에서 3개월 동안 보관합니다.
          </p>
        </li>

        <li className="list check">
          <ui.input.BasicCheckbox
            id={"check"}
            labelText={"개인정보 수집 및 이용에 동의합니다.(필수)"}
            checked={check}
            onChange={(e) => {
              setCheck(e.target.checked);
            }}
          />
        </li>
      </ul>

      <div className="buttonContainer">
        <div className="errorText">
          {errorText && <layout.ConfirmText error text={errorText} />}
        </div>

        <ui.button.BasicButton buttonText={"문의하기"} onClick={onNext} />

        <ui.button.BasicButton
          buttonText={"취소"}
          buttonType={"white"}
          onClick={inquiryInfo.cancelInquiry}
        />
      </div>
    </Styles.Container>
  );
}

export default ContactWritingForm;
