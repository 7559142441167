import Styles from "./styles";
import { highlighting } from "utils/etc";

// img
import ImgDefaultProfile from "resources/image/img_default_profile.svg";
import IconLikeOff from "resources/image/icon/icon_like_off.svg";
import IconLikeOn from "resources/image/icon/icon_like_on.svg";
import IconLocate from "resources/image/icon/icon_locate.svg";
import IconEye from "resources/image/icon/icon_eye.svg";
import IconList from "resources/image/icon/icon_list.svg";
import utils from "utils";

function CommunityList({ data, keyword, index, scrapInfo }) {
  return (
    <Styles.Container>
      <div className="titleContainer">
        <p className="title">
          {data.delete_yn === "Y"
            ? "삭제된 게시글입니다."
            : data.report_yn === "Y"
            ? "신고된 게시글입니다."
            : highlighting(data?.title, keyword)}
        </p>

        {data.report_yn !== "Y" && data.delete_yn !== "Y" && (
          <button
            type="button"
            className="likeButton"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              scrapInfo.toggleScrap(data.id, index, 4);
            }}
          >
            <img
              src={data.scrap_yn === "Y" ? IconLikeOn : IconLikeOff}
              alt="IconLike"
            />
          </button>
        )}
      </div>

      <div className="detailContainer">
        <div className="profileContainer">
          <div className="profileImg">
            <img
              src={
                data?.profile_image ? data?.profile_image : ImgDefaultProfile
              }
              alt="profileImg"
            />
          </div>
          <p className="userName">{data?.nickname}</p>
        </div>

        <ul className="reactionListWrap">
          <li className="reactList">
            <div className="iconWrap">
              <img src={IconLocate} alt="IconLocate" />
            </div>
            {data?.location ? data?.location : "지역 무관"}
          </li>

          <li className="reactList">
            <div className="iconWrap">
              <img src={IconEye} alt="IconEye" />
            </div>
            {data?.view}
          </li>

          <li className="reactList">
            <div className="iconWrap">
              <img src={IconList} alt="IconList" />
            </div>
            {data.reply_count}
          </li>
        </ul>

        <p className="date">{utils.date.getDateType(data?.created_at)}</p>
      </div>
    </Styles.Container>
  );
}

export default CommunityList;
