import Styles from "./styles";
import { useAuth } from "hooks";
import { Link, NavLink, useLocation } from "react-router-dom";

// img
import IconHome from "resources/image/icon/icon_home.svg";
import IconWiki from "resources/image/icon/icon_wiki.svg";
import IconBoard from "resources/image/icon/icon_board.svg";
import IconSearch from "resources/image/icon/icon_search01.svg";
import IconLogin from "resources/image/icon/icon_login.svg";
import IconUser from "resources/image/icon/icon_user.svg";

function NavigationBar() {
  const location = useLocation();
  const authInfo = useAuth({});

  const list = [
    {
      url: "/",
      icon: IconHome,
      text: "Home",
    },
    {
      url: "/wiki",
      icon: IconWiki,
      text: "댕댕위키",
    },
    {
      url: "/community",
      icon: IconBoard,
      text: "수발러 게시판",
    },
    {
      url: "/search",
      icon: IconSearch,
      text: "검색",
    },
    {
      url: authInfo.isLogin ? "/mypage" : "/login",
      icon: authInfo.isLogin ? IconUser : IconLogin,
      text: authInfo.isLogin ? "마이페이지" : "로그인",
    },
  ];

  return (
    <Styles.Container>
      {list.map((item, index) => {
        return (
          <li className="navList" key={"navList" + index}>
            {item.url === "/search" || item.url === "/wiki" ? (
              <NavLink to={item.url}>
                <div className="iconWrap">
                  <img src={item.icon} alt={item.icon} />
                </div>

                <p className="navText">{item.text}</p>
              </NavLink>
            ) : (
              <Link
                to={item.url}
                className={location.pathname === item.url ? "active" : ""}
              >
                <div className="iconWrap">
                  <img src={item.icon} alt={item.icon} />
                </div>

                <p className="navText">{item.text}</p>
              </Link>
            )}
          </li>
        );
      })}
    </Styles.Container>
  );
}

export default NavigationBar;
