import Styles from "./styles";
import { common, ui } from "components";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// img
import ImgSignupComeplete from "resources/image/img_signup_complete.png";

function SignupCompletePage() {
  const navigate = useNavigate();

  useEffect(()=>{
    if(!sessionStorage.getItem("userInfo")){
      window.location.replace("/");
    }
  },[])

  return (
    sessionStorage.getItem("userInfo") &&
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.SubHeader pageName={"회원가입"} />

        <Styles.Container>
          <div className="inner">
            <h2 className="title">펫트워크 공식 수발러가 되었습니다!</h2>

            <p className="text">
              우리 댕댕이 정보와 사진을 등록하시고 <br />내 프로필 사진과 댕댕이
              프로필 카드를 완성하세요!
            </p>

            <div className="exampleContainer">
              <img src={ImgSignupComeplete} alt="ImgSignupComeplete" />
            </div>

            <ul className="buttonListWrap">
              <li className="buttonList">
                <ui.button.BasicButton
                  buttonText={"개린이 프로필 등록"}
                  onClick={() => {
                    sessionStorage.removeItem("petInfo")
                    sessionStorage.removeItem("userInfo")
                    navigate("/mypage/dog/stepA", { state: true });
                  }}
                />
              </li>
              <li className="buttonList">
                <ui.button.BasicButton
                  buttonText={"메인으로 돌아가기"}
                  buttonType={"gray01"}
                  onClick={() => {
                    sessionStorage.removeItem("userInfo")
                    navigate("/");
                  }}
                />
              </li>
            </ul>
          </div>
        </Styles.Container>
      </div>
    </>
  );
}

export default SignupCompletePage;
