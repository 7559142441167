import { api } from "service";
import { useQuery } from "react-query";

//img

export default function useAlarm({listOption, isCount, }) {

  const listQuery = useQuery(["alarm_list", listOption], () => {
    return api.alarm.getList(listOption);
  }, { 
    refetchOnWindowFocus: false,
    enabled: !!listOption
  });

  const countQuery = useQuery(["alarm_count", isCount], () => {
    return api.alarm.getTotalCount();
  }, { 
    refetchOnWindowFocus: false,
    enabled: !!isCount
  });

  return {
    listQuery,
    countQuery,
  }
}