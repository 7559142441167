import Styles from "./styles";

// img
import IconDelete from "resources/image/icon/icon_delete.svg";

function Keyword({ text, edit, onClick }) {
  return (
    <Styles.Container onClick={onClick}>
      {text}{" "}
      {edit && (
        <button type="button" className="deleteButton">
          <img src={IconDelete} alt="IconDelete" />
        </button>
      )}
    </Styles.Container>
  );
}

export default Keyword;
