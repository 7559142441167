import { Helmet } from 'react-helmet-async';

function Head() {
  return (
    <Helmet>
      <meta charset="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="댕댕이와 함께하는 바쁜 일상에 힘이 되는 다양한 정보를 제공합니다."
      />
      {/* 설명 */}
      <meta
        property="description"
        content="댕댕이와 함께하는 바쁜 일상에 힘이 되는 다양한 정보를 제공합니다."
      />
      <meta
        name="keywords"
        content="반려견,반려견어플,애견동반,강아지키우기,강아지정보,유기견입양,강아지입양,강아지커뮤니티"
      />
      {/* og meta tag */}
      <meta
        property="og:title"
        content="펫트워크, 반려견을 돌보는 실전정보 네트워크"
      />
      {/* <meta property="og:image" content="%PUBLIC_URL%/img_meta.png" /> */}
      <meta
        property="og:description"
        content="댕댕이와 함께하는 바쁜 일상에 힘이 되는 다양한 정보를 제공합니다."
      />
      {/* 트위터 미리보기 설정 */}
      <meta
        name="twitter:title"
        content="펫트워크, 반려견을 돌보는 실전정보 네트워크"
      />
      <meta
        name="twitter:description"
        content="댕댕이와 함께하는 바쁜 일상에 힘이 되는 다양한 정보를 제공합니다."
      />
      {/* <meta name="twitter:image" content="%PUBLIC_URL%/img_meta_twitter.png" /> */}
      {/* favicon */}
      
      {/* <link rel="icon" href="%PUBLIC_URL%/favicon.png" />
      <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" /> */}
      <link rel="manifest" href="manifest.json" />
      <title>펫트워크, 반려견을 돌보는 실전정보 네트워크</title>
    </Helmet>
  )
}

export default Head;
