import styled from "styled-components";

const Container = styled.section`
  padding-top: 40px;

  &:not(:last-child) {
    padding-bottom: 40px;
    border-bottom: 20px solid var(--gray01);
  }

  & h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;

    & span {
      font-size: 22px;
      color: var(--main);
    }
  }

  & .listWrap {
    margin-bottom: 40px;
    cursor: pointer;
  }

  & .emptyContainer {
    margin-top: -5px;
    padding-bottom: 2px;
  }

  & .wikiList {
    padding: 20px 0;
    border-bottom: 1px solid var(--gray04);

    &:first-child {
      border-top: 1px solid var(--gray04);
    }
  }

  & .magazineList:not(:last-child),
  .eventList:not(:last-child) {
    margin-bottom: 40px;
  }

  & .communityList:not(:last-child) {
    margin-bottom: 15px;
  }

  & .moreSearchButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    width: 166px;
    height: 43px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 500;
    background-color: var(--gray01);
    border-radius: 6px;

    & .iconWrap {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 499px) {
    padding-top: calc(100vw * (40 / 500));

    &:not(:last-child) {
      padding-bottom: calc(100vw * (40 / 500));
      border-bottom: calc(100vw * (20 / 500)) solid var(--gray01);
    }

    & h2 {
      margin-bottom: calc(100vw * (20 / 500));
      font-size: calc(100vw * (24 / 500));

      & span {
        font-size: calc(100vw * (22 / 500));
      }
    }

    & .listWrap {
      margin-bottom: calc(100vw * (40 / 500));
    }

    & .emptyContainer {
      margin-top: calc(100vw * (-5 / 500));
      padding-bottom: calc(100vw * (2 / 500));
    }

    & .wikiList {
      padding: calc(100vw * (20 / 500)) 0;
    }

    & .magazineList:not(:last-child),
    .eventList:not(:last-child) {
      margin-bottom: calc(100vw * (40 / 500));
    }

    & .communityList:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 500));
    }

    & .moreSearchButton {
      gap: calc(100vw * (6 / 500));
      width: calc(100vw * (166 / 500));
      height: calc(100vw * (43 / 500));
      font-size: calc(100vw * (16 / 500));
      border-radius: calc(100vw * (6 / 500));

      & .iconWrap {
        width: calc(100vw * (20 / 500));
        height: calc(100vw * (20 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
