import Styles from "./styles";
import { Link } from "react-router-dom";
import { ui, layout } from "components";
import { useSupport } from "hooks";

function Notice() {
  const supportInfo = useSupport({ options: { noticeList: true } });
  const yLength = supportInfo.getNoticeHoldList?.list?.filter(v => v.hold_yn == "Y").length;

  return (
    <Styles.Container>
      {supportInfo.getNoticeList?.list?.length > 0 ? (
        <>
          <ul className="listWrap">
            {supportInfo.getNoticeList?.list
              .map((item, index) => {
                return (
                  <li className="list" key={"noticeList" + index}>
                    <Link to={"/notice/detail?id=" + item.id}>
                      <layout.NoticeList data={item} index={(((supportInfo.getNoticeList?.pagination?.page - 1) * 10) + index) - yLength}/>
                    </Link>
                  </li>
                );
              })
            }
          </ul>
          <div className="paginationContainer">
            <ui.button.Pagination
              page={supportInfo.getNoticeList?.pagination?.page}
              maxPage={supportInfo.getNoticeList?.pagination?.totalPageCount}
              list={supportInfo.getNoticeList?.pagination?.page_list}
            />
          </div>
        </>
      ) : (
        <div className="emptyContainer">
          <layout.EmptyContainer text={"등록된 게시물이 없습니다."} />
        </div>
      )}
    </Styles.Container>
  );
}

export default Notice;
