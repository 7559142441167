import Styles from "./styles";

// img
import IconError from "resources/image/icon/icon_error.svg";
import IconConfirm from "resources/image/icon/icon_confirm.svg";

function ConfirmText({ error, text }) {
  return (
    <Styles.Container className={error ? " error" : ""}>
      <span className="iconWrap">
        <img
          src={error ? IconError : IconConfirm}
          alt={error ? IconError : IconConfirm}
        />
      </span>
      {text}
    </Styles.Container>
  );
}

export default ConfirmText;
