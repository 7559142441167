import Styles from "./styles";
import components from "./components";
import { common, ui, layout } from "components";
import { useEffect, useState } from "react";
import { useCategory, useWiki } from "hooks";
import { Link, useLocation, useNavigate } from "react-router-dom";

// img
import IconCategory from "resources/image/icon/icon_category.svg";
import IconSearch from "resources/image/icon/icon_search_color.svg";
import IconCat from "resources/image/icon/icon_cat.svg";
import utils from "utils";

function WikiPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [filter, setFilter] = useState(null);
  const params = utils.etc.getSearchParam();
  const categoryInfo = useCategory({
    listOption: params.parent_info_id == 20 ? null : {
      type : 2,
      category_info_id : params.parent_info_id,
    }
  });
  const categoryMainInfo = useCategory({
    listOption:{
      type : 2,
      parent_info_id : 0,
    }
  })
  const wikiInfo = useWiki({ listOption: filter });
  const category = categoryInfo.list[0] ? categoryInfo.list[0] : {};
  const subCategory = category.sub_list?.filter(v=>v.id == utils.etc.getSearchParam().category_info_id)[0];
  const wikiData = wikiInfo.listQuery?.data ? wikiInfo.listQuery?.data : {};
  const filterList = [{ title : "최신순", value : 1 },{ title : "조회순", value : 2 },{ title : "가나다순", value : 3 }];

  useEffect(()=>{
    let param = utils.etc.getSearchParam();
    let f = { 
      page : param.page ? param.page : 1,
      recordSize : param.recordSize ? param.recordSize : 10,
      pageSize:5,
      order : param.order ? param.order : 1,
      type : 2,
    };
    if(param.category_info_id){
      f.category_info_id = param.category_info_id;
    }
    if(param.cat_yn){
      f.cat_yn = param.cat_yn;
    }
    setFilter({...f});
  },[location])

  const title = categoryMainInfo.list[0]?.title
  const subTitle = categoryMainInfo.list[0]?.subtitle

  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.Header />

        <Styles.Container>
          <section className="categorySection">
            <div className="buttonContainer">
              <Link
                to={"/wiki/category"}
                state={category.title}
                className="categoryButton"
              >
                <div className="iconWrap">
                  <img src={IconCategory} alt="IconCategory" />
                </div>
                전체 카테고리
              </Link>

              <Link to={"/search"} className="searchButton">
                무엇을 찾고 있나요?
                <div className="iconWrap">
                  <img src={IconSearch} alt="IconSearch" />
                </div>
              </Link>
            </div>

            <div className="bannerContainer">
              <div className="bannerTitle">
                {<>{category.title ? category.title : title} {subCategory ? ">> " : ""}{subCategory?.title}</>}
              </div>
              <p className="desciption">{subCategory?.subtitle ? subCategory.subtitle : category.subtitle ? category.subtitle : subTitle}</p>
            </div>

            {categoryInfo.list[0] && (
              <components.CategoryContainer
                data={[categoryInfo.list[0]].concat(categoryInfo.list[0] ? categoryInfo.list[0].sub_list : [])}
              />
            )}
          </section>

          <section className="listSection">
            <div className="inner">
              <div className="filterContainer">
                <div className="checkContainer">
                  <ui.input.BasicCheckbox
                    id={"catCheck"}
                    labelText={"고양이 태그만 보기"}
                    checked={filter?.cat_yn == "Y"}
                    onChange={(e) => {
                      navigate(utils.etc.getQueryParamUrl({ cat_yn : filter?.cat_yn == 'Y' ? null : 'Y', page:1, },false));
                    }}
                  />
                </div>

                <div className="selectContainer">
                  <ui.input.BasicSelect
                    optionList={filterList}
                    select={filterList[filter?.order > 0 ? filter?.order - 1 : 0].title}
                    setSelect={(e)=>{
                      navigate(utils.etc.getQueryParamUrl({ order:e.value, page:1, },false));
                    }}
                    size={"small"}
                  />
                </div>
              </div>

              {wikiInfo.listQuery.isFetching || wikiData?.list?.length > 0 ? (
                <>
                  <ul className="listWrap">
                    {wikiData?.list?.map((item, index) => {
                        return (
                          <li className="list" key={"list" + index}>
                            <Link to={`/wiki/detail/${item.id}`}>
                              <layout.WikiList data={item} />
                            </Link>
                          </li>
                        );
                    })}
                  </ul>

                  <div className="guideText">
                    <div className="iconWrap">
                      <img src={IconCat} alt="IconCat" />
                    </div>
                    고양이 집사들도 구경해 볼 곳이예요!
                  </div>

                  <div className="paginationContainer">
                    <ui.button.Pagination
                      page={wikiData.pagination?.page}
                      list={wikiData.pagination?.page_list}
                      maxPage={wikiData.pagination?.totalPageCount}
                    />
                  </div>
                </>
              ) : (
                <div className="emptyContainer">
                  <layout.EmptyContainer text={"등록된 게시물이 없습니다."} />
                </div>
              )}
            </div>
          </section>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default WikiPage;
