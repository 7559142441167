import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 70px);
  min-height: calc(100dvh - 70px);
  padding: 40px 0 84px;
  text-align: center;

  & .title {
    font-size: 26px;
    font-weight: bold;
    color: var(--main);
  }

  & .text {
    margin: 20px 0 60px;
    font-size: 20px;
    line-height: 1.3;
  }

  & .exampleContainer {
    width: 279px;
    height: 407px;
    margin: 0 auto 60px;
  }

  & .buttonList:not(:last-child) {
    margin-bottom: 20px;
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (70 / 500)));
    min-height: calc(100dvh - calc(100vw * (70 / 500)));
    padding: calc(100vw * (40 / 500)) 0 calc(100vw * (84 / 500));

    & .title {
      font-size: calc(100vw * (26 / 500));
    }

    & .text {
      margin: calc(100vw * (20 / 500)) 0 calc(100vw * (60 / 500));
      font-size: calc(100vw * (20 / 500));
    }

    & .exampleContainer {
      width: calc(100vw * (279 / 500));
      height: calc(100vw * (407 / 500));
      margin: 0 auto calc(100vw * (60 / 500));
    }

    & .buttonList:not(:last-child) {
      margin-bottom: calc(100vw * (20 / 500));
    }
  }
`;

export { Container };

export default { Container };
