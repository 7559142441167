import Styles from "./styles";
import { useLocation, useParams } from "react-router-dom";
import { useSubaler } from "hooks";
import { common, layout, ui } from "components";
import { useEffect } from "react";

function EditPage() {
  const { id } = useParams();
  const location = useLocation();
  const subalerInfo = useSubaler({ detailOption: id });
  const detail = subalerInfo.detailQuery.data?.data ? subalerInfo.detailQuery.data?.data : {};

  useEffect(()=>{
    subalerInfo.setUnrelatedCheck(true);
    if (detail.location !== null) {
      subalerInfo.setLocationSelect(detail?.location);
      subalerInfo.setUnrelatedCheck(false);
    }
    subalerInfo.setInputs(e => { return { ...e,
      title: detail.title,
      url: detail.url,
      speaker: detail.self_yn === "Y" ? "concerned" : "speaker"
    }});
    subalerInfo.setDescription(detail.description);
    subalerInfo.setReview(detail.review);
    subalerInfo.setKeywordList(detail.keyword_list?.map((v)=>{return v.title}));
  },[detail])

  return (
    location.state &&
    detail.my_yn === "Y" &&
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.SubHeader
          pageName={detail.category_title}
          BackEvent={() => { subalerInfo.cancelSubaler(false) }}
        />

        <Styles.Container>
          <section>
            <div className="inner">
              <form>
                <layout.CommunityWritingForm
                  subalerInfo={subalerInfo}
                  detail={detail}
                  id={id}
                >
                  <ui.button.BasicButton
                    buttonText={"수정하기"}
                    onClick={subalerInfo.updateSubaler}
                  />

                  <ui.button.BasicButton
                    buttonText={"취소"}
                    buttonType={"white"}
                    onClick={() => { subalerInfo.cancelSubaler(false) }}
                  />
                </layout.CommunityWritingForm>
              </form>
            </div>
          </section>
        </Styles.Container>
      </div>
    </>
  );
}

export default EditPage;
