import Styles from "./styles";

function BasicRadio({ id, name, labelText, checked, setInputs }) {

  const onChange = (e) => {
    const { name, id } = e.target;
    setInputs((e) => {
      return { ...e, [name]: id };
    });
  };

  return (
    <>
      <Styles.Input
        id={id}
        name={name}
        type="radio"
        checked={checked === id}
        onChange={onChange}
      />

      <Styles.Label htmlFor={id}>
        <span className="checkContainer"></span>
        <span className="labelText">{labelText}</span>
      </Styles.Label>
    </>
  );
}

export default BasicRadio;
