import connector from "service/connector";
import utils from "utils";

const getScrapList = async (data) => {
  return await connector.authConnector(`my/scrap?${utils.etc.getFilterStr(data)}`,'GET');
}

const getReplyList = async (data) => {
  return await connector.authConnector(`my/active?${utils.etc.getFilterStr(data)}`,'GET');
}

export {
  getScrapList,
  getReplyList
};


export default {
  getScrapList,
  getReplyList
}