import Styles from "./styles";
import { common } from "components";
import { useBusiness } from "hooks";
import { clickDetail } from "utils/etc";
// import { Document, Page } from "react-pdf";

// img
import IconList from "resources/image/icon/icon_list_white.svg";
import Logo from "resources/image/icon/logo_gray.svg";

function BusinessPage() {
  const businessInfo = useBusiness({ business: true });
  const list = businessInfo.getBusiness?.data?.data;
  const pdfTitle = ["비즈니스 / PRESS 문의", "수발러 협력 문의"];

  // const PDFViewer = (pdfUrl) => {
  //   console.log(pdfUrl);
  //   return (
  //     <div>
  //       <Document file={{ url: `${pdfUrl}` }}
  //         onLoadSuccess={(pdf) => console.log('Loaded a file with ' + pdf.numPages + ' pages!')}
  //       >
  //         <Page pageNumber={1} />
  //       </Document>
  //     </div>
  //   );
  // };

  // console.log(PDFViewer("https://petwork.kr:8080/api/image/file/upload/original_044fb0d1-7d46-48b9-847c-e4135df71373.pdf"));

  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.Header />

        <Styles.Container>
          <h2 className="pageName">비즈니스 제휴 · 제안</h2>

          <section>
            <div className="inner">
              <p className="description">
                펫트워크는 <b>‘견생’</b>과 <b>‘인생’</b>을 중심으로 한 <br />
                모든 것에 열려 있습니다.
                <br />
                <br />
                비즈니스 업체, 지역 지자체는 물론 <br />
                댕댕 수발러들 역시 펫트워크를 통해 가능한 <br />
                무엇이든 제안할 수 있습니다.
              </p>

              <ul className="listWrap">
                {list?.map((item, index) => {
                  return (
                    //* 퍼블 깨짐을 우려한 주석입니다!, ( 기존 코드 )
                    // <li className="list" key={"list" + index}>
                    //   <a href="" target="_blank">
                    //     <p className="fileName">{item.text}</p>

                    //     <p className="fileOpenText">파일 열기</p>

                    //     <div className="iconWrap">
                    //       <img src={IconList} alt="IconList" />
                    //     </div>
                    //   </a>
                    // </li>

                    <li
                      className="list"
                      key={"list" + index}
                      // onClick={()=>{
                      //   PDFViewer(item.file_url);
                      // item.file_url && clickDetail(item.file_url)
                      // }}
                    >
                      {/* {PDFViewer("https://petwork.kr:8080/api/image/file/upload/original_044fb0d1-7d46-48b9-847c-e4135df71373.pdf")} */}
                      {/* <BlobProvider document={MyDoc}>
                        {({ url }) => (
                          <a href={url} target="_blank">Open in new tab</a>
                        )}
                      </BlobProvider> */}

                      <a
                        href={item.file_url + "?type=READ"}
                        target="_blank"
                        key={"list" + index}
                      >
                        <p className="fileName">{pdfTitle[index]}</p>

                        <p className="fileOpenText">파일 열기</p>

                        <div className="iconWrap">
                          <img src={IconList} alt="IconList" />
                        </div>
                      </a>
                    </li>
                  );
                })}
              </ul>

              <div className="logoWrap">
                <img src={Logo} alt="Logo" />
              </div>
            </div>
          </section>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default BusinessPage;
