import { api } from "service";
import { useMutation, useQueryClient } from "react-query";

export default function useLike({ queryName, callFilter }) {
  const queryClient = useQueryClient();

  const toggleLike = async (relateInfoId, type) => {
    doLike({
      relate_info_id: relateInfoId,
      type: type
    });
  }

  const { mutate: doLike } = useMutation(
    (e) => { return api.like.likeToggle(JSON.stringify(e)) },
    {
      async onMutate(variables) {
        queryClient.setQueryData([queryName, callFilter ? callFilter : variables.relate_info_id],
          e => {
            e.data.like_yn = e.data.like_yn === "Y" ? "N" : "Y"
            e.data.like_count = parseInt(e.data.like_count);
            e.data.like_count += e.data.like_yn === "Y" ? 1 : -1;
            return { ...e };
          }
        )
      },
      onError(error, variables, context) {
        context && context();
      },
    }
  );
  
  return {
    toggleLike
  }
}