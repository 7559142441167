import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  & .sectionTitle {
    font-size: 26px;
    font-weight: bold;

    & span {
      color: var(--main);
    }
  }

  & .moreButton {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    font-weight: 500;

    & .iconWrap {
      width: 13px;
      height: 13px;
    }
  }

  @media (max-width: 499px) {
    & .sectionTitle {
      font-size: calc(100vw * (26 / 500));
    }

    & .moreButton {
      gap: calc(100vw * (6 / 500));
      font-size: calc(100vw * (14 / 500));

      & .iconWrap {
        width: calc(100vw * (13 / 500));
        height: calc(100vw * (13 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
