import styled from "styled-components";

const Container = styled.div`
  padding-top: 40px;

  & .listWrap {
    margin-bottom: 80px;

    & .list:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  & .emptyContainer {
    padding-top: 20px;
  }

  @media (max-width: 499px) {
    padding-top: calc(100vw * (40 / 500));

    & .listWrap {
      margin-bottom: calc(100vw * (80 / 500));

      & .list:not(:last-child) {
        margin-bottom: calc(100vw * (15 / 500));
      }
    }

    & .emptyContainer {
      padding-top: calc(100vw * (20 / 500));
    }
  }
`;

export { Container };

export default { Container };
