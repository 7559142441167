import { useState } from "react";

export default function useImage() {
  const [imageUrl, setImageUrl] = useState(null);

  const imagePrint = (file) => {
    if (file !== undefined) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (data) => {
        if (typeof data.target?.result === "string") {
          setImageUrl(data.target?.result);
        };
      };
    }
  };

  return {
    imagePrint,
    setImageUrl,
    imageUrl
  }
}