import connector from "service/connector"
import utils from "utils";


const getPolicy = async (path) => {
  return await connector.connectFetchController("policy?type=" + path, "GET");
}
const getFaqList = async (filter) => {
  return await connector.connectFetchController("faq" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const getNoticeList = async (filter) => {
  return await connector.connectFetchController("notice" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const getNoticeDetail = async (path) => {
  return await connector.connectFetchController("notice/detail?id=" + path, "GET");
}


export {
  getPolicy,
  getFaqList,
  getNoticeList,
  getNoticeDetail
};


export default {
  getPolicy,
  getFaqList,
  getNoticeList,
  getNoticeDetail
}