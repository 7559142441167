import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 110px;

  & .pageName {
    padding: 30px 0;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
  }

  & .searchSection .inner {
    margin-bottom: 20px;
  }

  & .highlighting {
    color: var(--main);
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (110 / 500));

    & .pageName {
      padding: calc(100vw * (30 / 500)) 0;
      font-size: calc(100vw * (26 / 500));
    }
  }
`;

export { Container };

export default { Container };
