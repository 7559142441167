import Styles from "./styles";
import { common, ui } from "components";

// img
import Logo from "resources/image/icon/logo_white.svg";

function IntroPage() {
  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <Styles.Container>
          <div className="logoWrap">
            <img src={Logo} alt="logo" />
          </div>

          <p>견생과 함께하는 인생을 위한 정보 네트워크</p>
        </Styles.Container>
      </div>
    </>
  );
}

export default IntroPage;
