import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import { common, ui, layout } from "components";
import { useEffect, useState } from "react";

function DogInfoStepCPage() {
  const navigate = useNavigate();

  const [personality, setPersonality] = useState([]);
  const [place, setPlace] = useState([]);
  const [errorText, setErrorText] = useState("");

  const handlePersonalityCheck = (checked, id) => {
    if (checked) {
      if (personality.length < 3) { setPersonality(personality.concat(id)) }
    } else {
      setPersonality(personality.filter((el) => el !== id));
    }
  };
  const handlePlaceCheck = (checked, id) => {
    if (checked) {
      if (place.length < 3) { setPlace(place.concat(id)) }
    } else {
      setPlace(place.filter((el) => el !== id));
    }
  };

  // map
  const personalityList = [
    { text: "가족만 좋아요" },
    { text: "사람만 좋아요" },
    { text: "개 친구 싫어요" },
    { text: "개 친구만 좋아요" },
    { text: "모두와 친해요" },
    { text: "나 빼고 다 싫어" },
    { text: "야외 좋아요" },
    { text: "실내 좋아요" },
    { text: "어디든 좋아요" },
    { text: "익살꾸러기" },
    { text: "관찰자" },
    { text: "순둥이" },
    { text: "깍쟁이" },
    { text: "겁이 많아요" },
    { text: "관심 좋아요" }
  ];

  const placeList = [
    { text: "펜션" },
    { text: "호텔·리조트" },
    { text: "놀이터" },
    { text: "바다와 계곡" },
    { text: "식당·카페" },
    { text: "산과 숲" },
    { text: "유치원" },
    { text: "공원" },
    { text: "쇼핑몰" },
    { text: "동네" }
  ];

  useEffect(() => {
    JSON.parse(sessionStorage.getItem("petInfo"))?.character &&
      setPersonality(`${JSON.parse(sessionStorage.getItem("petInfo")).character}`.split(/,(?!\s)/))
    JSON.parse(sessionStorage.getItem("petInfo"))?.place &&
      setPlace(`${JSON.parse(sessionStorage.getItem("petInfo")).place}`.split(/,(?!\s)/))
  },[sessionStorage.getItem("petInfo")])
  

  const onNext = () => {
    if (place.length === 0) {
      setErrorText("최애 동반장소를 선택해주세요.");
    } else {
      sessionStorage.setItem("petInfo", JSON.stringify({
        ...JSON.parse(sessionStorage.getItem("petInfo")),
        character: personality.filter((v)=>{return v !== ""}),
        place: place.filter((v)=>{return v !== ""}),
      }))
      navigate("/mypage/dog/stepD");
    }
  }

  return (
    sessionStorage.getItem("petInfo") &&
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.SubHeader pageName={`개린이 ${JSON.parse(sessionStorage.getItem("petInfo")).id ? "수정" : "등록"}`} />

        <Styles.Container>
          <section>
            <div className="inner">
              <form>
                <ul className="listWrap">
                  <li className="list">
                    <p className="label">
                      성격특징 <span>(최대 3개)</span>
                    </p>

                    <ul className="personalityListWrap">
                      {personalityList?.map((item, index) => {
                        return (
                          <li
                            className="personalityList"
                            key={"personalityList" + index}
                          >
                            <ui.input.RoundCheckbox
                              id={item.text}
                              labelText={item.text}
                              checked={
                                personality.includes(item.text) ? true : false
                              }
                              onChange={(e) => {
                                handlePersonalityCheck(
                                  e.target.checked,
                                  e.target.id
                                );
                              }}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </li>

                  <li className="list">
                    <p className="label essential">
                      최애 동반장소 <span>(최대 3개)</span>
                    </p>

                    <ul className="placeListWrap">
                      {placeList?.map((item, index) => {
                        return (
                          <li className="placeList" key={"placeList" + index}>
                            <ui.input.RoundCheckbox
                              id={item.text}
                              labelText={item.text}
                              checked={place.includes(item.text) ? true : false}
                              onChange={(e) => {
                                handlePlaceCheck(e.target.checked, e.target.id);
                              }}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                </ul>

                <div className="buttonContainer">
                  <div className="errorTextContainer">
                    {errorText &&
                      <layout.ConfirmText error text={errorText} />
                    }
                  </div>

                  <div className="confirmButton">
                    <ui.button.BasicButton
                      buttonText={"다음단계"}
                      onClick={onNext}
                    />
                  </div>

                  <div className="cancelButton">
                    <ui.button.BasicButton
                      buttonText={"취소"}
                      buttonType={"gray01"}
                      onClick={() => {
                        sessionStorage.removeItem("petInfo")
                        navigate("/mypage");
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </section>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default DogInfoStepCPage;
