import utils from "utils";
import Styles from "./styles";
import { useVote } from "hooks";
import { common, ui, layout } from "components";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function VotePage() {

  let location = useLocation();
  const navigate = useNavigate();
  const [vote, setVote] = useState(false);
  const [filter, setFilter] = useState(null);
  const voteInfo = useVote({isCurrent:true, listOption : filter });
  const voteData = (voteInfo.currentVoteQuery.data?.data || {});


  const doToggleVote = async () =>{
    await voteInfo.toggleVote(JSON.stringify({
      vote_group_info_id : voteData.id,
      vote_info_id : vote,
    }))
    navigate(`/vote/result/${voteData.id}`);
  }

  useEffect(()=>{
    let param = utils.etc.getSearchParam();
    let f = { 
      page : param.page ? param.page : 1,
      recordSize : param.recordSize ? param.recordSize : 10,
      pageSize: 5,
      order : param.order ? param.order : 1,
    };
    setFilter({...f});
  },[location])

  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.Header />

        <Styles.Container>
          <h2 className="pageName">견심전심 투표</h2>

          <section className="section01">
            <div className="inner">
              <layout.VoteContainer 
                data={voteData} 
                vote={vote} 
                setVote={setVote} 
                doToggleVote={doToggleVote}
                detail={true}
              />
            </div>
          </section>

          <section className="section02">
            <div className="inner">
              <ul className="listWrap">
                {voteInfo.listQuery.data?.list?.map((item, index) => {
                  return (
                    <li className="list" key={"endVoteList" + index}>
                      <Link to={`/vote/result/${item.id}`}>
                        <div className="left">
                          <p className="title">{item.title}</p>
                          <p className="date">
                            {utils.date.getDateType(item.started_at)} ~ {utils.date.getDateType(item.ended_at)}
                          </p>
                        </div>

                        <div className="stateBox">
                          {item.total_vote_count}명 <br /> 완료
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>

              <div className="paginationContainer">
                <ui.button.Pagination
                  page={voteInfo.listQuery.data?.pagination?.page}
                  list={voteInfo.listQuery.data?.pagination?.page_list}
                  maxPage={voteInfo.listQuery.data?.pagination?.totalPageCount}
                />
              </div>
            </div>
          </section>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default VotePage;
