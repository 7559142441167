import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 110px;

  & .pageName {
    padding: 30px 0 20px;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
  }

  & .section01 {
    padding: 10px 0 30px;
    border-bottom: 20px solid var(--gray01);
  }

  & .section02 {
    padding-top: 15px;

    & .listWrap {
      margin-bottom: 80px;

      & .list {
        border-bottom: 1px solid var(--gray04);

        & a {
          display: flex;
          align-items: center;
          gap: 14px;
          padding: 15px 0;

          & .left {
            width: calc(100% - 75px);

            & .title {
              margin-bottom: 10px;
              font-weight: bold;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            & .date {
              font-size: 14px;
              font-weight: 500;
              color: var(--gray03);
            }
          }

          & .stateBox {
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 61px;
            height: 54px;
            font-size: 14px;
            font-weight: 600;
            color: var(--gray03);
            background-color: var(--gray04);
            border-radius: 6px;
            text-align: center;
          }
        }
      }
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (110 / 500));

    & .pageName {
      padding: calc(100vw * (30 / 500)) 0 calc(100vw * (20 / 500));
      font-size: calc(100vw * (26 / 500));
    }

    & .section01 {
      padding: calc(100vw * (10 / 500)) 0 calc(100vw * (30 / 500));
      border-bottom: calc(100vw * (20 / 500)) solid var(--gray01);
    }

    & .section02 {
      padding-top: calc(100vw * (15 / 500));

      & .listWrap {
        margin-bottom: calc(100vw * (80 / 500));

        & .list {
          & a {
            gap: calc(100vw * (14 / 500));
            padding: calc(100vw * (15 / 500)) 0;

            & .left {
              width: calc(100% - calc(100vw * (75 / 500)));

              & .title {
                margin-bottom: calc(100vw * (10 / 500));
              }

              & .date {
                font-size: calc(100vw * (14 / 500));
              }
            }

            & .stateBox {
              width: calc(100vw * (61 / 500));
              height: calc(100vw * (54 / 500));
              font-size: calc(100vw * (14 / 500));
              border-radius: calc(100vw * (6 / 500));
            }
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
