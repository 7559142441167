import utils from "utils";
import Styles from "./styles";
import { useState } from "react";
import { highlighting } from "utils/etc";

// img
import exceptionImage from "resources/image/icon/logo_gray.svg";
import IconLikeOff from "resources/image/icon/icon_like_off.svg";
import IconLikeOn from "resources/image/icon/icon_like_on.svg";
import IconLocate from "resources/image/icon/icon_locate.svg";
import IconDate from "resources/image/icon/icon_date.svg";

function EventList({ main, data, keyword, index, scrapInfo }) {

  return (
    <Styles.Container className={main ? "on" : ""}>
      <div className="imgWrap">
        {data.finish_yn == "Y" && <div className="endContainer">마감</div>}
        <img src={data.thumbnail ? data.thumbnail : exceptionImage} alt="eventImg" />
      </div>

      <div className="textContainer">
        <div className="left">
          <p className="title">{highlighting(data.title, keyword)}</p>

          {!main && (
            <p className="description">{highlighting(data.subtitle, keyword)}</p>
          )}

          <div className="dateContainer">
            {!main && data.location && (
              <div className="location">
                <div className="iconWrap">
                  <img src={IconLocate} alt="IconLocate" />
                </div>
                {data.location}
              </div>
            )}

            <div className="date">
              {!main && data.started_at && (
                <div className="iconWrap">
                  <img src={IconDate} alt="IconDate" />
                </div>
              )}
              {utils.date.getDateType(data.started_at)}~{utils.date.getDateType(data.ended_at)}
            </div>
          </div>
        </div>

        {!main && (
          <button
            type="button"
            className="likeButton"
            onClick={(e) => {
              e.preventDefault();
              scrapInfo.toggleScrap(data.id, index, 1)
            }}
          >
            <img src={data.scrap_yn === "Y" ? IconLikeOn : IconLikeOff} alt="IconLike" />
          </button>
        )}
      </div>
    </Styles.Container>
  );
}

export default EventList;
