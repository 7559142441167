import ConfirmText from "./ConfirmText";
import SearchContainer from "./SearchContainer";
import EventList from "./EventList";
import AdContainer from "./AdContainer";
import NoticeList from "./NoticeList";
import CommunityList from "./CommunityList";
import Comment from "./Comment";
import MagazineList from "./MagazineList";
import EmptyContainer from "./EmptyContainer";
import VoteContainer from "./VoteContainer";
import CommunityWritingForm from "./CommunityWritingForm";
import WikiList from "./WikiList";
import GuageContainer from "./GuageContainer";

export {
  ConfirmText,
  SearchContainer,
  EventList,
  AdContainer,
  NoticeList,
  CommunityList,
  Comment,
  MagazineList,
  EmptyContainer,
  VoteContainer,
  CommunityWritingForm,
  WikiList,
  GuageContainer,
};

export default {
  ConfirmText,
  SearchContainer,
  EventList,
  AdContainer,
  NoticeList,
  CommunityList,
  Comment,
  MagazineList,
  EmptyContainer,
  VoteContainer,
  CommunityWritingForm,
  WikiList,
  GuageContainer,
};
