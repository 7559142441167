import styled from "styled-components";

const Container = styled.div`
  padding: 30px 0 35px;
  font-size: 16px;
  color: var(--gray03);
  background-color: var(--gray01);

  & .topContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .logoWrap {
      width: 204px;
      height: 36px;
    }

    & .listWrap {
      display: flex;
      gap: 24px;

      & .list {
        position: relative;
        cursor: pointer;

        &:not(:last-child)::after {
          position: absolute;
          top: 50%;
          right: -12px;
          transform: translateY(-50%);
          width: 1px;
          height: 13px;
          background-color: var(--gray03);
          content: "";
        }
      }
    }
  }

  & .warning {
    margin: 60px 0 19px;
  }

  @media (max-width: 499px) {
    padding: calc(100vw * (30 / 500)) 0 calc(100vw * (35 / 500));
    font-size: calc(100vw * (16 / 500));

    & .topContainer {
      & .logoWrap {
        width: calc(100vw * (204 / 500));
        height: calc(100vw * (36 / 500));
      }

      & .listWrap {
        gap: calc(100vw * (24 / 500));

        & .list {
          &:not(:last-child)::after {
            right: calc(100vw * (-12 / 500));
            height: calc(100vw * (13 / 500));
          }
        }
      }
    }

    & .warning {
      margin: calc(100vw * (60 / 500)) 0 calc(100vw * (19 / 500));
    }
  }
`;

export { Container };

export default { Container };
