import styled from "styled-components";

const Container = styled.div`
  margin-bottom: 300px;

  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 100px;

  & .section01 {
    padding: 30px 0;
    border-bottom: 20px solid var(--gray01);
  }

  & .commentSection {
    padding-top: 30px;
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (100 / 500));

    & .section01 {
      padding: calc(100vw * (30 / 500)) 0;
      border-bottom: calc(100vw * (20 / 500)) solid var(--gray01);
    }

    & .commentSection {
      padding-top: calc(100vw * (30 / 500));
    }
  }
`;

export { Container };

export default { Container };
