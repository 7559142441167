import connector from "service/connector";
import utils from "utils";

const getList = async (data) => {
  return await connector.authConnector(`review?${utils.etc.getFilterStr(data)}`,'GET');
}
const getDetail = async (data) => {
  return await connector.authConnector(`review/info?${utils.etc.getFilterStr(data)}`,'GET');
}
const registReview = async (data) => {
  return await connector.authConnector(`review?${utils.etc.getFilterStr(data)}`,'post');
}
const deleteReview = async (data) => {
  return await connector.authConnector(`review/del?${utils.etc.getFilterStr(data)}`,'post');
}
const updateReview = async (data) => {
  return await connector.authConnector(`review/update?${utils.etc.getFilterStr(data)}`,'post');
}



export {
  getList,
  getDetail,
  registReview,
  deleteReview,
  updateReview
};


export default {
  getList,
  getDetail,
  registReview,
  deleteReview,
  updateReview
}