import Styles from "./styles";
import { useState } from "react";

// img
import IconQuestion from "resources/image/icon/icon_question.svg";
import IconAnswer from "resources/image/icon/icon_answer.svg";

function FaqList({ data }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Styles.Container>
      <div
        className={"questionContainer" + (isOpen ? " on" : "")}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="iconWrap">
          <img src={IconQuestion} alt="IconQuestion" />
        </div>
        <p className="question">{data.title}</p>
      </div>

      {isOpen && (
        <div className="answerContainer">
          <div className="iconWrap">
            <img src={IconAnswer} alt="IconAnswer" />
          </div>
          <p 
            className="answer editor ck-content"
            dangerouslySetInnerHTML={{__html: data.description}}
          >
          </p>
        </div>
      )}
    </Styles.Container>
  );
}

export default FaqList;
