import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 154px;

  & .pageName {
    padding: 30px 0 20px;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
  }

  & section {
    padding-top: 30px;

    & .description {
      margin-bottom: 30px;
      font-size: 19px;
      line-height: 1.31;
      text-align: center;

      & b {
        font-weight: 600;
      }
    }

    & .listWrap {
      margin-bottom: 40px;

      & .list {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        & a {
          display: flex;
          align-items: center;
          height: 75px;
          padding: 0 30px;
          font-size: 20px;
          font-weight: 500;
          color: #fff;
          background-color: var(--black);
          border-radius: 10px;

          & .fileOpenText {
            margin: 0 4px 0 auto;
            font-size: 14px;
            text-decoration: underline;
            text-underline-position: from-font;
          }

          & .iconWrap {
            width: 32px;
            height: 32px;
          }
        }
      }
    }

    & .logoWrap {
      width: 249px;
      height: 227px;
      margin: 0 auto;
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (154 / 500));

    & .pageName {
      padding: calc(100vw * (30 / 500)) 0 calc(100vw * (20 / 500));
      font-size: calc(100vw * (26 / 500));
    }

    & section {
      padding-top: calc(100vw * (30 / 500));

      & .description {
        margin-bottom: calc(100vw * (30 / 500));
        font-size: calc(100vw * (19 / 500));
      }

      & .listWrap {
        margin-bottom: calc(100vw * (40 / 500));

        & .list {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (20 / 500));
          }

          & a {
            height: calc(100vw * (75 / 500));
            padding: 0 calc(100vw * (30 / 500));
            font-size: calc(100vw * (20 / 500));
            border-radius: calc(100vw * (10 / 500));

            & .fileOpenText {
              margin: 0 calc(100vw * (4 / 500)) 0 auto;
              font-size: calc(100vw * (14 / 500));
            }

            & .iconWrap {
              width: calc(100vw * (32 / 500));
              height: calc(100vw * (32 / 500));
            }
          }
        }
      }

      & .logoWrap {
        width: calc(100vw * (249 / 500));
        height: calc(100vw * (227 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
