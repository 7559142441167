import utils from "utils";
import Styles from "./styles";

function MyCommentList({ data }) {

  return (
    <Styles.Container>
      <p className="comment">{data?.content}</p>
      <p className="date">{utils.date.getDateType(data?.created_at)}</p>
    </Styles.Container>
  );
}

export default MyCommentList;
