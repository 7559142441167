import Styles from "./styles";
import { getDateType } from "utils/date";

function NoticeList({ data, index, main }) {
  return (
    <Styles.Container className={data.hold_yn === "Y" || main ? "on" : ""}>
      <div className="number">{data.hold_yn === "Y" || main ? "중요" : index + 1}</div>
      <p className="title">{data.title}</p>
      <p className="date">{getDateType(data.created_at)}</p>
    </Styles.Container>
  );
}

export default NoticeList;
