import utils from "utils";
import Styles from "./styles";
import Marquee from "react-fast-marquee";
import { DOMAIN } from "service/connector";
import { getDateType } from "utils/date";
import { SectionTitle } from "./components";
import { common, ui, layout } from "components";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { clickDetail } from "utils/etc";
import {
  useModals,
  useBanner,
  useEvent,
  useScrap,
  useSearch,
  useSubaler,
  useVote,
  useWiki,
  useDaily,
  useSupport,
  useAuth,
} from "hooks";
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right_box.svg";

function MainPage() {

  const authInfo = useAuth({});
  const modalOption = useModals();
  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [vote, setVote] = useState(false);
  const bannerTopInfo = useBanner({ type: "0" });
  const bannerBottomInfo = useBanner({ type: 1 });
  const scrapInfo = useScrap({
    queryName: "subaler",
    callFilter: {
      page: 1,
      recordSize: 5,
      pageSize: 1,
      order: 1,
      type: 4,
      hold_yn: "N",
    },
  });
  const wikiInfo = useWiki({
    listOption: { page: 1, recordSize: 9, pageSize: 1, order: 1, type: 2 },
  });
  const supportInfo = useSupport({ options: { noticeList: true } });
  const subalerInfo = useSubaler({
    listOption: {
      page: 1,
      recordSize: 5,
      pageSize: 1,
      order: 1,
      type: 4,
    },
  });
  const eventInfo = useEvent({
    listOption: { page: 1, recordSize: 4, pageSize: 1, order: 1, type: 1 },
  });
  const voteInfo = useVote({ isCurrent: true });
  const searchInfo = useSearch({ isKeyword: true });
  const deilyInfo = useDaily({ date: getDateType(new Date(), "-") });

  const bannerTopList = bannerTopInfo.getBanner.data?.data || [];
  const bannerBottomList = bannerBottomInfo.getBanner.data?.data || [];
  const wikiList = wikiInfo.listQuery.data?.list || [];
  const sublarList = subalerInfo.listQuery.data?.list || [];
  const eventList = eventInfo.listQuery.data?.list || [];
  const voteData = voteInfo.currentVoteQuery.data?.data || {};
  const list = searchInfo.keywordQuery.data?.data || [];
  const deily = deilyInfo.getDaily.data?.data || [];

  const voteClick = async () => {
    if (!vote) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.title = "답변을 선택해주세요 :)";
        e.text = false;
        e.confirmButtonText = "확인";
        e.cancelButtonText = false;
        return { ...e };
      });
    } else {
      await voteInfo.toggleVote(
        JSON.stringify({
          vote_group_info_id: voteData.id,
          vote_info_id: vote,
        })
      );
      navigate(`/vote/result/${voteData.id}`);
    }
  };

  // 웹뷰 모달
  useEffect(() => {

    if(utils.etc.getCookie("jwt")){
      authInfo.getValidateToken();
    }

    supportInfo.setFilter((v) => {
      return { ...v, main_yn: "Y" };
    });
    modalOption.setWebViewModal((e) => {
      e.show = true;
      return { ...e };
    });
    if (location.search) {
      document.cookie = `jwt=${
        location.search.split("=")[1]
      };path=/;domain=${DOMAIN}`;
      window.location.replace("/");
    }
  }, []);

  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.Header />

        <Styles.Container>
          {bannerTopList.length ? (
            <section className="section01">
              <Swiper
                pagination={{
                  type: "fraction",
                  formatFractionCurrent: function (number) {
                    return number < 10 ? "0" + number : number;
                  },
                  formatFractionTotal: function (number) {
                    return number < 10 ? "0" + number : number;
                  },
                }}
                autoplay={{
                  delay: 3000,
                  speed: 500,
                  disableOnInteraction: false,
                }}
                loop={true}
                modules={[Pagination, Autoplay]}
                className="bannerSlider"
              >
                {bannerTopList?.map((item, index) => {
                  return (
                    <SwiperSlide
                      className="slideList"
                      key={"slideList" + index}
                      onClick={() => {
                        if (item.url) {
                          clickDetail(item.url);
                          bannerTopInfo.clickBanner(item.id);
                        } 
                      }}
                    >
                      <img src={item.path} alt={item.img} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </section>
          ) : null}
          <div
            className="rollingContainer"
            onClick={() => {
              deily[0]?.url && clickDetail(deily[0].url);
            }}
          >
            <div className="date">
              {(new Date().getMonth() + 1).toString().padStart(2, "0")}.
              {new Date().getDate().toString().padStart(2, "0")}
            </div>

            <div className="marqueeContainer">
              <Marquee delay={1} className={"marqueeText"}>
                {deily?.map((item, index) => {
                  return <p key={"marqueeText" + index} style={{marginRight:150}}>{item.title}</p>;
                })}
              </Marquee>
            </div>
          </div>

          <section className="section02">
            <div className="inner">
              <layout.SearchContainer
                main
                search={search}
                setSearch={setSearch}
              />
              <ul className="keywordListWrap">
                {list?.map((item, index) => {
                  return (
                    <li className="keywordList" key={"keywordList" + index}>
                      <ui.button.Keyword
                        text={item.title}
                        onClick={() => {
                          navigate(`/search/detail?keyword=${item.title}`);
                        }}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </section>

          <section className="section03">
            <div className="inner">
              <SectionTitle url={"/event"}>
                전국에 숨겨진 <br />
                <span>행사, 혜택, 꿀지원!</span>
              </SectionTitle>
            </div>
            {
              eventList && eventList.length > 0 &&
              <Swiper
                slidesPerView={"auto"}
                freeMode={true}
                className="eventSlider"
                autoplay={{
                  delay: 3000,
                  speed: 500,
                  disableOnInteraction: false,
                }}
                modules={[FreeMode, Autoplay]}
                grabCursor={true}
                loop={true}
              >
                {eventList?.map((item, index) => {
                  return (
                    <SwiperSlide key={"eventList" + index}>
                      <Link to={"/event/detail/" + item.id}>
                        <layout.EventList data={item} main />
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            }
          </section>

          <div className="inner">
            <layout.AdContainer />
          </div>

          <section className="section04">
            <div className="inner">
              <SectionTitle url={"/community"}>
                <span>수발러</span>들과 함께 <br />
                일상을 공유해보세요 :)
              </SectionTitle>

              <ul className="listWrap">
                {supportInfo.getNoticeList?.list?.[0] ? (
                  <li className="list">
                    <Link
                      to={`/notice/detail?id=${supportInfo.getNoticeList.list.filter(v=>v.hold_yn === "Y")[0].id}`}
                    >
                      <layout.NoticeList
                        data={
                          supportInfo.getNoticeList.list.filter(
                            (v) => v.hold_yn === "Y"
                          )[0]
                        }
                        main
                      />
                    </Link>
                  </li>
                ) : null}
                {sublarList?.map((item, index) => {
                  return (
                    <li className="list" key={"communityList" + index}>
                      <Link to={`/community/detail/${item.id}`}>
                        <layout.CommunityList
                          data={item}
                          index={index}
                          scrapInfo={scrapInfo}
                        />
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </section>

          <section className="section05">
            <div className="inner">
              <SectionTitle>
                댕댕<span>위키</span>
              </SectionTitle>
            </div>

            <div
              className="wikiBanner"
              onClick={() => {
                navigate("/wiki");
              }}
            >
              <p className="bannerText">
                2020년부터 댕댕이 가족들의 추천을 통해 업체 정보부터 <br />
                국내외 숨겨진 콘텐츠까지 분야별로 정리된 아카이브입니다.
              </p>
            </div>

            <div className="inner">
              <div className="sliderTitleContainer">
                <p className="title">최신 업데이트</p>

                <Link to={"/wiki"} className="moreButton">
                  더보기{" "}
                  <div className="iconWrap">
                    <img src={IconArrowRight} alt="IconArrowRight" />
                  </div>
                </Link>
              </div>
              {
                wikiList && wikiList.length > 0 &&
                <Swiper
                  
                  slidesPerView={3}
                  spaceBetween={11}
                  slidesPerGroup={3}
                  className="wikiSlider"
                  grabCursor={true}
                  breakpoints={{
                    281: { spaceBetween: 20 },
                  }}
                  autoplay={{
                    delay: 3000,
                    speed: 500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  loop={true}
                >
                  {wikiList?.map((item, index) => {
                    return (
                      <SwiperSlide key={"wikiList" + index}>
                        <Link to={"/wiki/detail/" + item.id}>
                          <layout.WikiList data={item} main />
                        </Link>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              }
            </div>
          </section>

          <section className="section06">
            <div className="inner">
              <SectionTitle>
                <span>견심전심</span> 투표
              </SectionTitle>

              {voteData?.vote_list && (
                <p className="date">
                  {utils.date.getDateType(voteData.started_at)} ~{" "}
                  {utils.date.getDateType(voteData.ended_at)}
                </p>
              )}

              <p className="description">
                <Link to={"/vote/result/" + voteData.id}>{voteData.title}</Link>
              </p>

              <ul className="listWrap">
                {voteData?.vote_list?.map((item, index) => {
                  return (
                    <li className="list" key={"voteList" + index}>
                      <ui.input.VoteRadio
                        detail={voteData}
                        data={item}
                        id={item.id}
                        name={"vote"}
                        onChange={(e) => {
                          setVote(item.id);
                        }}
                        checked={vote === item.id}
                      />
                    </li>
                  );
                })}
              </ul>

              <ui.button.BasicButton
                buttonText={
                  voteData?.vote_list ? "투표하기" : "진행중인 투표가 없습니다."
                }
                onClick={voteData?.vote_list ? voteClick : () => { navigate("/vote") }}
              />
            </div>
          </section>

          {bannerBottomList.length ? (
            <section className="section07">
              <div className="inner">
                <Swiper
                  autoplay={{
                    delay: 3000,
                    speed: 500,
                    disableOnInteraction: false,
                  }}
                  spaceBetween={10}
                  loop={true}
                  modules={[Autoplay]}
                  className="subBannerSlider"
                >
                  {bannerBottomList?.map((item, index) => {
                    return (
                      <SwiperSlide
                        className="slideList"
                        onClick={() => {
                          if (item.url) {
                            clickDetail(item.url);
                            bannerBottomInfo.clickBanner(item.id);
                          } 
                        }}
                        key={"subSlideList" + index}
                      >
                        <img src={item.path} alt={item.img} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </section>
          ) : null}
        </Styles.Container>

        <common.Footer />

        <common.QuickMenu />
        <common.NavigationBar />
      </div>
    </>
  );
}

export default MainPage;
