import styled, { css } from "styled-components";

const sizes = {
  small: {
    maxHeight: "216",
  },
  medium: {
    maxHeight: "266",
  },
};

const sizeStyles = css`
  ${({ size }) => css`
    max-height: ${sizes[size] && sizes[size].maxHeight
      ? sizes[size].maxHeight + "px"
      : "288px"};

    @media (max-width: 499px) {
      max-height: ${sizes[size] && sizes[size].maxHeight
        ? "calc(100vw * (" + sizes[size].maxHeight + "/ 500))"
        : "calc(100vw * (288 / 500))"};
    }
  `}
`;

const Container = styled.div`
  position: relative;
  cursor: pointer;

  & .nowSelect {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 16px;
    color: var(--gray03);
    background-color: var(--gray01);
    border-radius: 6px;

    & .iconWrap {
      width: 16px;
      height: 16px;
      filter: invert(92%) sepia(8%) saturate(186%) hue-rotate(201deg)
        brightness(92%) contrast(91%);
    }

    &.on {
      color: var(--black);

      & .iconWrap {
        filter: invert(28%) sepia(27%) saturate(0%) hue-rotate(206deg)
          brightness(80%) contrast(113%);
      }
    }

    &.open .iconWrap {
      transform: rotate(180deg);
    }
  }

  @media (max-width: 499px) {
    & .nowSelect {
      height: calc(100vw * (60 / 500));
      padding: 0 calc(100vw * (16 / 500));
      border-radius: calc(100vw * (6 / 500));

      & .iconWrap {
        width: calc(100vw * (16 / 500));
        height: calc(100vw * (16 / 500));
      }
    }
  }
`;

const OptionContainer = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  background-color: var(--gray01);
  box-shadow: 4px 8px 25px 0px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  z-index: 2;

  ${sizeStyles}

  /* &::-webkit-scrollbar {
    width: 24px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--gray02);
    border-radius: 999px;
    background-clip: padding-box;
    border: 10px solid transparent;
  } */

  & .menuContainer {
    max-height: inherit;
  }

  & .optionList {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 16px;
  }

  @media (max-width: 499px) {
    top: calc(100% + calc(100vw * (4 / 500)));
    box-shadow: calc(100vw * (4 / 500)) calc(100vw * (4 / 500))
      calc(100vw * (25 / 500)) 0 rgba(0, 0, 0, 0.06);
    border-radius: calc(100vw * (6 / 500));

    /* &::-webkit-scrollbar {
      width: calc(100vw * (24 / 500));
    }
    &::-webkit-scrollbar-thumb {
      border: calc(100vw * (10 / 500)) solid transparent;
    } */

    & .optionList {
      height: calc(100vw * (48 / 500));
      padding: 0 calc(100vw * (16 / 500));
    }
  }
`;

export { Container, OptionContainer };

export default { Container, OptionContainer };
