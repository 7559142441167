import connector from "service/connector";
import utils from "utils";

const getList = async (data) => {
  return await connector.authConnector(`subaler?${utils.etc.getFilterStr(data)}`,'GET');
}
const getDetail = async (id) => {
  return await connector.authConnector(`subaler/${id}`,'GET');
}
const getSubalerNotice = async () => {
  return await connector.connectFetchController(`subaler/notice`,'GET');
}
const insertSubaler = async (data) => {
  return await connector.authConnector("subaler", "POST", data);
}
const updateSubaler = async (data) => {
  return await connector.authConnector("subaler/u", "POST", data);
}
const deleteSubaler = async (data) => {
  return await connector.authConnector("subaler/del?id=" + data, "POST");
}


export {
  getDetail,
  getList,
  getSubalerNotice,
  insertSubaler,
  deleteSubaler,
  updateSubaler
};


export default {
  getDetail,
  getList,
  getSubalerNotice,
  insertSubaler,
  deleteSubaler,
  updateSubaler
}