import styled from "styled-components";

// img
import IconEssential from "resources/image/icon/icon_essential.svg";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: calc(50% + 75px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 100vh;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;

  & .modalContainer {
    width: 430px;
    max-height: 80vh;
    max-height: 80dvh;
    padding: 50px 20px;
    background-color: #fff;
    border-radius: 10px;
    overflow: auto;

    & .title {
      margin-bottom: 40px;
      font-size: 26px;
      font-weight: bold;
      text-align: center;
    }

    & .label {
      position: relative;
      width: fit-content;
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: bold;

      &::after {
        position: absolute;
        top: 50%;
        right: -12px;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        background: url(${IconEssential}) no-repeat center / cover;
        content: "";
      }
    }

    & .listContainer {
      margin-bottom: 30px;
      border-radius: 6px;
      border: 1px solid var(--gray02);

      & .list {
        padding: 14px 16px 16px;

        &:not(:last-child) {
          border-bottom: 1px solid var(--gray02);
        }
      }

      & .textareaWrap {
        padding: 0 16px 16px;

        & .textareaContainer {
          height: 196px;
        }
      }
    }

    & .buttonContainer {
      width: 320px;
      margin: 0 auto;

      & .buttonList:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  @media (max-width: 1200px) {
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 499px) {
    width: 100%;

    & .modalContainer {
      width: calc(100vw * (430 / 500));
      padding: calc(100vw * (50 / 500)) calc(100vw * (20 / 500));
      border-radius: calc(100vw * (10 / 500));

      & .title {
        margin-bottom: calc(100vw * (40 / 500));
        font-size: calc(100vw * (26 / 500));
      }

      & .label {
        margin-bottom: calc(100vw * (20 / 500));
        font-size: calc(100vw * (20 / 500));

        &::after {
          right: calc(100vw * (-12 / 500));
          width: calc(100vw * (8 / 500));
          height: calc(100vw * (8 / 500));
        }
      }

      & .listContainer {
        border-radius: calc(100vw * (6 / 500));

        & .list {
          padding: calc(100vw * (14 / 500)) calc(100vw * (16 / 500))
            calc(100vw * (16 / 500));
        }

        & .textareaWrap {
          padding: 0 calc(100vw * (16 / 500)) calc(100vw * (16 / 500));

          & .textareaContainer {
            height: calc(100vw * (196 / 500));
          }
        }
      }

      & .buttonContainer {
        width: calc(100vw * (320 / 500));
        margin-top: calc(100vw * (51 / 500));

        & .buttonList:not(:last-child) {
          margin-bottom: calc(100vw * (16 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
