import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  padding: 0 30px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);

  & .left,
  & .right,
  & .category {
    display: flex;
    align-items: center;
  }

  & .title {
    position: relative;
    margin-right: 16px;
    font-weight: 500;

    &::after {
      position: absolute;
      top: 50%;
      right: -8px;
      transform: translateY(-50%);
      width: 1px;
      height: 12px;
      background-color: var(--black);
      content: "";
    }
  }

  & .category {
    font-size: 14px;

    & .categoryList:not(:last-child)::after {
      content: "・";
    }
  }

  & .right {
    gap: 16px;

    & button {
      display: block;
      width: 22px;
      height: 22px;
    }

    & input {
      width: 100px;
      height: 40px;
      font-size: 16px;
      font-weight: 500;
      border-radius: 6px;
      background-color: var(--gray01);
      text-align: center;
    }
  }

  @media (max-width: 499px) {
    height: calc(100vw * (68 / 500));
    padding: 0 calc(100vw * (30 / 500));
    border-radius: calc(100vw * (6 / 500));
    box-shadow: 0 0 calc(100vw * (30 / 500)) 0 rgba(0, 0, 0, 0.1);

    & .title {
      margin-right: calc(100vw * (16 / 500));

      &::after {
        right: calc(100vw * (-8 / 500));
        height: calc(100vw * (12 / 500));
      }
    }

    & .category {
      font-size: calc(100vw * (14 / 500));
    }

    & .right {
      gap: calc(100vw * (16 / 500));

      & button {
        width: calc(100vw * (22 / 500));
        height: calc(100vw * (22 / 500));
      }

      & input {
        width: calc(100vw * (100 / 500));
        height: calc(100vw * (40 / 500));
        font-size: calc(100vw * (16 / 500));
        border-radius: calc(100vw * (6 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
