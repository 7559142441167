import connector from "service/connector"
import utils from "utils";


const getList = async (filter) => {
  return await connector.authConnector("vote" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}

const getCurrentVote = async () => {
  return await connector.authConnector("vote/process", "GET");
}

const doVote = async (data) => {
  return await connector.authConnector("vote", "POST", data);
}

const getDetail = async (data) => {
  return await connector.authConnector("vote/" + data, "GET");
}

export {
  getList,
  getCurrentVote,
  doVote,
  getDetail,
};

export default {
  getList,
  getCurrentVote,
  doVote,
  getDetail,
};