import Styles from "./styles";
import { common } from "components";
import { useAuth } from "hooks";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

// img
import Logo from "resources/image/icon/logo_white.svg";
import IconArrowLeft from "resources/image/icon/icon_arrow_left_white.svg";
import IconGoogle from "resources/image/icon/icon_google.svg";
import IconApple from "resources/image/icon/icon_apple.svg";
import IconKakao from "resources/image/icon/icon_kakao02.svg";
import IconNaver from "resources/image/icon/icon_naver.svg";
import utils from "utils";

function LoginPage() {
  const authInfo = useAuth({});
  const navigate = useNavigate();

  const [pageInfo, setPageInfo] = useState();
  const pageUrl = async (loginType) => {
    const result = await authInfo.socialLogin(loginType);
    setPageInfo(result?.data)
  }

  useEffect(()=>{
    pageInfo && window.location.replace(pageInfo);
  },[pageInfo])

  const list = [
    {
      icon: IconGoogle,
      onClick: async () => {
        let result = await authInfo.socialLogin("GOOGLE");
        utils.etc.callNative("GOOGLELOGIN",result.data);
      },
      text: "google",
    },
    {
      icon: IconApple,
      onClick: () => { pageUrl("APPLE") },
      text: "apple",
    },
    {
      icon: IconKakao,
      onClick: () => { pageUrl("KAKAO") },
      text: "kakao",
    },
    {
      icon: IconNaver,
      onClick: () => { pageUrl("NAVER") },
      text: "naver",
    }
  ];

  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <Styles.Container>
          <button
            type="button"
            className="prevButton"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={IconArrowLeft} alt="IconArrowLeft" />
          </button>

          <p className="title">견생과 함께하는 인생을 위한 정보 네트워크</p>

          <div className="logoWrap">
            <img src={Logo} alt="logo" />
          </div>

          <ul className="listWrap">
            {list?.map((item, index) => {
              return (
                <li className="list" key={"list" + index}>
                  <button
                    type="button"
                    className={`${item.text}`}
                    onClick={item.onClick}
                  >
                    <span className="iconWrap">
                      <img src={item.icon} alt={item.icon} />
                    </span>

                    <span className="text">{item.text}로 시작하기</span>
                  </button>
                </li>
              );
            })}
          </ul>

          <p className="benefit">펫트워크 회원 혜택</p>

          <p className="benefitText">
            분야별 관심 브랜드와 유용한 정보를 저장하고, <br />
            비공개 된 게시판 정보에 접근이 가능합니다.
          </p>
        </Styles.Container>
      </div>
    </>
  );
}

export default LoginPage;
