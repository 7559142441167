import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 70px);
  min-height: calc(100dvh - 70px);
  padding-bottom: 80px;

  & section {
    padding-top: 30px;

    & .inner {
      padding: 30px 20px 80px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0px 10px 60px 0px rgba(68, 74, 102, 0.15);
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (70 / 500)));
    min-height: calc(100dvh - calc(100vw * (70 / 500)));
    padding-bottom: calc(100vw * (80 / 500));

    & section {
      padding-top: calc(100vw * (30 / 500));

      & .inner {
        padding: calc(100vw * (30 / 500)) calc(100vw * (20 / 500))
          calc(100vw * (80 / 500));
        border-radius: calc(100vw * (10 / 500));
        box-shadow: 0 calc(100vw * (10 / 500)) calc(100vw * (60 / 500)) 0
          rgba(68, 74, 102, 0.15);
      }
    }
  }
`;

export { Container };

export default { Container };
