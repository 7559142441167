import Styles from "./styles";
import ui from "components/ui";

// img
import Logo from "resources/image/icon/logo_color.svg";

function DefaultModal({ modalOption, modalClose }) {
  return (
    <Styles.Container>
      <div className={"modalContainer"}>
        <div className="iconWrap">
          <img src={Logo} alt="logo" />
        </div>

        <p className="title">{modalOption.title}</p>

        {modalOption.text && <p className="text">{modalOption.text}</p>}

        <ul className="buttonListWrap">
          <li className="buttonList confirmButton">
            <ui.button.BasicButton
              buttonText={modalOption.confirmButtonText}
              onClick={() => {
                modalClose();
                {
                  modalOption.confirmButtonClick &&
                    modalOption.confirmButtonClick();
                }
              }}
            />
          </li>

          {modalOption.cancelButtonText && (
            <li className="buttonList cancelButton">
              <ui.button.BasicButton
                buttonText={modalOption.cancelButtonText}
                buttonType={"white"}
                onClick={() => {
                  modalClose();
                  {
                    modalOption.cancelButtonClick &&
                      modalOption.cancelButtonClick();
                  }
                }}
              />
            </li>
          )}
        </ul>
      </div>
    </Styles.Container>
  );
}

export default DefaultModal;
