import connector from "service/connector";
import utils from "utils";

const getList = async (data) => {
  return await connector.authConnector(`dogwiki?${utils.etc.getFilterStr(data)}`,'GET');
}

const getDetail = async (id) => {
  return await connector.authConnector(`dogwiki/${id}`,'GET');
}


export {
  getDetail,
  getList
};


export default {
  getDetail,
  getList
}