import connector from "service/connector"
import utils from "utils";


const getList = async (filter) => {
  return await connector.authConnector("alarm" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}

const getTotalCount = async () => {
  return await connector.authConnector("alarm/count", "GET");
}


export {
  getList,
  getTotalCount,
};

export default {
  getList,
  getTotalCount,
};