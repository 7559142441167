import styled from "styled-components";

const Container = styled.div`
  & .adContainer {
    height: 140px;
    margin-bottom: 8px;
    border-radius: 10px;
    overflow: hidden;
  }

  & p {
    font-size: 13px;
    text-align: center;
  }

  @media (max-width: 499px) {
    & .adContainer {
      // height: calc(100vw * (80 / 500));
      margin-bottom: calc(100vw * (8 / 500));
      border-radius: calc(100vw * (10 / 500));
    }

    & p {
      font-size: calc(100vw * (13 / 500));
    }
  }
`;

export { Container };

export default { Container };
