import utils from "utils";
import Styles from "./styles";

// img
import Logo from "resources/image/icon/logo_color03.svg";
import { useEffect } from "react";

function WebViewModal({ modalClose }) {


  const openApp = () => {
    window.open('https://petwork.page.link/6SuK','_blank');
  }


  const updateSevenDayCookie = () => {
    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 1000*60*60*24*7;
    now.setTime(expireTime);

    document.cookie = 'mdeivce=true; expires=' + now.toUTCString()+ '; path=/;';
  }

  useEffect(()=>{
    setTimeout(()=>{
      let isMobileDevice = utils.etc.getCookie('mdeivce');
      if(!isMobileDevice){
        if (window.ReactNativeWebView) { modalClose(); }
        if (window.ReactNativeWebView || window.innerWidth > 499) { 
          modalClose();
         }
      } else {
        modalClose();
        return null;
      } 
      
      if (window.ReactNativeWebView) { modalClose(); }
    },250)
  },[])

  
  let isMobileDevice = utils.etc.getCookie('mdeivce');
  if(!isMobileDevice){
    if (window.ReactNativeWebView || window.innerWidth > 499) { return null }
  } else {
    return null;
  } 

  return (
    <Styles.Container>
      <div className="modalContainer">
        <button
          type="button"
          className="closeButton"
          onClick={() => {
            updateSevenDayCookie();
            modalClose();
          }}
        >
          7일간 보지 않기
        </button>

        <div className="textContainer">
          <div className="iconWrap">
            <img src={Logo} alt="logo" />
          </div>

          <p className="text">
            산책하고 놀러가기도 바쁜 현생, <br />
            댕댕이와 관련된 다양한 실전 정보를 <br />한 곳에서 확인하세요!
          </p>
        </div>

        <ul className="buttonListWrap">
          <li className="buttonList">
            <button type="button" className="confirmButton" onClick={openApp}>
              펫트워크 앱으로 보기
            </button>
          </li>

          <li className="buttonList">
            <button
              type="button"
              className="cancelButton"
              onClick={() => {
                modalClose();
              }}
            >
              괜찮아요. 모바일웹으로 볼래요
            </button>
          </li>
        </ul>
      </div>
    </Styles.Container>
  );
}

export default WebViewModal;
