import utils from "utils";
import Styles from "./styles";
import { common } from "components";
import { useState } from "react";
import { useCategory } from "hooks";
import { Link, useLocation, useNavigate } from "react-router-dom";

function WikiCategoryPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = utils.etc.getSearchParam();
  const categoryInfo = useCategory({
    listOption:{
      type : 2,
      parent_info_id : 20,
    }
  })
  const categoryMainInfo = useCategory({
    listOption:{
      type : 2,
      parent_info_id : 0,
    }
  })

  const prevInfo = categoryInfo.list?.
    map((item, index)=>{return item.title === location.state && index}).
    filter((v)=>{return v !== false})[0]
  
  const [mainCategoryIndex, setMainCategoryIndex] = useState(
    toString(prevInfo)
      ? prevInfo : params.tab
        ? parseInt(params.tab) : false
  );

  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.SubHeader pageName={"댕댕위키"} />

        <Styles.Container>
          <section>
            <div className="topContainer">
              <p className="topTitle">{categoryMainInfo.list[0]?.title}</p>

              <p className="description">{categoryMainInfo.list[0]?.subtitle}</p>
            </div>

            <div className="categoryContainer">
              <div className="mainCategory">
                <ul className="listWrap">
                  {categoryInfo.list?.map((item, index) => {
                    return (
                      <li
                        className={
                          "list" + (mainCategoryIndex === index ? " on" : "")
                        }
                        key={"mainCategory" + index}
                        onClick={() => {
                          setMainCategoryIndex(index);
                          navigate(utils.etc.getQueryParamUrl({ tab: index }), { replace: true });
                        }}
                      >
                        <div className="iconWrap">
                          <img src={item.thumbnail ? item.thumbnail : item.icon} alt={item.thumbnail ? item.thumbnail : item.icon} />
                        </div>

                        <p>{item.title}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {
                categoryInfo.list[mainCategoryIndex] && mainCategoryIndex !== false && (
                <div className="subCategory">
                  {categoryMainInfo.listQuery.data.data[0].sub_list[mainCategoryIndex].banner_image &&
                    <div className="bannerImgWrap">
                      <img src={categoryMainInfo.listQuery.data.data[0].sub_list[mainCategoryIndex].banner_image} alt="ImgBanner" />
                    </div>
                  }

                  <ul className="listWrap">
                    <li className="list">
                      <Link to={`/wiki?parent_info_id=${categoryInfo.list[mainCategoryIndex].id}&category_info_id=${categoryInfo.list[mainCategoryIndex].id}`}>
                        ALL{" "}
                        <span className="number">
                          ({utils.etc.numberWithCommas(categoryInfo.list[mainCategoryIndex] ? categoryInfo.list[mainCategoryIndex].total_count : 0)})
                        </span>
                      </Link>
                    </li>

                    {categoryInfo.list[mainCategoryIndex]?.sub_list?.map(
                      (item, index) => {
                        return (
                          <li className="list" key={"subCategoryList" + index}>
                            <Link to={`/wiki?parent_info_id=${categoryInfo.list[mainCategoryIndex].id}&category_info_id=${item.id}`}>
                              {item.title}{" "}
                              <span className="number">
                                ({utils.etc.numberWithCommas(item.total_count)})
                              </span>
                            </Link>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              )}{" "}
            </div>
          </section>
        </Styles.Container>
      </div>
    </>
  );
}

export default WikiCategoryPage;
