import Styles from "./styles";
import { highlighting } from "utils/etc";

// img
import IconCat from "resources/image/icon/icon_cat.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right_mini.svg";
import defaultImage from "resources/image/img_wiki_default_profile.png";

function WikiList({ main, data, keyword }) {

  return (
    <Styles.Container className={main ? "on" : ""}>
      <div className="imgContainer">
        {data.cat_yn == "Y" && (
          <div className="iconWrap">
            <img src={IconCat} alt="IconCat" />
          </div>
        )}

        <div className="imgWrap">
          <img src={data.thumbnail ? data.thumbnail : defaultImage} alt={data.thumbnail} />
        </div>
      </div>

      <div className="textContainer">
        <p className="subCategory">{data.category_title}</p>
        <p className="company">{highlighting(data.title, keyword)}</p>

        {!main && (
          <>
            <p className="description">{highlighting(data.subtitle, keyword)}</p>

            <div className="more">
              more
              <div className="iconWrap">
                <img src={IconArrowRight} alt="IconArrowRight" />
              </div>
            </div>
          </>
        )}
      </div>
    </Styles.Container>
  );
}

export default WikiList;
