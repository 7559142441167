import Styles from "./styles";
import { useAuth } from "hooks";
import { useEffect, useState } from "react";
import { common, ui, layout } from "components";

function SignupStepCPage() {
  const authInfo = useAuth({});
  
  const [errorText, setErrorText] = useState("")
  const [inputs, setInputs] = useState({
    title: "",
    prev_amount: "",
    current_amount: "",
    interview: "",
    contract: "",
  });

  const onNext = () => {
    if (inputs.title === "") {
      setErrorText("펫트워크 유입 경로를 선택해주세요.")
    } else {
      authInfo.socialSignUp({ ...JSON.parse(sessionStorage.getItem("userInfo")), ...inputs })
    }
  }

  useEffect(()=>{
    if(authInfo.isLogin){
      window.location.replace("/");
    }
  },[authInfo])

  // map
  const inflowList = [
    "검색 포털을 통한 유입 (네이버, 구글 등)",
    "소셜을 통한 유입 (youtube, instagram)",
    "네이버 블로그를 통한 유입",
    "타 커뮤니티를 통한 유입",
    "지인 추천",
    "기타",
  ];
  const experienceList = [
    {
      prevText: "그 동안",
      nextText: "마리를 키워 본 경험이 있어요.",
      name: "prev_amount",
      value: inputs.prev_amount,
    },
    {
      prevText: "지금",
      nextText: "마리와 함께 살고 있어요.",
      name: "current_amount",
      value: inputs.current_amount,
    },
  ];
  const questionList = [
    {
      labelText: "원하는 분야",
      placeholder: "ex) 식단 글 연재, 임보일기 등",
      name: "interview",
      value: inputs.interview
    },
    {
      type: "number",
      labelText: "연락처",
      placeholder: "- 없이 숫자만 입력하세요.",
      name: "contract",
      value: parseInt(inputs.contract)
    },
  ];

  return (
    sessionStorage.getItem("userInfo") &&
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.SubHeader pageName={"회원가입"} />

        <Styles.Container>
          <div className="inner">
            <form autoComplete="off">
              <div className="inflowContainer">
                <p className="title">펫트워크 유입 경로</p>

                <div className="selectContainer">
                  <ui.input.BasicSelect
                    select={inputs.title}
                    setSelect={(v) => { setInputs((e) => { return { ...e, title: v } }) }}
                    optionList={inflowList}
                    placeholder={"유입 경로를 선택하세요."}
                  />
                </div>
              </div>

              <div className="experienceContainer">
                <p className="title">반려 경험</p>

                <ul className="listWrap">
                  {experienceList?.map((item, index) => {
                    return (
                      <li className="list" key={"experienceList" + index}>
                        {item.prevText}{" "}
                        <input
                          type="number"
                          placeholder="0"
                          name={item.name}
                          value={item.value}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setInputs((e) => {
                              return { ...e, [name]: value };
                            });
                          }}
                        />
                        {" "}{item.nextText}
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="questionContainer">
                <p className="title">
                  수익활동(설문조사, 체험단 등) 혹은 자신만의 전문 분야, <br />
                  연재 등 “다양하고 자유로운 형태” 로 펫트워크에 참여
                  <br /> 하실 의향이 있으신가요?
                </p>

                <ul className="listWrap">
                  {questionList?.map((item, index) => {
                    return (
                      <li className="list" key={"questionList" + index}>
                        <ui.input.BasicInput
                          type={item.type}
                          labelText={item.labelText}
                          placeholder={item.placeholder}
                          name={item.name}
                          value={item.value}
                          setInputs={setInputs}
                          phoneType={item.type === "number"}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="errorText">
                {errorText &&
                  <layout.ConfirmText error text={errorText} />
                }
              </div>

              <div className="nextButton">
                <ui.button.BasicButton
                  buttonText={"다음으로"}
                  onClick={onNext}
                />
              </div>
            </form>
          </div>
        </Styles.Container>
      </div>
    </>
  );
}

export default SignupStepCPage;
