import connector from "service/connector";
import utils from "utils";

const getList = async (data) => {
  return await connector.authConnector(`magazine?${utils.etc.getFilterStr(data)}`,'GET');
}

const getDetail = async (id) => {
  return await connector.authConnector(`magazine/${id}`,'GET');
}


export {
  getDetail,
  getList
};


export default {
  getDetail,
  getList
}