import styled from "styled-components";

// img
import IconLogo from "resources/image/icon/logo_gray02.svg";
import IconLogoColor from "resources/image/icon/logo_color02.svg";
import IconVersus from "resources/image/icon/icon_versus.svg";

const Container = styled.div`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 110px;

  & section {
    padding-top: 30px;

    & .listWrap {
      margin-bottom: 60px;

      & .list {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        &.weight input {
          flex-grow: 0;
          width: 91px;
          text-align: end;
        }

        & .label {
          position: relative;
          width: fit-content;
          margin-bottom: 10px;
          font-size: 20px;
          font-weight: 500;

          & span {
            font-size: 14px;
            font-weight: normal;
          }
        }

        & .inputContainer {
          align-items: center;
        }

        & .bodyTypeListWrap,
        & .featuresListWrap,
        & .concernsListWrap {
          display: flex;
          flex-wrap: wrap;
        }

        & .bodyTypeListWrap {
          gap: 10px;

          & li {
            width: calc(calc(100% - 10px) / 2);

            & label {
              background: url(${IconLogo}) no-repeat right center / 61px 59px
                var(--gray01);
            }

            & input:checked + label {
              background-image: url(${IconLogoColor});
            }
          }
        }

        & .featuresListWrap {
          position: relative;
          gap: 18px;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 38px;
            height: 38px;
            background: url(${IconVersus}) no-repeat center / cover;
            content: "";
          }

          & li {
            width: calc(calc(100% - 18px) / 2);

            & label {
              justify-content: center;
              font-weight: 500;
            }
          }
        }

        & .concernsListWrap {
          gap: 10px;
        }
      }
    }

    & .buttonContainer {
      position: relative;

      & .errorTextContainer {
        position: absolute;
        top: -29px;
        left: 50%;
        transform: translateX(-50%);
      }

      & .confirmButton {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (110 / 500));

    & section {
      padding-top: calc(100vw * (30 / 500));

      & .listWrap {
        margin-bottom: calc(100vw * (60 / 500));

        & .list {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (20 / 500));
          }

          &.weight input {
            width: calc(100vw * (91 / 500));
          }

          & .label {
            margin-bottom: calc(100vw * (10 / 500));
            font-size: calc(100vw * (20 / 500));

            & span {
              font-size: calc(100vw * (14 / 500));
            }
          }

          & .bodyTypeListWrap {
            gap: calc(100vw * (10 / 500));

            & li {
              width: calc(calc(100% - calc(100vw * (10 / 500))) / 2);

              & label {
                background: url(${IconLogo}) no-repeat right center /
                  calc(100vw * (61 / 500)) calc(100vw * (59 / 500))
                  var(--gray01);
              }
            }
          }

          & .featuresListWrap {
            gap: calc(100vw * (18 / 500));

            &:not(:last-child) {
              margin-bottom: calc(100vw * (10 / 500));
            }

            &::after {
              width: calc(100vw * (38 / 500));
              height: calc(100vw * (38 / 500));
            }

            & li {
              width: calc(calc(100% - calc(100vw * (18 / 500))) / 2);
            }
          }

          & .concernsListWrap {
            gap: calc(100vw * (10 / 500));
          }
        }
      }

      & .buttonContainer {
        & .errorTextContainer {
          top: calc(100vw * (-29 / 500));
        }

        & .confirmButton {
          margin-bottom: calc(100vw * (20 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
