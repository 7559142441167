import styled from "styled-components";

// img
import IconArrowTop from "resources/image/icon/icon_arrow_top.svg";
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom.svg";

const Container = styled.div`
  cursor: pointer;

  & .questionContainer {
    display: flex;
    padding-bottom: 20px;
    background: url(${IconArrowBottom}) no-repeat right 4px / 16px;
    border-bottom: 1px solid var(--purple01);

    &.on {
      background-image: url(${IconArrowTop});
    }

    & .iconWrap {
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }

    & .question {
      width: 398px;
      font-size: 20px;
      font-weight: 600;
    }
  }

  & .answerContainer {
    display: flex;
    padding: 18px 16px 20px 30px;
    background-color: var(--gray01);

    & .iconWrap {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }

    & .answer {
      width: 380px;
      padding-top: 2px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  @media (max-width: 499px) {
    & .questionContainer {
      padding-bottom: calc(100vw * (20 / 500));
      background: url(${IconArrowBottom}) no-repeat right
        calc(100vw * (4 / 500)) / calc(100vw * (16 / 500));

      & .iconWrap {
        width: calc(100vw * (24 / 500));
        height: calc(100vw * (24 / 500));
        margin-right: calc(100vw * (6 / 500));
      }

      & .question {
        width: calc(100vw * (398 / 500));
        font-size: calc(100vw * (20 / 500));
      }
    }

    & .answerContainer {
      padding: calc(100vw * (18 / 500)) calc(100vw * (16 / 500))
        calc(100vw * (20 / 500)) calc(100vw * (30 / 500));

      & .iconWrap {
        width: calc(100vw * (24 / 500));
        height: calc(100vw * (24 / 500));
        margin-right: calc(100vw * (10 / 500));
      }

      & .answer {
        width: calc(100vw * (380 / 500));
        padding-top: calc(100vw * (2 / 500));
        font-size: calc(100vw * (16 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
