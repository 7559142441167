import useModals from "hooks/useModals";
import { api } from "service";
import { useQuery } from "react-query";
import { useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function useSubaler({
  listOption,
  detailOption,
  notice
}) {
  const navigate = useNavigate();
  const modalOption = useModals();
  const location = useLocation();
  const { id } = useParams();

  const loadRef = useRef(false);

  const renderPageName = (pageId) => {
    switch (pageId) {
      case "자유게시판": return 3;
      case "비밀의 개나무숲": return 4;
      case "사건사고": return 5;
      case "Contact us": return 6;
    }
  };

  const listQuery = useQuery(["subaler",listOption], 
    ()=>{
      return api.subaler.getList(listOption);
    }, {
    enabled:!!listOption,
    refetchOnWindowFocus: false,
  });
  const detailQuery = useQuery(["subalerDetail",detailOption], 
    ()=>{
      return api.subaler.getDetail(detailOption);
    }, {
    enabled:!!detailOption,
    refetchOnWindowFocus: false,
  });
  const getSubalerNotice = useQuery(["subalerDetail",detailOption], 
    ()=>{
      return api.subaler.getSubalerNotice(detailOption);
    }, {
    enabled:!!notice,
    refetchOnWindowFocus: false,
  });

  const [locationSelect, setLocationSelect] = useState("");
  const [unrelatedCheck, setUnrelatedCheck] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    url: "",
    keyword: "",
    speaker: ""
  });
	const [description, setDescription] = useState("");
	const [review, setReview] = useState("");
  const [keywordList, setKeywordList] = useState([]);
  const [check, setCheck] = useState(false);
  const [errorText, setErrorText] = useState("");

  const validate = () => {
    setErrorText("");
    if (locationSelect === "" && !unrelatedCheck) {
      setErrorText("지역을 선택하세요.");
      return false;
    }
    if (inputs.title.trim() === "") {
      setErrorText("제목을 입력해주세요.");
      return false;
    }
    if (location.state.type === "사건사고" && inputs.speaker === "") {
      setErrorText("전달자를 선택해주세요.");
      return false;
    }
    if (description === "") {
      location.state.type === "사건사고"
        ? setErrorText("사건요약 내용을 입력해주세요.")
        : setErrorText("내용을 입력해주세요.")
      return false;
    }
    if (location.state.type === "사건사고" && review === "") {
      setErrorText("의견을 입력해주세요.")
      return false;
    }
    if (location.state.type === "사건사고" && check === false) {
      setErrorText("필수 체크박스를 확인해주세요.");
      return false;
    }
    return true;
  }

  const insertData = () => {
    const descriptionText = description?.replace(/(<([^>]+)>)/ig, "");
    const reviewText = review?.replace(/(<([^>]+)>)/ig, "");
    const callData = {
      category_info_id: renderPageName(location.state.type),
      id: id,
      location: locationSelect ? locationSelect : "",
      title: inputs?.title,
      description: description,
      description_text: descriptionText,
      keyword_list: keywordList,
      review: review,
      review_text: reviewText,
      self_yn: inputs?.speaker === "concerned" ? "Y" : "N",
      url: inputs?.url
    }
    return callData
  }
  const accessRestrict = async () => {
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.title = "게시글을 확인할 수 없습니다.";
      e.text = "삭제되었거나 신고된 게시글입니다.";
      e.confirmButtonText = "확인";
      e.confirmButtonClick = () => {
        navigate("/");
      };
      e.cancelButtonText = false;
      return { ...e };
    })
  }
  const insertSubaler = async () => {
    if (validate() && loadRef.current == false) {
      loadRef.current = true;
      const result = await api.subaler.insertSubaler(JSON.stringify(insertData()));
      if (result.success) {
        modalOption.setDefaultModal((e) => {
          e.show = true;
          e.title = "게시글이 등록되었습니다!";
          e.text = false;
          e.confirmButtonText = "확인";
          e.confirmButtonClick = () => {
            loadRef.current = false;
            navigate("/community/detail/" + result.data, { replace: true });
          };
          e.cancelButtonText = false;
          return { ...e };
        })
      }
    }
  }
  const updateSubaler = async () => {
    if (validate()) {
      const result = await api.subaler.updateSubaler(JSON.stringify(insertData()));
      if (result.success) {
        modalOption.setDefaultModal((e) => {
          e.show = true;
          e.title = "게시글이 수정되었습니다!";
          e.text = false;
          e.confirmButtonText = "확인";
          e.confirmButtonClick = () => {
            navigate(-1);
          };
          e.cancelButtonText = false;
          return { ...e };
        });
      }
    }
  }
  const deleteSubaler = async (data) => {
    const result = await api.subaler.deleteSubaler(data);
    if (result.success) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.title = "게시글이 삭제되었습니다!";
        e.text = false;
        e.confirmButtonText = "확인";
        e.confirmButtonClick = () => {
          navigate("/community");
        };
        e.cancelButtonText = false;
        return { ...e };
      });
    }
  }
  const cancelSubaler = (insert = true) => {
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.title = (
        <>
          {insert ? "작성" : "수정"} 중인 내용이 있습니다. <br />
          나가시겠습니까?
        </>
      );
      e.text = "작성 중인 데이터는 저장되지 않습니다.";
      e.confirmButtonText = "페이지 나가기";
      e.confirmButtonClick = () => {
        navigate(-1);
      };
      e.cancelButtonText = "취소";
      e.cancelButtonClick = false;
      return { ...e };
    });
  }

  return {
    locationSelect, setLocationSelect,
    unrelatedCheck, setUnrelatedCheck,
    inputs, setInputs,
    description, setDescription,
    review, setReview,
    keywordList, setKeywordList,
    check, setCheck,
    errorText, setErrorText,

    listQuery,
    detailQuery,
    getSubalerNotice,

    renderPageName,
    validate,
    accessRestrict,
    insertSubaler,
    updateSubaler,
    deleteSubaler,
    cancelSubaler
  };
}
