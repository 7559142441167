import Styles from "./styles";
import { usePet } from "hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// img
import IconMore from "resources/image/icon/icon_more03.svg";

function DogList({ data }) {
  const petInfo = usePet({});
  const navigate = useNavigate();

  const [isMoreOpen, setIsMoreOpen] = useState(false);

  return (
    <Styles.Container>
      <div className="moreButtonContainer">
        <button
          type="button"
          className="moreButton"
          onClick={() => {
            setIsMoreOpen(!isMoreOpen);
          }}
        >
          <img src={IconMore} alt="IconMore" />
        </button>

        {isMoreOpen && (
          <ul className="moreListWrap">
            <li className="moreList">
              <button
                type="button"
                onClick={() => {
                  setIsMoreOpen(false);
                  sessionStorage.removeItem("petInfo")
                  navigate("/mypage/dog/stepA", { state: { id: data.id } });
                }}
              >
                수정
              </button>
            </li>

            <li className="moreList">
              <button
                type="button"
                onClick={() => {
                  petInfo.deleteInfo(data.id, setIsMoreOpen)
                }}
              >
                삭제
              </button>
            </li>
          </ul>
        )}
      </div>

      <div className="mottoContainer">
        <p>{data.goal}</p>
      </div>

      <div className="dogImgWrap">
        <img src={data.image} alt="dogImg" />
      </div>

      <p className="dogName">{data.title}</p>
      <ul className="infoListWrap">
        <li className="infoList">{data.dog_type}</li>
        <li className="infoList">{data.birthday ? data.birthday.split("-")[0] + "년생" : "알 수 없음"}</li>
        <li className="infoList">{data.gender}</li>
      </ul>
    </Styles.Container>
  );
}

export default DogList;
