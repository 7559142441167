import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid var(--gray04);
  border-radius: 5px;
  overflow: hidden;

  &.on {
    font-weight: 600;
    color: var(--main);
    border-color: var(--main);

    & .textContainer {
      & .percent {
        color: var(--main);
      }

      & .bg {
        background-color: var(--purple01);
      }
    }
  }

  & input {
    display: none;
  }

  & label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
  }

  & .imgWrap {
    flex-shrink: 0;
    width: 160px;
    height: 134px;
  }

  & .textContainer {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    padding: 0 20px;
    white-space: pre-wrap;

    & .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: var(--gray04);
    }

    & .text {
      position: relative;
      line-height: 1.25;
      z-index: 1;
    }

    & .percent {
      position: relative;
      align-self: flex-end;
      font-size: 14px;
      font-weight: bold;
      color: var(--gray03);
      z-index: 1;
    }
  }

  @media (max-width: 499px) {
    font-size: calc(100vw * (16 / 500));
    border: calc(100vw * (2 / 500)) solid var(--gray04);
    border-radius: calc(100vw * (5 / 500));

    & .imgWrap {
      width: calc(100vw * (160 / 500));
      height: calc(100vw * (134 / 500));
    }

    & .textContainer {
      gap: calc(100vw * (8 / 500));
      padding: 0 calc(100vw * (20 / 500));
      white-space: pre-wrap;

      & .percent {
        font-size: calc(100vw * (14 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
