import styled from "styled-components";

//img
import IconArrowCurved from "resources/image/icon/icon_arrow_curved.svg";

const Container = styled.div`
  position: relative;
  margin-bottom: 40px;

  & .moreButtonContainer {
    position: absolute;
    top: 6px;
    right: 0;
    width: 24px;
    height: 24px;

    & .moreListWrap {
      position: absolute;
      top: 0;
      right: 100%;
      background: #fff;
      border-radius: 6px;
      box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.12);
      overflow: hidden;

      & .moreList:not(:last-child) {
        border-bottom: 1px solid var(--gray04);
      }

      & button {
        width: 68px;
        height: 38px;
        font-size: 16px;
      }
    }
  }

  & .profileContainer {
    display: flex;
    align-items: center;

    & .profileImgWrap {
      width: 37px;
      height: 37px;
      margin-right: 8px;
      border-radius: 50%;
      overflow: hidden;
    }

    & .userName {
      max-width: 324px;
      font-size: 18px;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  & .reviewWrap {
    padding: 0 24px 0 45px;

    & .review {
      margin: 10px 0 20px;
    }

    & .imgListWrap {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;

      & .imgList {
        width: 100px;
        height: 100px;
        overflow: hidden;
      }
    }

    & .bottomContainer {
      display: flex;
      gap: 16px;
      font-size: 16px;
      color: var(--gray03);
    }
  }

  @media (max-width: 499px) {
    margin-bottom: calc(100vw * (20 / 500));
    padding-bottom: calc(100vw * (20 / 500));

    & .moreButtonContainer {
      top: calc(100vw * (6 / 500));
      width: calc(100vw * (24 / 500));
      height: calc(100vw * (24 / 500));

      & .moreListWrap {
        border-radius: calc(100vw * (6 / 500));
        box-shadow: calc(100vw * (5 / 500)) calc(100vw * (5 / 500))
          calc(100vw * (30 / 500)) 0 rgba(0, 0, 0, 0.12);

        & button {
          width: calc(100vw * (68 / 500));
          height: calc(100vw * (38 / 500));
          font-size: calc(100vw * (16 / 500));
        }
      }
    }

    & .profileContainer {
      & .profileImgWrap {
        width: calc(100vw * (37 / 500));
        height: calc(100vw * (37 / 500));
        margin-right: calc(100vw * (8 / 500));
      }

      & .userName {
        max-width: calc(100vw * (324 / 500));
        font-size: calc(100vw * (18 / 500));
      }
    }

    & .reviewWrap {
      padding: 0 calc(100vw * (24 / 500)) 0 calc(100vw * (45 / 500));

      & .review {
        margin: calc(100vw * (10 / 500)) 0 calc(100vw * (20 / 500));
      }

      & .imgListWrap {
        gap: calc(100vw * (10 / 500));
        margin-bottom: calc(100vw * (20 / 500));

        & .imgList {
          width: calc(100vw * (100 / 500));
          height: calc(100vw * (100 / 500));
        }
      }

      & .bottomContainer {
        gap: calc(100vw * (16 / 500));
        font-size: calc(100vw * (16 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
