import styled from "styled-components";

// img
import IconEssential from "resources/image/icon/icon_essential.svg";

const Container = styled.div`
  & .listWrap {
    margin-bottom: 60px;

    & .list {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      & .selectContainer {
        width: 150px;
      }

      & .label {
        display: inline-block;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 500;

        & span {
          position: relative;

          &::after {
            position: absolute;
            top: 50%;
            right: -12px;
            transform: translateY(-50%);
            width: 8px;
            height: 8px;
            background: url(${IconEssential}) no-repeat center / cover;
            content: "";
          }
        }
      }

      & .textareaContainer {
        height: 450px;
        margin-bottom: 10px;
        padding: 18px 16px;
        background-color: var(--gray01);

        & textarea {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      & .guideText {
        font-size: 16px;
        color: var(--gray03);
        line-height: 1.5;
      }
    }
  }

  & .buttonContainer {
    position: relative;

    & .errorText {
      position: absolute;
      top: -29px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 16px;
    }

    & button:first-child {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 499px) {
    & .listWrap {
      margin-bottom: calc(100vw * (60 / 500));

      & .list {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (20 / 500));
        }

        & .selectContainer {
          width: calc(100vw * (150 / 500));
        }

        & .label {
          margin-bottom: calc(100vw * (10 / 500));
          font-size: calc(100vw * (20 / 500));

          & span {
            &::after {
              right: calc(100vw * (-12 / 500));
              width: calc(100vw * (8 / 500));
              height: calc(100vw * (8 / 500));
            }
          }
        }

        & .textareaContainer {
          height: calc(100vw * (450 / 500));
          margin-bottom: calc(100vw * (10 / 500));
          padding: calc(100vw * (18 / 500)) calc(100vw * (16 / 500));
        }

        & .guideText {
          font-size: calc(100vw * (16 / 500));
        }
      }
    }

    & .buttonContainer {
      & .errorText {
        top: calc(100vw * (-29 / 500));
        font-size: calc(100vw * (16 / 500));
      }

      & button:first-child {
        margin-bottom: calc(100vw * (20 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
