import { api } from "service";
import { useQuery } from "react-query";
import { useModals } from "hooks"

//img

export default function useVote({listOption,detailOption,  isCurrent }) {
  const modalOption = useModals();

  const listQuery = useQuery(["vote_list", listOption], () => {
    return api.vote.getList(listOption);
  }, { 
    refetchOnWindowFocus: false,
    enabled: !!listOption
  });

  const currentVoteQuery = useQuery(["vote_current_list", isCurrent], () => {
    return api.vote.getCurrentVote();
  }, { 
    refetchOnWindowFocus: false,
    enabled: !!isCurrent
  });

  const detailQuery = useQuery(["voteDetail",detailOption], 
    ()=>{
      return api.vote.getDetail(detailOption);
    }, {
    enabled:!!detailOption,
    refetchOnWindowFocus: false,
  });

  const toggleVote = async (data) => {
    await api.vote.doVote(data);
    currentVoteQuery.refetch();
    detailQuery.refetch();
  }

  return {
    listOption,
    listQuery : listQuery,
    currentVoteQuery : currentVoteQuery,
    detailQuery,
    toggleVote,
  }
}