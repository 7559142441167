import Styles from "./styles";
import utils from "utils";
import { api } from "service";
import { clickDetail } from "utils/etc";
import { common, ui, layout } from "components";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useBanner, useCategory, useScrap, useSubaler } from "hooks";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";

// img
import IconNotice from "resources/image/icon/icon_notice.svg";

function CommunityPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [filter, setFilter] = useState(null);

  const bannerInfo = useBanner({ type: 2 });
  const scrapInfo = useScrap({ queryName: "subaler", callFilter: filter });
  const subaler = useSubaler({ listOption: filter });
  const subalerNotice = useSubaler({ notice: true });
  const categoryInfo = useCategory({
    listOption: { parent_info_id: 1, type: 4 },
  });

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? param.page : 1,
      recordSize: param.recordSize ? param.recordSize : 15,
      pageSize: 5,
      order: param.order ? param.order : 1,
      type: 4,
    };
    if (param.location) {
      f.location = param.location;
    }
    if (param.category_info_id) {
      f.category_info_id = param.category_info_id;
    }
    setFilter({ ...f });
  }, [location]);

  const bannerList = bannerInfo.getBanner.data?.data || [];
  const filterList = [
    { title: "최신순", value: 1 },
    { title: "조회순", value: 2 },
    { title: "댓글순", value: 3 },
  ];
  const data = subaler.listQuery?.data ? subaler.listQuery?.data : {};
  const notice = subalerNotice.getSubalerNotice?.data?.data
    ? subalerNotice.getSubalerNotice?.data?.data
    : [];

  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.Header />

        <Styles.Container>
          <h2 className="pageName">수발러 게시판</h2>

          <ui.button.Tab
            tabList={[{ title: "종합" }].concat(categoryInfo.list)}
            tabIndex={
              filter?.category_info_id
                ? categoryInfo.list.findIndex(
                    (e) => e.id == filter?.category_info_id
                  ) + 1
                : 0
            }
            setTabIndex={(e) => {
              if (e == 0) navigate(utils.etc.getQueryParamUrl({}, true));
              else
                navigate(
                  utils.etc.getQueryParamUrl(
                    { category_info_id: categoryInfo.list[e - 1].id },
                    true
                  )
                );
            }}
          />

          <section className="section01">
            <div className="inner">
              <Swiper
                autoplay={{
                  delay: 4000,
                  speed: 500,
                  disableOnInteraction: false,
                }}
                spaceBetween={10}
                loop={true}
                modules={[Autoplay]}
                className="bannerSlider"
              >
                {bannerList?.map((item, index) => {
                  return (
                    <SwiperSlide
                      className="slideList"
                      onClick={() => {
                        if (item.url) {
                          clickDetail(item.url);
                          bannerInfo.clickBanner(item.id);
                        }
                      }}
                      key={"slideList" + index}
                    >
                      <img src={item.path} alt={item.img} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </section>

          <section className="section02">
            <div className="inner">
              <ul className="listWrap">
                {notice.map((item, index) => {
                  return (
                    <li className="list" key={"noticeList" + index}>
                      <Link to={`detail/${item.id}`}>
                        <div className="iconWrap">
                          <img src={IconNotice} alt="IconNotice" />
                        </div>

                        <div className="right">
                          <p className="listTitle">{item.title}</p>

                          <div className="bottomContainer">
                            <p className="userName">
                              {item.nickname ? item.nickname : "탈퇴유저"}
                            </p>

                            <p className="date">
                              {utils.date.getDateType(item.created_at)}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </section>

          <section className="section03">
            <div className="inner">
              <div className="filterContainer">
                <div className="selectContainer">
                  <ui.input.BasicSelect
                    optionList={filterList}
                    select={
                      filterList[filter?.order > 0 ? filter?.order - 1 : 0]
                        .title
                    }
                    setSelect={(e) => {
                      navigate(
                        utils.etc.getQueryParamUrl(
                          { order: e.value, page: 1 },
                          false
                        )
                      );
                    }}
                  />
                </div>

                <div className="selectContainer">
                  <ui.input.BasicSelect
                    optionList={[
                      ...["전국"].concat(api.dummy.locationList),
                      "온라인",
                    ]}
                    select={filter?.location || ""}
                    setSelect={(e) => {
                      navigate(
                        utils.etc.getQueryParamUrl(
                          { location: e == "전국" ? null : e, page: 1 },
                          false
                        )
                      );
                    }}
                    placeholder={"지역"}
                    size={"small"}
                  />
                </div>
              </div>

              {subaler.listQuery.isFetching || data?.list?.length > 0 ? (
                <>
                  <ul className="listWrap">
                    {data?.list?.map((item, index) => {
                      return (
                        <>
                          <div className="list" key={"communityList" + index}>
                            <Link to={"detail/" + item.id}>
                              <layout.CommunityList
                                data={item}
                                index={index}
                                scrapInfo={scrapInfo}
                              />
                            </Link>
                          </div>
                          {index === 3 && (
                            <li className="list adContainer">
                              <layout.AdContainer key={"ads_com_" + index} />
                            </li>
                          )}
                        </>
                      );
                    })}
                  </ul>

                  <div className="paginationContainer">
                    <ui.button.Pagination
                      page={data.pagination?.page}
                      list={data.pagination?.page_list}
                      maxPage={data.pagination?.totalPageCount}
                    />
                  </div>
                </>
              ) : (
                <div className="emptyContainer">
                  <layout.EmptyContainer text={"등록된 게시물이 없습니다."} />
                </div>
              )}
            </div>
          </section>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default CommunityPage;
