import styled from "styled-components";
const Container = styled.div`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 110px;

  & section {
    padding-top: 30px;

    & h2 {
      margin-bottom: 6px;
      font-size: 20px;
      font-weight: 500;
    }

    & .text {
      margin-bottom: 30px;
      font-size: 14px;
    }

    & .listWrap {
      margin-bottom: 60px;

      & .list {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    & .buttonContainer {
      position: relative;

      & .errorText {
        position: absolute;
        top: -29px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 16px;
      }

      & .confirmButton {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (110 / 500));

    & section {
      padding-top: calc(100vw * (30 / 500));

      & h2 {
        margin-bottom: calc(100vw * (6 / 500));
        font-size: calc(100vw * (20 / 500));
      }

      & .text {
        margin-bottom: calc(100vw * (30 / 500));
        font-size: calc(100vw * (14 / 500));
      }

      & .listWrap {
        margin-bottom: calc(100vw * (60 / 500));

        & .list {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (10 / 500));
          }
        }
      }

      & .buttonContainer {
        & .confirmButton {
          margin-bottom: calc(100vw * (20 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
