import Styles from "./styles";
import layout from "components/layout";

function BasicInput({
  children,
  id,
  labelText,
  essential,
  type,
  name,
  value,
  setInputs,
  placeholder,
  isDisabled,
  confirmText,
  errorText,
  nameType,
  phoneType,
}) {
  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs((e) => {
      return { ...e, [name]: value };
    });
  };

  return (
    <Styles.Container>
      {labelText && (
        <label htmlFor={id} className={essential ? "essential" : ""}>
          <span>{labelText}</span>
        </label>
      )}

      <div className="inputContainer">
        <input
          id={id ? id : name}
          type={type ? type : "text"}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
          onKeyDown={(e) => {
            const nameRegEx = /^[0-9a-zA-Z가-힣ㆍᆞᆢㄱ-ㅎㅏ-ㅣ]*$/gi;

            if (nameType && nameRegEx.test(e.key) === false) {
              e.preventDefault()
            }
            if (phoneType && (e.key === "-" || e.key === " ")) {
              e.preventDefault()
            }
          }}
        />

        {children}
      </div>

      {confirmText && <layout.ConfirmText text={confirmText} />}

      {errorText && <layout.ConfirmText error text={errorText} />}
    </Styles.Container>
  );
}

export default BasicInput;
