import connector from "service/connector"


const postShare = async (data) => {
  return await connector.jsonConnector("share", "POST", data);
}


export {
  postShare,
};


export default {
  postShare,
}