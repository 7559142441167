import { clickDetail } from "utils/etc";
import Styles from "./styles";

// img
import Logo from "resources/image/icon/logo.svg";

function Footer() {
  const list = [
    {
      url: "https://www.instagram.com/petwork_official/",
      text: "인스타그램",
    },
    {
      url: "https://www.youtube.com/channel/UC_c5uQYWQze4I-ILJNrH0MA",
      text: "유튜브",
    },
    {
      url: "https://blog.naver.com/petwork",
      text: "블로그",
    },
  ];
  return (
    <Styles.Container>
      <div className="inner">
        <div className="topContainer">
          <div className="logoWrap">
            <img src={Logo} alt="logo" />
          </div>

          <ul className="listWrap">
            {list.map((item, index) => {
              return (
                <li
                  className="list"
                  key={"list" + index}
                  onClick={() => {
                    item.url && clickDetail(item.url);
                  }}
                >
                  {item.text}
                </li>
              );
            })}
          </ul>
        </div>

        <p className="warning">
          펫트워크의 자체 제작 콘텐츠에 대한 무단복제, 모방, 배포, 스크래핑 등의{" "}
          <br />
          행위는 저작권법, 콘텐츠 산업 진흥법 등 관련 법령에 의하여 엄격히
          <br />
          금지합니다.
        </p>

        <p className="copyright">© 2024. PETWORK. all rights reserved.</p>
      </div>
    </Styles.Container>
  );
}

export default Footer;
