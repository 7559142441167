import Styles from "./styles";
import TextareaAutosize from "react-textarea-autosize";
import { useAuth } from "hooks";

function CommentInput({ reply, value, setValue, onClick }) {
  const authInfo = useAuth({});

  return (
    <Styles.Container>
      <TextareaAutosize
        maxRows={4}
        value={value}
        onChange={(e)=>{
          setValue(e.target.value);
        }}
        placeholder={reply ? "답글을 남겨주세요." : "댓글을 남겨주세요."}
        onFocus={()=>{
          if (authInfo.certification() === false) {
            setValue("")
            document.activeElement.blur();
          }
        }}
      />

      <button type="button" className="submitButton" onClick={onClick}>
        등록
      </button>
    </Styles.Container>
  );
}

export default CommentInput;
