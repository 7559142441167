import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: calc(50% + 75px);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 500px;
  height: 100vh;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;

  & .modalContainer {
    position: relative;
    width: 100%;
    height: auto;
    padding: 50px 70px 65px;
    background-color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    & .closeButton {
      position: absolute;
      bottom: calc(100% + 10px);
      right: 20px;
      width: fit-content;
      color: #fff;
      text-decoration: underline;
      text-underline-position: from-font;
    }

    & .textContainer {
      display: flex;
      align-items: center;
      gap: 19px;
      margin-bottom: 50px;

      & .iconWrap {
        flex-shrink: 0;
        width: 69px;
        height: 69px;
      }

      & .text {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.3;
      }
    }

    & .buttonList:not(:last-child) {
      margin-bottom: 20px;
    }

    & .confirmButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 74px;
      font-size: 24px;
      font-weight: bold;
      color: var(--main);
      background-color: #f1effc;
      border-radius: 500px;
    }

    & .cancelButton {
      display: block;
      margin: 0 auto;
      color: var(--gray03);
    }
  }

  @media (max-width: 1200px) {
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 499px) {
    width: 100%;

    & .modalContainer {
      padding: calc(100vw * (50 / 500)) calc(100vw * (70 / 500))
        calc(100vw * (65 / 500));
      border-top-left-radius: calc(100vw * (20 / 500));
      border-top-right-radius: calc(100vw * (20 / 500));

      & .closeButton {
        bottom: calc(100% + calc(100vw * (10 / 500)));
        right: calc(100vw * (20 / 500));
      }

      & .textContainer {
        gap: calc(100vw * (19 / 500));
        margin-bottom: calc(100vw * (50 / 500));

        & .iconWrap {
          width: calc(100vw * (69 / 500));
          height: calc(100vw * (69 / 500));
        }

        & .text {
          font-size: calc(100vw * (20 / 500));
        }
      }

      & .buttonList:not(:last-child) {
        margin-bottom: calc(100vw * (20 / 500));
      }

      & .confirmButton {
        height: calc(100vw * (74 / 500));
        font-size: calc(100vw * (24 / 500));
        border-radius: calc(100vw * (500 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
