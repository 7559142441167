import styled from "styled-components";

const Container = styled.div`
  margin-bottom: 300px;

  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 110px;

  & .detailSection {
    margin-bottom: 30px;
    padding: 30px 0;
    border-bottom: 20px solid var(--gray01);

    & .profileContainer {
      display: flex;
      align-items: center;
      font-weight: 500;

      & .profileImgWrap {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        overflow: hidden;
      }
    }

    & .titleContainer {
      padding-bottom: 30px;
      border-bottom: 1px solid var(--gray04);

      & .topContainer {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        & .profileContainer {
          gap: 10px;
          font-size: 16px;
        }

        & .person {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 52px;
          height: 23px;
          font-size: 14px;
          font-weight: 600;
          color: var(--main);
          background-color: var(--gray04);
          border-radius: 4px;
        }
      }

      & .moreButtonContainer {
        position: relative;
        width: 24px;
        height: 24px;
        margin-left: auto;

        & .moreListWrap {
          position: absolute;
          top: 0;
          right: 100%;
          background: #fff;
          border-radius: 6px;
          box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.12);
          overflow: hidden;

          & .moreList:not(:last-child) {
            border-bottom: 1px solid var(--gray04);
          }

          & button {
            width: 68px;
            height: 38px;
            font-size: 16px;
          }
        }
      }

      & .title {
        margin-bottom: 10px;
        font-size: 26px;
        font-weight: 600;
      }

      & .bottomContainer {
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 14px;
        font-weight: 500;
        color: var(--gray03);

        & .listWrap {
          display: flex;
          gap: 8px;

          & .list {
            display: flex;
            align-items: center;
            gap: 4px;

            & .iconWrap {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }

    & .editorContainer {
      padding: 13px 0 30px;

      & .guideText {
        margin-bottom: 20px;
        padding: 10px 0;
        font-size: 13px;
        font-weight: 500;
        color: #b7b7d2;
        text-align: center;
        background-color: var(--gray01);
        border-radius: 5px;
      }

      & .summaryContainer {
        margin-bottom: 30px;

        & .summaryTitleContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          font-weight: 500;

          & .summary,
          .linkButton {
            display: flex;
            align-items: center;
            gap: 6px;

            & .iconWrap {
              width: 24px;
              height: 24px;
            }
          }

          & .summary {
            color: var(--main);
          }

          & .linkButton {
            font-size: 16px;
            font-weight: 500;
            text-decoration: underline;
            text-underline-position: from-font;
          }
        }
      }

      & .opinionContainer {
        & .profileContainer {
          margin-bottom: 20px;
          color: var(--main);

          & .profileImgWrap {
            margin-right: 6px;
          }
        }
      }

      & .editor {
        padding: 0;
      }
    }

    & .editor {
      padding: 30px 0;
    }

    & .reactContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      & .empathyButton {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-weight: 500;
        color: var(--gray02);

        &.on {
          color: var(--main);
        }

        & .iconWrap {
          display: inline-block;
          width: 30px;
          height: 30px;
        }
      }

      & .likeButton {
        width: 30px;
        height: 30px;
      }
    }

    & .keywordListWrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (110 / 500));

    & .detailSection {
      margin-bottom: calc(100vw * (30 / 500));
      padding: calc(100vw * (30 / 500)) 0;
      border-bottom: calc(100vw * (20 / 500)) solid var(--gray01);

      & .profileContainer {
        & .profileImgWrap {
          width: calc(100vw * (38 / 500));
          height: calc(100vw * (38 / 500));
        }
      }

      & .titleContainer {
        padding-bottom: calc(100vw * (30 / 500));

        & .topContainer {
          margin-bottom: calc(100vw * (10 / 500));

          & .profileContainer {
            gap: calc(100vw * (10 / 500));
            font-size: calc(100vw * (16 / 500));
          }

          & .person {
            width: calc(100vw * (52 / 500));
            height: calc(100vw * (23 / 500));
            font-size: calc(100vw * (14 / 500));
            border-radius: calc(100vw * (4 / 500));
          }
        }

        & .moreButtonContainer {
          width: calc(100vw * (24 / 500));
          height: calc(100vw * (24 / 500));

          & .moreListWrap {
            border-radius: calc(100vw * (6 / 500));
            box-shadow: calc(100vw * (5 / 500)) calc(100vw * (5 / 500))
              calc(100vw * (30 / 500)) 0 rgba(0, 0, 0, 0.12);

            & button {
              width: calc(100vw * (68 / 500));
              height: calc(100vw * (38 / 500));
              font-size: calc(100vw * (16 / 500));
            }
          }
        }

        & .title {
          margin-bottom: calc(100vw * (10 / 500));
          font-size: calc(100vw * (26 / 500));
        }

        & .bottomContainer {
          gap: calc(100vw * (16 / 500));
          font-size: calc(100vw * (14 / 500));

          & .listWrap {
            gap: calc(100vw * (8 / 500));

            & .list {
              gap: calc(100vw * (4 / 500));

              & .iconWrap {
                width: calc(100vw * (18 / 500));
                height: calc(100vw * (18 / 500));
              }
            }
          }
        }
      }

      & .editorContainer {
        padding: calc(100vw * (13 / 500)) 0 calc(100vw * (30 / 500));

        & .guideText {
          margin-bottom: calc(100vw * (20 / 500));
          padding: calc(100vw * (10 / 500)) 0;
          font-size: calc(100vw * (13 / 500));
          border-radius: calc(100vw * (5 / 500));
        }

        & .summaryContainer {
          margin-bottom: calc(100vw * (30 / 500));

          & .summaryTitleContainer {
            margin-bottom: calc(100vw * (20 / 500));

            & .summary,
            .linkButton {
              gap: calc(100vw * (6 / 500));

              & .iconWrap {
                width: calc(100vw * (24 / 500));
                height: calc(100vw * (24 / 500));
              }
            }

            & .linkButton {
              font-size: calc(100vw * (16 / 500));
            }
          }
        }

        & .opinionContainer {
          & .profileContainer {
            margin-bottom: calc(100vw * (20 / 500));

            & .profileImgWrap {
              margin-right: calc(100vw * (6 / 500));
            }
          }
        }
      }

      & .editor {
        padding: calc(100vw * (30 / 500)) 0;
      }

      & .reactContainer {
        margin-bottom: calc(100vw * (30 / 500));

        & .empathyButton {
          gap: calc(100vw * (8 / 500));
          font-size: calc(100vw * (16 / 500));

          & .iconWrap {
            width: calc(100vw * (30 / 500));
            height: calc(100vw * (30 / 500));
          }
        }

        & .likeButton {
          width: calc(100vw * (30 / 500));
          height: calc(100vw * (30 / 500));
        }
      }

      & .keywordListWrap {
        gap: calc(100vw * (10 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
