import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 110px;

  & .pageName {
    padding: 30px 0;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
  }

  & .keywordListWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin: 20px 0 30px;
  }

  & .bannerImgWrap {
    height: 112px;
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (110 / 500));

    & .pageName {
      padding: calc(100vw * (30 / 500)) 0;
      font-size: calc(100vw * (26 / 500));
    }

    & .keywordListWrap {
      gap: calc(100vw * (10 / 500));
      margin: calc(100vw * (20 / 500)) 0 calc(100vw * (30 / 500));
    }

    & .bannerImgWrap {
      height: calc(100vw * (112 / 500));
    }
  }
`;

export { Container };

export default { Container };
