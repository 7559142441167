import utils from "utils";
import Styles from "./styles";
import { highlighting } from "utils/etc";

// img
import IconLikeOff from "resources/image/icon/icon_like_off.svg";
import IconLikeOn from "resources/image/icon/icon_like_on.svg";
import IconEye from "resources/image/icon/icon_eye.svg";
import exceptionImage from "resources/image/icon/logo_gray.svg";
import exception02Image from "resources/image/icon/logo_gray02.svg";

function MagazineList({ data, keyword, index, scrapInfo, exception }) {
  return (
    <Styles.Container>
      <div className="imgWrap">
        <img src={data.thumbnail ? data.thumbnail : exception ? exception02Image : exceptionImage} alt="magazineImg" />
      </div>

      <div className="textContainer">
        <div className="left">
          <p className="title">{highlighting(data.title, keyword)}</p>

          <p className="description">{highlighting(data.subtitle, keyword)}</p>

          <div className="dateContainer">
            <div className="date">{utils.date.getDateType(data.created_at)}</div>

            <div className="view">
              <div className="iconWrap">
                <img src={IconEye} alt="IconEye" />
              </div>
              {data.view}
            </div>
          </div>
        </div>

        <button
          type="button"
          className="likeButton"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            scrapInfo.toggleScrap(data.id, index, 3)
          }}
        >
          <img src={data.scrap_yn === "Y" ? IconLikeOn : IconLikeOff} alt="IconLike" />
        </button>
      </div>
    </Styles.Container>
  );
}

export default MagazineList;
