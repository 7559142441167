import styled from "styled-components";

// img
import IconEssential from "resources/image/icon/icon_essential.svg";
import IconLogo from "resources/image/icon/logo_gray02.svg";
import IconLogoColor from "resources/image/icon/logo_color02.svg";

const Container = styled.div`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 110px;

  & section {
    padding-top: 30px;

    & .profileImgContainer {
      margin-bottom: 40px;

      & .imgContainer {
        width: 280px;
        height: 280px;
        padding: 20px;
        margin: 0 auto 20px;
        border: 1px dashed var(--gray02);
        border-radius: 20px;

        & .imgWrap {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 181px;
          height: 181px;
          margin: 0 auto 20px;
          background-color: #f2f2f2;
          border-radius: 50%;
          overflow: hidden;

          & .iconWrap {
            width: 40px;
            height: 40px;
          }
        }

        & input {
          display: none;
        }

        & .uploadButton {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 40px;
          font-size: 16px;
          font-weight: 500;
          color: #fff;
          background-color: var(--black);
          border-radius: 20px;
          cursor: pointer;
        }
      }

      & .text {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.25;
        text-align: center;

        & span {
          color: var(--main);
        }
      }
    }

    & .listWrap {
      margin-bottom: 60px;

      & .list {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        &.date {
          display: flex;
          align-items: flex-end;
          gap: 21px;

          & .dateContainer {
            flex-grow: 1;
          }

          & .checkboxContainer {
            flex-shrink: 0;
            padding-bottom: 19px;
          }
        }

        & .label {
          position: relative;
          width: fit-content;
          margin-bottom: 10px;
          font-size: 20px;
          font-weight: 500;

          &::after {
            position: absolute;
            top: 50%;
            right: -12px;
            transform: translateY(-50%);
            width: 8px;
            height: 8px;
            background: url(${IconEssential}) no-repeat center / cover;
            content: "";
          }
        }

        & .genderListWrap,
        & .meetingListWrap {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;

          & li {
            width: calc(calc(100% - 10px) / 2);

            & label {
              background: url(${IconLogo}) no-repeat right center / 61px 59px
                var(--gray01);
            }

            & input:checked + label {
              background-image: url(${IconLogoColor});
            }
          }
        }
      }
    }

    & .buttonContainer {
      position: relative;

      & .errorTextContainer {
        position: absolute;
        top: -29px;
        left: 50%;
        transform: translateX(-50%);
      }

      & .confirmButton {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 499px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (110 / 500));

    & section {
      padding-top: calc(100vw * (30 / 500));

      & .profileImgContainer {
        margin-bottom: calc(100vw * (40 / 500));

        & .imgContainer {
          width: calc(100vw * (280 / 500));
          height: calc(100vw * (280 / 500));
          padding: calc(100vw * (20 / 500));
          margin: 0 auto calc(100vw * (20 / 500));
          border-radius: calc(100vw * (20 / 500));

          & .imgWrap {
            width: calc(100vw * (181 / 500));
            height: calc(100vw * (181 / 500));
            margin: 0 auto calc(100vw * (20 / 500));

            & .iconWrap {
              width: calc(100vw * (40 / 500));
              height: calc(100vw * (40 / 500));
            }
          }

          & .uploadButton {
            height: calc(100vw * (40 / 500));
            font-size: calc(100vw * (16 / 500));
            border-radius: calc(100vw * (20 / 500));
          }
        }

        & .text {
          font-size: calc(100vw * (16 / 500));
        }
      }

      & .listWrap {
        margin-bottom: calc(100vw * (60 / 500));

        & .list {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (20 / 500));
          }

          &.date {
            gap: calc(100vw * (21 / 500));

            & .checkboxContainer {
              padding-bottom: calc(100vw * (19 / 500));
            }
          }

          & .label {
            margin-bottom: calc(100vw * (10 / 500));
            font-size: calc(100vw * (20 / 500));

            &::after {
              right: calc(100vw * (-12 / 500));
              width: calc(100vw * (8 / 500));
              height: calc(100vw * (8 / 500));
            }
          }

          & .genderListWrap,
          & .meetingListWrap {
            gap: calc(100vw * (10 / 500));

            & li {
              width: calc(calc(100% - calc(100vw * (10 / 500))) / 2);

              & label {
                background: url(${IconLogo}) no-repeat right center /
                  calc(100vw * (61 / 500)) calc(100vw * (59 / 500))
                  var(--gray01);
              }
            }
          }
        }
      }

      & .buttonContainer {
        & .errorTextContainer {
          top: calc(100vw * (-29 / 500));
        }

        & .confirmButton {
          margin-bottom: calc(100vw * (20 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
