import { useQuery } from "react-query";
import { api } from "service";

export default function useEvent({
  listOption,
  detailOption,
}) {
  
  const listQuery = useQuery(["event",listOption], 
    ()=>{
      return api.event.getList(listOption);
    }, {
    enabled:!!listOption,
    refetchOnWindowFocus: false,
  });

  const detailQuery = useQuery(["eventDetail",detailOption], 
    ()=>{
      return api.event.getDetail(detailOption);
    }, {
    enabled:!!detailOption,
    refetchOnWindowFocus: false,
  });

  return {
    listQuery,
    detailQuery,
  };
}
