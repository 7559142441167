import Styles from "./styles";
import { useState } from "react";
import { useSearch } from "hooks";
import { useNavigate } from "react-router-dom";
import { common, ui, layout } from "components";

// img
import ImgBanner from "resources/image/img_search_banner.png";

function SearchPage() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const searchInfo = useSearch({ 
    isKeyword: true,
    keywordOption: {
      keyword: search
    }
  });
  const list = (searchInfo.keywordQuery.data?.data || []);
  const searchKeywordList = (searchInfo.searchKeywordQuery.data?.data || []);

  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.Header />

        <Styles.Container>
          <h2 className="pageName">검색</h2>

          <section>
            <div className="inner">
              <layout.SearchContainer 
                search={search}
                setSearch={setSearch}
                relateList={searchKeywordList}
              />
              <ul className="keywordListWrap">
                {list?.map((item, index) => {
                  return (
                    <li className="keywordList" key={"keywordList" + index}>
                      <ui.button.Keyword
                        text={item.title}
                        onClick={() => {
                          navigate(`/search/detail?keyword=${item.title}`);
                        }}
                      />
                    </li>
                  );
                })}
              </ul>

              <div className="bannerImgWrap">
                <img src={ImgBanner} alt="ImgBanner" />
              </div>
            </div>
          </section>
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default SearchPage;
