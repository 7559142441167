import ui from "components/ui";
import Styles from "./styles";
import layout from "components/layout";
import { useUpload } from "hooks";
import { useState, useEffect } from "react";

// img
import IconUpload from "resources/image/icon/icon_upload.svg";
import IconDelete from "resources/image/icon/icon_delete.svg";

function ReviewModal({ modalClose, modalOption }) {
  const uploadInfo = useUpload();

  const data = modalOption.reviewInfo.listQuery.data.data.filter(
    (v) => v.write_yn === "Y"
  )[0];

  const [check, setCheck] = useState(null);
  const [textarea, setTextarea] = useState("");
  const [files, setFiles] = useState([]);
  const [isDisabled, setIsDisable] = useState(true);
  const [isBlocked, setIsBlocked] = useState(false);

  const handleUploadFiles = async (e) => {
    setIsBlocked(true);
    let fileData = [];
    for (let i = 0; i < e.target.files.length && i < 3 - files.length; i++) {
      fileData.push(await uploadInfo.uploadImage(e.target.files[i]));
    }
    setFiles((e) => {
      return [...e, ...fileData.map(v => v.data.image)];
    });
    setIsBlocked(fileData && false);
  };
  const handleDeleteFile = (index) => {
    setFiles(files.filter((e, idx) => idx !== index));
  };

  useEffect(() => {
    if (files[0] !== undefined && files[0] === "") {
      setFiles([]);
    }
  }, [files]);

  useEffect(() => {
    setIsDisable(
      !check ||
      textarea === "" ||
      isBlocked
    );
  }, [check, textarea, isBlocked]);


  useEffect(() => {
    if (data && data) {
      setCheck(data.score);
      setTextarea(data.description);
      setFiles(data.images?.split(","));
    }
  }, [data]);

  return (
    <Styles.Container>
      <div className="modalContainer">
        <form>
          <p className="title">수발러 경험 작성</p>

          <p className="smallText">
            다른 수발러들을 위해 여러분의 솔직한 경험담을 나눠주세요.
          </p>

          <p className="text">
            개인이 느낀 긍정적인 경험의 정도를 <br />
            온도로 표현해주세요!
          </p>

          <layout.GuageContainer
            check={check}
            setCheck={setCheck}
            name={"modalTemperature"}
            id={"modal"}
          />

          <ul className="inputListWrap">
            <li className="inputList">
              <label className="label">수발러 의견</label>
              <div className="textareaContainer">
                <textarea
                  placeholder="의견을 입력하세요."
                  value={textarea}
                  onChange={(e) => {
                    setTextarea(e.target.value);
                  }}
                ></textarea>
              </div>
            </li>

            <li className="inputList">
              <p className="label">이미지 첨부</p>
              <input
                type="file"
                id="imgUpload"
                name="imgUpload"
                accept=".png, .jpeg, .jpg"
                multiple
                onChange={(e) => {
                  handleUploadFiles(e);
                }}
              />
              <label className="imgUpload" htmlFor="imgUpload">
                <span className="iconWrap">
                  <img src={IconUpload} alt="IconUpload" />
                </span>
                파일 등록하기
              </label>

              {files?.length > 0 && (
                <ul className="fileListWrap">
                  {files?.map((item, index) => {
                    return (
                      <li className="fileList" key={"fileList" + index}>
                        <div className="img">
                          <img src={item} alt="fileImg" />
                        </div>

                        <button
                          type="button"
                          className="deleteButton"
                          onClick={() => {
                            handleDeleteFile(index);
                          }}
                        >
                          <img src={IconDelete} alt="IconDelete" />
                        </button>
                      </li>
                    );
                  })}
                </ul>
              )}

            </li>
          </ul>
        </form>

        <ul className="buttonListWrap">
          <li className="buttonList confirmButton">
            <ui.button.BasicButton
              disabled={isDisabled}
              buttonText={isBlocked ? "이미지 처리중" : "경험 작성하기"}
              onClick={() => {
                modalOption.reviewInfo[
                  modalOption.edit ? "updateReview" : "registReview"](
                  {
                    description: textarea,
                    score: check,
                    images: files,
                  },
                  modalClose
                );
              }}
            />
          </li>

          <li className="buttonList cancelButton">
            <ui.button.BasicButton
              buttonText={"닫기"}
              buttonType={"white"}
              onClick={modalClose}
            />
          </li>
        </ul>
      </div>
    </Styles.Container>
  );
}

export default ReviewModal;
