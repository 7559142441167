import Head from "./Head";
import Header from "./Header";
import SubHeader from "./SubHeader";
import Footer from "./Footer";
import NavigationBar from "./NavigationBar";
import FixedBg from "./FixedBg";
import QuickMenu from "./QuickMenu";
import CustomCkEditor from "./CustomCkEditor";

export { Head, Header, SubHeader, Footer, NavigationBar, FixedBg, QuickMenu,CustomCkEditor };

export default { Head, Header, SubHeader, Footer, NavigationBar, FixedBg, QuickMenu, CustomCkEditor };
