import styled from "styled-components";

const Input = styled.input`
  display: none;

  &:checked + label {
    color: var(--main);
    border: 1px solid var(--main);

    & .iconWrap {
      filter: invert(18%) sepia(89%) saturate(5816%) hue-rotate(256deg)
        brightness(85%) contrast(89%);
    }
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 25px;
  color: var(--gray03);
  background-color: var(--gray01);
  border-radius: 6px;
  cursor: pointer;

  & .iconWrap {
    display: inline-block;
    height: auto;
    filter: invert(93%) sepia(9%) saturate(177%) hue-rotate(201deg)
      brightness(91%) contrast(90%);
  }

  @media (max-width: 499px) {
    height: calc(100vw * (60 / 500));
    padding: 0 calc(100vw * (25 / 500));
    border-radius: calc(100vw * (6 / 500));
  }
`;

export { Input, Label };

export default { Input, Label };
