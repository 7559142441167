import { atom } from "jotai";

const modalFlagAtom = atom(false);
const defaultModalAtom = atom({ show: false, zIndex: 20 });
const reviewModalAtom = atom({ show: false, zIndex: 20 });
const deleteModalAtom = atom({ show: false, zIndex: 20 });
const reportModalAtom = atom({ show: false, zIndex: 20 });
const webViewModalAtom = atom({ show: false, zIndex: 20 });

export {
  modalFlagAtom,
  defaultModalAtom,
  reviewModalAtom,
  deleteModalAtom,
  reportModalAtom,
  webViewModalAtom,
};

export default {
  modalFlagAtom,
  defaultModalAtom,
  reviewModalAtom,
  deleteModalAtom,
  reportModalAtom,
  webViewModalAtom,
};
