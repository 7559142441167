import connector from "service/connector"

const getBusiness = async () => {
  return await connector.connectFetchController("business", "GET");
}

export {
  getBusiness,
};

export default {
  getBusiness,
}