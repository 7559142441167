import { api } from "service";
import { useQuery } from "react-query";


export default function useSearch({listOption, isKeyword, keywordOption}) {
  const listQuery = useQuery(["search_list", listOption], () => {
    return api.search.getList(listOption);
  }, { 
    refetchOnWindowFocus: true,
    enabled: !!listOption
  });

  const keywordQuery = useQuery(["best_keyword_list", isKeyword], () => {
    return api.search.getKeywordList();
  }, { 
    refetchOnWindowFocus: false,
    enabled: !!isKeyword
  });

  const searchKeywordQuery = useQuery(["search_keyword_list", keywordOption], () => {
    return api.search.getSearchKeywordList(keywordOption);
  }, { 
    refetchOnWindowFocus: false,
    enabled: !!keywordOption
  });

  return {
    listQuery,
    keywordQuery,
    searchKeywordQuery,
  }
}