import useModals from "hooks/useModals";
import { api } from "service";
import { useNavigate } from "react-router-dom";

export default function useInquiry() {
  const modalOption = useModals();
  const navigate = useNavigate();

  const postInquiry = async (data) => {
    const result = await api.inquiry.postInquiry(JSON.stringify(data));
    if (result.success) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.title = "의견 주셔서 감사합니다 :)";
        e.text = (
          <>
            이메일을 남기신 분에게는 <br />
            결과를 전달해드리겠습니다.
          </>
        );
        e.confirmButtonText = "확인";
        e.confirmButtonClick = () => {
          // navigate("/");
          window.history.back();
        };
        e.cancelButtonText = false;
        return { ...e };
      });
    }
  };
  const cancelInquiry = () => {
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.title = (
        <>
          작성 중인 내용이 있습니다. <br />
          나가시겠습니까?
        </>
      );
      e.text = "작성 중인 데이터는 저장되지 않습니다.";
      e.confirmButtonText = "페이지 나가기";
      e.confirmButtonClick = () => {
        navigate(-1);
      };
      e.cancelButtonText = "취소";
      e.cancelButtonClick = false;
      return { ...e };
    });
  }

  return {
    postInquiry,
    cancelInquiry
  };
}
