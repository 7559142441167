import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  border: 3px solid var(--main);
  border-radius: 500px;

  & input {
    flex-grow: 1;
    max-width: calc(100% - 91px);
    padding: 0 40px;
    font-weight: 500;

    &::placeholder {
      color: var(--gray02);
    }
  }

  & .searchButton {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 91px;
    height: 100%;
    background-color: var(--main);
    border-radius: 26px;

    & .iconWrap {
      display: inline-block;
      width: 33px;
      height: 33px;
    }
  }

  & .relationListWrap {
    position: absolute;
    top: calc(100% + 5px);
    left: 20px;
    width: 370px;
    max-height: 196px;
    padding: 9px 0;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 4px 10px 15px 0px rgba(0, 0, 0, 0.25);
    overflow: auto;

    & .relationList {
      padding: 10px 20px;
      font-weight: 500;
      cursor: pointer;

      & span {
        color: var(--main);
      }
    }
  }

  @media (max-width: 499px) {
    height: calc(100vw * (60 / 500));
    border: calc(100vw * (3 / 500)) solid var(--main);
    border-radius: calc(100vw * (500 / 500));

    & input {
      max-width: calc(100% - calc(100vw * (91 / 500)));
      padding: 0 calc(100vw * (40 / 500));
    }

    & .searchButton {
      width: calc(100vw * (91 / 500));
      border-radius: calc(100vw * (26 / 500));

      & .iconWrap {
        width: calc(100vw * (33 / 500));
        height: calc(100vw * (33 / 500));
      }
    }

    & .relationListWrap {
      top: calc(100% + calc(100vw * (5 / 500)));
      left: calc(100vw * (20 / 500));
      width: calc(100vw * (370 / 500));
      max-height: calc(100vw * (196 / 500));
      padding: calc(100vw * (9 / 500)) 0;
      border-radius: calc(100vw * (6 / 500));
      box-shadow: calc(100vw * (4 / 500)) calc(100vw * (10 / 500))
        calc(100vw * (15 / 500)) 0 rgba(0, 0, 0, 0.25);

      & .relationList {
        padding: calc(100vw * (10 / 500)) calc(100vw * (20 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
