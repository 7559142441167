import styled from "styled-components";

//img
import IconArrowCurved from "resources/image/icon/icon_arrow_curved.svg";

const Container = styled.div`
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--gray04);

  &.reply {
    padding-left: 45px;

    &::before {
      position: absolute;
      top: 0;
      left: 18px;
      width: 16px;
      height: 23px;
      background: url(${IconArrowCurved}) no-repeat center / cover;
      content: "";
    }

    & .profileContainer .userName {
      max-width: 282px;
    }
  }

  & .moreButtonContainer {
    position: absolute;
    top: 6px;
    right: 0;
    width: 24px;
    height: 24px;

    & .moreListWrap {
      position: absolute;
      top: 0;
      right: 100%;
      background: #fff;
      border-radius: 6px;
      box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.12);
      overflow: hidden;

      & .moreList:not(:last-child) {
        border-bottom: 1px solid var(--gray04);
      }

      & button {
        width: 68px;
        height: 38px;
        font-size: 16px;
      }
    }
  }

  & .profileContainer {
    display: flex;
    align-items: center;

    & .profileImgWrap {
      width: 37px;
      height: 37px;
      margin-right: 8px;
      border-radius: 50%;
      overflow: hidden;
    }

    & .userName {
      max-width: 324px;
      font-size: 18px;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & .writer {
      margin-left: 4px;
      padding: 4px 9px;
      font-size: 14px;
      font-weight: 500;
      color: var(--main);
      background-color: var(--gray04);
      border-radius: 50px;
    }
  }

  & .comment {
    margin: 10px 0 20px;
    padding: 0 24px 0 45px;
  }

  & .bottomContainer {
    display: flex;
    gap: 16px;
    padding-left: 45px;

    & .date,
    .replyButton {
      font-size: 16px;
      color: var(--gray03);
    }
  }

  & .editCommentContainer {
    margin-top: 16px;
  }

  @media (max-width: 499px) {
    margin-bottom: calc(100vw * (20 / 500));
    padding-bottom: calc(100vw * (20 / 500));

    &.reply {
      padding-left: calc(100vw * (45 / 500));

      &::before {
        left: calc(100vw * (18 / 500));
        width: calc(100vw * (16 / 500));
        height: calc(100vw * (23 / 500));
      }

      & .profileContainer .userName {
        max-width: calc(100vw * (282 / 500));
      }
    }

    & .moreButtonContainer {
      top: calc(100vw * (6 / 500));
      width: calc(100vw * (24 / 500));
      height: calc(100vw * (24 / 500));

      & .moreListWrap {
        border-radius: calc(100vw * (6 / 500));
        box-shadow: calc(100vw * (5 / 500)) calc(100vw * (5 / 500))
          calc(100vw * (30 / 500)) 0 rgba(0, 0, 0, 0.12);

        & button {
          width: calc(100vw * (68 / 500));
          height: calc(100vw * (38 / 500));
          font-size: calc(100vw * (16 / 500));
        }
      }
    }

    & .profileContainer {
      & .profileImgWrap {
        width: calc(100vw * (37 / 500));
        height: calc(100vw * (37 / 500));
        margin-right: calc(100vw * (8 / 500));
      }

      & .userName {
        max-width: calc(100vw * (324 / 500));
        font-size: calc(100vw * (18 / 500));
      }

      & .writer {
        margin-left: calc(100vw * (4 / 500));
        padding: calc(100vw * (4 / 500)) calc(100vw * (9 / 500));
        font-size: calc(100vw * (14 / 500));
        border-radius: calc(100vw * (50 / 500));
      }
    }

    & .comment {
      margin: calc(100vw * (10 / 500)) 0 calc(100vw * (20 / 500));
      padding: 0 calc(100vw * (24 / 500)) 0 calc(100vw * (45 / 500));
    }

    & .bottomContainer {
      gap: calc(100vw * (16 / 500));
      padding-left: calc(100vw * (45 / 500));

      & .date,
      .replyButton {
        font-size: calc(100vw * (16 / 500));
      }
    }

    & .editCommentContainer {
      margin-top: calc(100vw * (16 / 500));
    }
  }
`;

export { Container };

export default { Container };
