import { useAtom } from "jotai";
import { useQuery } from "react-query";
import { api } from "service";
import { modalAtom } from "store/atoms";

export default function useCategory({
  listOption
}) {

  
  const listQuery = useQuery(["category",listOption], 
    ()=>{
      return api.category.getList(listOption);
    }, {
    enabled:!!listOption,
    refetchOnWindowFocus: false,
  });

  return {
    listQuery,
    list : listQuery.data?.data ? listQuery.data?.data : [] 
  };
}
