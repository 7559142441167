import useModals from "hooks/useModals";
import { api } from "service";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

export default function usePet({
  isList,
}) {
  const modalOption = useModals();
  const navigate = useNavigate();
  
  const listQuery = useQuery(["user_pet"], 
    ()=>{
      return api.pet.getList();
    }, {
    enabled:!!isList,
    refetchOnWindowFocus: false,
  });

  const registInfo = async (petInfo) => {
    petInfo.character = petInfo.character?.join(",");
    petInfo.concern = petInfo.concern?.join(",");
    petInfo.extra_body = petInfo.extra_body?.join(",");
    petInfo.place = petInfo.place?.join(",");

    const result = await api.pet.registInfo(JSON.stringify(petInfo));
    if (result.success) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.title = "프로필이 등록되었습니다.";
        e.text = false;
        e.confirmButtonText = "확인";
        e.confirmButtonClick = () => {
          sessionStorage.removeItem("petInfo")
          navigate("/mypage");
        };
        e.cancelButtonText = false;
        return { ...e };
      });
    }
  }
  const deleteInfo = (id, setIsMoreOpen) => {
    setIsMoreOpen(false);
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.title = "프로필을 삭제하시겠습니까?";
      e.text = (
        <>
          프로필을 삭제하지 않고도 <br />
          최대 10가지 버전을 만들 수 있어요!
        </>
      );
      e.confirmButtonText = "삭제하기";
      e.confirmButtonClick = async () => {
        const result = await api.pet.deleteInfo(id);
        if (result.success) {
          modalOption.setDefaultModal((e) => {
            e.show = true;
            e.title = "프로필이 삭제되었습니다.";
            e.text = false;
            e.confirmButtonText = "확인";
            e.confirmButtonClick = () => { navigate(0) };
            e.cancelButtonText = false;
            return { ...e };
          });
        }
      };
      e.cancelButtonText = "취소";
      e.cancelButtonClick = false;
      return { ...e };
    });
  }
  const updateInfo = async (petInfo) => {
    petInfo.character = petInfo.character?.join(",");
    petInfo.concern = petInfo.concern?.join(",");
    petInfo.extra_body = petInfo.extra_body?.join(",");
    petInfo.place = petInfo.place?.join(",");

    const result = await api.pet.updateInfo(JSON.stringify(petInfo));
    if (result.success) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.title = "프로필이 업데이트되었습니다.";
        e.text = false;
        e.confirmButtonText = "확인";
        e.confirmButtonClick = () => {
          sessionStorage.removeItem("petInfo")
          navigate("/mypage");
        };
        e.cancelButtonText = false;
        return { ...e };
      });
    }
  }

  
  return {
    listQuery,
    registInfo,
    deleteInfo,
    updateInfo
  }
}