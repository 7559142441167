import styled from "styled-components";

// img
import ImgLogin from "resources/image/img_login.png";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  height: 100vh;
  height: 100dvh;
  padding-top: 20vh;
  padding-top: 20dvh;
  color: #fff;
  background: url(${ImgLogin}) no-repeat center / cover;

  & .prevButton {
    position: absolute;
    top: 23px;
    left: 23px;
    width: 24px;
    height: 24px;
  }

  & .title {
    margin-bottom: 14px;
    font-size: 24px;
    font-weight: 500;
  }

  & .logoWrap {
    width: 373px;
    height: 60px;
    margin-bottom: 93px;
  }

  & .listWrap {
    width: 358px;
    margin-bottom: 40px;

    & .list {
      &:not(:last-child) {
        margin-bottom: 16px;
      }

      & button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 64px;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        text-transform: capitalize;
        border-radius: 60px;

        &.google {
          color: #101010;
          background-color: #fff;
        }

        &.apple {
          background-color: #101010;
        }

        &.kakao {
          color: #101010;
          background-color: #fee500;
        }

        &.naver {
          background-color: #0ccc61;
        }

        & .iconWrap {
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  & .benefit {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
  }

  & .benefitText {
    font-size: 14px;
    line-height: 1.42;
    text-align: center;
  }

  @media (max-width: 499px) {
    width: 100%;

    & .prevButton {
      top: calc(100vw * (23 / 500));
      left: calc(100vw * (23 / 500));
      width: calc(100vw * (24 / 500));
      height: calc(100vw * (24 / 500));
    }

    & .title {
      margin-bottom: calc(100vw * (14 / 500));
      font-size: calc(100vw * (24 / 500));
    }

    & .logoWrap {
      width: calc(100vw * (373 / 500));
      height: calc(100vw * (60 / 500));
      margin-bottom: calc(100vw * (93 / 500));
    }

    & .listWrap {
      width: calc(100vw * (358 / 500));
      margin-bottom: calc(100vw * (40 / 500));

      & .list {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (16 / 500));
        }

        & button {
          height: calc(100vw * (64 / 500));
          font-size: calc(100vw * (16 / 500));
          border-radius: calc(100vw * (60 / 500));

          & .iconWrap {
            left: calc(100vw * (20 / 500));
            width: calc(100vw * (24 / 500));
            height: calc(100vw * (24 / 500));
          }
        }
      }
    }

    & .benefit {
      margin-bottom: calc(100vw * (10 / 500));
      font-size: calc(100vw * (14 / 500));
    }

    & .benefitText {
      font-size: calc(100vw * (14 / 500));
    }
  }
`;

export { Container };

export default { Container };
