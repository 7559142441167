import { api } from "service";
import { useAuth } from "hooks";
import { useMutation, useQueryClient } from "react-query";

export default function useScrap({ queryName, callFilter }) {
  const authInfo = useAuth({});
  const queryClient = useQueryClient();

  const toggleScrap = async (relateInfoId, index, type) => {
    if (authInfo.certification()) {
      doScrap({
        relate_info_id: relateInfoId,
        index: index,
        type: type
      });
    }
  }

  const { mutate: doScrap } = useMutation(
    (e) => { return api.scrap.scrabToggle(JSON.stringify(e)) },
    {
      async onMutate(variables) {
        queryClient.setQueryData([queryName, callFilter ? callFilter : variables.relate_info_id],
          e => {
            if (e.data) {
              if (e.data.length > 0) {
                const scrap = e.data.filter(v => { return v.type == variables.type })[0].list[variables.index]
                scrap.scrap_yn = scrap.scrap_yn === "Y" ? "N" : "Y"
              } else {
                e.data.scrap_yn = e.data.scrap_yn === "Y" ? "N" : "Y"
              }
            } else {
              e.list[variables.index].scrap_yn = e.list[variables.index].scrap_yn === "Y" ? "N" : "Y"
            }
            return { ...e };
          }
        )
      },
      onError(error, variables, context) {
        context && context();
      },
    }
  );
  
  return {
    toggleScrap
  }
}