import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;

  & .iconArrow {
    width: 44px;
    height: 44px;
  }

  & .paginationList {
    display: flex;
    gap: 10px;
  }

  & .paginationList button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    font-weight: bold;
    background-color: var(--gray01);
    border-radius: 2px;
  }

  & .paginationList button.on {
    color: #fff;
    background-color: var(--main);
  }

  @media (max-width: 499px) {
    gap: calc(100vw * (10 / 500));

    & .iconArrow {
      width: calc(100vw * (44 / 500));
      height: calc(100vw * (44 / 500));
    }

    & .paginationList {
      gap: calc(100vw * (10 / 500));
    }

    & .paginationList button {
      width: calc(100vw * (44 / 500));
      height: calc(100vw * (44 / 500));
      border-radius: calc(100vw * (2 / 500));
    }
  }
`;

export { Container };

export default { Container };
