import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: calc(50% + 75px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 100vh;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;

  & .modalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 430px;
    padding: 65px 0;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;

    & .iconWrap {
      width: 70px;
      height: 70px;
      margin-bottom: 40px;
    }

    & .title {
      font-size: 26px;
      font-weight: bold;
    }

    & .text {
      margin-top: 16px;
      font-size: 20px;
      line-height: 1.4;
    }

    & .color {
      color: var(--main);
    }

    & .buttonListWrap {
      width: 320px;
      margin-top: 51px;

      & .buttonList:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  @media (max-width: 1200px) {
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 499px) {
    width: 100%;

    & .modalContainer {
      width: calc(100vw * (430 / 500));
      padding: calc(100vw * (65 / 500)) 0;
      border-radius: calc(100vw * (10 / 500));

      & .iconWrap {
        width: calc(100vw * (70 / 500));
        height: calc(100vw * (70 / 500));
        margin-bottom: calc(100vw * (40 / 500));
      }

      & .title {
        font-size: calc(100vw * (26 / 500));
      }

      & .text {
        margin-top: calc(100vw * (16 / 500));
        font-size: calc(100vw * (20 / 500));
      }

      & .buttonListWrap {
        width: calc(100vw * (320 / 500));
        margin-top: calc(100vw * (51 / 500));

        & .buttonList:not(:last-child) {
          margin-bottom: calc(100vw * (16 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
