import utils from "utils";
import Styles from "./styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function CategoryContainer({ data }) {
  const navigate = useNavigate();
  const [check, setCheck] = useState(utils.etc.getSearchParam().category_info_id);

  return (
    <Styles.Container className="inner">
      <ul className="listWrap">

        {data?.map((item, index) => {
          return (
            <li className="list" key={"list" + index}>
              <input
                type="radio"
                name="category"
                id={item.id}
                checked={check == item.id}
                onChange={(e) => {
                  setCheck(e.target.id);
                  navigate(utils.etc.getQueryParamUrl({ category_info_id : item.id, parent_info_id : utils.etc.getSearchParam().parent_info_id },true))
                }}
              />
              <label htmlFor={item.id}>{index == 0 ? "ALL" : item.title}</label>
            </li>
          );
        })}
      </ul>
    </Styles.Container>
  );
}

export default CategoryContainer;
