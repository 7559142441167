import utils from "utils";
import Styles from "./styles";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// img
import IconSearch from "resources/image/icon/icon_search.svg";

function SearchContainer({ main, search, setSearch, relateList }) {
  const navigate = useNavigate();

  const [relationOpen, setRelationOpen] = useState(false);

  useEffect(() => {
    if (!main && search !== "" && relateList.length > 0 && !relationOpen) {
      setRelationOpen(true);
    }
    if (search === "") {
      setRelationOpen(false);
    }
  }, [search]);
  
  return (
    <Styles.Container>
      <input
        type="text"
        value={search}
        placeholder="검색어를 입력해주세요."
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        onKeyUp={e=>{
          if(e.key == "Enter"){
            window.location.pathname.includes("search/detail") == false
              ? navigate('/search/detail?' + utils.etc.getQueryParamUrl({ keyword: encodeURI(search) }, false, true))
              : navigate(utils.etc.getQueryParamUrl({ keyword: encodeURI(search) }))
          }
        }}
      />

      <button
        type="button"
        className="searchButton"
        onClick={() => {
          window.location.pathname.includes("search/detail") == false
            ? navigate('/search/detail?' + utils.etc.getQueryParamUrl({ keyword: encodeURI(search) }, false, true))
            : navigate(utils.etc.getQueryParamUrl({ keyword: encodeURI(search) })) 
        }}
      >
        <span className="iconWrap">
          <img src={IconSearch} alt="IconSearch" />
        </span>
      </button>

      {relationOpen && (
        <ul className="relationListWrap">
          {relateList?.map((item, index) => {
            return (
              <li
                className="relationList"
                key={"relationList" + index}
                onClick={() => {
                  setSearch(item.title);
                }}
              >
                {
                  utils.etc.highlighting(item.title,search.replace(/ /gi,''))
                }
              </li>
            );
          })}
        </ul>
      )}
    </Styles.Container>
  );
}

export default SearchContainer;
